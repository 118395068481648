import { useEffect, useState } from 'react';

import getViewLoading from './ViewParts/LoadingPublicationLiveStock';
import getSellerContainer from './ViewParts/Main/SellerContainer';
import Title from './ViewParts/Main/TitleLiveStock';
import getBatchSwitch from './ViewParts/Switch/BatchSwitch';
import getRevisionSwitch from './ViewParts/Switch/RevisionSwitch';
import getLocationSwitch from './ViewParts/Switch/LocationSwitch';
import { getPublication, getPublicationDetail } from '../../api/private/client';
import { getPublicationDetailPublic, getPublicationPublic } from '../../api/public/client';
import Container from '../../components/container/Container';
import Line from '../../components/line/Line';
import MultipleSwitch from '../../components/multipleSwitch/MultipleSwitch';
import useWindowSize from '../../hooks/useWindows';
import Traslate from '../../utils/translation/User.json';
import './PublicationLiveStock.css';
import getDatosBancariosSwitch from './ViewParts/Switch/DatosBancariosSwitch';
import { useAuthContext } from "../../context/AuthenticatedContext";
import { formatPrice } from '../../utils/helper';

const PublicationLiveStockPublic = ( {props} ) => {

    const match = props.match;    
    const size = useWindowSize();
    const [product, setProduct] = useState(null);
    const [detail, setDetail] = useState(null);
    const [mobil, setMobil] = useState(false);
    const [disabledBuy, setDisabledBuy] = useState(true);
    const [video, setVideo] = useState("");
    const { isCommercial } = useAuthContext();



    useEffect(() => {
        setMobil(size.width < 780);
    }, [size]);


    useEffect(() => {

        (async () => {
            const body = document.querySelector('#root');
            body.scrollIntoView({
                behavior: 'smooth'
            }, 500);
            const { data } = await getPublicationPublic(match.params.id);
            setProduct(data);
        })();               

    }, [match.params.id]);

    useEffect(() => {
        if (product) {

            (async () => {
                const { data } = await getPublicationDetailPublic({
                    idPublication: match.params.id,
                    esCompra: true,
                    subastaDirecta: product.subasta_directa
                });
                setDetail(data);
            })();            

            product.catalogue.map((element) => {
                if (!element.includes('jpg') && !element.includes('jpeg') && !element.includes('png'))
                    setVideo(element); 
            })
            
        }
        
    }, [product]);


    if (!product) return getViewLoading();

    return (
        <div className='main-container'>

            <Container className='xercana-main-container row' shadow={true}>
                {Title(product.title, product.id)}

                <div className='col-lg-8 d-flex justify-content-center mt-3'>                
                    <div className="shadow-lg p-1 bg-light rounded">
                        <video src={video}  width={'100%'} controls></video>
                        <div className="border rounded shadow col-12 p-2 w-100 text-justify mt-2">                    
                            {/* <div className='col-11 text-justify' style={{ lineHeight: "2" }}> */}
                                <p>{product.description}</p>
                            {/* </div> */}
                        </div>
                        { isCommercial() 
                        ? (<><div className="border rounded shadow col-12 pl-4 mb-5 w-100 text-justify">
                            <h1 className='col-12 font-weight-bold'>{Traslate.PUBLICATION_LIVE_STOCK.INTERNAL_DESCRIPTION}</h1>
                            <div className='col-11 text-justify' style={{ lineHeight: "2" }}>
                                <p>{product.internal_description}</p>
                            </div>
                        </div></>)
                        : (null)}
                    </div>  
                </div>
                
                <Container showBorder={!mobil} className='col publication-offer' shadow={true}>
                    <div className='row p-3'>               
                        <p className='SaleContainer-subtitle'>
                            Precio total
                        </p>                  
                        <p className='SaleContainer-price mb-3'>
                            { ( product.subasta_directa ) ? formatPrice( detail?.costoPublicacion ): formatPrice(detail?.total) }
                        </p>                

                        { ( !product.subasta_directa ) &&
                        (<>
                            <Line></Line>
                            <p className='SaleContainer-subtitle'> Detalle de costos</p>
                            {detail ? (
                            <ul className="PublicationCostsDetail-list">
                                <li>Costo de publicación: {formatPrice(detail?.costoPublicacion)}</li>
                                <li>
                                    Gastos de comercialización:                                    
                                    <span style={{ color: 'green', marginLeft: '0.5rem' }}>
                                        + {formatPrice(detail?.gastosComercializacion)}
                                    </span>
                                </li>
                                <li>Sub-Total: {formatPrice(detail?.subTotal)}</li>                    
                                <li>
                                    IVA:
                                    <span style={{ color: 'green', marginLeft: '0.5rem' }}>
                                        + {formatPrice(detail?.iva)}
                                    </span>
                                </li>
                                <li><small className='bold'>Pueden incluirse costos extra</small></li>
                            </ul>
                            ) : null}                            
                        </>)} 

                        

                        <div className='col-12 d-flex justify-content-center'>
                        <div className="card p-2">
                            <div className="card-body">
                                <strong>Para poder ofertar, comprar o hacer preguntas sobre esta publicación deberás iniciar sesión.</strong>
                            </div>
                            <a className='btn btn-success mb-2' href={ process.env.REACT_APP_GOOGLE_AUTH_URL }>
                                Ingresar
                            </a>
                        </div>
                            
                        </div>                        
                    </div>
                </Container>                    
                
                
                {mobil ? <Line className='line-edit' /> : null}
                <div className='row mb-3'>
                    {getSwitches(mobil, product, disabledBuy)}
                    {mobil ? <Line className='line-edit' /> : null}
                    {getSellerContainer(mobil, product)}
                </div>                
            </Container>
        </div>)    
  };



function getSwitches(mobil, product, disabledBuy) {
    
    return <div className='col-12 col-lg-8'> 
        
        <MultipleSwitch classNameChild='col-lg-11 col-sm-12'>
            {getBatchSwitch(mobil, product)}
            {getRevisionSwitch(mobil, product)}
            {getLocationSwitch(mobil, product)}
        </MultipleSwitch>        

    </div>;
}



export default PublicationLiveStockPublic;
