import React from 'react';
import ReactDOM from 'react-dom';
import './style/bootstrap.min.css';
import './style/xercana.framework.css';
import './assets/fontawesome-free-5.15.1-web/css/all.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
ReactDOM.render(
  //<React.StrictMode>
  <App />
  //</React.StrictMode>,
  , document.getElementById('root')
);

serviceWorkerRegistration.register();
