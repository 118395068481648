import { MapContainer, Marker, TileLayer } from "react-leaflet";

import Traslate from "../../../../utils/translation/User.json";
import ElementSwitch from "../../../../components/multipleSwitch/ElementSwitch";

export default function getLocationSwitch(mobil, product) {
  const position = [
    product?.establecimiento?.lat,
    product?.establecimiento?.lng,
  ];

  return (
    <ElementSwitch
      showBorder={!mobil}
      labelName={Traslate.PUBLICATION_LIVE_STOCK.LOCATION}
    >
      
      {!position[0] || !position[1] ? null : (
        <>
          <div className="d-flex w-100 mt-4">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="font-weight-bold">
                  Establecimiento: {product?.establecimiento?.nombre}
                </h1>
              </div>
              <div className="col-sm-6">
                <h2>
                  Provincia: {product?.establecimiento?.province?.province}
                </h2>
              </div>
              <div className="col-sm-6">
                <h2>Localidad: {product?.establecimiento?.locality?.name}</h2>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
              // width: '90%',
              height: "250px",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "50px",
            }}
          >
            <MapContainer center={position} zoom={14}>
              {/* <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              /> */}
              <TileLayer
                url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                maxZoom= {20}
                subdomains={['mt0','mt1','mt2','mt3']}
              />
              <Marker position={position} />
            </MapContainer>
          </div>
        </>
      )}
    </ElementSwitch>
  );
}
