import React from 'react'
import BottonLine from '../../../components/BottonLine';
import { RealBreadCrumb } from '../../../components/breadcrumb/Breadcrumb';
import LinkWsp from '../../../components/LinkWsp/LinkWsp';
import routers from '../../../config/routers';

import '../HelpScreen.css';

export default function SecuritySection() {    
    return (
        <div style={{minHeight:'80vh',paddingBottom:'5rem'}}>
        <h1 className="text-center w-100 font-weight-bold" style={{fontSize:'2rem',marginTop:'3rem'}}>Seguridad</h1>
        <div style={{width:'70%',margin:'0 auto',marginTop:'1rem'}}>
            <RealBreadCrumb
                    refNames={['Ayuda ','Seguridad']}
                    hrefs={[routers.help.path,  '#']}
                    />
            </div>
        <div className="helpScreenContainerDiv helpIndividual">
             <div style={{display:'flex',flexDirection:'column'}}>
                <h1 className="text-center">Seguridad del market place</h1>
                <BottonLine width="100%"/>
                <p className="p-5 mt-4 mb-1">
                En Xercana MARKET PLACE nos preocupamos por la seguridad de los usuarios, sus datos, publicaciones y transacciones realizadas, cada una de los movimientos son registrados con detalle y puestos en auditoria por los administradores del sitio, utilizamos estandares de seguridad web que garanticen la efectividad en cada transacción y el resguardo de datos de importancia.
                </p>
                <p style={{textAlign:'center'}}>
                <img src="https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/1200/public/media/image/2017/07/248945-que-es-importante-que-tu-pagina-web-cuente-certificado-ssl.png?itok=A22opbxj" alt="SSL" height="200px" width="300px"/>
                </p>
                
                <LinkWsp></LinkWsp>
            </div>
        </div>
        </div>
    )
}
