import React from "react";

import StateBadge from "../../../components/StateBadge/StateBadge";

interface StateCardProps {
  state: string;
  publicationId: number,
  publicationTitle: string
}

const StateCard: React.FC<StateCardProps> = ({ state, publicationId, publicationTitle }) => {
  return (
    <div className="card my-4 text-center">
      <div className="card-body p-2 p-lg-4">
        <h2 className="d-flex justify-content-center bold">
          #{publicationId} - {publicationTitle}
        </h2>
        <h2 className="mb-3 mt-4">
          Estado actual: <StateBadge state={state} />
        </h2>
      </div>
    </div>
  );
};

export default StateCard;
