import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import JWT from 'jsonwebtoken';

import Navbar from '../../../components/navbar/Navbar';
import './NavbarAdmin.css';

const NavbarAdmin = () => {
    const isLog = localStorage.getItem('user_type') ? true : false;

    const [nameUser, setNameUser] = useState(null);
    const [imageProfile, setImageProfile] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (isLog === false) history.push('/');
        let decoded = JWT.decode(localStorage.getItem('user_type') ? localStorage.getItem('user_type') : null);
        if (decoded) {
            const { image, name } = decoded;
            setImageProfile(image);
            setNameUser(name);
        }


    }, [imageProfile, nameUser, isLog, imageProfile, nameUser])

    return <Navbar image='xercana-brand-admin.svg' isAdminSite={true} />;
}

export default NavbarAdmin;
