// React
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// Context
import { useAuthContext } from "../../context/AuthenticatedContext";

// Components
import BottonLine from '../../components/BottonLine';
import PublicationsProvider from '../../context/PublicationsContext';
import PublicationsModal from './Modal/PublicationsModal';
import PublicationsList from './List/PublicationsList';
import Tabs from './Tabs/Tabs';
import Swal from 'sweetalert2';

// Endpoint functions
import { getUser, getPublicationUnificada } from '../../api/private/client';

// Styles
import './PublicationsPage.css';


/**
 * @brief Pagina general de las publicaciones de un usuario
 * 
 * En esta pagina un usuario podra ver todas las publicaciones
 * correspondientes a sus roles dentro del sistema. Si es usuario
 * es vendedor, podra ver un listado con sus publicaciones,
 * si es comprador, vera las publicaciones que ha comprado, si es
 * corredor, vera las publicaciones que se le han asignado para
 * calificar, etc.  
 */
function PublicationsPage() {

    const [ enabled, setEnabled ] = useState( null );
    const [ roles, setRoles ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( true );
    const [ publications, setPublications ] = useState()
    const history = useHistory();
    const newRoles = [];
    const { getRoles, isEnabled } = useAuthContext();


    const unificadaApiCall = async () => {
        const { data } = await getPublicationUnificada();
        if ( newRoles?.map( e => { return e } ).includes( "ROLE_ADMIN" ) ) {
            const newPublications = { admin: data }
            setPublications( newPublications )
            setIsLoading( false ); // remove spinner
            return;
        }
        setPublications( data );
        setIsLoading( false ); // remove spinner
    }

    

    useEffect( () => {
        
        if ( isLoading ) {
            ( async () => {
                const userRoles = getRoles();
                if ( userRoles ) {
                    // if admin get only admin
                    const admin = userRoles.map( e => { return e } ).includes( "ROLE_ADMIN" );
                    if ( admin ) {
                        newRoles.push( 'ROLE_ADMIN' );
                        setRoles( newRoles );
                        setEnabled( isEnabled() ); // Save enabled attr to verify possible actions
                        unificadaApiCall();
                        return;
                    }
                    // If user disabled, return to previous page 
                    if ( isEnabled() === false ) {
                        Swal.fire( "Tu cuenta no esta verificada!",
                            "Por favor espera a que un administrador verifique tu cuenta.",
                            "error" ).then( () => history.goBack() );
                        return;
                    }
                    // Get user roles for tab navigation
                    userRoles.forEach( ( role ) => {
                        newRoles.push( role );
                    } );

                    setRoles( newRoles ); // save roles into state
                    setEnabled( isEnabled() ); // Save enabled attr to verify possible actions
                    unificadaApiCall();
                }
            } )();
        }

    }, [] );

    return <>
        <PublicationsProvider>
            <PublicationsModal />
            <div className="container bg-white rounded py-3 my-3 shadow-lg">
                {/* Main title, may be changed */ }
                <div className="row">
                    <div className="col-12">
                        <h1 className="container__title text-center">Movimientos</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <BottonLine width='80%' margintop='mx-auto mb-4' />
                        <h3>Selecciona la pestaña a visualizar</h3>
                    </div>
                </div>
                {
                    isLoading ?
                        /* Loading spinner */
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="spinner-grow" role="status">
                                    <span className="visually-hidden" /> {/* TODO: Change */ }
                                </div>
                            </div>
                        </div>
                        :
                        /* Nav and tabs with their respective content */
                        <div className="row">
                            <div className="col-12 mx-2 mb-3">
                                <Tabs roles={ roles } key={ roles.forEach( e => { return e.toString() } ) } />
                                <div className="tab-content">
                                    <div id="publications-pane" className={ roles?.map( e => { return e } ).includes( "ROLE_ADMIN" ) ? "tab-pane" : "tab-pane" }>
                                        <h1 className="container__title col-12 text-center">
                                            Mis publicaciones
                                        </h1>
                                        <PublicationsList role={ "owner" } enabled={ enabled } publications={ publications?.publicaciones } />
                                    </div>
                                    <div id="sales-pane" className="tab-pane">
                                        <h1 className="container__title col-12 text-center">
                                            Mis ventas
                                        </h1>
                                        <PublicationsList role={ "seller" } enabled={ enabled } publications={ publications?.ventas } />
                                    </div>
                                    <div id="purchases-pane" className="tab-pane">
                                        <h1 className="container__title col-12 text-center">
                                            Mis compras
                                        </h1>
                                        <PublicationsList role={ "buyer" } enabled={ enabled } publications={ publications?.compras } />
                                    </div>
                                    <div id="broker-pane" className="tab-pane">
                                        <h1 className="container__title col-12 text-center">
                                            Publicaciones a calificar
                                        </h1>
                                        <PublicationsList role={ "broker" } enabled={ enabled } publications={ publications?.reviews } />
                                    </div>
                                    <div id="shipper-pane" className="tab-pane">
                                        <h1 className="container__title col-12 text-center">
                                            Publicaciones a transportar
                                        </h1>
                                        <PublicationsList role={ "shipper" } enabled={ enabled } publications={ publications?.transporte } />
                                    </div>
                                    <div id="admin-pane" className={ roles?.map( e => { return e } ).includes( "ROLE_ADMIN" ) ? "tab-pane active" : "tab-pane" }>
                                        <h1 className="container__title col-12 text-center">
                                            Administración
                                        </h1>
                                        <PublicationsList role={ "admin" } enabled={ enabled } publications={ publications?.admin !== undefined ? publications.admin : [] } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
            </div>
        </PublicationsProvider>
    </>
}

export default PublicationsPage