// React
import React, { useEffect, useState } from "react";

// Components
import Swal from "sweetalert2";
import Modal from "react-modal";
import Media from "./Media";
import Review from "./Review";

// Endpoint Functions
import {
  getPublication,
  postPublicationGrade,
} from "../../../../../../api/private/client";

// Others
import axios from "axios";

function QualificationModal(
  { modalIsOpen, setModalIsOpen, publicationID, history },
) {
  const [title, setTitle] = useState();
  const [honorarios, setHonorarios] = useState();
  const [catalogue, setCatalogue] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [videoFileURL, setVideoFileURL] = useState(null);
  const [review, setReview] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (modalIsOpen) {
      (async () => {
        const { data: publication } = await getPublication(publicationID);
        const newCatalogue = publication.catalogue.map((e) => {
          return { preview: e, file: null };
        });
        setTitle(publication.title);
        setCatalogue(newCatalogue);
        setIsLoading(false);
      })();
    }
  }, [modalIsOpen]);

  const verifyCamps = () => {
    if (review === null || review === "") return false;
    if (honorarios === null || honorarios === "") return false;
    return true;
  };

  const SwalAxiosWait = async (title, func) => {
    return await Swal.fire({
      title: title,
      didOpen: () => {
        Swal.clickConfirm();
      },
      preConfirm: async () => {
        Swal.showLoading();
        return await func();
      },
    }).then((res) => {
      return res;
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Verify blank camps
    if (!verifyCamps()) {
      Swal.fire(
        "Error",
        "Por favor complete los campos vacios restantes",
        "error",
      );
      return;
    }

    // Confirm operation
    const { isConfirmed } = await confirm();
    if (!isConfirmed) return;

    // Upload images
    const res = await SwalAxiosWait("Subiendo imagenes", uploadImages);
    const uploadSuccess = res.value.uploadSuccess;
    const uploadedFiles = res.value.uploadedFiles;

    // Verify video upload error
    if (uploadSuccess === false) {
      Swal.fire(
        "Error al subir las imagenes",
        "Intente de nuevo mas tarde",
        "error",
      );
      return;
    }

    // Upload video
    // const youtubeRes = videoFile &&
    //   await SwalAxiosWait("Subiendo video", youtubeUpload);
    // const youtubeURL = (youtubeRes.value.response !== undefined) &&
    //   `http://youtube.com/embed/${youtubeRes.value.data.id}`;
    // youtubeURL && uploadedFiles.push(youtubeURL);

    // // Verify video upload error
    // if ((await youtubeRes).value.response !== undefined) {
    //   Swal.fire(
    //     "Error al subir el video",
    //     "Intente de nuevo mas tarde",
    //     "error",
    //   );
    //   return;
    // }

    // send qualification
    await SwalAxiosWait(
      "Subiendo calificación",
      async () => await qualify(uploadedFiles),
    );
    return;
  };

  const confirm = () => {
    return Swal.fire({
      titleText: "Seguro que deseas guardar los cambios?",
      showCancelButton: true,
    });
  };

  const uploadImages = async () => {
    let uploadSuccess = true;
    const uploadedFiles = catalogue.map((e) => {
      return e.preview;
    });
    const toUploadFiles = newImages.map((e) => {
      return e.file;
    });
    if (toUploadFiles.length === 0) return { uploadSuccess, uploadedFiles };
    const cloudUrl = "https://api.cloudinary.com/v1_1/xercanadev/upload";
    for (let i in toUploadFiles) {
      const formData = new FormData();
      formData.append("file", toUploadFiles[i]);
      formData.append("upload_preset", "xercanita");
      const res = await fetch(cloudUrl, {
        method: "POST",
        body: formData,
      });
      if (res.ok === false) {
        uploadSuccess = false;
        break;
      }
      const res_data = await res.json();
      uploadedFiles.push(res_data.secure_url);
    }
    return { uploadSuccess, uploadedFiles };
  };

  // const youtubeUpload = async () => {
  //   const upload_content_type = "video/*";
  //   const data = {
  //     "snippet": {
  //       "title": "[Video] - " + title,
  //       "description": "Video revisión para la publicación " + title +
  //         " de Xercana",
  //     },
  //     "status": {
  //       "privacyStatus": "public",
  //       "embeddable": true,
  //       "license": "youtube",
  //     },
  //   };
  //   const headers = {
  //     "Authorization": `Bearer ${accessToken}`,
  //     "Content-Type": "application/json charset=UTF-8",
  //     "X-Upload-Content-Length": videoFile.size,
  //     "X-Upload-Content-Type": upload_content_type,
  //   };

  //   const config = {
  //     headers: headers,
  //   };
  //   return await axios.post(
  //     "https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status&key=AIzaSyAWxra9BgLnwC0gkh_rtdb_aMTehezLaNw",
  //     data,
  //     config,
  //   ).then(async (res) => {
  //     // console.log(res);
  //     const location = res.headers.location;
  //     return await axios.put(location, videoFile, {
  //       headers: {
  //         "Authorization": `Bearer ${accessToken}`,
  //         "Content-Length": videoFile.size,
  //         "Content-Type": upload_content_type,
  //       },
  //     }).then((res) => {
  //       return res;
  //     }).catch((err) => {
  //       return err;
  //     });
  //   }).catch((err) => {
  //     return err;
  //   });
  // };

  const qualify = async (media) => {
    const body = {
      honorarios: honorarios,
      review: review,
      catalogue: [...media],
    };
    await postPublicationGrade(publicationID, body).then((res) => {
      console.log(res);
      Swal.fire("Calificacion realizada con exito! ", "", "success").then(() =>
        history.go(0)
      );
    }).catch((err) => {
      console.error(err);
      Swal.fire("Hubo un error! ", "Por favor intentelo mas tarde.", "error");
      setModalIsOpen(false);
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {
        setModalIsOpen(false);
        setIsLoading(true);
      }}
      contentLabel="Calificar"
    >
      {isLoading === true
        ? (
          <div className="text-center">
            {/* TODO: Change */}
            <div className="spinner-grow" role="status">
              <span className="visually-hidden" />
            </div>
          </div>
        )
        : (
          <div className="container mx-lg-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1>
                  <b>Calificar publicacion</b>
                </h1>
              </div>
            </div>
            <form onSubmit={(e) => onSubmit(e)}>
              <Review
                review={review}
                setReview={setReview}
                honorarios={honorarios}
                setHonorarios={setHonorarios}
              />
              <div className="accordion" id="accordionReview">
                <Media
                  catalogue={newImages}
                  setCatalogue={setNewImages}
                  videoFile={videoFile}
                  setVideoFile={setVideoFile}
                  videoFileURL={videoFileURL}
                  setVideoFileURL={setVideoFileURL}
                />
              </div>
              {/* Confirm Btn */}
              <div className="row my-3 d-flex justify-content-lg-end">
                <div className="col-12 col-lg-4 d-flex justify-content-lg-end">
                  <button
                    className="btn btn-primary mx-1"
                    type="button"
                    onClick={() => setModalIsOpen(false)}
                  >
                    Cancelar
                  </button>
                  <button className="btn btn-success mx-1" type="submit">
                    Confirmar
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
    </Modal>
  );
}

export default QualificationModal;
