import { useEffect, useState } from "react";
import { useProfileContext } from "../../../../context/ProfileContext";
import { useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import Swal from 'sweetalert2';
import { postPreoffer, getPublicationOffers } from "../../../../api/private/client";


export const PreOfferForm = ({product, price}) => {
    
    const { profile } = useProfileContext();  
    const { handleSubmit, errors, register, setValue } = useForm();  
    const [ isLoading, setIsLoading ] = useState( false );
    const [ offers, setOffers ] = useState([]);

    useEffect(() => {
      (async () => {
            const { data } = await getPublicationOffers( product.id );
            setOffers( data );
        })();
    }, [])


    useEffect( () => {
        if(profile){
            setValue('cuit', profile.cuit),
            setValue('contact_mail', profile.mail_contacto),
            setValue('name', profile.name), 
            setValue('surname', profile.surname), 
            setValue('phone', profile.phone)
        }
        
    }, [ profile ] );

    const submitForm = async (dataToSend) => {
        const toSend = {
            ...dataToSend,
            amount: parseFloat(dataToSend.amount),
            cuit: parseFloat(dataToSend.cuit),
            phone: parseFloat(dataToSend.phone),
            user_id: profile.id,
            publication_id: product.id
        }

        if ( toSend.amount <= 0 ) {
            return Swal.fire(
              'Error!',
              'El monto debe ser mayor a cero',
              'error'
            );
        }

        if(product.subasta_directa && (toSend.amount <  price)){            
            return Swal.fire(
                'Atención!',
                `El monto debe ser mayor a $${price}`,
                'warning'
            );            
        }
        

        setIsLoading(true);
        const data = await postPreoffer(toSend);
        console.log(data.status)
        if (data.status === 200) {
            await Swal.fire(
                'Registro exitoso', 
                'Su oferta ha sido recibida exitosamente', 
                'success'
            ); 
            window.location.reload();
        } else {
            Swal.fire('Error', 'No se pudo registrarse su oferta. Intente nuevamente', 'error');
        }
        setIsLoading(false);
    }

    return (    
    <>  
        <div className="col">
            <button type="button" className="btn btn-block btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Ofertar
            </button>
            <small className="bold">*Su oferta se informará al área comercial y será contactado/a a la brevedad.</small><br></br><br></br>
            { offers[0]?.amount &&
            (<div className="alert alert-secondary text-center" role="alert">
                {`Mejor oferta al momento: $ ${ offers[0]?.amount}`}
            </div>)}
            

        </div>
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title fs-5 bold" id="staticBackdropLabel">Complete los datos</h2>
                        {!isLoading ? <FaCircleXmark data-bs-dismiss="modal" aria-label="Close" /> : null }
                    </div>
                    <form onSubmit={handleSubmit(submitForm)}>
                    <div className="modal-body">
                        <div className="form-row">              
                            <div className="form-group col-md-12">
                                <label className="bold" htmlFor="contact_mail">Email de contacto</label>
                                <input 
                                type="email" 
                                className="form-control" 
                                name="contact_mail"
                                ref={register({
                                    required: 'El e-mail es requerido',
                                    pattern: { value: /^\S+@\S+$/i, message: 'El e-mail no es válido'}
                                })}
                                />
                            <small className="error-text">{ errors.contact_mail && errors.contact_mail.message }</small>              
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label className="bold" htmlFor="name">Nombre</label>
                                <input 
                                className="form-control" 
                                name="name"
                                ref={register({
                                    required: 'El nombre es requerido',
                                    minLength: { value: 3, message: 'Ingresa al menos 3 carácteres' }
                                })}
                                />
                                <small className="error-text">{ errors.name && errors.name.message }</small> 
                            </div>
                            <div className="form-group col-md-6">
                                <label className="bold" htmlFor="surname">Apellido</label>
                                <input 
                                className="form-control" 
                                name="surname"
                                ref={register({
                                    required: 'El apellido es requerido',
                                    minLength: { value: 3, message: 'Ingresa al menos 3 carácteres' }
                                })}
                                />
                                <small className="error-text">{ errors.surname && errors.surname.message }</small> 
                            </div>
                        </div>      
                        <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="bold" htmlFor="phone">Teléfono</label>
                            <input 
                            className="form-control" 
                            name="phone"
                            ref={register({
                                required: 'El teléfono es requerido',
                                minLength: { value: 8, message: 'Ingresa al menos 8 carácteres' }
                            })}
                            />
                            <small className="error-text">{ errors.phone && errors.phone.message }</small> 
                        </div>
                        <div className="form-group col-md-6">
                            <label className="bold" htmlFor="cuit">CUIT (sin guiones ni puntos)</label>
                            <input 
                            className="form-control" 
                            name="cuit"
                            ref={register({
                                required: 'El CUIT es requerido',
                                minLength: { value: 11, message: 'Ingresa 11 carácteres. Sólo números' },
                                maxLength: { value: 11, message: 'Ingresa 11 carácteres. Sólo números' },
                            })}
                            />
                            <small className="error-text">{ errors.cuit && errors.cuit.message }</small> 
                        </div>
                        </div>   
                        <div className="form-row">              
                            <div className="form-group col-md-12">
                                <label className="bold red-text" htmlFor="amount">Monto a ofertar (en pesos argentinos)</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                name="amount"
                                ref={register({
                                    required: 'El monto es requerido',
                                    pattern: { value: /^[0-9]+$/i, message: 'Sólo números'}
                                })}
                                />
                            <small className="error-text">{ errors.amount && errors.amount.message }</small>              
                            </div>
                        </div>
                    </div>
                    {isLoading ? 
                        <div className='ProfilePage-spinner my-4'>
                            <div className='spinner-border' role='status' />
                        </div> 
                        : 
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="submit" className="btn btn-success">Enviar Oferta</button>
                        </div>                                        
                    }
                    


                    
                </form>
                </div>
            </div>
        </div>
    </>
  )
}
