import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { getAdminConsignee, postAdminConsignee } from "../../../api/private/client";
import BottonLine from "../../../components/BottonLine";
import AuctionPricesModal from "./AuctionPricesModal";

interface ConsigneeForm {
  id: number;
  name: string;
  cuit: number;
  mail: string;
  gastosComerVenta: number;
  gastosComerCompra: number;
  porcentajeIva: number;
  gastosVariosSinIvaCompra: number;
  gastosVariosSinIvaVenta: number;
  diasCompradasHome: number;
}

interface ConsigneeResponse {
  data: ConsigneeForm;
}

const ConsigneePage: React.FC<any> = () => {
  const [ isOpen, setOpen ] = useState( false );
  const { register, handleSubmit, reset, errors } = useForm<ConsigneeForm>();
  const [ isLoading, setIsLoading ] = useState<boolean>( false );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const { data } = await getAdminConsignee() as ConsigneeResponse;
      reset( data );
      setIsLoading( false );
    } )();
  }, [] );

  const closeModal = async () => {
    setOpen( false );
  };

  const confirmModal = async (): Promise<boolean> => {
    const { isConfirmed } = await Swal.fire( {
      title: "¿Está seguro?",
      icon: "question",
      confirmButtonColor: "green",
      confirmButtonText: "Confirmar",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "Cancelar",
    } );
    return isConfirmed;
  };

  const errorModal = () => {
    Swal.fire( {
      title: "Error",
      text: "Parámetros de consignataria editados insatisfactoriamente.",
      icon: "error",
    } );
  };

  const infoModal = () => {
    Swal.fire( {
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
    } );
  };

  const successModal = () => {
    Swal.fire( {
      title: "Confirmado",
      text: "Parámetros de consignataria editados satisfactoriamente.",
      icon: "success",
    } );
  };

  const onSubmit = async ( data: ConsigneeForm ) => {
    const isConfirmed = await confirmModal();
    if ( isConfirmed ) {
      infoModal();
      try {
        await postAdminConsignee( {
          ...data,
          id: 1,
        } );
        successModal();
      } catch ( err ) {
        errorModal();
      }
    }
  };

  return <>
    <div className="container">
      <div className="card my-5 shadow-lg">
        <div className="card-body">
          <p className="mb-4" style={ { fontSize: "22px", fontWeight: "bold", textAlign: "center" } }>
            Parámetros de consignataria
          </p>
          { isLoading ? (
            <div className="d-flex justify-content-center w-100 my-5">
              <div className="spinner-border" role="status" />
            </div>
          ) : (
            <>
              <form onSubmit={ handleSubmit( onSubmit ) }>
                <div className="mb-3">
                  <label htmlFor="id_nombre">Nombre</label>
                  <input
                    id="id_nombre"
                    className={ `form-control ${errors.name ? "is-invalid" : ""} shadow-sm` }
                    name="name"
                    placeholder="Ingrese el nombre..."
                    ref={ register( { required: true } ) }
                    type="text"
                  />
                  <div id="id_nombre" className="invalid-feedback">
                    Por favor, ingrese un nombre válido.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="id_cuit">CUIT</label>
                  <input
                    id="id_cuit"
                    className={ `form-control ${errors.cuit ? "is-invalid" : ""} shadow-sm` }
                    name="cuit"
                    placeholder="Ingrese el cuit..."
                    ref={ register( { required: true } ) }
                    type="text"
                  />
                  <div id="id_cuit" className="invalid-feedback">
                    Por favor, ingrese un cuit válido.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="id_email">Email</label>
                  <input
                    id="id_email"
                    className={ `form-control ${errors.mail ? "is-invalid" : ""} shadow-sm` }
                    name="mail"
                    placeholder="Ingrese el email..."
                    ref={ register( { required: true } ) }
                    type="email"
                  />
                  <div id="id_email" className="invalid-feedback">
                    Por favor, ingrese un email válido.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="id_gastos_comer_compra">Gastos de comercialización por compra</label>
                  <div className="input-group">
                    <span className="input-group-text">%</span>
                    <input
                      id="id_gastos_comer_compra"
                      className={ `form-control ${errors.gastosComerCompra ? "is-invalid" : ""} shadow-sm` }
                      name="gastosComerCompra"
                      placeholder="Ingrese el gasto..."
                      ref={ register( { required: true, valueAsNumber: true } ) }
                      type="text"
                    />
                    <div id="id_gastos_comer_compra" className="invalid-feedback">
                      Por favor, ingrese un gasto válido.
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="id_gastos_comer_venta">Gastos de comercialización por venta</label>
                  <div className="input-group">
                    <span className="input-group-text">%</span>
                    <input
                      id="id_gastos_comer_venta"
                      className={ `form-control ${errors.gastosComerVenta ? "is-invalid" : ""} shadow-sm` }
                      name="gastosComerVenta"
                      placeholder="Ingrese el gasto..."
                      ref={ register( { required: true, valueAsNumber: true } ) }
                      type="text"
                    />
                    <div id="id_gastos_comer_venta" className="invalid-feedback">
                      Por favor, ingrese un gasto válido.
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="id_iva">Porcentaje de IVA</label>
                  <div className="input-group">
                    <span className="input-group-text">%</span>
                    <input
                      id="id_iva"
                      className={ `form-control ${errors.porcentajeIva ? "is-invalid" : ""} shadow-sm` }
                      name="porcentajeIva"
                      placeholder="Ingrese el iva..."
                      ref={ register( { required: true, valueAsNumber: true } ) }
                      type="text"
                    />
                    <div id="id_gastos_comer_venta" className="invalid-feedback">
                      Por favor, ingrese un IVA válido.
                    </div>
                  </div>
                </div>
                <button className="btn btn-info" type="submit">
                  Guardar parámetros
                </button>
              </form>
              <BottonLine width="100%"></BottonLine>
              <BottonLine width="100%"></BottonLine>
              <h2 className="mb-4" style={ { fontSize: "22px", fontWeight: "bold", textAlign: "center" } }> Precios de Remate </h2>
              <div className="mt-3">
                <button className="btn btn-info pull-right" type="button" onClick={ () => setOpen( true ) }>
                  Cargar Imágenes
                </button>
              </div>
              <AuctionPricesModal isOpen={ isOpen }
                closeModal={ closeModal }></AuctionPricesModal>
            </>
          ) }
        </div>
      </div>
    </div>
  </>;
};

export default ConsigneePage;
