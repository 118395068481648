import React from "react";
import { useHistory } from "react-router-dom";

import LogoNavbar from "../navbar/LogoNavbar/LogoNavbar";
import routers from "../../config/routers";
import "./Footer.css";

const r = routers as any;

interface FooterProps {
  isAuctionsPages: boolean
}

const Footer: React.FC<FooterProps>= ({ isAuctionsPages = false}) => {
  const history = useHistory();

  const items = [
    {
      id: 2,
      text: "Nosotros",
      onClick: ( e: any ) => {
        e.preventDefault();
        history.push( r.join.path );
      },
      href: r.join.path,
    },
    {
      id: 3,
      text: "Ayuda",
      onClick: ( e: any ) => {
        e.preventDefault();
        history.push( r.help.path );
      },
      href: r.help.path,
    },
    {
      id: 4,
      text: "Contáctenos",
      onClick: ( e: any ) => {
        e.preventDefault();
        history.push( r.howquestion.path );
      },
      href: r.howquestion.path,
    },
  ];

  return (
    <footer className="Footer-container">
      <div className="Footer-row row">
        <div
          className="Footer-row-left col-12 col-lg-3 d-flex align-items-center justify-content-center"
        >
          <LogoNavbar image="xercana-brand-admin.svg" />
        </div>
        {
          !isAuctionsPages ?
            <div className="Footer-row-center col-12 col-lg-6">
            <ul className="Footer-list">
              { items &&
                items.map( (
                  item,
                ) => (
                  <li className="Footer-list-item" key={ item.id }>
                    <a
                      className="Footer-list-item-text"
                      onClick={ item.onClick }
                      href={ item.href }
                    >
                      { item.text }
                    </a>
                  </li>
                ) ) }
            </ul>
          </div>
        : null
        }
        <div className="col-0 col-lg-3" />
        
      </div>
      <div className="row">
        <p className="Footer-copyright">
          versión: { `${process.env.REACT_APP_VERSION}` } ©{ new Date().getFullYear() } Eganadero S.A. Todos los derechos
          reservados |
          {
            !isAuctionsPages ? 
              <a className="Footer-terms-conditions" href={ r.conditions.path }>
                Términos y condiciones
              </a>
            : null
          }          
        </p>
      </div>
    </footer>
  );
};

export default Footer;
