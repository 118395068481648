import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '../image/Image';
import Container from '../container/Container';

import './card.css';
import { IconBag, IconCountElements, IconLocation } from '../Icons/Icons';
import { now } from 'moment';

/**
 * @description: componentse de tipo Card, con imagen, titulo, subtitlo y detalles del lote
 * @param detailList: array de strings, enumera caracteristicas del lote
 * @param detail: string, caracteristica del lote
 * @param icon: icono de la caracteristica
 * @param img: String to image Link
 * @param preloader: Bool, if is true, this return a simple card loader
 * @param state: String, state of the publication (sold, publicated, qualified, etc.)
 * @param publicationId: 
 */
const Card = ({ img, title, subtitle, detailList = {}, href, preloader, redirected, state, publicationId, expiration = 0}) => {

    const expirationDate = expiration.slice(0, 10).split("-").reverse().join("-");
    
  
    const cardLoader = (
        <Container className='container-card' hover={true} shadow={false} href={href}>
            <div className='centered-div'>

                <Image source={img} className='card-img' state={state} />

                <span className="badge badge-light pl-2">Lote Nro: {publicationId}</span><br></br>
                <p className={'card-title'}>{title}</p>
                <p className={'card-subtitle'}>{subtitle.toLocaleString('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 0 })}</p>

            </div>
            <div className='row'>
                <div className='col '>
                    <p className='card-details'><IconCountElements /> Cantidad: <strong>{detailList.count}</strong></p>                    
                    { (detailList.weight > 1) ? (<p className='card-details '><IconBag height="26"/><strong >{detailList.weight}</strong> Kg.</p>) : null}
                    { detailList.province ? (<p className='card-details'><IconLocation height="14" width="16" />{detailList.locality}, {detailList.province.toUpperCase()}</p>) : null}
                    <span className="badge badge-light pl-2">Vto: {expirationDate}</span>

                </div>
            </div>
        </Container>
    );

    const cardPreLoader = (
        <Container className='container-card' hover={true} shadow={false} href={href}>
            <div className='centered-div'>

                <div className='loading img-loader' style={{ height: '8rem', width: '100%' }}></div>

                <div className='row d-flex justify-content-center'>
                    <div className='col-11 loading text-loader'></div>
                </div>

            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-10 loading text-loader'></div>
                <div className='col-10 loading text-loader'></div>
                <div className='col-10 loading text-loader'></div>
            </div>
        </Container>
    );

    return preloader ? cardPreLoader : cardLoader;
}

const detailLot = {
    detail: PropTypes.string,
    icon: PropTypes.string
};

Card.propType = {
    label: PropTypes.string,
    subtitle: PropTypes.string,
    detailList: PropTypes.arrayOf(detailLot),
    publicationId: PropTypes.number
}

Card.defaultProps = {
    title: 'Title',
    subtitle: 'subtitle',
    detailList: ['Detalle', 'Detalle', 'Detalle'],
    publicationId: 'publicationId'
}


export default Card;