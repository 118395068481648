import { useEffect, useState } from 'react';

import { getCompaniesAvailable } from '../../../api/private/client';
import Padding from '../../../components/padding/Padding';
import { ACTIONS } from '../../../hooks/profile/useProfile';
import Translate from '../../../utils/translation/User.json';
import cuitValidator from './cuitValidator';

const FiscalInformation = ( { profile, dispatch } ) => {

    const [ companies, setCompanies ] = useState( null );
    const [ company, setCompany ] = useState( null );
    const [ readOnly, setReadOnly ] = useState( false );
    const [ isValidCuit, setIsValidCuit ] = useState( null );

    useEffect( () => {
        ( async () => {
            const { data: companies } = await getCompaniesAvailable();
            setCompanies( companies );
            const company = companies.find( c => c.cuit === profile.company?.cuit );
            if ( company ) {
                setCompany( company );
                setReadOnly( true );
                setIsValidCuit( true );
            }

        } )();
    }, [] );

    const handleCuit = e => {
        dispatch( { payload: e.target.value, type: ACTIONS.SET_COMPANY_CUIT } );
        const company = companies.find( c => c.cuit === e.target.value );
        if ( company ) {
            setCompany( company );
            setReadOnly( true );
            dispatch( { payload: company.businessName, type: ACTIONS.SET_COMPANY_NAME } );
            return;
        }
        setReadOnly( false );
        if ( e.target.value.length === 11 ) {
            ( cuitValidator( e.target.value ) ) ? setIsValidCuit( true ) : setIsValidCuit( false );
        }
        ( e.target.value.length == 0 ) && setIsValidCuit( false );
    };

    const handleName = e => {
        dispatch( { payload: e.target.value, type: ACTIONS.SET_COMPANY_NAME } );
    };

    return (
        <>
            <h2 className='col-12 d-flex justify-content-center mt-4 font-weight-bold'>
                { Translate.PERSON.FISCAL_INFORMATION }
            </h2>
            <Padding>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_company_cuit'>
                        CUIT para operar (sin guiones)
                    </label>
                    <input
                        maxLength={ 11 }
                        minLength={ 11 }
                        id='id_company_cuit'
                        className={ `form-control ${ isValidCuit ? 'border-success' : 'border-danger' }` }
                        name='company.cuit'
                        placeholder='Busque o ingrese CUIT'
                        list='companiesOptions'
                        value={ profile.company?.cuit }
                        onChange={ handleCuit }
                        pattern='[0-9]{2}-?[0-9]{8}-?[0-9]'
                        readOnly={ readOnly }
                        type='text'
                    // required={ profile.cuit ? false : true }
                    />
                    { ( isValidCuit ) ? (
                        <h5 hidden={ isValidCuit == null } style={ { color: 'green' } }>CUIT Válido</h5>
                    ) : (
                        <h5 hidden={ isValidCuit == null } style={ { color: 'red' } }>CUIT Inválido</h5>
                    ) }
                    <datalist id='companiesOptions'>
                        { companies?.map( company =>
                            <option key={ company.id } value={ company.cuit }>{ company.businessName }</option>
                        ) }
                    </datalist>
                    <div className='invalid-feedback'>
                        Este campo es obligatorio.
                    </div>
                </div>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_company_name'>
                        Razon social
                    </label>
                    <input
                        id='id_company_name'
                        className={ 'form-control' }
                        name='company.name'
                        placeholder='Ingrese el nombre de su compañía'
                        value={ readOnly ? company.businessName : profile.company?.businessName }
                        onChange={ handleName }
                        readOnly={ readOnly }
                        // required={ profile.cuit ? false : true }
                        type='text'
                    />
                    <div className='invalid-feedback'>
                        Este campo es obligatorio al cargar una nueva compañía.
                    </div>
                </div>
            </Padding>
        </>
    );
};

export default FiscalInformation;
