import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import '../auctions.css';
import { getAuctionUser, postAuctionUser } from '../../../api/private/client';

export const AuctionsRegisterPage = () => {

  const { handleSubmit, errors, register, setValue } = useForm();
  const [ isLoading, setIsLoading ] = useState( false );

  useEffect( () => {

    ( async () => {
      setIsLoading( true );
      const { data } = await getAuctionUser();
      setIsLoading( false );
      
      setValue('cuit', data.cuit),
      setValue('mail_contacto', data.mail_contacto),
      setValue('nickname', data.nickname),
      setValue('name', data.name), 
      setValue('surname', data.surname), 
      setValue('phone', data.phone)
      
    } )();
    
  }, [] );

  const submitForm = async (data) => {
    setIsLoading(true);
    const filas = await postAuctionUser(data);
    if (filas.data === 1) {
        Swal.fire('Registro completo', 'Usuario registrado correctamente', 'success'); 
        //redirigir a la pagina de donde viene      
    } else {
        Swal.fire('Error', 'No se pudo completar su registro. Intente nuevamente', 'error');
    }
    setIsLoading(false);
  }

  return (
    <div className="container my-5">
      {isLoading ? (
        <div className='ProfilePage-spinner my-4'>
            <div className='spinner-border' role='status' />
        </div>
      ) :
      <div className="row justify-content-center" >
      <div className="card shadow w-75" style={{ marginBottom: '65px'}}>
        <h2 className="card-header bold">Completar Registro</h2>
        <div className="card-body">
          <p className="card-text">Para poder preofertar, primero debe registrarse completando el siguiente formulario. 
            Todos los campos son obligatorios.
          </p>
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="form-row">              
              <div className="form-group col-md-12">
                <label className="bold" htmlFor="mail_contacto">Email de contacto</label>
                <input 
                  type="mail_contacto" 
                  className="form-control" 
                  name="mail_contacto"
                  ref={register({
                    required: 'El e-mail es requerido',
                    pattern: { value: /^\S+@\S+$/i, message: 'El e-mail no es válido'}
                  })}
                />
              <small className="error-text">{ errors.mail_contacto && errors.mail_contacto.message }</small>              
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="bold" htmlFor="name">Nombre</label>
                <input 
                  className="form-control" 
                  name="name"
                  ref={register({
                    required: 'El nombre es requerido',
                    minLength: { value: 3, message: 'Ingresa al menos 3 carácteres' }
                  })}
                />
                <small className="error-text">{ errors.name && errors.name.message }</small> 
              </div>
              <div className="form-group col-md-6">
                <label className="bold" htmlFor="surname">Apellido</label>
                <input 
                  className="form-control" 
                  name="surname"
                  ref={register({
                    required: 'El apellido es requerido',
                    minLength: { value: 3, message: 'Ingresa al menos 3 carácteres' }
                  })}
                />
                <small className="error-text">{ errors.surname && errors.surname.message }</small> 
              </div>
            </div>      
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="bold" htmlFor="phone">Teléfono</label>
                <input 
                  className="form-control" 
                  name="phone"
                  ref={register({
                    required: 'El teléfono es requerido',
                    minLength: { value: 8, message: 'Ingresa al menos 8 carácteres' }
                  })}
                />
                <small className="error-text">{ errors.phone && errors.phone.message }</small> 
              </div>
              <div className="form-group col-md-6">
                <label className="bold" htmlFor="cuit">CUIT (sin guiones ni puntos)</label>
                <input 
                  className="form-control" 
                  name="cuit"
                  ref={register({
                    required: 'El CUIT es requerido',
                    minLength: { value: 11, message: 'Ingresa 11 carácteres. Sólo números' },
                    maxLength: { value: 11, message: 'Ingresa 11 carácteres. Sólo números' },
                  })}
                />
                <small className="error-text">{ errors.cuit && errors.cuit.message }</small> 
              </div>
            </div>   
            <button type="submit" className="btn btn-success">Enviar</button>
          </form>
        </div>

      </div> 
      </div>}
           
    </div>
    
  )
}
