import React from 'react';
import PropTypes from 'prop-types';

import './image.css'

const Image = ({ className, source, state }) => {
    return (
        <div className='img-container text-center'>
            <img
                className={`img-react ${className}`}
                src={source}
                alt='Loading'
            />
            {state === 'Comprada' && <div className="sold-text">VENDIDO!</div>}
        </div>
    );
};

Image.propType = {
    source: PropTypes.string,
};

export default Image;