import React from 'react';
import { Redirect, Route } from 'react-router';

import routers from './routers';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import { useAuthContext } from '../context/AuthenticatedContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLogged } = useAuthContext();

    return (
        isLogged()
            ? <Route {...rest} render={props =>
                <>
                    <Navbar />
                    <Component {...props} />
                    <Footer />
                </>
            } />
            : <Redirect to={routers.join.path} />
    );
};

export default PrivateRoute;
