import React from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { postAuctionPrices } from "../../../api/private/client";

interface AuctionPricesForm {
  image1: FileList;
  image2: FileList;
  image3: FileList;
}

const AuctionPricesModal: React.FC<any> = ( { isOpen, closeModal } ) => {
  const { register, handleSubmit } = useForm<AuctionPricesForm>();

  const infoModal = () => {
    Swal.fire( {
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } );
  };

  const successModal = async () => {
    return await Swal.fire( {
      title: "Datos enviados",
      text: "Imágenes cargadas correctamente.",
      icon: "success",
    } );
  };

  const errorModal = () => {
    Swal.fire( {
      title: "Error.",
      text: "Ha ocurrido un error al momento de cargar la imagen.",
      icon: "error",
    } );
  };

  const onSubmit = async ( data: AuctionPricesForm ) => {
    infoModal();
    try {
      const arrayImg = [];
      arrayImg.push( data.image1[ 0 ] );
      arrayImg.push( data.image2[ 0 ] );
      arrayImg.push( data.image3[ 0 ] );
      const resp = await postAuctionPrices( arrayImg );
      console.log( resp.data );
      await successModal();
      closeModal();
    } catch ( err ) {
      errorModal();
    }
  };

  return (

    <Modal isOpen={ isOpen } onRequestClose={ closeModal }
      style={ {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "100%",
          width: "60%",
          padding: "2rem",
          borderRadius: "10px",
          borderColor: "#f0f0f0",
        },
        overlay: { backgroundColor: "rgba(50, 50, 50, 0.904)" },
      } }
    >
      <div className='d-flex justify-content-end mb-3'>
        <i
          className='far fa-times-circle StepperModal-btn-close'
          onClick={ closeModal }
        />
      </div>
      <div className="col">
        <h2 className="d-flex justify-content-center bold mb-3">
          Placas de Precios de Remate
        </h2>
        <form onSubmit={ handleSubmit( onSubmit ) } encType="multipart/form-data">
          <div className="form-group">
            <label className="form-label bold mb-1" htmlFor="image1">
              Placa 1
            </label>
            <input
              id="image1"
              className='btn btn-block btn-light border rounded shadow-sm'
              name="image1"
              ref={ register( { required: true } ) }
              type="file"
            />
            <div className="invalid-feedback">
              Placa 1 es requerida.
            </div>
          </div>
          <div className="form-group">
            <label className="form-label bold mb-1" htmlFor="image2">
              Placa 2
            </label>
            <input
              id="image2"
              className='btn btn-block btn-light border rounded shadow-sm'
              name="image2"
              ref={ register( { required: true } ) }
              type="file"
            />
            <div className="invalid-feedback">
              Placa 2 es requerida.
            </div>
          </div>
          <div className="form-group">
            <label className="form-label bold mb-1" htmlFor="image3">
              Placa 3
            </label>
            <input
              id="image3"
              className='btn btn-block btn-light border rounded shadow-sm'
              name="image3"
              ref={ register( { required: true } ) }
              type="file"
            />
            <div className="invalid-feedback">
              Placa 3 es requerida.
            </div>
          </div>
          <button type="submit" className="btn btn-success mt-4">
            Cargar nuevas imágenes
          </button>
        </form>
      </div>
    </Modal>

  );
};

export default AuctionPricesModal;