import React from 'react';
import GoogleLogin, { useGoogleLogin } from 'react-google-login';
import store from 'store-js';
// import { ModalError } from '../components/Modals/Modals';
import routers from '../config/routers';

const GoogleAuthContext = React.createContext()

const onSuccess = res => {

    // pegarle a un endpoint para que nos devuelva el JWT
    // const data = awat verifyToken(res);
    //store.set('token', data)
    // console.log(res.profileObj.name);
    // store.set('username',res.profileObj.name)
    
    // eslint-disable-next-line
    const{name,email,givenName,familyName,googleId,imageUrl}=res.profileObj;
    const userInfoAuht={
        name,
        email,
        imageUrl,
        googleId
    }
    // console.log(res.profileObj);
    localStorage.setItem('useData',JSON.stringify(userInfoAuht));
    store.set('token', res.tokenId)
    // window.location.href = routers.user_dashboard.path;
    window.location.href = routers.user_profile.path;
    console.log(res);
}

const onFailure = async res => {
    // await ModalError('Tenemos problemas tecnicos, intente mas tarde')
}

export const GoogleAuthProvider = ({ children }) => {

    const googleAuth = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onSuccess,
        onFailure
    })

    return (
        <GoogleAuthContext.Provider value={googleAuth}>
            {children}
        </GoogleAuthContext.Provider>
    )
}

export const useGoogleAuth = () => React.useContext(GoogleAuthContext)

export const GoogleLoginComponent = ({ className, style }) =>
    <div className={`d-flex justify-content-center ${className}`} style={style}>
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="REGISTRARSE CON GOOGLE"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
        />
    </div>
