import { RealBreadCrumb } from '../../components/breadcrumb/Breadcrumb';
import routers from '../../config/routers';
import { useHistory } from 'react-router';
import "./HelpScreen.css";

const HelpScreen = () => {
    const history = useHistory();
    return (
        <>

            <div className="mt-5 min-vh-100 justify-content-center align-items-center mb-5">
                <div className="w-100">
                    <h1 className="text-center font-weight-bold title__helpScreen">Centro de asistencia de Xercana</h1>
                </div>
                <div style={ { width: '70%', margin: '0 auto', marginTop: '1rem' } }>
                    <RealBreadCrumb
                        refNames={ [ 'Inicio ', 'Compras' ] }
                        hrefs={ [ routers.home.path, '#' ] }
                    />
                </div>
                <div className="helpScreenContainerDiv">
                    {/* SECCIONES COMENTADAS A PEDIDO DEL CLIENTE QUE DECIDE ELIMINARLAS (DICIEMBRE 2022) */ }

                    {/* <div className="divQuestion__helpScreen" onClick={()=>history.push(routers.beneficts.path)}>
                <h3>Beneficios</h3>
            </div> */}
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.primarySteps.path ) }>
                        <h3>Primeros Pasos</h3>
                    </div>
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.howquestion.path ) }>
                        <h3>¿Cómo preguntar en una publicación?</h3>
                    </div>
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.atentiontime.path ) }>
                        <h3>¿Cuáles son los horarios y días de atención?</h3>
                    </div>
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.prices.path ) }>
                        <h3>Precios</h3>
                    </div>
                    {/* <div className="divQuestion__helpScreen" onClick={()=>history.push(routers.methodtopay.path)}>
                <h3>Medios de pago</h3>
            </div>
            <div className="divQuestion__helpScreen" onClick={()=>history.push(routers.sendandrecive.path)}>
                <h3>Envíos y retiro de productos</h3>
            </div> */}
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.seefacture.path ) }>
                        <h3>Ver factura</h3>
                    </div>
                    <div className="divQuestion__helpScreen" onClick={ () => history.push( routers.securitySection.path ) }>
                        <h3>Seguridad</h3>
                    </div>
                </div>
                {/*
           <div className="container mt-4 ">
           <h2 className="mt-4 mb-4">Preguntas frecuentes:</h2>
           
           

            <div className="card">
                <div className="card-header" id="headingOne">
                    <div className="mb-0">
                        <h3>¿Como comprar?</h3>
                    </div>
                </div>
                <div className="card-body">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam feugiat dolor eget orci molestie rhoncus. Sed sollicitudin placerat tortor, eget tincidunt dolor egestas eget. Duis id magna condimentum tortor posuere posuere a ut turpis. Nullam sem tellus, ullamcorper vitae purus eu, varius gravida massa. Donec semper velit eget ligula ultricies, at bibendum ex auctor. Suspendisse eleifend felis et sagittis lacinia. Donec rutrum mattis purus eu tincidunt
                    </p>
                </div>
            </div>
            

            <div className="card mt-2">
                <div className="card-header" >
                    <div className="mb-0">
                        <h3>¿Quiénes somos?</h3>
                    </div>
                </div>
                <div className="card-body">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam feugiat dolor eget orci molestie rhoncus. Sed sollicitudin placerat tortor, eget tincidunt dolor egestas eget. Duis id magna condimentum tortor posuere posuere a ut turpis. Nullam sem tellus, ullamcorper vitae purus eu, varius gravida massa. Donec semper velit eget ligula ultricies, at bibendum ex auctor. Suspendisse eleifend felis et sagittis lacinia. Donec rutrum mattis purus eu tincidunt
                    </p>
                </div>
            </div>

            <div className="card mt-2">
                <div className="card-header" >
                    <div className="mb-0">
                        <h3>Envíos</h3>
                    </div>
                </div>
                <div className="card-body">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam feugiat dolor eget orci molestie rhoncus. Sed sollicitudin placerat tortor, eget tincidunt dolor egestas eget. Duis id magna condimentum tortor posuere posuere a ut turpis. Nullam sem tellus, ullamcorper vitae purus eu, varius gravida massa. Donec semper velit eget ligula ultricies, at bibendum ex auctor. Suspendisse eleifend felis et sagittis lacinia. Donec rutrum mattis purus eu tincidunt
                    </p>
                </div>
            </div>


           </div> */}


            </div>
        </>
    );
}

export default HelpScreen;