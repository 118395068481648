// React
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';

// Components
import Swal from 'sweetalert2';
import PublicationCard from '../Card/PublicationCard';

// others
import routers from '../../../config/routers';
import { states } from './publicationStates';

/**
 * @brief Lista de publicaciones segun rol
 * 
 * Este componente renderiza las publicaciones relacionadas
 * al usuario segun el rol enviado por parametro. Ademas de
 * un searchBar para buscar publicacion por titulo y una 
 * seccion para filtrar dichas publicaciones
 * 
 * @param {boolean} enabled booleano que indica si el usuario fue verificado por un admin 
 * @param {String} role Rol del usuario que delimita las publicaciones a renderizar
 */
function PublicationsList( { enabled, role, publications } ) {

    const history = useHistory();

    const [ renderPublications, setRenderPublications ] = useState( publications );
    const [ searchByTitle, setSearchByTitle ] = useState( "" );
    const [ filterByState, setFilterByState ] = useState( "" );
    const [ filterById, setFilterById ] = useState( "" );

    const approved = enabled;

    useEffect( () => {
        setRenderPublications();
    }, [] );

    useEffect( () => {
        let newPublications;
        if ( searchByTitle === "" ) newPublications = publications;
        else ( newPublications = publications.filter( ( e ) => e.title.toLowerCase().includes( searchByTitle.toLowerCase() ) || e.id === parseInt( searchByTitle ) ) );

        if ( filterByState !== "" ) newPublications = newPublications.filter( ( e ) => e.state.toLowerCase().includes( filterByState.toLowerCase() ) );
        setRenderPublications( newPublications );
    }, [ searchByTitle, filterByState, filterById ] );

    const handleNewPublication = () => {
        if ( approved ) {
            history.push( routers.user_new_publication.path );
        } else {
            Swal.fire(
                '¡No estas verificado!',
                'Debes esperar a que un administrador apruebe tu perfil para poder publicar',
                'info' );
        }
    }

    const buildPublicationCard = ( publication, index ) => {
        let publicationState;
        states.forEach( ( state ) => {
            if ( state.name === publication.state ) {
                publicationState = <span className={ `badge rounded-pill ${ state.style }` }>{ state.description }</span>
            }
        } )

        return <div className="col-12" key={ index }>
            <PublicationCard
                state={ publicationState }
                modificacion={ publication.lastTransition }
                publication={ publication }
                key={ index }
                role={ role }
                approved={ approved } />
        </div>;
    }

    return <div className="container">
        {/* Add new publication Btn */ }
        {
            role === "owner" &&
            <div className="row my-4 d-flex justify-content-end">
                <div className="col-6 col-lg-2">
                    <button
                        className='col p-2 btn_custom_secondary'
                        onClick={ () => handleNewPublication() }>
                        Nueva Publicación
                    </button>
                </div>
            </div>
        }
        {/* Filter and search by title */ }
        <div className="row my-4 border rounded">
            <div className="col-12 my-2">
                <div>
                    <div className="row d-flex justify-content-center"><h3>Buscar por título - #ID</h3></div>
                    <div className={ "mx-4" }>
                        <input
                            type="text"
                            className={ "w-100 inputBroker__BrokerPage" }
                            value={ searchByTitle }
                            onChange={ ( e ) => setSearchByTitle( e.target.value ) }
                            placeholder={ "Ejemplo: 50 vaquillones..." } />
                    </div>
                </div>
            </div>
            <div className="col-12 my-2">
                <div className="row d-flex justify-content-center">
                    <h3>Filtrar</h3>
                </div>
                <div className="row d-flex justify-content-center justify-content-lg-start">
                    <div className={ "mx-2" }>Estado</div>
                    <div className={ "mx-2" }>
                        <select value={ filterByState } onChange={ ( e ) => setFilterByState( e.target.value ) }>
                            <option key={ -1 } value="">---</option>
                            { states.map( ( state, index ) => {
                                return <option value={ state.name } key={ index }>{ state.description }</option>
                            } )
                            }
                        </select>
                    </div>
                </div>
            </div>
            { role === "shipper" &&
                <div className="col-12 my-2">
                    <p className="text-center color-grey">
                        <i><b>
                            * Recuerde que las publicaciones en estado "DTE Cargado" indican que estan listas para su servicio
                        </b></i>
                    </p>
                </div>
            }
        </div>
        {/* Publications list */ }
        <div className="row">
            {
                renderPublications &&
                renderPublications.map( ( publication, index ) => buildPublicationCard( publication, index ) )
            }
        </div>
    </div>;
}

export default PublicationsList;
