import React from "react";
import { useHistory } from "react-router";

import "./BackButton.css";

const BackButton: React.FC = () => {
  const history = useHistory();

  return (
    <div className="BackButton-container" onClick={() => history.goBack()}>
      <i className="BackButton-icon fas fa-angle-left" />
    </div>
  );
};

export default BackButton;
