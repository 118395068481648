import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

import "./Card.css";

interface MapCardProps {
  position: [number, number];
  origen: string;
  establishment?: {
    name: string;
    province: string;
    locality: string;
  };
}

const MapCard: React.FC<MapCardProps> = (
  { position, origen, establishment },
) => {
  return (
    <div className="card my-4 p-4">
      <p className="Card-title text-center">
        {origen}: {establishment?.name ?? "-"}
      </p>
      <div className="row">
        <div className="col-12 col-lg-6">
          <h4>
            Provincia: {establishment?.province}
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <h4>Localidad: {establishment?.locality}</h4>
        </div>
      </div>
      <MapContainer
        className="Card-map-container"
        center={position}
        zoom={12}
      >
        {/* <TileLayer
          attribution={`&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt0','mt1','mt2','mt3']}
        />
        <Marker position={position} />
      </MapContainer>
    </div>
  );
};

export default MapCard;
