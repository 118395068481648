import { useEffect } from 'react';


export const AdminAuctionDashboard = () => {

  const exampleAuctions = [
    {
      id: 1,
      active: true,
      banner: 'https://preofertas.elrural.com/img/uploads/8891cc466.jpg',
      name: 'Subasta de prueba',
      place: 'Jesus Maria, Cordoba',
      date: '15/09/2023',
      time: '12:00',
      preOfferStart: '2023-09-15 19:10:25-07',
      preOfferEnds: '2023-09-20 19:10:25-07',
      categories: ['vacas', 'toros', 'vaquillonas']
    },
      {
      id: 2,
      active: true,
      banner: 'https://preofertas.elrural.com/img/uploads/8891cc466.jpg',
      name: 'Subasta de prueba',
      place: 'Jesus Maria, Cordoba',
      date: '15/09/2023',
      time: '12:00',
      preOfferStart: '2023-09-15 19:10:25-07',
      preOfferEnds: '2023-09-20 19:10:25-07',
      categories: ['vacas', 'toros', 'vaquillonas', 'blablabla']
    }
  ]

  useEffect(() => {
  exampleAuctions.map( ( auction, index ) => {
  console.log(auction.categories, index);})
  }, [])
  

  return (
    
   <>
    <div className="container mt-3">
      <h3>Listado de subastas</h3>
       <div className='d-flex justify-content-center'> 
        <button 
          className='btn btn-outline-danger btn-sm m-2' 
          // onClick={ handlePrevPage } 
          // disabled={disablePrevButton}
        >
          Nueva Subasta
        </button>
      </div> 
      <hr></hr>
      <table className="table table-bordered shadow">
        <thead className="table-light">
          <tr style={{'text-align':'center'}}>
            <th scope="col">Id</th>
            <th scope="col">Activa</th>
            {/* <th scope="col">Imagen</th> */}
            <th scope="col">Nombre</th>
            <th scope="col">Lugar</th>
            <th scope="col">Fecha Remate</th>
            <th scope="col">Hora</th>
            <th scope="col">Comienzo</th>
            <th scope="col">Fin</th>
            <th scope="col">Categorias</th>
            <th scope="col">Editar</th>
          </tr>
        </thead>
        <tbody>
        {
          exampleAuctions.map( ( auction ) =>(
            <tr key={ auction.id } className='border'>
              <td scope="row"> { auction.id } </td>
              <td> { (auction.active) ? "SI" : "NO"} </td>
              {/* <td> { auction.banner } </td> */}
              <td> { auction.name } </td>
              <td> { auction.place } </td>
              <td> { auction.date } </td>
              <td> { auction.time } </td>
              <td> { auction.preOfferStart } </td>
              <td> { auction.preOfferEnds } </td>
              <td> { auction.categories.map( category => { return category + ' '}) } </td>
              <td>
                {/* <Link 
                  to={`/edit-client/${ auction.id }`} > */}
                  <button className="btn btn-primary btn-sm"> Editar </button>
                {/* </Link> */}
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
      </div>
    </>
  )
}
