import React from 'react';
import { LoadingPoints } from '../../../components/loading/Loading';

export default function getViewLoading() {
    return (
        <div className='main-loader'>
            <div className='loading loader-title'>
            </div>
            <div className='row'>
                <div className='row'>
                    <div className='col-12 col-md-8 container-loader'>
                        <div className='loader-1'>
                            <div className='d-flex justify-content-center align-items-center' style={{ paddingTop: '15%' }}>
                                <LoadingPoints />
                            </div>
                        </div>
                    </div>
                    <div className='col container-loader'>
                        <div className='row loader-1 d-flex justify-content-center' style={{ paddingTop: '20px' }}>
                            <div className='col-11 loading text-loader'></div>
                            <div className='col-11 loading text-loader'></div>
                            <div className='col-11 loading text-loader'></div>
                            <div className='col-11 loading text-loader'></div>
                            <div className='col-11 loading text-loader'></div>
                            <div className='col-11 loading button-loader'></div>
                            <div className='col-11 loading button-loader'></div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-8 container-loader'>
                        <div className='loader-1'>
                            <div className='d-flex justify-content-center row' style={{ paddingTop: '20px' }}>
                                <div className='col-3 loading button-loader'></div>
                                <div className='col-1'></div>
                                <div className='col-3 loading button-loader'></div>
                                <div className='col-1'></div>
                                <div className='col-3 loading button-loader'></div>
                                <div className='col-11 loading text-loader' style={{ paddingTop: '20px', marginTop: '20px' }}></div>
                                <div className='col-11 loading text-loader' style={{ paddingTop: '20px', marginTop: '20px' }}></div>
                                <div className='col-11 loading text-loader' style={{ paddingTop: '20px', marginTop: '20px' }}></div>
                                <div className='col-11 loading text-loader' style={{ paddingTop: '20px', marginTop: '20px' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='col container-loader'>
                        <div className='row loader-1 d-flex justify-content-center' style={{ paddingTop: '20px' }}>

                            <div className='col-11 loading button-loader'></div>
                            <div className='col-11 loading button-loader'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}