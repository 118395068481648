import { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindows";
import "./AuctionsHomePage.css"
import { getAllActiveAuctions } from "../../api/public/client";
import { AuctionsTabs } from "../../components/AuctionsTabs/AuctionsTabs";

export const AuctionsHomePage = () => {

  const exampleAuctions = [
    {
      id: 1,
      active: true,
      banner: 'https://preofertas.elrural.com/img/uploads/8891cc466.jpg',
      name: 'Subasta de prueba',
      place: 'Jesus Maria, Cordoba',
      date: '15/09/2023',
      time: '12:00',
      preOfferStart: '2023-09-15 19:10:25-07',
      preOfferEnds: '2023-09-20 19:10:25-07',
      categories: ['vacas', 'toros', 'vaquillonas']
    },
      {
      id: 2,
      active: true,
      banner: 'https://preofertas.elrural.com/img/uploads/8891cc466.jpg',
      name: 'Subasta de prueba',
      place: 'Jesus Maria, Cordoba',
      date: '15/09/2023',
      time: '12:00',
      preOfferStart: '2023-09-15 19:10:25-07',
      preOfferEnds: '2023-09-20 19:10:25-07',
      categories: ['vacas', 'toros', 'vaquillonas', 'blablabla']
    }
  ];

  const [ sizeMobil, setSizeMobil ] = useState( false );
  const [ auctions, setAuctions ] = useState();
  const size = useWindowSize();
  
  useEffect( () => {
      setSizeMobil( size.width < 760 );
    }, [ size ] );

    // ( async () => {
    //   setIsLoading( true );
    //   const { data } = await getAllActiveAuctions();
    //   setAuctions( data );
    //   setIsLoading( false );
    // } )();

  return (
    <>

      { !sizeMobil
        ? (
          
          <div className="father-container container mt-4">
            <img
              className="auction-categories-banner shadow rounded"
              src='https://irp-cdn.multiscreensite.com/bb1e0d57/DESKTOP/png/2362494-new_image1592586198.png'
            />
          </div>
        )
        : (
          <div className="father-container">
            <img
              className="auction-categories-banner-mobile shadow rounded"
              src='https://irp-cdn.multiscreensite.com/bb1e0d57/DESKTOP/png/2362494-new_image1592586198.png'
            />
          </div>
        )
      }
      <hr className="solid"/>

      { exampleAuctions.map( ( auction, index ) => (
        <div key={ index }>

          { !sizeMobil 
          ? (
            <div className="father-container container mt-4">
              <img
                className="auction-categories-banner shadow rounded"
                src={auction.banner}
              />
            </div>
          )
          : 
          (
            <div className="father-container">
              <img
                className="auction-categories-banner-mobile shadow rounded"
                src={auction.banner}
              />
            </div>
          )
          }

          <div className="d-flex justify-content-center">
            <h3><strong>PARA PODER PRE-OFERTAR REGISTRATE HACIENDO CLICK</strong> <a style={{color: 'blue'}} href="www.google.com">AQUI</a></h3>
          </div>

          <div className="d-flex justify-content-center">
            <h3><strong>Apertura de pre-ofertas:</strong> { auction.preOfferStart }</h3>
          </div>
          <div className="d-flex justify-content-center">
            <h3><strong>Cierre de pre-ofertas:</strong> { auction.preOfferEnds }</h3>
          </div>
          <div className="d-flex justify-content-center">
            <h3><strong>Remate:</strong> { auction.date } - { auction.time }</h3>
          </div>
          <br/>

          <div className="d-flex justify-content-center">
            <h3>CATEGORIAS</h3>
          </div>
         
            <div className="row row-cols-1 row-cols-md-2 g-4 container border rounded shadow p-3">
            { auction.categories.map( ( category ) => (
              <div key={ index + category }>
                <div className="col container">
                  <div className="card m-1">
                    <img src='https://fastly.picsum.photos/id/493/800/300.jpg?hmac=Wv3e0FAyfZkGGl5WLKYchzPXNivbqV566cQwcC7KO_I' className="card-img-top"/>
                    <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <h5 className="card-title"> { category } </h5>
                    </div>
                      {/* <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                    </div>
                  </div>
                </div>
              </div>
          ))}
            </div>
        <AuctionsTabs/>
        <hr className="solid"/>
        </div>
  ) ) }
  </>
  )
}

export default AuctionsHomePage
