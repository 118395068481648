

// TRANSFORM TO REQUIRED STRING
export const isRequired = string => string + '*'

// TELEPHONE'S NUMBER LIKE AS REGULAR EXPRESSION
export const numberMatch = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

//CUIT LIKE AS REGULAR EXPRESSION
export const cuitMatch = /[0-9]{11}/g;

// WITH THIS, THE INPUTS CAN WRITE AS XX-XXXXXXXX-X AUTOMATICLY
export const handleCUIT = (event, callback) => {
    const value = event.target.value;
    const match = value.trim() === "" ? "" : value.match(/[0-9]{0,11}/g)[0];
    if (match || match === "")
        callback(match)
    else return;
}

export const UnixToTimeStyle = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "numeric",
}

export const formatPrice = (price) => {
    const result = Number(price);

    if (!result) return '$ -';

    const options = {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
    };
    return result.toLocaleString('es-ar', options);
};

export const formatDouble = (num) => {
    const result = Number(num);
    if (!result) return ' - ';

    const options = {
        minimumFractionDigits: 2,
    }
    return result.toLocaleString('es-ar', options);
}

export const removeHyphen = (str) => {
    if (typeof str === 'string') {
        str = str.replaceAll('-', '');
    }
    return str;
};

export const formatDate = (dateStr) => {
    if (!dateStr) {
        return '-';
    }
    const date = new Date(dateStr);
    return date.toLocaleString('es-ar');
};


