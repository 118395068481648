import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Swal from "sweetalert2";

import { getAdvancedPublicationSearch, getProvinces, getPublicationSearch } from "../../api/private/client";
import BackButton from "../../components/BackButton/BackButton";
import Card from "../../components/card/Card";
import Padding from "../../components/padding/Padding";
import routers from "../../config/routers";
import { formatPrice } from "../../utils/helper";
import { categories } from "../Dashboard/Publication/constants";
import { useForm } from 'react-hook-form';

interface Publication {
  img: string;
  title: string;
  subtitle: string;
  detailList: {};
  href: string;
  preloader: boolean;
  redirected: boolean;
  state: string;
  publicationId:number;
}

interface Province {
  id: number;
  province: string;
}

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center my-4">
      <div className="spinner-border" role="status" />
    </div>
  );
};

const PublicationSearch = () => {
  const query = new URLSearchParams(useLocation().search);

  const { register, handleSubmit, watch } = useForm();
  const [publications, setPublications] = useState<Publication[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [province, setProvince] = useState<number>();
  const [cayegory, setCategory] = useState<string>();

  useEffect(() => {
    (async () => {
      const q = query.get("query")?.trim();
      setIsLoading(true);
      try {
        const { data } = await getPublicationSearch(q) as any;      
        const dataProvinces = await getProvinces();
        setProvinces(dataProvinces?.data);  
        let publications: Publication[] = [];
        const r = routers as any;
        for (const p of data) {
          const publication: Publication = {
            img: p.photo,
            title: p.title,
            subtitle: formatPrice(p.price),
            detailList: {
              weight: p.detail.weight,
              province: p.detail.province,
              count: p.detail.count
            },
            href: `${r.publicationView.path_1(p.id)}`,
            preloader: false,
            redirected: false,
            state: p.state,
            publicationId:p.id
          };
          publications.push(publication);
        }
        setPublications(publications);
      } catch (err) {
        Swal.fire({
          title: "Error en la búsqueda",
          icon: "error",
        });
      }
      setIsLoading(false);
    })();
  }, []);

  const handleProvince = ( e: { target: { value: any; }; } ) => {
    const est = provinces.find( ( prov: Province ) => {
      return prov.id === Number( e.target.value );
    } );
    setProvince( est?.id );
  };

  const handleCategory = ( e: { target: { value: any; }; } ) => {
    const est = categories.find( ( cat ) => {
      return cat ===  e.target.value;
    } );
    setCategory( est );
  };

  const onSubmit = async () => {
    setIsLoading( true );
    const dataToSend = {
      texto: query.get("query"),
      categoria: watch( 'category', false ),
      id_provincia: watch( 'province.id', false )
    }
    try {
      console.log(dataToSend);
      const { data } = await getAdvancedPublicationSearch(dataToSend) as any;
      console.log(data);
      setIsLoading( false );
      let publications: Publication[] = [];
        const r = routers as any;
        for (const p of data) {
          const publication: Publication = {
            img: p.photo,
            title: p.title,
            subtitle: formatPrice(p.price),
            detailList: {
              weight: p.detail.weight,
              province: p.detail.province,
              count: p.detail.count
            },
            href: `${r.publicationView.path_1(p.id)}`,
            preloader: false,
            redirected: false,
            state: p.state,
            publicationId:p.id
          };
          publications.push(publication);
        }
        setPublications(publications);
    } catch ( err ) {
      console.error( err );
      setIsLoading( false );      
    }
  }



  return (
    <div className="container bg-white rounded py-3 my-3 shadow-lg">
      <BackButton />
      <h2 className="text-center bold pb-3">
        Buscando por: "{query.get("query") ?? "-"}"
      </h2>
      
      <div className="container">  
        <p className="bold">
          Agregar filtros:
        </p>          
        <div className="row pb-4">
        <form className="form-inline" onSubmit={ handleSubmit( onSubmit ) }>        
          <div className='form-group my-2'>        
              <label className='col-form-label' htmlFor='id_province'>
                Provincia
              </label>
              <select
                  id='id_province'
                  className='form-control mx-sm-4'
                  name='province.id'                
                  onChange={handleProvince}
                  ref={ register }
              >
                  <option value=''>Seleccione una provincia...</option>
                  {provinces?.map(province =>
                      <option
                          key={province.id}
                          value={province.id}
                      >
                          {province.province}
                      </option>
                  )}
              </select>
            </div>
            <div className='form-group my-2'>
              <label className='col-form-label' htmlFor='category'>
                Categoría
              </label>
              <select
                  id='category'
                  className='form-control mx-sm-4'
                  name='category'                
                  onChange={handleCategory}
                  ref={ register }
              >
                  <option value=''>Seleccione una categoría...</option>
                  {categories?.map(cat =>
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  )}
              </select>
            </div>
            <div className='form-group my-2'>
              <button
                  className="btn btn-info ml-sm-2 ml-md-2"
                  type="submit"
              >
                  Buscar
              </button>
            </div>
            </form> 
          </div> 
      </div> 
      
      {isLoading ? <LoadingSpinner /> : (
        <div className="container">
          <div className="row py-4">
            {publications.length > 0 &&
              publications.map((p) => (
                <div className="col-12 col-lg-3 mb-4 mb-lg-2" key={p.publicationId}>
                  <Card {...p} />
                </div>
              ))}
            {publications.length === 0 && (
              <div className="col-12">
                <p className="text-center">No se han encontrado ocurrencias.</p>
              </div>
            )}
          </div>
        </div>
        
      )}
    </div>
  );
};

export default PublicationSearch;
