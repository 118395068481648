import { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import Padding from '../../../../components/padding/Padding'
import ComboBox from '../../../../components/ComboBox/ComboBox';
import ComboBox2 from '../../../../components/ComboBox2/ComboBox2';
import { getHashForm, getPaymentMethods, postPaymentMethodComment } from '../../../../api/private/client';

import { useStepperContext } from '../../../../components/StepperModal/StepperModal';
import { formatDouble, formatPrice } from '../../../../utils/helper';
import Translate from '../../../../utils/translation/User.json';
import './DetailStep.css';
import routers from "../../../../config/routers";
import BottonLine from "../../../../components/BottonLine";
import { FaSpinner } from "react-icons/fa6";



const DetailStep = ( { context } ) => {

  const { purchase, isLoading } = context;
  const { handleBack } = useStepperContext();
  const history = useHistory();
  const [ paymentMethod, setPaymentMethod ] = useState( '' );
  const [ paymentComment, setPaymentComment ] = useState( '' );
  const [ loadingCards, setLoadingCards ] = useState(false);

  // Cantidad de Cuotas
  // const installments = [1, 3, 6, 7, 8, 13, 16];
  const installments = [ 1 ];

  const [ cards, setCards ] = useState( [] )
  const [ cardSelected, setcardSelected ] = useState( '' )
  const [ instSelected, setinstSelected ] = useState( 1 )


  useEffect(() => {
    if(paymentMethod === 'creditCard'){
      const controller = new AbortController();
      getCards();
      return () => controller.abort();
    }    
  }, [paymentMethod] );

  const errorModal = () => {
    return Swal.fire( {
      title: "Error",
      text: "Ha ocurrido un error, por favor intente nuevamente en unos instantes.",
      icon: "error",
    } );
  };

  const noCardSelectedModal = () => {
    Swal.fire( {
      title: "Atención!",
      text: "Debe seleccionar una tarjeta.",
      icon: "info",
      allowOutsideClick: true,
      showConfirmButton: true,
    } );
  };

  const loadingPaymentModal = () => {
    Swal.fire( {
      title: "Cargando pago",
      text: "Por favor espere unos momentos.",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
    } );
  };

  const getCards = async () =>{
    try {
      setLoadingCards(true);
      const cards = await getPaymentMethods();
      setCards( cards );
      setLoadingCards(false);
    } catch ( error ) {
      setLoadingCards(false);
      await errorModal();
    }
  }
  

  const onCardChange = ( { target } ) => {
    const { value } = target;
    setcardSelected( value );
  };
  

  const legalAdvertisingModal = async () => {
    const { isConfirmed: acceptLegal } = await Swal.fire( {
      title: "Atención!",
      text: Translate.LEGAL_ADVERTISING.TEXT,
      customClass: "swal-wide",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Acepto',
      cancelButtonText: `No acepto`,
    } )
    return acceptLegal;
  };

  const handlePurchase = async () => {
    if ( cardSelected === '' ) {
      noCardSelectedModal();
      return;
    }
    const acceptLegal = await legalAdvertisingModal();
    if ( !acceptLegal ) {
      history.push( routers.home.path );
    } else {
      loadingPaymentModal();
      try {
        const payload = JSON.stringify( {
          "idPublication": purchase.idProduct,
          "idEstablecimiento": purchase.establishment.id,
          "idTransportista": 0, //mando en cero, luego el backend buscara ID si el transporte lo hace Xercana
          "total": parseFloat( purchase.detail.total ).toFixed( 2 ),
          "kmEnvio": purchase.costs.cantKm,
          "costoEnvio": parseFloat( purchase.detail.costoEnvio ).toFixed( 2 ),
          "amount": purchase.detail.total,
          "currency": "ARS",
          "payment_method_id": cardSelected,
          "installments": parseInt( instSelected ),
          "tiene_transportista": purchase.transportaXercana,
          "tipo_transporte": purchase.tipo_transporte,
          "empresa_transporte": purchase.empresa_transporte,
          "cuit_transporte": purchase.cuit_transporte,
          "chofer": purchase.chofer,
          "marca_transporte": purchase.marca_transporte,
          "patente_chasis": purchase.patente_chasis,
          "tiene_acoplado": purchase.tiene_acoplado,
          "patente_acoplado": purchase.patente_acoplado
        } )

        // Extraigo el HASH para el form
        const { data } = await getHashForm( payload );

        // Navego al form indicandole el hash
        // const url = `https://developers.decidir.com/web/forms/${data.hash}?apikey=78460533452d491fb71ea9883e37665e`;
        const url = process.env.REACT_APP_FORM_URL + `${ data.hash }?apikey=` + process.env.REACT_APP_DECIDIR_PUBLIC_APIKEY;
        window.location.href = url;
      } catch ( error ) {
        await errorModal();
        console.log( error );
        history.go( 0 );
      }
    }    
  };

  const handlePaymentComment = async () => {
    if ( paymentComment === '' ) {
      Swal.fire( {
        title: "Atención!",
        text: "Debe ingresar un medio de pago.",
        icon: "info",
        allowOutsideClick: true,
        showConfirmButton: true,
      } );
      return;
    }

    const toSend = JSON.stringify( {
      "publication_id": purchase.idProduct,
      "payment_comment": paymentComment
    });

    Swal.fire({
      title: 'Por favor espere',
      html: 'Enviando información...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      willOpen: () => {
          Swal.showLoading()
      },
    });

    const filas = await postPaymentMethodComment( toSend );


    if (filas.data === 1) {
      Swal.fire('Envío correcto', 'Su comentario fue registrado correctamente. Nos comunicaremos a la brevedad', 'success'); 
      history.push( routers.home.path );      
    } else {
      Swal.fire('Error', 'No se pudo registrar su comentario. Intente nuevamente', 'error'); 
    }
  }


  if ( !purchase || !purchase.detail ) {
    return (
      <>
        <div className="d-flex justify-content-center my-4">
          <div className="spinner-border" role="status" />
        </div>
        <div className='d-flex gap-2 justify-content-end'>
          <button
            className='btn btn-primary'
            onClick={ handleBack }
            type='button'
          >
            Atrás
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className='d-flex justify-content-center'>
        Detalle de compra
      </h2>
      { isLoading ? (
        <div className="d-flex justify-content-center my-4">
          <div className="spinner-border" role="status" />
        </div>
      ) : (
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <table className="table table-hover table-bordered tabla-angosta">
              <tbody>
                <tr>
                  <td style={ { 'fontWeight': 'bold' } }>Costo de publicación:</td>
                  <td align='right'>{ formatPrice( purchase.detail.costoPublicacion ) }</td>
                </tr>
                <tr>
                  <td style={ { 'fontWeight': 'bold' } }>Gastos de comercialización:</td>
                  <td align='right'>{ formatPrice( purchase.detail.gastosComercializacion ) }</td>
                </tr>
                <tr>
                  <td style={ { 'fontWeight': 'bold' } }>Sub-Total:</td>
                  <td align='right'>{ formatPrice( purchase.detail.subTotal ) }</td>
                </tr>
                <tr>
                  <td style={ { 'fontWeight': 'bold' } }>IVA:</td>
                  <td align='right'>{ formatPrice( purchase.detail.iva ) }</td>
                </tr>
                { ( purchase.transportaXercana == "no" )
                  ? (
                    <tr>
                      <td style={ { 'fontWeight': 'bold' } }>Costo de envío:</td>
                      <td align='right'>$ 0</td>
                    </tr>
                  )
                  : ( <tr>
                    <td style={ { 'fontWeight': 'bold' } }>Costo de envío:</td>
                    <td align='right'>{ formatPrice( purchase.detail.costoEnvio ) }</td>
                  </tr> ) }
                <tr>
                  <td style={ { 'fontWeight': 'bold' } }>Cantidad de kilometros:</td>
                  <td align='right'>{ formatDouble( purchase.detail.kmEnvio ) } km</td>
                </tr>
                <tr>
                  <td style={ { 'fontWeight': 'bold', 'fontSize': 20 } }>Total:</td>
                  <td align='right' style={ { 'fontWeight': 'bold', 'fontSize': 20 } }>{ formatPrice( purchase.detail.total ) }</td>
                </tr>
                { instSelected && (
                  <tr>
                    <td style={ { 'fontWeight': 'bold' } }>{ instSelected } { ( instSelected > 1 ) ? ( 'cuotas de:' ) : ( 'cuota de:' ) }</td>
                    <td align='right'>{ formatPrice( purchase.detail.total / instSelected ) }</td>
                  </tr>
                ) }

              </tbody>
            </table>
          </div>

          <div className='card my-4'>
            <div className='card-body'>
              <h2 className="bold mb-3">Seleccione forma de pago</h2>
              <div className="row">
                <div className="col-md-4">
              <div className="card border-info p-3 mb-2" 
                style={(paymentMethod === 'creditCard') ? {backgroundColor: '#b9d1eb'} : {}}>
                <div className="car-body">
                  <div className="form-check ">
                  <input className="form-check-input" 
                    type="radio" name="paymentMethod" 
                    id="creditCard" 
                    value="creditCard"
                    checked={paymentMethod === "creditCard"}
                    onChange={e => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="creditCard">Tarjeta de crédito</label>
                </div>
                </div>
              </div>
              <div className="card border-info p-3"
                style={(paymentMethod === 'other') ? {backgroundColor: '#b9d1eb'} : {}}>
                <div className="car-body">
                  <div className="form-check">
                  <input className="form-check-input" 
                  type="radio" 
                  name="paymentMethod" 
                  id="other" 
                  value="other"
                  checked={paymentMethod === "other"}
                  onChange= {e => setPaymentMethod(e.target.value)}
                />
                  <label className="form-check-label" htmlFor="other">Otro medio de pago</label>
                </div>
                </div>
              </div>
              </div>
              <div className="col-md-1"></div>
              { paymentMethod === "creditCard" && 
                <div className="col-md-6">
                  <p className="bold">Tarjeta de Crédito</p>
                  {loadingCards ? <FaSpinner/> : 
                    <>
                      <ComboBox2
                      label="Seleccione tarjeta"
                      name="card_selection"
                      placeholder={ "Seleccione tarjeta" }
                      values={
                        ( cards?.data !== undefined )
                          ? cards?.data.map( ( item ) => item )
                          : []
                      }
                      onChange={ onCardChange }
                      id={
                        ( cards?.data !== undefined )
                          ? cards?.data.map( ( item ) => item.idmediopago )
                          : []
                      }
                    />
                    <ComboBox
                      label="Seleccione cantidad de cuotas"
                      name="installments"
                      placeholder={ "Solo se permiten pagos en 1 cuota" }
                    />
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-success'
                      onClick={ () => handlePurchase() }
                      type='button'
                    >
                      Comprar
                    </button>
                  </div>
                  </>}                    
                </div>                
              }    
              {
                paymentMethod === 'other' &&
                <div className="col-md-6">               
                <h3 className="bold">Otro medio de pago</h3>
                  <div className="mt-3">
                    <p>Ingrese una breve descripción del medio de pago que desea utilizar (Depósito / transferencia, efectivo, a plazo, etc) y 
                    un asesor del área comercial se pondrá en contacto con usted para avanzar en la operación.</p>
                    <textarea className="form-control"
                      rows="3" maxLength={200}
                      value={paymentComment}
                      onChange={e => setPaymentComment(e.target.value)}
                    ></textarea>  
                  </div>
                  <div className='d-flex justify-content-end mt-3'>
                    <button
                      className='btn btn-success'
                      onClick={ () => handlePaymentComment() }
                      type='button'
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      ) }
      <div className='d-flex gap-2 justify-content-start container mt-5'>
        <button
          className='btn btn-light mt-3'
          onClick={ handleBack }
          type='button'
        >
          Atrás
        </button>
      </div>
    </> )
};

export default DetailStep;
