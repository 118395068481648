import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import AdminRoute from './config/AdminRoute';
import PrivateRoute from './config/PrivateRoute';
import PublicRoute from './config/PublicRoute';
import AuctionRoute from './config/AuctionRoute';
import routers from './config/routers';
import Reducer from './utils/reducers/index.js';

function App() {
  const Routes = Object.entries(routers).map(route => {

    const { state, ...rest } = route[1];


    switch (state) {
      case 'Private':
        return <PrivateRoute key={route[0]} {...rest} />
      case 'Public':
        return <PublicRoute key={route[0]} {...rest} />
      case 'Admin':
        return <AdminRoute key={route[0]} {...rest} />
      case 'Auction':
      return <AuctionRoute key={route[0]} {...rest} />
    
      default:
        break;
    }
  });
  return (
    <Reducer>
      <Router>
        <Switch>
          {Routes}
        </Switch>
      </Router>
    </Reducer>
  );
}

export default App;
