import React, { useContext, createContext } from "react";

import useAuth from '../hooks/useAuth';

const AuthContext = createContext();

const AuthenticatedProvider = ({ children }) => {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthenticatedProvider;
