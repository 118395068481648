import React from "react";

interface DocumentsCardProps {
  dte?: string;
  liquidacionCompra?: string;
  liquidacionVenta?: string;
}

const DocumentButton: React.FC<{ document: string }> = ({ document }) => {
  return (
    <a className="btn btn-info" href={document} role="button" target="_blank">
      Ver
    </a>
  );
};

const DocumentsCard: React.FC<DocumentsCardProps> = (
  { dte, liquidacionCompra, liquidacionVenta },
) => {
  return (
    <div className="card my-4">
      <h2 className="text-center my-4 bold">
        Documentos
      </h2>
      <div className="card-body">
        <table className="table table-striped table-data">
          <thead>
            <tr>
              <th>Documento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {dte && (
              <tr>
                <td>DTE</td>
                <td>
                  <DocumentButton document={dte} />
                </td>
              </tr>
            )}
            {liquidacionCompra && (
              <tr>
                <td>Liquidación de compra</td>
                <td>
                  <DocumentButton document={liquidacionCompra} />
                </td>
              </tr>
            )}
            {liquidacionVenta &&
              (
                <tr>
                  <td>Liquidación de venta</td>
                  <td>
                    <DocumentButton document={liquidacionVenta} />
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentsCard;
