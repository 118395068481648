const cuitValidator = ( cuit: string ): boolean => {
  // Eliminar caracteres no numéricos
  cuit = cuit.replace( /[^0-9]/g, '' );

  // Validar longitud
  if ( cuit.length !== 11 ) {
    return false;
  }

  // Validar dígito verificador
  let acumulado = 0;
  const digitos = cuit.split( '' );
  const digitoVerificador = parseInt( digitos.pop()!, 10 );
  const coeficientes = [ 5, 4, 3, 2, 7, 6, 5, 4, 3, 2 ];
  for ( let i = 0; i < coeficientes.length; i++ ) {
    acumulado += parseInt( digitos[ i ], 10 ) * coeficientes[ i ];
  }
  const resultado = 11 - ( acumulado % 11 );
  if ( resultado === 11 ) {
    return digitoVerificador === 0;
  } else if ( resultado === 10 ) {
    return digitoVerificador === 9;
  } else {
    return digitoVerificador === resultado;
  }
}

export default cuitValidator
