import React, { useEffect, useState } from 'react';
import { Carousel as C } from 'react-responsive-carousel';
import useWindowSize from '../../hooks/useWindows';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from 'prop-types';
import "./carousel.css";
import { useHistory } from 'react-router';
import { LoadingPoints } from '../loading/Loading';
import { Box, IconButton } from '@material-ui/core';
import { DeleteIcon } from '../Icons/Icons';
import ReactPlayer from 'react-player/youtube'


/**
 * @description: componentse de tipo Carrusel
 * @param imgs: required array con imgs. String imgs
 * @param autoPlay: bool. Default true. Si tiene que reproducirse automaticamente
 * @param interval: Number. Default 3000. Intervalo de tiempo para cmabiar entre imagen automaticamente
 * @param showIndicators: bool. Default true. Muestra los items circulares inferirores.
 * @param showArrows: bool. Default true. Show arrows
 * @param showThumbs: bool. Default false. Show horizontal thumbs
 * @param showStatus: bool. Default false. SHow status in the image
 * @param showVerticalThumbs bool. Default false. Show verticals Thumbs
 */

const CarouselPublication = ({ height, className, imgs, autoPlay, interval, showIndicators, showArrows, showThumbs, imgFit, showVerticalThumbs, showStatus, colorArrow = "#2B3E4F", redirect = '', remove }) => {
    const history = useHistory();
    const [mutedSound, setMutedSound] = useState(false);

    const redirectHandler = () => {
        if (redirect !== '') {
            history.push(redirect)
        }
        else return false;
    }

    const [mobil, setMobil] = useState(false);
    const [useHover, setHover] = useState(false);
    const [imageSelect, setImage] = useState(0);
    const [prevImg, setPrevImg] = useState(false);
    const size = useWindowSize();

    useEffect(() => {
        if (size.width < 500) {
            setMobil(true);
        } else {
            setMobil(false);
        }
    }, [size, imageSelect])

    if (!imgs)
        return (
            <div className='container-carousel'>
                <div className='loader'>
                    <LoadingPoints />
                </div>
            </div>
        );

    useEffect(() => {
    }, [])

    const handleImage = event => {
        setImage(event);
        setPrevImg(event + 1);
        if (prevImg > imageSelect) {
            let images = document.getElementsByClassName('fixedHover');
            if (images) {
                // images.;
                for (let i = 0; i < images.length; i++) {
                    // eslint-disable-next-line
                    let img = images[i].classList.remove('selected-vertical-image');
                }
            }
        }
    }

    const renderCustomThumbs = (imgs) => {
        const thumbList = imgs.map((img, index) => {
            let customImage = img.props.children.props.children[0].props.src !== undefined ?
                img.props.children.props.children[0].props.src
                : img.props.children.props.children[0].props.url;

            if (customImage.includes("youtube")) {
                const pos = customImage.indexOf("embed/") + "embed/".length
                const thumbnailID = customImage.slice(pos, customImage.length);
                const thumbnail = `https://img.youtube.com/vi/${thumbnailID}/default.jpg`;
                return <img key={index}
                    src={thumbnail}
                    alt={img}
                    height="50" />
            }

            if (customImage.includes("youtu.be")) {
                const thumbnailID = customImage.slice(-11);
                const thumbnail = `https://img.youtube.com/vi/${thumbnailID}/default.jpg`;
                return <img key={index}
                    src={thumbnail}
                    alt={img}
                    height="50" />
            }

            return (
                <img
                    key={index}
                    src={customImage}
                    alt={img}
                    height="50"
                />
            )
        })
        return (thumbList)
    }

    const handlePause = () => {
        setMutedSound(true);
    }

    const handlePlay = (index) => {
        setMutedSound(true);
        setImage(index);
    }

    return (
        <div
            className={`${className} row `}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>

            {showVerticalThumbs ? VerticalThumbs(imgs, handleImage) : null}

            <div className='col'>
                <C
                    statusFormatter={(current, total) => `${current}/${total}`}
                    showArrows={showArrows ? useHover ? !mobil : false : false}
                    showStatus={showStatus}
                    showThumbs={showThumbs}
                    emulateTouch={true}
                    infiniteLoop={true}
                    showIndicators={showIndicators}
                    autoPlay={false}
                    centerMode={true}
                    centerSlidePercentage={100}
                    interval={interval}
                    renderArrowNext={renderArrowNext(colorArrow)}
                    renderArrowPrev={renderArrowPrev(colorArrow)}
                    // selectedItem={imageSelect}
                    selectedItem={imageSelect}
                    renderThumbs={renderCustomThumbs}
                >

                    {imgs?.map((img, index) => {

                        return (
                            <div key={index} className='container-carousel'
                                style={height ? { maxHeight: '500px', marginTop: '.3rem' } : redirect !== '' ? { cursor: 'pointer' } : {}}
                                onClick={redirectHandler}
                            >
                                <div style={{ display: 'flex', width: '85%', margin: '0 auto', height: '100%' }}>
                                    {
                                        img.includes("youtube") || img.includes("youtu.be") ?
                                            <ReactPlayer url={img} width={mobil ? "100%" : '100%'} height="100%"
                                                controls={true}
                                                onPause={() => handlePause()}
                                                onPlay={() => handlePlay(index)}
                                                playing={imageSelect !== index ? false : true}
                                                muted={mutedSound}

                                            />
                                            :
                                            <img className="mb-4 mx-auto" key={index} height="100%" width="100%" style={{ objectFit: 'cover' }} alt={img.name} src={img} />
                                    }

                                    <Box color={"error.main"}>
                                        <IconButton
                                            color="inherit"
                                            onClick={() => { remove(index) }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </div>
                            </div>
                        )
                    })
                    }
                </C>
            </div>
        </div>
    )
}

CarouselPublication.propType = {
    autoPlay: PropTypes.bool,
    imgs: PropTypes.arrayOf(PropTypes.string),
    interval: PropTypes.number,
    showIndicators: PropTypes.bool,
    showArrows: PropTypes.bool,
    imgFit: PropTypes.bool,
    showStatus: PropTypes.bool,
    showVerticalThumbs: PropTypes.bool
}

CarouselPublication.defaultProps = {
    autoPlay: true,
    imgs: [],
    interval: 3000,
    showIndicators: true,
    showArrows: true,
    imgFit: false,
    // height: 300,
    showStatus: false,
    showVerticalThumbs: false,
}

export default CarouselPublication;




function VerticalThumbs(imgs, handleImage, removeClass) {
    return (
        <div className='col-2 vertical-images'>
            {imgs.map((img, index) => (
                (index < 4) ? (
                    <button key={index} value={index} className='row'>
                        <img id={index} className="fixedHover" src={img} alt="images" onMouseEnter={() => handleImage(index)} onMouseLeave={() => removeClass(index)} />
                    </button>
                ) : (
                    null
                )))}
            {/* <div className='more-img d-flex align-items-center align-self-center'>
                <h3 style={imgs.length > 9 ? { paddingLeft: '3px' } : { paddingLeft: '5px' }}>
                                +{imgs.length - 4}
                </h3>
            </div> */}
        </div>
    );
}

function renderArrowPrev(colorArrow) {
    return (onClickHandler, hasPrev) => hasPrev && (
        <button type="button" className='arrow arrow-left' onClick={onClickHandler} style={{ left: '-8px', background: 'rgba(255, 255, 255, 0.6)', height: '70px', boxShadow: '0px 0px 10px 0px #919191' }}>
            <i className='fa fa-chevron-left' style={{ color: colorArrow }}></i>
        </button>
    );
}

function renderArrowNext(colorArrow) {
    return (onClickHandler, hasPrev) => {
        return hasPrev && (
            <button type="button" className='arrow arrow-right' onClick={onClickHandler} style={{ right: '-8px', background: 'rgba(255, 255, 255, 0.6)', height: '70px', boxShadow: '0px 0px 10px 0px #919191' }}>
                <i className='fa fa-chevron-right' style={{ color: colorArrow }}></i>
            </button>
        );
    };
}
