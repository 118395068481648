import React from "react";

import Traslate from "../../utils/translation/User.json";

import "./AuctionJoinPage.css";

const googleLogo = `${ process.env.PUBLIC_URL }/images/google-logo.png`;

const AuctionJoinPage = () => {
  return (
    <div className="container" style={ { minHeight: "78vh" } }>
      <div className="row mt-5 mb-5">
        <div className="col-lg-6">
          <h1 className="col-12 mb-4 font-weight-bold h2">
            { Traslate.JOIN.TITLE }
          </h1>
          <h2 className="col-12 mb-4">
            Xercana la forma más cómoda para comercializar tu hacienda:
          </h2>
          <p className="col-12 mb-4 text-justify">
            <ul>
              <li>Operación 100% garantizada.</li>
              <li>
                Compra y vende tu hacienda sin moverte de tu lugar de producción
                (mercado online de hacienda).
              </li>
              <li>
                Tus Liquidaciones de Compra/Venta emitidas por Consignataria
                (Consignaciones Córdoba S.A.).
              </li>
              <li>
                Pagá tus compras con los tarjeta de crédito.
              </li>
            </ul>
          </p>
          <h2 className="col-12 mb-4">Sobre nosotros</h2>
          <p className="col-12 mb-4 text-justify">
            Xercana nace en respuesta a las necesidades del Mercado Ganadero de
            agilizarse, modernizar sus procesos e incorporar nuevas tecnologías.
            Es fruto de un convenio de desarrollo entre la “Universidad Católica
            de Córdoba”, Consignaciones Córdoba SA, Productores y Profesionales
            de varios sectores.
          </p>
        </div>
        <div className="col-lg-6 ">
          <h2 className="text-center mb-4 h3">
            ¡Únete a Xercana solo con tu cuenta de Google!
          </h2>
          <div className="col-12 w-100">
            <a
              className="mx-auto btn btn-block social-btn google"
              href={ process.env.REACT_APP_AUCTIONS_GOOGLE_AUTH_URL }
            >
              <img src={ googleLogo } alt="Google" /> Registrate con google AUCTIONS
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionJoinPage;
