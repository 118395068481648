import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import useWindowSize from "../../../../hooks/useWindows";
import { getTransportTypes } from '../../../../api/private/client';
import { isLogin } from '../../../../libs/Auth';


const CarrierStep = ( { context } ) => {
  const size = useWindowSize();

  const {
    purchase,
    setTransportaXercana,
    setTipoTransporte,
    setEmpresaTransporte,
    setMarcaTransporte,
    setCuitTransporte,
    setChofer,
    setPatenteAcoplado,
    setPatenteChasis,
    setTieneAcoplado,
  } = context;

  const [ sizeMobil, setSizeMobil ] = useState( false );
  const [ loading, setLoading ] = useState( false );

  const [ customerTransportFlag, setCustomerTransportFlag ] = useState( false );
  const [ xercanaTransportFlag, setXercanaTransportFlag ] = useState( false );

  const [ isOpen, setIsOpen ] = useState( false );
  const [ transportTypes, setTransportTypes ] = useState();
  const [ transportType, setTransportType ] = useState();

  useEffect( () => {
    setSizeMobil( size.width < 760 );
    if ( purchase?.transportaXercana === 'no' ) setCustomerTransportFlag( true );
    if ( purchase?.transportaXercana === 'si' ) setXercanaTransportFlag( true );
  }, [ size ] );

  const closeModal = () => {
    setIsOpen( false );
  }

  const openModal = async () => {
    setLoading( true );
    const types = await getTransportTypes();
    setTransportTypes( types?.data );
    setTransportaXercana( "no" );
    setIsOpen( true );
    setLoading( false );
  };

  const customStyles = {
    content: {
      // top: '50%',
      left: '0%',
      right: '0%',
      bottom: 'auto',
      maxHeight: 'calc(100vh - 100px)',
      overflowY: 'auto'
      // marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
    },
  };

  function handleCustomerTransportDataForm( e ) {
    e.preventDefault();
    setTipoTransporte( "1" );
    setCustomerTransportFlag( true );
    setXercanaTransportFlag( false );
    setTransportaXercana( "no" );
    setIsOpen( false );
  }

  function handleXercanaTransport() {
    setCustomerTransportFlag( false );
    setXercanaTransportFlag( true );
    setTransportaXercana( "si" );
  }

  const handleTransportTypes = ( e ) => {
    const est = transportTypes.find( ( tipo ) => {
      return tipo.id === e.target.value;
    } );
    setTransportType( est?.id );
    setTipoTransporte( est?.id );
  };


  return <>
    <div className='container'>
      <h2 className='d-flex justify-content-center mt-3'>
        Transporte
      </h2>
      <div className="text-center alert alert-info">
        Recuerde que nosotros podemos encargarnos del traslado de su compra, el cual será cotizado en los pasos siguientes;
        pero si usted quiere trasladar su compra, por favor haga click en el botón inferior <strong>"Quiero trasladar mi compra"</strong>.
      </div>
      <div className={ sizeMobil ? '' : 'd-flex justify-content-around'}>

        <CustomerTransportButton
          className='col-md-6'
          loading={ loading }
          customerTransport={ customerTransportFlag }
          openModal={ openModal }
        />
        
        <XercanaTransportButton 
          className='col-md-6'
          loading={ loading }
          xercanaTransport={ xercanaTransportFlag }
          handleXercanaTransport={ handleXercanaTransport }  
        />
        
      </div>

      <Modal isOpen={ isOpen } onRequestClose={ closeModal } style={ customStyles } shouldCloseOnOverlayClick={ false }>

        <div className='d-flex justify-content-end mb-3'>
          <i
            className='far fa-times-circle StepperModal-btn-close'
            onClick={ closeModal }
          />
        </div>
        <div className="text-center alert alert-success">
          Recuerde que todos los datos son obligatorios.{ <br></br> }
        </div>
        <form>
          <div className={ !sizeMobil ? "container-xl pb-4" : "container" }>
            <div className='row'>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="alert alert-info w-100" role="alert">
                  <div className="mb-3">
                    <label htmlFor="iva_id" className="font-weight-bold">Tipo de transporte</label>
                    <select
                      placeholder="Seleccione el tipo de transporte"
                      id="tipo_transporte_id"
                      className="form-control"
                      value={ transportType }
                      name="tipo_transporte"
                      onChange={ handleTransportTypes }
                    >
                      { transportTypes?.map( ( e ) => (
                        <option key={ e.id } value={ e.id }>
                          { e.tipo }
                        </option>
                      ) ) }
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="font-weight-bold">Chofer</label>
                    <input
                      id="chofer_id"
                      className="form-control"
                      name="chofer"
                      placeholder="Nombre del chofer"
                      type="text"
                      onChange={ e => setChofer( e.target.value ) }
                    />
                  </div>

                  <div className="mb-3">
                    <label className="font-weight-bold">CUIT transporte</label>
                    <input
                      id="cuit_id"
                      className="form-control"
                      name="cuit_transporte"
                      placeholder="CUIT transporte"
                      type="text"
                      onChange={ e => setCuitTransporte( e.target.value ) }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="font-weight-bold">Empresa de transporte</label>
                    <input
                      id="empresa_id"
                      className="form-control"
                      name="empresa_transporte"
                      placeholder="Nombre de empresa transporte"
                      type="text"
                      onChange={ e => setEmpresaTransporte( e.target.value ) }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="alert alert-info w-100" role="alert">
                  <div className="mb-3">
                    <label className="font-weight-bold">Marca de transporte</label>
                    <input
                      id="marca_id"
                      className="form-control"
                      name="marca_transporte"
                      placeholder="Marca transporte"
                      type="text"
                      onChange={ e => setMarcaTransporte( e.target.value ) }
                    />
                  </div>
                  <div className="mb-5">
                    <label className="font-weight-bold">Patente del chasis</label>
                    <input
                      id="patente_chasis_id"
                      className="form-control"
                      name="patente_chasis"
                      placeholder="Patente chasis"
                      type="text"
                      onChange={ e => setPatenteChasis( e.target.value ) }
                    />
                  </div>
                  <div className="mb-4">
                    <label className="font-weight-bold">Tiene acoplado?</label>
                    <div className="col-md-2 float-right">
                      <input
                        id="tiene_acoplado_id"
                        className="form-control"
                        name="tiene_acoplado"
                        placeholder="Tiene acoplado?"
                        type="checkbox"
                        onChange={ e => setTieneAcoplado( e.target.checked ) }
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="font-weight-bold">Patente acoplado</label>
                    <input
                      id="patente_acoplado_id"
                      className="form-control"
                      name="patente_acoplado"
                      placeholder="Patente acoplado"
                      type="text"
                      onChange={ e => setPatenteAcoplado( e.target.value ) }
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <button
                disabled={
                  ( purchase.chofer === "" ||
                    purchase.cuit_transporte === "" ||
                    purchase.empresa_transporte === "" ||
                    purchase.marca_transporte === "" ||
                    purchase.patente_chasis === "" ||
                    ( ( purchase.tiene_acoplado && purchase.patente_acoplado === "" ) ||
                      ( !purchase.tiene_acoplado && purchase.patente_acoplado !== "" ) ) )
                }
                className="btn btn-info"
                type="button"
                onClick={ handleCustomerTransportDataForm }>
                Guardar datos de transporte
              </button>
            </div>
          </div>
        </form>
      </Modal>


      <div className='d-flex gap-2 justify-content-end mt-3'>
        <button
          className='btn btn-info'
          type='submit'
          disabled={ !xercanaTransportFlag && !customerTransportFlag }
        >
          Siguiente
        </button>
      </div>
      {/* )} */ }
    </div>

  </>;
};

const XercanaTransportButton = ({ xercanaTransport, handleXercanaTransport, loading }) => {
  return <button
          className={ xercanaTransport ? 'btn btn-success m-2' : 'btn btn-outline-info m-2' }
          disabled={ loading }
          type='button'
          onClick={ handleXercanaTransport }
        >
          Quiero que Xercana transporte mi compra
        </button>
}

const CustomerTransportButton = ({ customerTransport, openModal, loading }) => {
  return loading 
          ?
            <div className='btn btn-outline-info d-flex justify-content-center m-2'>
              <div className="spinner-border"/>
            </div>
          :
            <button
              className={ customerTransport ? 'btn btn-success m-2' : 'btn btn-outline-info m-2' }
              disabled={ loading }
              type='button'
              onClick={ openModal }
            >{
            ( customerTransport )
              ?
              'Datos de transporte guardados'
              :
              'Quiero trasladar mi compra'
            }
            </button>
}

export default CarrierStep;
