import { TileLayer, MapContainer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import './EstablishmentCard.css';


const EstablishmentCard = ({ establishment }) => {
    const position = [establishment.lat, establishment.lng];

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon2x,
        shadowUrl: markerShadow,
    })

    function ChangeMapView({ coords }) {
        const map = useMap();
        map.setView(coords, map.getZoom());

        return null;
    }

    return <>
        <div className='card my-4'>
            <div className='row card-body'>
                <div className='col-12'>
                    <p className='EstablishmentCard-title'>{establishment?.nombre}</p>
                    <p className='card-text'>Código Renspa: {establishment?.codeRenspa ?? '-'}</p>
                    <p className='card-text'>Provincia: {establishment?.province?.province ?? '-'}</p>
                    <p className='card-text'>Localidad: {establishment?.locality?.name ?? '-'}</p>
                </div>
                <div className='col-12 w-100 my-4 EstablishmentCard-map-container'>
                    <MapContainer
                        center={position}
                        zoom={12}
                    >
                        {/* <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        /> */}
                        <TileLayer
                            url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                            maxZoom= {20}
                            subdomains={['mt0','mt1','mt2','mt3']}
                        />
                        <Marker
                            position={position}
                        />
                        <ChangeMapView coords={position} />
                    </MapContainer>
                </div>
            </div>
        </div>
    </>;
};

export default EstablishmentCard;
