import React from 'react'
import BottonLine from '../../../components/BottonLine';
import { RealBreadCrumb } from '../../../components/breadcrumb/Breadcrumb';
import LinkWsp from '../../../components/LinkWsp/LinkWsp';
import routers from '../../../config/routers';

import '../HelpScreen.css';

export default function AtentionTime() {
    
    return (
        <div style={{minHeight:'80vh',paddingBottom:'5rem'}}>
        <h1 className="text-center w-100 font-weight-bold" style={{fontSize:'2rem',marginTop:'3rem'}}>Horarios de atención</h1>
        <div style={{width:'70%',margin:'0 auto',marginTop:'1rem'}}>
            <RealBreadCrumb
                    refNames={['Ayuda ','Horarios de atención']}
                    hrefs={[routers.help.path,  '#']}
                    />
            </div>
        <div className="helpScreenContainerDiv helpIndividual">
             <div style={{display:'flex',flexDirection:'column'}}>
                <h1 className="text-center">¿Cuáles son los horarios y días de atención?</h1>
                <BottonLine width="100%"/>
                <p className="p-5 mt-4 mb-1">
                Xercana MARKET PLACE, cuenta con horarios de atención de 10 a 17 hs de Lunes a Viernes. <br/>El horario de atención de cada vendedor puedes consultarlo directamente con cada uno.
                </p>
                <LinkWsp></LinkWsp>
            </div>
        </div>
        </div>
    )
}
