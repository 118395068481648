import { useEffect, useState } from 'react';

import getViewLoading from './ViewParts/LoadingPublicationLiveStock';
import getImagesContainer from './ViewParts/Main/ImageContainer';
import SaleContainer from './ViewParts/Main/SaleContainer';
import getSellerContainer from './ViewParts/Main/SellerContainer';
import ReviewContainer from './ViewParts/Main/ReviewContainer'
import Title from './ViewParts/Main/TitleLiveStock';
import getBatchSwitch from './ViewParts/Switch/BatchSwitch';
import getRevisionSwitch from './ViewParts/Switch/RevisionSwitch';
import getLocationSwitch from './ViewParts/Switch/LocationSwitch';
import { getPublication, getPublicationDetail } from '../../api/private/client';
import { getPublicationAuction } from '../../api/public/client';
import BottonLine from '../../components/BottonLine';
import Comment from '../../components/comment/Comment';
import Container from '../../components/container/Container';
import Line from '../../components/line/Line';
import MultipleSwitch from '../../components/multipleSwitch/MultipleSwitch';
import Scroll from '../../components/scroll/Scroll';
import useWindowSize from '../../hooks/useWindows';
import Traslate from '../../utils/translation/User.json';
import './PublicationLiveStock.css';
import useProfile from '../../hooks/profile/useProfile';
import getDatosBancariosSwitch from './ViewParts/Switch/DatosBancariosSwitch';
import { useAuthContext } from "../../context/AuthenticatedContext";
import ProfileProvider from '../../context/ProfileContext';
import PublicationLiveStockPublic from './PublicationLiveStockPublic';

const PublicationLiveStock = ( props ) => {

    const { isLogged  } = useAuthContext();

    if (!isLogged()) {
        return <PublicationLiveStockPublic props={props}/>;
    }

    return <>
      <ProfileProvider>
        <PublicationLiveStockChildren props={props}/>
      </ProfileProvider>
    </>;
  };


const PublicationLiveStockChildren = ({props}) => {
    const match = props.match;    
    const size = useWindowSize();
    const [product, setProduct] = useState(null);
    const [others, setOthers] = useState(null);
    const [detail, setDetail] = useState(null);
    const [mobil, setMobil] = useState(false);
    const [disabledBuy, setDisabledBuy] = useState(false);
    const [sameBuyerAsSeller, setSameBuyerAsSeller] = useState(false);
    const { profile } = useProfile();
    const [video, setVideo] = useState("");
    const { auth, isCommercial } = useAuthContext();



    useEffect(() => {
        setMobil(size.width < 780);
    }, [size]);


    useEffect(() => {

        (async () => {
            const body = document.querySelector('#root');
            body.scrollIntoView({
                behavior: 'smooth'
            }, 500);
            const { data } = await getPublication(match.params.id);
            setProduct(data);
        })();
        (async () => {
            const { data } = await getPublicationAuction();
            setOthers(data);
        })();        

    }, [match.params.id]);

    useEffect(() => {
        if (product) {

            (async () => {
                const { data } = await getPublicationDetail({
                    idPublication: match.params.id,
                    esCompra: (match.params.sale === 'no') ? false : true,
                    subastaDirecta: product.subasta_directa
                });
                setDetail(data);
            })();            

            product.catalogue.map((element) => {
                if (!element.includes('jpg') && !element.includes('jpeg') && !element.includes('png'))
                    setVideo(element); 
            })

            // Producto Desactivado (ej: caso que ya este comprado)
            if (!product.enabled) {
                setDisabledBuy(true);
                return;
            }

            // get user id and validate that it is not the seller
            if (product?.person?.id === profile?.id) {
                setDisabledBuy(true);
                setSameBuyerAsSeller(true);
                return;
            }
        }
        if (match.params.sale === 'no') {
            setDisabledBuy(true);
        }
    }, [product]);


    if (!product) return getViewLoading();

    return (
        <div className='main-container'>

            <Container className='xercana-main-container row' shadow={true}>
                {Title(product.title, product.id)}
                {/* {getImagesContainer(mobil, video)} */}

                <div className='col-lg-8 d-flex justify-content-center mt-3'>                
                    <div className="shadow-lg p-1 bg-light rounded">
                        <video src={video}  width={'100%'} controls></video>
                        <div className="border rounded shadow col-12 p-2 w-100 text-justify mt-2">                    
                            {/* <div className='col-11 text-justify' style={{ lineHeight: "2" }}> */}
                                <p>{product.description}</p>
                            {/* </div> */}
                        </div>
                        { isCommercial() 
                        ? (<><div className="border rounded shadow col-12 pl-4 mb-5 w-100 text-justify">
                            <h1 className='col-12 font-weight-bold'>{Traslate.PUBLICATION_LIVE_STOCK.INTERNAL_DESCRIPTION}</h1>
                            <div className='col-11 text-justify' style={{ lineHeight: "2" }}>
                                <p>{product.internal_description}</p>
                            </div>
                        </div></>)
                        : (null)}
                    </div>  
                </div>
                
                    <SaleContainer
                        mobil={mobil}
                        product={product}
                        detail={detail}
                        disabledBuy={disabledBuy}
                        sameBuyerAsSeller={sameBuyerAsSeller}
                        sale={match.params.sale}
                    />
                
                
                {mobil ? <Line className='line-edit' /> : null}
                <div className='row mb-3'>
                    {getSwitches(mobil, product, disabledBuy)}
                    {mobil ? <Line className='line-edit' /> : null}
                    {getSellerContainer(mobil, product)}
                </div>
                {/* <ReviewContainer product={product} /> */}
                {product.state !== "Comprada" &&
                    <Comment product={product} />}
                {getOtherPublications(others)}
            </Container>
        </div>
    )
}

function getSwitches(mobil, product, disabledBuy) {
    // const { auth, isCommercial } = useAuthContext();

    return <div className='col-12 col-lg-8'>
        {/* <div className="border rounded shadow col-12 pl-4 mb-2 w-100 text-justify mt-2">
            <h1 className='col-12 font-weight-bold'>{Traslate.PUBLICATION_LIVE_STOCK.PRODUCT_DESCRIPTION}</h1>
            <div className='col-11 text-justify' style={{ lineHeight: "2" }}>
                <p>{product.description}</p>
            </div>
        </div>
        { isCommercial() 
        ? (<><div className="border rounded shadow col-12 pl-4 mb-5 w-100 text-justify">
            <h1 className='col-12 font-weight-bold'>{Traslate.PUBLICATION_LIVE_STOCK.INTERNAL_DESCRIPTION}</h1>
            <div className='col-11 text-justify' style={{ lineHeight: "2" }}>
                <p>{product.internal_description}</p>
            </div>
        </div></>)
        : (null)} */}
        
        {disabledBuy ? (
            <MultipleSwitch classNameChild='col-lg-11 col-sm-12'>
                {getBatchSwitch(mobil, product)}
                {getRevisionSwitch(mobil, product)}
                {getLocationSwitch(mobil, product)}
                {getDatosBancariosSwitch(mobil, product)}
            </MultipleSwitch>
        ) : (
            <MultipleSwitch classNameChild='col-lg-11 col-sm-12'>
                {getBatchSwitch(mobil, product)}
                {getRevisionSwitch(mobil, product)}
                {getLocationSwitch(mobil, product)}
            </MultipleSwitch>)
        }

    </div>;
}

function getOtherPublications(others) {
    return <div className='row'>
        <div className='col-12'>
            <BottonLine width="100%" margintop="mb-4" />
            <h1 className="font-weight-bold text-center mb-3 mt-2">{Traslate.PUBLICATION_LIVE_STOCK.SIMILAR_PUBLICATION}</h1>
            <BottonLine width="100%" margintop="mb-4" />
            <Scroll scrolltop="true" data={others}></Scroll>
        </div>
    </div>;
}

export default PublicationLiveStock;
