import React from 'react';
import AuthenticatedProvider from '../../context/AuthenticatedContext';
import { GoogleAuthProvider } from '../../libs/AuthenticatedGoogle';

const Reducer = (props) => {
    return (
        <AuthenticatedProvider>
            <GoogleAuthProvider>
                {props.children}
            </GoogleAuthProvider>
        </AuthenticatedProvider>
    )
}

export default Reducer; 