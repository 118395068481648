import { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindows";
import { getLinears, getPublicationOffer } from "../../api/public/client";
import BottonLine from "../../components/BottonLine";
import BtnWsp from "../../components/BtnWsp/BtnWsp";
import Container from "../../components/container/Container";
import {
  ClockIcon,
  EqualIcon,
  IconUpGreen,
  PigIcon,
  SecureIcon,
  UpIconRed,
} from "../../components/Icons/Icons";
import Scroll from "../../components/scroll/Scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./HomePage.css";


const HomePage = () => {
  const size = useWindowSize();
  const [ sizeMobil, setSizeMobil ] = useState( false );
  const [ otros, setOtros ] = useState( [] );
  const [ vendidos, setVendidos ] = useState( [] );
  const [ publicaciones, setPublicaciones ] = useState( [] );
  const [ subastaDirecta, setSubastaDirecta ] = useState( [] );
  const [ dataLinears, setLinears ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ priceTable0 ] = useState( `https://storage.googleapis.com/xercana/containers/storage/tablaPrecios0?time=${ new Date() }` );
  const [ priceTable1 ] = useState( `https://storage.googleapis.com/xercana/containers/storage/tablaPrecios1?time=${ new Date() }` );
  const [ priceTable2 ] = useState( `https://storage.googleapis.com/xercana/containers/storage/tablaPrecios2?time=${ new Date() }` );
  const mobileBannerUrl  = `${process.env.PUBLIC_URL}/images/banner-mobile.png`;
  const desktopBannerUrl = `${process.env.PUBLIC_URL}/images/banner-1600px-medium.png`;

  const pricesForPlate = {
    priceTable0,
    priceTable1,
    priceTable2
  };

  useEffect( () => {
    setSizeMobil( size.width < 760 );
  }, [ size ] );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const { data } = await getPublicationOffer();
      let mej = [];
      let novillitos = [];
      let novillos = [];
      let terneras = [];
      let terneros = [];
      let toros = [];
      let torunos = [];
      let vacCc = [];
      let vacaCria = [];
      let vacas = [];
      let vaquillonas = [];

      let publicaciones = [];
      let otros = [];
      let vendidos = [];
      let subastaDirectaArray = [];

      data.forEach( element => {
        const category = element?.detail?.category;
        const state = element?.state;
        const subasta = element?.subasta_directa;

        if ( state === 'Publicada' ) {
          if ( subasta ) {
            subastaDirectaArray.push( element );
            return;
          }

          switch ( category ) {
            case 'MEJ':
              mej.push( element );
              break;
            case 'Novillitos':
              novillitos.push( element );
              break;
            case 'Novillos':
              novillos.push( element );
              break;
            case 'Terneras':
              terneras.push( element );
              break;
            case 'Terneros':
              terneros.push( element );
              break;
            case 'Toros':
              toros.push( element );
              break;
            case 'Torunos':
              torunos.push( element );
              break;
            case 'Vac. c/c':
              vacCc.push( element );
              break;
            case 'Vaca Cria':
              vacaCria.push( element );
              break;
            case 'Vacas':
              vacas.push( element );
              break;
            case 'Vaquillonas':
              vaquillonas.push( element );
              break;

            default:
              break;
          }
        }

        if ( state == 'Comprada' ) { vendidos.push( element ) }
      } );

      // Aqui compruebo si los arrays de cada categoria contienen mas de 2 publicaciones.
      // Si tiene mas de 2 publicaciones, agrego ese array al de Publicaciones para luego mostrar un Carousel por cada Categoria.
      // Si no, sumo el contenido al array Otros.
      ( mej.length > 0 )         ? publicaciones.push( mej )         : otros.push( ...mej );
      ( novillitos.length > 0 )  ? publicaciones.push( novillitos )  : otros.push( ...novillitos );
      ( novillos.length > 0 )    ? publicaciones.push( novillos )    : otros.push( ...novillos );
      ( terneras.length > 0 )    ? publicaciones.push( terneras )    : otros.push( ...terneras );
      ( terneros.length > 0 )    ? publicaciones.push( terneros )    : otros.push( ...terneros );
      ( toros.length > 0 )       ? publicaciones.push( toros )       : otros.push( ...toros );
      ( torunos.length > 0 )     ? publicaciones.push( torunos )     : otros.push( ...torunos );
      ( vacCc.length > 0 )       ? publicaciones.push( vacCc )       : otros.push( ...vacCc );
      ( vacaCria.length > 0 )    ? publicaciones.push( vacaCria )    : otros.push( ...vacaCria );
      ( vacas.length > 0 )       ? publicaciones.push( vacas )       : otros.push( ...vacas );
      ( vaquillonas.length > 0 ) ? publicaciones.push( vaquillonas ) : otros.push( ...vaquillonas );

      // publicaciones.push( mej, novillitos, novillos, terneras, terneros, toros, torunos, vacCc, vacaCria, vacas, vaquillonas );

      setPublicaciones( publicaciones );
      // setOtros( otros );
      setVendidos( vendidos );
      setSubastaDirecta( subastaDirectaArray );
    } )();

    ( async () => {
      const { data } = await getLinears();
      setLinears( data );
      setIsLoading( false );
    } )();
  }, [] );


  return (
    <>
      <BtnWsp />

      { !sizeMobil 
      ? <HomePageBanner urlBanner={ desktopBannerUrl } />
      : <HomePageBanner urlBanner={ mobileBannerUrl } />}

      <div className={ !sizeMobil ? "container-xl pb-4" : "container" }>

        { ( !sizeMobil ) && <InfoCards /> }

        <div className="row container_HomeScreen">
          <div
            className={ sizeMobil || !dataLinears
              ? "col-12"
              : "col-lg-8 col-md-12 col-sm-12" }
          >
            { subastaDirecta.length > 0 ? <CategoryBanner title={'Subasta directa'} data={ subastaDirecta } /> : null }

            { publicaciones.map( ( item, index ) => (
              <div key={ index }>
                <CategoryBanner title={ item[ 0 ]?.detail?.category } data={ item } />
              </div>
            ) ) }

            {/* { otros.length > 0 ? <CategoryBanner title={'Otras categorias'} data={ otros } /> : null } */}

            { vendidos.length > 0 ? <CategoryBanner title={'Operaciones concretadas'} data={ vendidos } /> : null }
          </div>

          { ( sizeMobil ) && <InfoCards /> }

          <div className="col-lg-4 container_Linears col-md-9 mx-auto">
            <PricePlates prices={pricesForPlate} />
            <LinearsCards dataLinears={ dataLinears } loading={ isLoading }/>
          </div>
        </div>
      </div>
    </>
  );
};


const InfoCards = () => {
  return (
    <>
      <div className="containerInfoCards ">
        <div className="containerinfoCards_item">
          <div>
            <PigIcon height="50" width="53" />
          </div>
          <div className="containerInfoCards_text">
            <h1>Ahorrá tu dinero</h1>
            <p>Comprá los lotes más rentables para tu negocio.</p>
          </div>
        </div>
        <div className="containerinfoCards_item ">
          <div>
            <ClockIcon height="50" width="53" />
          </div>
          <div className="containerInfoCards_text">
            <h1>Pensá en tiempo</h1>
            <p>
              Aprovechá y concretá grandes oportunidades sin moverte de tu casa.
            </p>
          </div>
        </div>
        <div className="containerinfoCards_item">
          <div>
            <SecureIcon height="50" width="53" />
          </div>
          <div className="containerInfoCards_text">
            <h1>Simple y seguro</h1>
            <p>Una forma fácil y segura de operar las 24hs.</p>
          </div>
        </div>
      </div>
    </>
  );
};

const LinearsCards = ({ dataLinears, loading }) => {
  return (
    <>
      { loading ? 
      ( <div className="d-flex justify-content-center mt-5">
          <div className="spinner-grow text-success" role="status"></div>
        </div>
      ) :
      (<>
        <p className="d-flex justify-content-center mt-5">
          <button className='btn btn-info' type="button" data-bs-toggle="collapse" data-bs-target="#linearsCard" aria-expanded="false" aria-controls="linearsCard">

            Ver precios del Mercado de Cañuelas
            <span className="fas fa-angle-down ml-2 pt-1" aria-hidden="true"></span>
          </button>
        </p>
        <div className="collapse" id="linearsCard">
          <div className="d-flex justify-content-center">
            <p className="bold text-center mb-0 pl-5 pr-5">Precios al { dataLinears[ 0 ]?.fecha_cotiz }</p>
          </div>
          <Container>
            <table className="table table-hover table-info border-success">
              <tbody>
                { dataLinears?.map( ( e, index ) => (
                  <tr key={ index } className=''>
                    <td className="border-bottom">
                      <span className="bold">{ e.title }</span><br />
                      <span>Cant. operada: { e.cabezas }</span>
                    </td>
                    <td className="text-right border-bottom bold">${ e.price }</td>
                    <td className="text-center mt-4">{ ( e.balance === 'down' ) ? <UpIconRed /> : ( e.balance === 'equal' ) ? <EqualIcon /> : <IconUpGreen /> }  </td>
                  </tr>
                ) ) }
              </tbody>
            </table>
          </Container >
        </div>
      </>)}
     
    </>
  )
}

const PricePlates = ({ prices }) => {
  return (
  <>
    <div className="d-flex justify-content-center">
      <img
        className="logo-consignee"
        src={ `${ process.env.PUBLIC_URL }/images/logo-txto-cc.jpeg` }
      />
    </div>
    <div className="card rounded-5 shadow bg-ligth">
      <div className="card-body p-1">
        <Carousel
          autoPlay={ true }
          emulateTouch={ true }
          infiniteLoop={ true }
          showThumbs={ false }
          stopOnHover={ true }
          className='shadow-lg'
        >
          <div>
            <img src={ prices.priceTable0 } />
          </div>
          <div>
            <img src={ prices.priceTable1 } />
          </div>
          <div>
            <img src={ prices.priceTable2 } />
          </div>
        </Carousel>
      </div>
    </div>
  </>
  )
}

const CategoryBanner = ({ title, data }) => {
  return <>
          <div className='bold ml-2 mb-2'>
            {title}
          </div>
          <Scroll data={ data } colorArrow="#1275D8" className="pl-0" />
          <BottonLine width="94%" />
        </>
}

const HomePageBanner = ({ urlBanner }) => {
  return <img className="HomePage-banner" src={ urlBanner } />
}

export default HomePage;
