import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { TileLayer, useMap, MapContainer, Marker } from 'react-leaflet';
import SearchField from '../../SearchFieldMap/SearchFieldMap';
import routers from './../../../config/routers'
import { useHistory } from 'react-router';
import { useAuthContext } from "../../../context/AuthenticatedContext";



import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';

import Padding from '../../../components/padding/Padding';
import { useProfileContext } from '../../../context/ProfileContext';
import useAddress from '../../../hooks/profile/useAddress';
import Translate from '../../../utils/translation/User.json';
import './EstablishmentModal.css'
import Swal from "sweetalert2";
import { createUserEstablishment, updateUserEstablishment } from '../../../api/private/client';

const EstablishmentModal = ( { establishment, isOpen, closeModal } ) => {

    const { register, handleSubmit, reset, errors } = useForm();
    const { addEstablishment, editEstablishment, profile, postProfile } = useProfileContext();
    const { provinces, localities, changeProvince } = useAddress();
    const [ position, setPosition ] = useState( [ -31.417, -64.183 ] );
    const [ positionProvice, setPositionProvince ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( true );
    const [ newUser, setNewUser ] = useState( false );
    const history = useHistory();
    const auth = useAuthContext();


    useEffect( () => {
        setIsLoading( true );
        const { lat, lng } = establishment;
        if ( lat && lng ) {
            setPosition( [ lat, lng ] );
        } else {
            setPosition( [ -31.417, -64.183 ] );
        }
        ( async () => {
            await changeProvince( establishment?.province?.id );
            reset( establishment );
            setIsLoading( false );
        } )();
    }, [ establishment.index ] );

    useEffect( () => {
        if ( profile === null ) {
            return;
        } else {
            const userRoles = profile.roles;
            const haveVisitorRole = profile.roles.find( ( obj ) => obj.id === 1 );

            if ( userRoles.length === 1 && haveVisitorRole ) setNewUser( true ); // Tiene un solo rol y es Rol Base
        }
    }, [ profile ] );


    const onSubmit = async ( data ) => {
        const [ lat, lng ] = position;

        if ( JSON.stringify( position ) === JSON.stringify( positionProvice ) ) {
            Swal.fire(
                "Error en ubicación",
                "Debe precisar la ubicación de su Establecimiento en el mapa. Puede utilizar el buscador para aproximarse al mismo",
                "warning"
            );
            return;
        }
        const est = { ...establishment, ...data, lat: lat, lng: lng };
        if ( establishment.index === null ) {
            sendingDataModal();
            addEstablishment( est );
            try {
                await postProfile();
                await createUserEstablishment( profile.id, est );
                if ( newUser ) {
                    successfulUpdateModal();
                }
                else {
                    Swal.fire( {
                        title: 'Establecimiento creado',
                        text: 'El Establecimiento fue registrado exitosamente.',
                        icon: 'success',
                    } );
                }

            } catch ( err ) {
                errorModal();
            }
        } else {
            sendingDataModal();
            editEstablishment( est );
            try {
                await updateUserEstablishment( profile.id, est );
                Swal.fire( {
                    title: 'Establecimiento editado',
                    text: 'El Establecimiento fue editado correctamente.',
                    icon: 'success',
                } );
            } catch ( err ) {
                errorModal();
            }
        }
        closeModal();
    };

    const successfulUpdateModal = () => {
        Swal.fire( {
            customClass: "swal-wide",
            title: '¡Datos actualizados!',
            text: 'Su perfil ha sido actualizado, pero su cuenta aún no ha sido habilitada para operar. Recibirá una notificación dentro de las próximas 24 horas una vez que sus datos sean verificados',
            icon: 'success',
        } ).then( () => {
            auth.logOut();
            history.go( 0 );
            // history.push( routers.home.path );
        } );
    }

    const errorModal = () => {
        Swal.fire( {
            title: 'Error',
            text: 'El establecimiento no ha podido ser registrado.',
            icon: 'error',
        } );
    }

    const sendingDataModal = () => {
        Swal.fire( {
            title: 'Enviando datos',
            text: 'Aguarde unos instantes.',
            icon: 'info',
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
        } );
    }

    const handleProvince = e => {
        changeProvince( e.target.value );
        let provincia = provinces.find( provincia => provincia.id == e.target.value );
        setPosition( [ parseFloat( provincia.latitude ), parseFloat( provincia.longitude ) ] );
        setPositionProvince( [ parseFloat( provincia.latitude ), parseFloat( provincia.longitude ) ] );
    };

    const handleMap = e => {
        const { lat, lng } = e.latlng;
        setPosition( [ lat, lng ] );
    };

    function ChangeMapView( { coords } ) {
        const map = useMap();
        map.setView( coords, map.getZoom() );
        return null;
    }



    return <>
        <Modal
            isOpen={ isOpen }
            onRequestClose={ closeModal }
        >
            <div className='d-flex justify-content-end mb-3'>
                <i
                    className='far fa-times-circle StepperModal-btn-close'
                    onClick={ closeModal }
                />
            </div>
            <h2 className='bold d-flex justify-content-center'>
                { `${ establishment.index === null ? 'Crear' : 'Editar' } Establecimiento` }
            </h2>
            { isLoading ? (
                <div className='ProfilePage-spinner my-4'>
                    <div className='spinner-border' role='status' />
                </div>
            ) : (
                <>
                    <Padding>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_establishment_name'>
                                Nombre del establecimiento
                            </label>
                            <input
                                id='id_establishment_name'
                                className={ `form-control ${ errors.nombre ? 'is-invalid' : '' }` }
                                name='nombre'
                                ref={ register( { required: true } ) }
                                placeholder='Ingrese el nombre'
                                type='text'
                            />
                            <div className='invalid-feedback'>
                                El nombre del establecimiento es requerido.
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_establishment_code'>
                                Código Renspa
                            </label>
                            <input
                                id='id_establishment_code'
                                className={ `form-control ${ errors.codeRenspa ? 'is-invalid' : '' }` }
                                name='codeRenspa'
                                ref={ register( { required: true } ) }
                                placeholder='Ingrese el código de renspa'
                                type='text'
                            />
                            <div className='invalid-feedback'>
                                El código de renspa es requerido.
                            </div>
                        </div>
                    </Padding>
                    <Padding>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_province'>
                                { Translate.PERSON.PROVINCE }
                            </label>
                            <select
                                id='id_province'
                                className={ `form-control ${ errors.province?.id ? 'is-invalid' : '' }` }
                                name='province.id'
                                ref={ register( { required: true, valueAsNumber: true } ) }
                                onChange={ handleProvince }
                            >
                                <option value=''>Seleccione su provincia...</option>
                                { provinces?.map( province =>
                                    <option
                                        key={ province.id }
                                        value={ province.id }
                                    >
                                        { province.province }
                                    </option>
                                ) }
                            </select>
                            <div className='invalid-feedback'>
                                La provincia es requerida.
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_locality'>
                                { Translate.PERSON.LOCALITY }
                            </label>
                            <select
                                id='id_locality'
                                className={ `form-control ${ errors.locality?.id ? 'is-invalid' : '' }` }
                                name='locality.id'
                                ref={ register( { required: true, valueAsNumber: true } ) }
                            >
                                <option value=''>Seleccione su localidad...</option>
                                { localities?.map( locality =>
                                    <option
                                        key={ locality.id }
                                        value={ locality.id }
                                    >
                                        { locality.name }
                                    </option>
                                ) }
                            </select>
                            <div className='invalid-feedback'>
                                La localidad es requerida.
                            </div>
                        </div>
                    </Padding>
                    <Padding>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_establishment_name'>
                                Dirección
                            </label>
                            <input
                                id='direccion'
                                className={ `form-control ${ errors.direccion ? 'is-invalid' : '' }` }
                                name='direccion'
                                ref={ register( { required: true } ) }
                                placeholder='Ingrese la dirección'
                                type='text'
                            />
                            <div className='invalid-feedback'>
                                La dirección del establecimiento es requerida.
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='col-form-label' htmlFor='id_establishment_name'>
                                Referencia
                            </label>
                            <input
                                id='referencia'
                                className={ `form-control ${ errors.referencia ? 'is-invalid' : '' }` }
                                name='referencia'
                                ref={ register( { required: true } ) }
                                placeholder='Ingrese la referencia'
                                type='text'
                            />
                            <div className='invalid-feedback'>
                                La referencia del establecimiento es requerida.
                            </div>
                        </div>
                    </Padding>

                    <h2 className='bold d-flex justify-content-center'>
                        { 'Para encontrar la posicion exacta, puede ayudarse con el buscador' }
                    </h2>
                    <div className='EstablishmentModal-map-container row'>
                        <div className='col-12'>
                            <MapContainer
                                center={ position }
                                zoom={ 6 }
                                whenReady={ map => map.target.on( "click", handleMap ) }
                            >
                                {/* <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                /> */}
                                <TileLayer
                                    url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                    maxZoom={ 20 }
                                    subdomains={ [ 'mt0', 'mt1', 'mt2', 'mt3' ] }
                                />
                                <Marker position={ position } />
                                <ChangeMapView coords={ position } />
                                <SearchField ></SearchField>
                            </MapContainer>
                        </div>
                    </div>
                    <button className='btn btn-info' onClick={ handleSubmit( onSubmit ) } type='button'>
                        { establishment.index === null ? 'Guardar establecimiento' : 'Guardar cambios' }
                    </button>
                </>
            ) }
        </Modal>
    </>;
};

export default EstablishmentModal;
