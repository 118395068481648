import React, { useState } from 'react';

import EstablishmentModal from './EstablishmentModal';
import EstablishmentTable from './EstablishmentTable';
import { useProfileContext } from '../../../context/ProfileContext';
import Translate from '../../../utils/translation/User.json';
import { getUserEstablishments } from '../../../api/private/client';
import Swal from 'sweetalert2';

const AddressInformation = ({ }) => {
    const [isOpen, setOpen] = useState(false);
    const { establishments, reloadEstablishments } = useProfileContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    let invalidElements = [];

    const initialValue = {
        nombre: '',
        codeRenspa: '',
        province: { id: null },
        locality: { id: null },
        lat: '',
        lng: '',
    };
    const [establishment, setEstablishment] = useState(initialValue);

    const closeModal = async () => {
        setIsLoading(true);
        setOpen(false);
        const { data } = await getUserEstablishments();
        reloadEstablishments(data);
        setIsLoading(false);
    };

    const openModal = (index = null) => {
        const data = establishments[index] ?? initialValue;
        setEstablishment({ ...data, index });
        setOpen(true);
    };

    const checkEmptyFields = () => {
        const elementsList = document.querySelectorAll("#id_company_cuit, #id_company_name");
        const elementsArray = [...elementsList];
        invalidElements = [];
        let html = "";
        elementsArray.map(e => {
          if (!e.checkValidity()) {
            switch (e.id) {
              case 'id_company_cuit':
                invalidElements.push('Cuit para operar');
                break;
              case 'id_company_name':
                invalidElements.push('Nombre de la compañia');
                break;
              default:
                break;
            }
          }    
        })
    
        invalidElements.forEach(element => {
          html += `<i>- ${element}</i><br>`
        });
    
        if (invalidElements.length > 0) {
          return Swal.fire({
            icon: 'warning',
            title: "Faltan datos por completar!",
            html: html
          });                    
        }else{
            openModal();
        }
    }

    return (
        <>
            <h2 className='col-12 d-flex justify-content-center mt-4 mb-3 font-weight-bold'>
                {Translate.PERSON.UBICATION_INFORMATION}
            </h2>
            <div className='d-flex justify-content-end'>
                <button
                    className='btn btn-success'
                    onClick={ async () =>  {
                        await checkEmptyFields();                         
                    }}
                    type='button'
                >
                    Agregar Establecimiento
                </button>
            </div>

            <EstablishmentModal
                establishment={establishment}
                setEstablishment={setEstablishment}
                isOpen={isOpen}
                closeModal={closeModal}
            />
            {establishments.length > 0 && !isLoading ? (
                <EstablishmentTable establishments={establishments} openModal={openModal} />
            ) : (isLoading) ? (
                <div className='ProfilePage-spinner my-4'>
                    <div className='spinner-border' role='status' />
                </div>
            ) : null}

        </>
    );
};

export default AddressInformation;
