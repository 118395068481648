import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import BuyerCard from "../../Card/BuyerCard";
import ReviewerCard from "../../Card/ReviewerCard";
import { getPublicationCompra, getTransportTypes } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";
import CostsCard from "../../Card/CostsCard";
import DocumentsCard from "../../Card/DocumentsCard";
import StateCard from "../../Card/StateCard";
import MapCard from "../../Card/MapCard";
import TransportCard from '../../Card/TransportCard';
import useProfile from "../../../../hooks/profile/useProfile";
import SellerCard from '../../Card/SellerCard';

interface Documents {
  dte?: string;
  liquidacionCompra?: string;
  liquidacionVenta?: string;
}

interface ITransportType {
  id: number;
  tipo: string;
}

interface IRoles {
  id: number;
  name: string;
  description: string;
}

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center my-4">
      <div className="spinner-border" role="status" />
    </div>
  );
};

/**
 * Publication detail modal.
 */
const PurchaseDetailModal: React.FC = () => {
  const { profile } = useProfile();
  const { modal, idPublication } = usePublicationsContext();
  const [ detail, setDetail ] = useState<any>();
  const [ documents, setDocuments ] = useState<Documents>();
  const [ isLoading, setIsLoading ] = useState( true );
  const [ isAdminLogged, setIsAdminLogged ] = useState( false );
  const [transportTypes, setTransportTypes] = useState<ITransportType[]>([]);

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      try {
        const { data } = await getPublicationCompra( idPublication ) as any;        
        setDetail( data );
        setDocuments( {
          dte: data.dte,
          liquidacionCompra: data.liquidacionCompra,
          liquidacionVenta: data.liquidacionVenta,
        } );
        const types = await getTransportTypes(); 
        setTransportTypes(types?.data);
        setIsLoading( false );
      } catch ( err ) {
        modal?.closeModal();
        Swal.fire( {
          title: "Error",
          icon: "error",
        } );
      }
    } )();
  }, [] );

  useEffect( () => {
    if ( profile === null ) {
      return;
    } else {
      const isAdmin = profile.roles.find( ( obj: IRoles ) => obj.id === 2 );
      if(isAdmin){
        setIsAdminLogged(true);
      }      
    }
  }, [ profile ] )
  

  return (
    <>          
      <h2 className="d-flex justify-content-center bold">
        Detalle de compra
      </h2>
      { isLoading ? <LoadingSpinner /> : (
        <>       
          { detail.publicacion.state &&
            <StateCard state={ detail.publicacion.state } publicationId={detail.publicacion.id} publicationTitle={detail.publicacion.title} /> }
          { detail && <CostsCard costs={ { ...detail } } /> }          
          <TransportCard profile={profile} transport={{ ...detail }} tiposTransporte={transportTypes} />
          { detail.publicacion.establecimiento &&
            (
              <MapCard
                position={ [
                  detail.publicacion.establecimiento.lat,
                  detail.publicacion.establecimiento.lng,
                ] }
                origen={ 'Origen' }
                establishment={ {
                  name: detail.publicacion.establecimiento.nombre,
                  province: detail.publicacion.establecimiento.province.province,
                  locality: detail.publicacion.establecimiento.locality.name,
                } }
              />
            ) }
          { detail.destino &&
            (
              <MapCard
                position={ [
                  detail.destino.lat,
                  detail.destino.lng,
                ] }
                origen={ 'Destino' }
                establishment={ {
                  name: detail.destino.nombre,
                  province: detail.destino.province.province,
                  locality: detail.destino.locality.name,
                } }
              />
            ) }
          { detail.comprador && <BuyerCard buyer={ detail.comprador } isAdminLogged={isAdminLogged} /> }
          { (detail.vendedor && isAdminLogged) && <SellerCard seller={ detail.vendedor } /> }
          { detail.publicacion.review?.reviewer &&
            <ReviewerCard reviewer={ detail.publicacion.review?.reviewer } /> }
          { documents && <DocumentsCard { ...documents } /> }
        </>
      ) }
    </>
  );
};

export default PurchaseDetailModal;
