const Logo = `${ process.env.PUBLIC_URL }/favicon.png`;

export default function Conditions() {
  return (
    <div style={ { minHeight: "79vh", width: "100%", marginTop: "2rem", paddingBottom: '4rem', paddingTop: '2rem' } }>
      <div
        style={ {
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
        } }
      >
        <img src={ Logo } width="95px" height="95px" alt="" />
      </div>
      <h1 style={ { textAlign: "center", fontWeight: "bold" } }>
        TERMINOS Y CONDICIONES DE USO
      </h1>
      <div
        style={ {
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          marginTop: "4rem",
        } }
      >

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>1. Introducción. Usuario.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Los presentes términos y condiciones se aplican al uso de los servicios ofrecidos por E- GANADERO S.A.S., (en adelante “Xercana")
              y rigen el acceso, visita, uso y toda otra interacción que se genere a través de la plataforma web,
              denominada “Xercana” accesible a través de la URL https://xercana.com y/o la aplicación informática para dispositivos móviles.
              Las presentes condiciones generales tienen carácter vinculante y obligatorio para toda persona humana o jurídica que se registre y los utilice.
              La razón social propietaria de la plataforma es E- GANADERO S.A.S., con domicilio en la provincia de Córdoba, CUIT Nro. 30-71690254-0.
            </p>

            <p>
              El usuario deberá leer y aceptarlos para usar todos los servicios, productos e información que se facilitan desde el sistema.
              El mero acceso y/o utilización del sistema, de todos o parte de sus contenidos y/o servicios
              significa la plena aceptación de las presentes condiciones.
              Al completar el proceso de registro, el usuario acepta, de manera plena, y sin reservas, el sistema,
              todas y cada una de las cláusulas de los Términos y Condiciones, sus anexos (artículos 957, 958, 959 y 971 del Código Civil y Comercial de la Nación y ss).
              En caso de que no tenga intención de ser usuario de Xercana, o no esté de acuerdo,
              no pueda cumplir de manera total y absoluta con los términos y condiciones o no los visualice correctamente,
              deberá abstenerse de utilizar los servicios y la plataforma
            </p>
            <p>
              La utilización de los servicios se encuentra, sometida a todos los avisos, reglamentos de uso, instrucciones,
              políticas de privacidad, términos y condiciones presentes o que en el futuro pueda publicar Xercana en el sitio de internet http://www.xercana.com/
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>2. Capacidad legal.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Para utilizar la plataforma se requiere tener capacidad legal para contratar.
              No podrán acceder a los servicios quienes carezcan de ella, los que hayan sido suspendidos o inhabilitados,
              ni los menores de edad. Los padres, tutores o responsables de los menores de edad o incapaces que utilicen
              serán responsables por dicho uso, incluyendo cualquier cargo o daño que se derive de él.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>3. Usuario. Información. Cuenta.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Al completar el formulario de registro, el usuario acepta: (i) Proveer información veraz, cierta, precisa,
              completa y actualizada. Xercana procesará y almacenará la información en servidores con sistemas de seguridad;
              (ii) Elegirá nombre de usuario y contraseña. Será responsable de conservar la confidencialidad de su cuenta y contraseña,
              además de todas y cada una de las actividades que se llevan a cabo a través de su cuenta.
              Por otro lado, aceptan notificar de inmediato a Xercana sobre cualquier uso no autorizado de su cuenta
              o de cualquier otro peligro contra la seguridad (artículos 1, 2 y 9 de la Ley 25.326 sobre “Protección de Datos Personales”).
              Xercana no será responsable de ninguna pérdida en la que pudiera incurrir el usuario como resultado de que otra persona
              utilice su cuenta o contraseña, con o sin su conocimiento. Sin embargo, será factible responsabilizar a los usuarios
              por las pérdidas en las que incurra Xercana u otra parte debido a que otra persona utilice la cuenta o contraseña de
              usuario; (iii) Si la información es falsa, incorrecta, desactualizada o incompleta, o la empresa tenga una base razonable
              de sospecha sobre ello, tendrá el derecho de suspender o finalizar la posibilidad de que el usuario utilice los servicios.
            </p>
            <p>
              La cuenta es personal, única e intransferible, y no podrá tener más de una cuenta, ni utilizar la cuenta de otra persona
              en ningún momento sin el debido permiso de su titular, caso contrario, Xercana podrá cancelar, suspender o inhabilitar
              la/s cuenta/s.
            </p>
            <p>
              La cuenta no es una propiedad del usuario, sino una herramienta para acceder a los servicios de la plataforma o app.
              Queda terminantemente prohibida la venta, cesión, y transferencia de la cuenta, cualquier acto de disposición
              en general y todos sus atributos (responsabilidades, deudas, créditos) bajo ningún título.
            </p>
            <p>
              Los usuarios deberán mantener la información de contacto actualizada incluyendo contacto telefónico y/o email
              (de conformidad con el texto del artículo 4 de la Ley 25.326).
              Xercana no tiene obligación de verificar la identidad individual de los usuarios y por lo tanto,
              no será responsable por robo de la identidad o uso inadecuado de la identidad o datos personales de cualquier usuario.
            </p>
            <p>
              Xercana se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente
              aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho
              a indemnización o resarcimiento.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>4. Responsabilidad.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              El usuario reconoce y acepta que Xercana no otorga ninguna garantía de cualquier naturaleza, ya sea expresa o implícita,
              sobre los datos, contenidos, información y servicios que se incorporan y ofrecen desde Xercana.
              Ello no implica que no se tomarán todos los recaudos.
              Cuando la ley imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga,
              no garantiza ni asume responsabilidad alguna respecto a los posibles daños causados por el uso y utilización de la
              información, datos y servicios que el sistema ofrece.
              Xercana se excluye de cualquier responsabilidad por los daños que puedan deberse a la información y/o servicios prestados
              o suministrados por terceros diferentes de nuestra empresa.
              Toda responsabilidad será del tercero ya sea proveedor o colaborador, conforme lo establece el artículo 1722 del Cód. Civil y Com. de la Nación.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>5. Servicios.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Xercana ofrece a sus “usuarios” - entre otros - los siguientes servicios:
              Se  proveerá a los usuarios y consignatarios que así lo soliciten, en el alcance determinado en la plataforma,
              los servicios de publicación de ofertas, remates, compra-venta, transporte, logística, de comunicación,
              publicitarios y todos aquellos necesarios para el correcto funcionamiento y propósito de la empresa
              y sus legítimos usuarios.
            </p>
            <ol style={ { marginTop: '1rem' } }>
              <li>
                Publicación de oferta y servicios: los consignatarios podrán dar de alta la/s publicación /es en la plataforma
                de Xercana, ofertas de remates, compraventas ganaderas, de maquinaria, u otras ofertas que hagan a la naturaleza
                de la plataforma, previa aceptación de Xercana.
              </li>
              <li>
                Servicios de comunicación a los consignatarios: Xercana tendrá en su página web y redes sociales el material o
                contenido del consignatario de hacienda dadas de alta por los consignatarios,
                siempre que se cumplan con los términos del presente.
              </li>
              <li>
                Servicios de provisión y administración de páginas web de los escritorios: Todo el material que suba el consignatario
                de hacienda en la plataforma, serán administrados por la base de datos de Xercana, alojándose en el servidor
                contratado a tal efecto.
              </li>
              <li>
                Servicios estadísticos y de analítica del uso de la plataforma. Los consignatarios podrán acceder desde la web
                ingresando a su cuenta de usuario a información de estadísticas del uso de la plataforma vinculada con las ofertas
                dadas de alta por los consignatarios y las manifestaciones de interés relacionadas por el público visitante de dicha
                plataforma.
              </li>
            </ol>
            <p>
              Xercana no se considerará en ningún caso proveedor de cualquiera de los productos o servicios que sean dados de alta
              a través de ofertas o información general, limitándose a ofrecer estrictamente los servicios mencionados.
              Xercana brinda una solución digital que permite la comunicación y gestión de la oferta y la demanda de los productos
              entre los usuarios y los proveedores.
              Xercana no es una entidad financiera ni presta servicios de intermediación financiera, ni participa en la operación
              que se realiza entre los usuarios, ni en la negociación y/o posterior perfeccionamiento del contrato.
              La propiedad sobre los productos es en todo momento del proveedor, desde su oferta y hasta su correcta entrega al usuario.
              Los proveedores tienen la obligación de adoptar todos los recaudos y medidas necesarias para el fiel cumplimiento
              de lo acordado con el destinatario final del bien ofrecido. En consecuencia, el perfeccionamiento del contrato u
              operación de compraventa sobre los bienes y/o la contratación de los servicios se realiza directa y exclusivamente
              entre el usuario y quien resulte titular del bien ofrecido en la plataforma web o app.
            </p>
            <p>
              Xercana realiza sus mejores esfuerzos y pone en funcionamiento todos los medios para brindar una buena experiencia en la
              plataforma, pero no otorga ninguna garantía ni se entenderá que existen garantías implícitas de comerciabilidad,
              de éxito de venta, de resultados, o idoneidad para un propósito particular.
              Xercana ofrece servicios de contratación de espacios publicitarios en los sitios de www.xercana.com  y/u otros sitios
              de su propiedad, y/u otros canales de comunicación elegidos por aquella (plataformas de comunicación de Xercana),
              para los usuarios que deseen promover sus marcas, productos y/o servicios (en adelante "anuncios").
              Xercana no se considerará en ningún caso proveedor de cualquiera de los productos o servicios objeto de publicidad,
              limitándose a ofrecer un espacio publicitario para la promoción, por lo tanto no garantiza:
              (i) la ubicación, posicionamiento o el tiempo de entrega de cualquier anuncio, o (ii) el número, de impresiones,
              publicaciones, conversiones o clics sobre los anuncio en la plataformas de comunicación de Xercana.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>6. Identificación de animales.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              El ganado, y/o los animales deberán estar identificada (caravana, marca a fuego, etc.) de manera visible y de acuerdo a las
              normativas vigentes de SENASA y/o demás organismos públicos. Completada la operación, el recibo, pesada y posterior carga de
              la hacienda se llevará a cabo de acuerdo a lo pactado con el vendedor y se le aplicará a la tropa el desbaste acordado según los usos.
              Los gastos de balanza (en caso utilizarse balanza pública y/o de sociedad rural y/o ferias), guías, sanitarios, certificados u otra
              documentación relativa a la carga de la hacienda serán a cargo del vendedor, y los importes resultantes serán debitados de las
              liquidaciones de venta correspondientes. En caso de verse impedida la carga de la tropa por causas no imputables a ninguna de las partes,
              como por ejemplo factores climáticos, huelgas de transportes, etc., se decidirá un nuevo cronograma de cargas de común acuerdo entre las partes.
              Al momento del pesaje de la hacienda en destino la merma de kilos estará determinada por los kilómetros recorridos y usos y costumbres.
              La carga de los lotes vendidos se realizará en el plazo convenido entre las partes.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>7. Comisiones. Facturación.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Las comisiones serán informadas en cada caso.
              Los gastos de fletes estarán a cargo del comprador, elección que se hará dentro de las opciones disponibles en la plataforma Xercana.
              Las retenciones y/o percepciones impositivas que correspondan, se realizarán de acuerdo a las normativas vigentes al momento de la
              facturación de la operación y al momento de la cancelación de las mismas. Xercana se responsabilizará del proceso de facturación,
              liquidación y cobranza de las operaciones concretadas, asumiendo solo las obligaciones inherentes a las mismas. Los pagos se realizarán,
              sin excepciones, mediante botón de pago, medio electrónico de pago, cheques electrónicos, y todos los medios de pagos habilitados por Xercana.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>8. Modificación de términos y condiciones.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Xercana tendrá derecho en cualquier momento a cambiar o modificar los términos y condiciones en cualquiera de sus partes y/o imponer nuevas
              condiciones, incluyendo la incorporación de cargos por el uso del servicio. Todo lo anterior entrará en vigencia inmediatamente después de
              que se los notifique, lo que puede llevarse a cabo por distintos medios, incluyendo: publicación en la página web de Xercana, correo
              electrónico o convencional, o cualquier otro medio idóneo. Cualquier uso de la página web por parte del usuario posterior a dicha notificación
              se considerará como aceptación. También se reserva el derecho a actualizar los términos y condiciones de uso en cualquier momento, siendo debidamente comunicado.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>9. Personas legalmente capaces de contratar.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Sólo podrán ser usuarios los mayores de 18 años y personas legalmente capaces para contratar, sin excepciones (de acuerdo con lo establecido
              en los artículos 2, 25 y 26 del C.C.C.N.). Xercana podrá restringir, condicionar o terminar el carácter de un usuario sin causa y previa notificación.
              Puede ser indefinida o limitada por un período de tiempo o podrá restringir la prestación del servicio. Ningún usuario podrá transferir la membresía,
              credenciales o calidad de usuario a ninguna otra entidad, ya sea persona humana o jurídica. El usuario declara bajo juramento que la información
              (personal y/o societaria) que provea, es exacta, veraz y vigente, siendo el único y exclusivo responsable por ello, y que posee las facultades
              suficientes para usar y proporcionar los datos con los que se registra, y realizar las diferentes operaciones. Además es responsable por sus
              publicaciones y posteos. Xercana podrá solicitar datos o información adicionales para el caso de usuarios que realicen transacciones que requieran
              de facultades adicionales exigidas por ley o por las políticas actuales o futuras, así como solicitar documentación para corroborar la veracidad y
              exactitud de los datos personales, impositivos y/o societarios.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>10. Garantías y responsabilidad limitadas.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              El Usuario acepta que el uso de la plataforma tendrá lugar exclusivamente bajo su responsabilidad.
              Acepta y reconoce que los servicios pueden no siempre estar disponibles debido a actualizaciones, dificultades técnicas, fallas de internet,
              ciberataques etc. o por cualquier otro motivo ajeno a la empresa. Xercana contrata su acceso a internet y el de sus servidores con un tercero,
              proveedor de dicho servicio. En consecuencia, no garantiza la total disponibilidad y continuidad del funcionamiento de los servicios, ni su infalibilidad.
              La empresa no podrá ser considerada responsable por fallos, inhabilidad de acceso o condiciones débiles de acceso al sistema dado el uso inapropiado
              del equipo, mal funcionamiento relacionado con los usuarios/proveedores, proveedores de servicios de telecomunicaciones, o sobrecarga de la red u
              otras razones legalmente admisibles.
            </p>
            <p>
              Xercana no garantiza ni asume responsabilidad alguna por cualquier daño, perjuicio o pérdida frente al usuario
              o a terceros causador por (i) fallas en el sistema, en el servidor o en Internet, errores, imprecisiones, del contenido; (ii) daños de cualquier naturaleza
              que resulte de su acceso, visita, uso; (iii) cualquier virus, ciberataques o similar que pueda ser transmitido desde o a través del sitio y/o la APP por un
              tercero; y/o (iv) cualquier error, omisión o ilicitud en el contenido o por cualquier perdida o daño de cualquier tipo en el cual se haya incurrido como
              resultado del uso del contenido de la plataforma y (v) la seguridad y privacidad en la utilización de la plataforma y/o los Servicios. En ningún caso Xercana
              responderá por daños directos, indirectos y/o imprevistos. Los usuarios son responsables por el buen uso de la plataforma, comprometiéndose expresamente a evitar
              cualquier tipo de acción que pueda dañar a sistemas, equipos o servicios accesibles, directa o indirectamente a través de Internet.
            </p>
            <p>
              Toda la información publicada
              y ofrecida es proporcionada por el proveedor, siendo su responsabilidad exclusiva, como así también lo referente a su comercialización. Los usuarios asumen plena
              responsabilidad frente a Xercana, y a terceros por daños y perjuicios que se produjeran como consecuencia del accionar propio, de sus dependientes o de terceros
              y los que resulten de la inobservancia de las leyes o reglamentaciones, de otros hechos ilícitos o del mal uso que se haga del servicio, debiendo los usuarios
              indemnizar y mantener indemne a Xercana ante cualquier reclamo que pudiera corresponder. Asimismo se excluye cualquier responsabilidad por los daños que sean
              originados en forma directa, indirecta o remota, por la interrupción, suspensión, finalización, falta de disponibilidad o de continuidad del funcionamiento de
              la página y los servicios, por la imposibilidad de lograr la utilidad, ingresos, ganancias o beneficios que el usuarios hubiere podido atribuir a la empresa, a
              la falibilidad de los servicios, y en particular, aunque no de modo exclusivo, por las fallas en el acceso a los Servicios.
              El usuario reconoce que el software
              y las bases de datos no se encuentran libre de errores, por lo que acepta que su existencia no puede implicar incumplimiento del presente acuerdo.
            </p>
            <p>
              Xercana garantiza que es el dueño de los derechos sobre el software y las bases de datos del software y que hará todo lo razonablemente necesario para protegerla
              de usos no autorizados (artículo 4 de la ley 11.723). Sin perjuicio de ello, no garantiza que el sistema y sus contenidos sean exactos, completos o vigentes en todo
              tiempo. Tampoco asegura la comerciabilidad, o legalidad de los productos de los consignatarios/usuarios, así como tampoco la adecuación de los servicios para un
              propósito particular. Por otro lado, Xercana no se responsabiliza por el correcto envío ni por su contenido de comunicaciones, mensajes, archivos, contestaciones
              o transmisión de contenidos enviados y recibidos por el/los usuarios del sistema y/o que al utilizar el sitio y/o la APP el usuario puede estar expuesto a contenido
              objetable por diversos motivos.
            </p>
            <p>
              Xercana no realiza manifestaciones ni otorga garantías respecto de la precisión, fiabilidad o la autenticidad del contenido de la
              plataforma, ni que el uso del servicio por parte de los usuarios no implicará una infracción a derechos de terceros. Los usuarios defenderán y mantendrán a Xercana,
              sus socios, empleados, directores, representantes, agentes, operarios, proveedores, libres de daño, contra pérdidas responsabilidades asumiendo directamente la
              responsabilidad de cualquier pérdida, gastos, daños o costos incluidos los gastos en asesores, abogados, generados del incumplimiento de los términos y condiciones
              del acuerdo de usuario, o del desarrollo de prácticas negligentes o cualquier actividad ilegal, a través de ésta cuenta de usuario, directamente o a través de
              terceros que usen dicha cuenta (artículo 1722 y subsiguientes del Código Civil y Comercial de la Nación).
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>11. Uso. Prohibiciones.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              El Usuario se compromete a utilizar Xercana, de conformidad los términos y condiciones, la ley, la moral, las buenas costumbres, el orden público, y bajo su exclusivo
              riesgo y responsabilidad. Asimismo, se compromete hacer un uso adecuado del sitio y/o la aplicación. El infractor podrá ser sancionado con la suspensión o cancelación
              de su inscripción como usuario y/o de cualquier otra forma que estime pertinente, sin que esto dé lugar a reclamo de ningún tipo y sin perjuicio de las acciones legales
              a que pueda dar lugar por la configuración de delitos o contravenciones o los perjuicios civiles que pueda causar a Xercana y/o a terceros.
            </p>
            <p>
              Está prohibido que los usuarios y/o quienes accedan al sistema lo utilicen para actividades ilegales, fraudulentas, que atenten contra los derechos de terceros y/o que
              infrinjan la normativa o regulación sobre propiedad intelectual e industrial, o cualesquiera otras normas del ordenamiento jurídico aplicable (ejemplificativamente se
              condena dichas actividades en los artículos 128, 153, 153 bis, 155, 173 inciso 16, 183 del Código Penal Argentino, cuyo texto fuera modificado por ley 26.388).
            </p>
            <p>
              El usuario no deberá publicar ni transmitir a través de Xercana ningún material que viole o transgreda de alguna manera los derechos de otras personas, que sea ilegal,
              amenazador, difamatorio, que invada los derechos a la privacidad, de imagen, u otro derecho de la personalidad, que sea vulgar, obsceno, irreverente o de algún otro
              modo objetable, que estimule una conducta que podría constituir un delito penal, o discriminatorio o dar origen a una responsabilidad civil o de algún otro modo violar
              cualquier ley, o que, sin la explícita autorización previa de Xercana (artículo 5 de la ley 25.326, incorporado al Código Penal como artículo 153 bis), contenga
              publicidad comercial, con la salvedad de lo que forma parte del servicio que ofrece el sistema. No se permitirá ninguna conducta por parte de un usuario que a criterio
              de Xercana limite o inhiba a cualquier otro usuario en cuanto al uso o disfrute del sistema Xercana.
            </p>
            <p>
              El Usuario no utilizará el sistema para publicitar o realizar ningún tipo de solicitud comercial, incluyendo, pero sin limitarse a la solicitud de que los usuarios
              se conviertan en suscriptores de otros servicios de información en línea que compitan con Xercana. Las disposiciones antes mencionadas se interpretarán en beneficio
              de Xercana, sus subsidiarias, afiliados y terceros otorgantes de licencias y proveedores de material, y cada uno de ellos tendrá derecho a imponer y hacer cumplir
              dichas disposiciones en forma directa o en su nombre. Los Servicios incluyen la posibilidad de pedir, enviar, y recibir comunicaciones y en forma colectiva
              “servicios de comunicación". El usuario acepta utilizar dichos servicios de comunicación únicamente para publicar, enviar y recibir mensajes y material que sea
              apropiado y que, según corresponda, se relacione con el objeto del Servicio que ofrece el sistema. Contenidos prohibidos: el usuario no utilizará los servicios
              de comunicación en conexión con encuestas, y concursos, esquemas piramidales, cartas en cadena, correo electrónico no deseado, envío de spams o cualquier mensaje por
              duplicado o no solicitado (comercial o de otro tipo).
            </p>
            <p>
              No difamará, abusará, acosará, acechará, amenazará o violará de algún otro modo los derechos de otras personas (en concordancia con los artículos 128, 153, 153 bis,
              155, 173 inciso 16, 183 del Código Penal Argentino, cuyo texto fuera modificado por ley 26.388). No publicará, cargará, distribuirá o divulgará ningún tema, nombre,
              material o información inapropiados, irreverentes, difamatorios, discriminatorios, obscenos, indecentes o ilegales. No cargará o pondrá a disposición de algún otro
              modo archivos que contengan imágenes, fotografías, software u otro material protegido por derechos de privacidad o publicidad o derechos de propiedad intelectual,
              incluyendo, a modo de ejemplo y no como una limitación, los derechos de autor, sobre marcas registradas, a menos que el usuario posea o controle estos derechos o se
              le haya concedido el consentimiento necesario para hacerlo (conforme ley 11.723 “Régimen Legal de la Propiedad Intelectual”).
            </p>
            <p>
              No utilizará ningún material o información, incluyendo imágenes o fotografías, que se encuentren disponibles a través de los servicios de manera tal que viole los
              derechos de autor, marca registrada, patente, secreto comercial u otro derecho de propiedad exclusiva de cualesquiera de las partes.<br />
              No cargará archivos que contengan virus, troyanos, gusanos, bombas de tiempo, canceladores de correo, archivos corruptos o cualquier otro software o programa
              similar que pudiera perjudicar el funcionamiento del equipo de otra persona o su propiedad.<br />
              No publicitará u ofrecerá para la venta o compra ningún bien o servicio con fines comerciales, a menos que se trate de bienes relacionados con el objeto del sistema.
              No descargará ningún archivo publicado por otro usuario del servicio de comunicación que el usuario sepa, o debería saber, que desde el punto de vista legal no se
              puede reproducir, mostrar, ejecutar y/o distribuir de esa manera.<br />
              No falsificará ni eliminará ninguna información sobre la administración de derechos de autor, como atribuciones a los autores, notificaciones legales o de otro
              tipo o designación de propiedad exclusiva o etiquetas que indiquen el origen o la procedencia del software u otro material contenido en un archivo que se carga
              (acorde ley 11.723 y artículo 173 del Código Penal).<br />
              No limitará o inhibirá a ningún otro usuario en cuanto al uso y disfrute de los servicios de comunicación.<br />
              No violará ningún código de conducta u otras pautas que se apliquen a cualquier servicio de comunicación en particular.<br />
              No recogerá o recolectará de algún otro
              modo distintos a los provistos por el servicio información sobre otras personas, incluyendo direcciones de correo electrónico.<br />
              No violará ninguna reglamentación o ley aplicable.<br />
              No creará una identidad falsa con el fin de engañar a otras personas.<br />
              No utilizará, descargará o copiará de algún otro modo o proporcionará (ya sea a cambio de un pago o no) a una persona o entidad ningún directorio de usuarios de los
              servicios u otro tipo de información sobre los usuarios o el uso o cualquier parte de los mismos.<br />
              No podrá suministrar información falsa, o que haga incurrir en error, debiendo siempre procurar que se pueda verificar su identidad.
            </p>
            <p>
              Xercana no tiene obligación alguna de controlar los servicios de comunicación, sin embargo, se reserva el derecho a revisar el material contenido o publicado
              en los servicios de comunicación y a quitar cualquier material a su entera discreción.<br />
              Xercana se reserva en todo momento el derecho a revelar cualquier información según lo considere necesario para cumplir con una ley aplicable, reglamentación,
              proceso legal o solicitud gubernamental, o a editar, negarse a publicar o quitar cualquier información o material, total o parcialmente, a entera discreción de Xercana.<br />
              Es posible que el material cargado a los servicios de comunicación esté sujeto a limitaciones publicadas de uso, reproducción y/o divulgación; el usuario es
              responsable de respetar dichas limitaciones si descarga el material.<br />
              El usuario deberá ser cuidadoso al suministrar cualquier tipo de información de identificación personal en alguno de los Servicios de comunicación.<br />
              Xercana renuncia específicamente a cualquier responsabilidad con respecto a los servicios de comunicación y a cualquier acción que resulte de la
              participación del usuario en alguno de los servicios de comunicación.
            </p>
            <p>
              En caso de incumplimientos, y sin que las mencionadas prohibiciones sean taxativas, sin perjuicio de otras medidas, Xercana podrá suspender en forma temporal
              o inhabilitar definitivamente la cuenta del usuario. Xercana se reserva el derecho a poner fin al acceso del usuario a todos sin previo aviso y por cualquier motivo.<br />
              Podrá también iniciar las acciones que estime pertinentes y/o suspender la prestación de sus servicios. En el caso de la suspensión o inhabilitación de un usuario,
              todas las ofertas pendientes serán removidos del sistema y en ningún caso se devolverán o bonificarán los cargos involucrados.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>12. Propiedad intelectual y protección de los secretos comerciales.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Se considera el software y la base de datos como un objeto de propiedad intelectual, sujeto a secreto.<br />
              Toda la información disponible en la plataforma Xercana, incluyendo, pero sin limitarse a: texto, software, base de datos, diseño, logotipos, mapas, gráficos,
              estadísticas, fotografías, nombres de dominio, videos, gráficos, música y sonido, se encuentran protegidos por derechos de autor, secretos comerciales,
              confidencialidad, patentes, marcas, y/o cualquier otro derecho de propiedad intelectual o industrial que pudiera corresponder.
            </p>
            <p>
              Todos los contenidos de la plataforma tienen derechos de autor como una obra colectiva de acuerdo con las leyes sobre los derechos de autor vigentes. De tal manera,
              posee derechos de autor sobre la selección, coordinación, arreglo y mejora de datos y contenidos, como así también sobre el contenido originariamente propio.
              El usuario no puede modificar, publicar, transmitir, participar en la transferencia o vender, crear trabajos derivados o explotar de alguna forma cualesquiera
              de los contenidos, ya sea total o parcialmente. Sin embargo, puede usar o descargar material con derechos de autor únicamente para su uso personal.<br />
            </p>
            <p>
              No se permite la copia, redistribución, retransmisión, publicación o explotación comercial del
              material descargado sin el permiso explícito de Xercana (bajo sanciones previstas en artículo 71 y siguientes de la ley 11.723 “Régimen Legal de la
              Propiedad Intelectual”). El Usuario conoce y acepta que al descargar el material no adquiere la propiedad de los mismo si cuentan con derechos de autor.<br />
              Queda prohibida toda reproducción, transformación o distribución de dichos contenidos, fuera del acceso, visualización o reproducción de los
              mismos a través del servicio.<br />
              El usuario acepta no usar robots, u otros dispositivos automatizados o procesos manuales para controlar o copiar cualquier contenido del servicio.<br />
              Xercana otorga una licencia de uso sobre el software, y sus futuras versiones a los usuarios, como un servicio, en forma personal, no transferible y no exclusiva,
              obligándose a no realizar directa o indirectamente una explotación comercial, que no sea la prevista en la plataforma, de los elementos e información obtenidos a
              través de la Plataforma.<br />
              Se prohíbe expresamente a los usuarios (licenciatarios) realizar actos de ingeniería inversa, descompilación o desensamblado del software.
            </p>
            <p>
              El usuario otorga a Xercana, a sus compañías afiliadas y a los sublicenciatarios necesarios incluyendo, sin limitarse a ello a una licencia, no exclusiva,
              transferible, con derechos de sublicenciar, de alcance global para utilizar los contenidos en conexión con el funcionamiento de todos los servicios de Xercana
              que se describen en estos términos y condiciones. En ningún caso Xercana pretenderá titularidad sobre la marca y contenidos dados de alta por el
              consignatario de hacienda, con la salvedad de la licencia de uso necesaria para la prestación del servicio.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>13. Política de privacidad y protección de datos personales de Xercana.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              El usuario deberá proporcionar cierta información personal como condición de acceso a la plataforma y servicios de Xercana, que el usuario acepta brindar a tales
              efectos, consintiendo también su recopilación y tratamiento de acuerdo a la ley 25.326, de Protección de Datos Personales. La Ley cumple con estándares internacionales
              de protección.<br />
              Dichos datos proporcionados voluntariamente por el usuario podrán ser incorporados a un fichero automatizado, a registrarse de conformidad con lo normado
              a partir del artículo 21 de la Ley 25.326 sobre “Protección de Datos Personales”, bajo la titularidad de E-GANADERO SAS. Los datos personales podrán alojarse en
              los servidores contratados por la empresa con un nivel de protección adecuado.
            </p>
            <p>
              Para esto, se han implementado todas las medidas de seguridad necesarias para evitar que terceros no autorizados accedan a los datos. Ello incluye las medidas
              de seguridad físicas y el acceso sólo de empleados o subcontratistas que necesiten acceder a los datos por razones laborales, los cuales estarán sujetos a
              cláusulas de confidencialidad y obligaciones de secreto profesional, contemplado en los artículos 9 y 10 primer inciso de la Ley 25.326.<br />
              La información que Xercana reciba por ejemplo a través de su página web, sección registro, será utilizada con finalidad de la provisión de los servicios
              prestados por Xercana (así como su mejoramiento o desarrollo) o datos estadísticos del comportamiento de los usuarios, así como el envío de promociones y
              publicidad relativos al servicio prestado.
            </p>
            <p>
              Xercana podrá utilizar cookies a los efectos de medición y análisis de tráfico en la plataforma, así como finalidades relacionadas con los servicios prestados.<br />
              La información personal proporcionada por los usuarios no será transferida comercialmente a ningún tercero, a menos que se recabe expresamente su consentimiento.<br />
              Xercana toma todas las medidas de seguridad necesarias para asegurar la debida conservación de sus datos, tanto para evitar su extravío o alteración,
              como para impedir el acceso por parte de terceros, siguiendo lo dispuesto legalmente.<br />
              Los usuarios de Xercana garantizan la veracidad y exactitud de los datos comunicados, siendo responsables a cualquier efecto por la información suministrada por su parte.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>14. Ejercicio de los derechos.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Xercana se compromete a facilitar al usuario el ejercicio de los derechos de acceso, rectificación, supresión o inclusión de los datos personales proporcionados,
              comunicando permanentemente su localización para hacer efectivo el ejercicio de tales derechos.
            </p>
            <p>
              Si tiene consultas o sugerencias sobre esta Política de privacidad de este sitio web, diríjase a info@xercana.com.ar
            </p>
            <p>
              Xercana se considerará encargado de tratamiento de los datos personales cargados por los usuarios como consecuencia del acceso a material o contenidos
              dados de alta o remitidos para su publicación por los consignatarios. En dichos casos los consignatarios serán consideraros los responsables de dicha base
              de datos personales, como se halla comprendido en la ley de Protección de Datos Personales, artículos 1 y 2.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>15. Precios.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Los precios de los servicios referidos en los presentes términos, que no tengan que ver con las comisiones pactadas, serán publicados en la página web
              y se considerarán adicionales a los presentes términos y vinculantes.
            </p>
            <p>
              Xercana se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes, en cualquier momento, lo cual será notificado a los Usuarios,
              en la forma establecida en éstos términos y condiciones de contratación, o en la página web http://www.xercana.com/.
            </p>
            <p>
              En caso de haberse facturado cargos que no hubiesen correspondido, el Usuario deberá comunicarse con nuestro equipo de atención al cliente para resolver
              dicha cuestión. Cualquier duda consulta nuestras políticas y tarifas de facturación que serán consideradas partes integrantes de los Términos y Condiciones Generales.
            </p>
          </div>
        </div>

        <div style={ { marginTop: "3rem" } }>
          <h2 style={ { fontWeight: "bold" } }>16. Legislación aplicable, jurisdicción competente y notificaciones.</h2>
          <hr />
          <div style={ { marginTop: "1rem", textAlign: "justify" } }>
            <p>
              Las presentes condiciones se rigen y se interpretan de acuerdo con las Leyes de la República Argentina.<br />
              Para cualquier reclamo serán competentes los Juzgados y Tribunales ordinarios de la ciudad de Córdoba. Todas las notificaciones, requerimientos, peticiones
              y otras comunicaciones que el Usuario desee efectuar a Xercana deberán realizarse por escrito y se entenderá que han sido correctamente realizadas cuando
              hayan sido recibidas a la dirección de correo electrónico info@xercana.com
            </p>
          </div>
        </div>

      </div>
    </div >
  );
}
