import Traslate from '../../../../utils/translation/User.json';
import ElementSwitch from '../../../../components/multipleSwitch/ElementSwitch';
import moment from 'moment';

export default function getRevisionSwitch(mobil, product) {
    return <ElementSwitch
        showBorder={!mobil}
        labelName={Traslate.PUBLICATION_LIVE_STOCK.REVISION}>
        
        <table
        style={{border:'1px solid #e1e1e1',borderRadius:'5px'}}
        className='table table-striped responsiveBatch'>
            <tbody>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_QUANTITY}</td>
                    <td>{product?.batch?.count?product.batch.count: ''}</td>
                </tr>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_QUALITY}</td>
                    <td>{product?.batch?.review?.quality?product.batch.review.quality: ''}</td>
                </tr>
                {product?.batch?.review?.health&&(
                    <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_HEALTH}</td>
                    <td>{product?.batch?.review?.health?product.batch.review.health:''}</td>
                    </tr>
                )}
                {product?.batch?.review?.health_zone&&(
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_ZONE}</td>
                    <td>{product?.batch?.review?.health_zone? product.batch.review.health_zone: ''}</td>
                </tr>
                )}
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_WEEDS}</td>
                    <td>{product.batch?.review?.knows_weeds ?? '-'}</td>
                </tr>   
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_KNOW_EAT}</td>
                    <td>{product?.batch?.review?.knows_eat ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_WEAN}</td>
                    <td>{product?.batch?.review?.weaned_off? 'Si' : 'No'}</td>
                </tr>
                {product?.batch?.review?.weaned_off_date&&(

                <tr style={{borderBottom:'1px solid #e1e1e1'}}>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.REVISION_WEAN_DATE}</td>
                    {/* <td>{product.batch.review?.weaned_off_date?product.batch.review.weaned_off_date.slice(0,10).replace(/-/g,'/'):''}</td> */}
                    <td>{product.batch?.review?.weaned_off_date?moment(product?.batch?.review.weaned_off_date).format('DD/MM/YYYY'):''}</td>

                </tr>
                )}               
            </tbody>
        </table>
    </ElementSwitch>;
}