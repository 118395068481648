import { useHistory } from 'react-router';

import BottonLine from '../../../components/BottonLine';
import Container from '../../../components/container/Container';
import Padding from '../../../components/padding/Padding';
import AddressInformation from '../../../components/profile/AddressInformation/AddressInformation';
import FiscalInformation from '../../../components/profile/FiscalInformation/FiscalInformation';
import PersonalInformation from '../../../components/profile/PersonalInformation/PersonalInformation';
import ProfileStatus from '../../../components/profile/ProfileStatus/ProfileStatus';
import ProfileType from '../../../components/profile/ProfileType/ProfileType';
import ProfileProvider, { useProfileContext } from '../../../context/ProfileContext';

import './RegisterDataPage.css';
import routers from '../../../config/routers';


const ProfilePage = ( props ) => {
  const fromMenu = props.history.location.state?.fromMenu
  return <>
    <ProfileProvider>
      <ProfileComponent props={ fromMenu } />
    </ProfileProvider>
  </>;
};

const ProfileComponent = ( props ) => {
  const { profile, isLoading, dispatch } = useProfileContext();
  const fromMenu = props.props;
  const history = useHistory();

  const handleCancel = () => {
    history.push( routers.home.path );
  };

  const handlePhoneEditing = () => {
    history.push( {
      pathname: '/user/phone',
      state: {
        isEditing: true,
      },
    } )
  }

  return (
    <div className='d-flex justify-content-center responsive_custom_container'>
      <Container className='ProfilePage-container xercana-main-container'>
        { fromMenu
          ? ( <h1 className='profil-titles col-12'>Perfil de usuario</h1> )
          : ( <h1 className='profil-titles col-12'>Registro de usuario</h1> ) }

        <BottonLine width='80%' margintop='mx-auto mb-4 mt-2' />
        { isLoading ? (
          <div className='ProfilePage-spinner'>
            <div className='spinner-border' role='status' />
          </div>
        ) : (
          <>
            <ProfileStatus enabled={ profile.enabled } />
            <form>
              {/* // Se deja oculto el form para asignar perfil de comprador/vendedor al usuario que complete el formulario */ }
              <div id='type-pane' hidden={ true } className='tab-pane' role='tabpanel' aria-labelledby='type-tab'>
                <ProfileType profile={ profile } dispatch={ dispatch } buyerSeller={ true } />
              </div>
              <div id='fiscal-pane' className='tab-pane active' role='tabpanel' aria-labelledby='fiscal-tab'>
                <FiscalInformation profile={ profile } dispatch={ dispatch } />
              </div>
              <hr></hr>
              <div id='personal-pane' className='tab-pane' role='tabpanel' aria-labelledby='personal-tab'>
                <PersonalInformation profile={ profile } dispatch={ dispatch } />
                { fromMenu && (
                  // Si viene del menu, es que va a ver su perfil, en ese caso puede editar su numero de telefono.
                  // Si no, es que se esta registrando por primera vez y no puede editar su numero de telefono.
                  <button
                    className='btn btn-info mt-2'
                    onClick={ handlePhoneEditing }
                  >Cambiar número de teléfono</button>
                ) }

              </div>
              <hr></hr>
              <div id='address-pane' className='tab-pane' role='tabpanel' aria-labelledby='address-tab'>
                <AddressInformation profile={ profile } dispatch={ dispatch } />
              </div>
              <hr></hr>

              { !fromMenu ? (
                <div className='col-lg-5 col-sm-12 mx-auto text-align-left'>
                  <Padding className='xercana-vertical-padding'>
                    <button
                      className='btn btn-outline-secondary mt-3 mt-md-0 mt-sm-3 mt-lg-0 mt-xl-0'
                      onClick={ handleCancel }
                      type='button'
                    >
                      Volver al Home / Cancelar
                    </button>
                  </Padding>
                </div> ) : ( <></> ) }

            </form>
          </>
        ) }
      </Container>
    </div >
  );
};

export default ProfilePage;
