import { useEffect, useState, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useAuthContext } from '../../../context/AuthenticatedContext';

// LIBRARY
import 'leaflet/dist/leaflet.css';
import { TileLayer, MapContainer, Marker } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import Swal from 'sweetalert2';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
registerLocale( "es", es );

// HOOKS
import { useFormString } from "../../../hooks/UseForm";

// COMPONENTS
import { categories, CUSTOM_BOX_STYLES, qualityConstant, breedTypes, crossbreedData, cowBreed } from "./constants";
import { CustomInput } from "../../../components/CustomInput/CustomInput";
import { IconAdress } from "../../../components/Icons/Icons";
import BottonLine from "../../../components/BottonLine";
import Button from "../../../components/button/Button";
import ComboBox from "../../../components/ComboBox/ComboBox";
import getViewLoading from "../../Publication/ViewParts/LoadingPublicationLiveStock";
import MapRealloc from "../../../components/MapRealloc/MapRealloc";

// TRANSLATE
import Traslate from "../../../utils/translation/User.json";
import TraslateAdmin from "../../../utils/translation/Admin.json";

// CONFIG
import routers from "../../../config/routers";

// HELPERS
import { formatPrice } from "../../../utils/helper";
import "react-datepicker/dist/react-datepicker.css";

// ENDPOINTS
import {
  getPublication,
  getPublicationDetail,
  getPublicationDetailNew,
  getUserEstablishmentsById,
  postPublicationEdit,
  postPublicationImage,
  postPublicationVideo,
} from "../../../api/private/client";


// WORKING MAP POINTER CONFIG
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions( {
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
} )

const RebuildPublicationEdit = ( { match } ) => {
  const history = useHistory();
  const [ lote, setLote ] = useState( true );
  const [ revision, setRevision ] = useState( false );
  const [ ubication, setUbication ] = useState( false );
  const [ datosBancarios, setDatosBancarios ] = useState( false );
  const [ weanedOff, setWeanedOff ] = useState();
  const [ requireReviewed, setRequireReviewed ] = useState( false );
  const [ loadimage, setLoadImage ] = useState( false );
  const [ publicationApproved, setPublicationApproved ] = useState( false );
  const [ publicationState, setPublicationState ] = useState( "" );
  const [ descriptionText, setDescriptionText ] = useState( '' );
  const [ internalDescriptionText, setInternalDescriptionText ] = useState( '' );


  // THIS STATES WE NEEED TO UPDATE FROM EDIT PUBLICATION:
  const [ title, setTitle ] = useState( "" );
  const [ type_lote, setTypelote ] = useState( "" );
  const [ publication, setPublication ] = useState( "" );
  const [ knowsEat, setKnowsEat ] = useState( false );
  const [ offdes, setOffdes ] = useState( false );
  const [ detail, setDetail ] = useState( null );
  const [ esBulto, setEsBulto ] = useState( false );
  const [ establishment, setEstablishment ] = useState( null );
  const [ establishments, setEstablishments ] = useState( [] );
  const [ esRaza, setEsRaza ] = useState( false );
  const [ esMestizo, setEsMestizo ] = useState( false );

  // CUSTOM USESTATES:
  const [ position, setPosition ] = useState( [ -31.4135, -64.18105 ] );

  // VIDEO URL:
  const [ uploadingVideos, setUploadingVideos ] = useState( false );
  const [ videoUrl, setVideoUrl ] = useState( '' );
  const [ imageUrl, setImageUrl ] = useState( '' );
  const [ imageFile, setImageFile ] = useState();

  // Library FORM
  const { register, errors, handleSubmit, setValue, control } = useForm();
  const [ expirationDate, setExpirationDate ] = useState( new Date() );
  const { fields, append, remove } = useFieldArray( {
    control,
    name: "catalogue",
  } );

  const race = useFormString();
  const breedType = useFormString();
  const crossbreed = useFormString();
  const { isCommercial, isAdmin } = useAuthContext();


  /* EFFECT FIND PUBLICATION BY ID IF NOT CONTAINS CONTINUED TO NEW PUBLIACTION */
  useEffect( () => {
    if ( match.params.id ) {
      ( async () => {
        const { data } = await getPublication( match.params.id );
        setPublication( data );
        setExpirationDate( new Date( data.expirationDate ) );
      } )();
      
    }

  }, [ setPublication, match ] );

  useEffect( () => {
    if ( publication !== "" ) {
      ( async () => {
        const { data } = await getPublicationDetail( {
          idPublication: match.params.id,
          esCompra: false,
          subastaDirecta: publication?.subasta_directa
        } );
        setDetail( data );
      } )();

      ( async () => {
        const { data } = await getUserEstablishmentsById( publication.person.id );
        setEstablishments( data );
      } )();

      if ( publication.batch?.race.substring( 0, 5 ) === 'Cruza' ) {
        setEsRaza( false );
        setEsMestizo( true );
      } else {
        setEsRaza( true );
        setEsMestizo( false );
      }
    }
  }, [ publication ] );

  useEffect( () => {
    if ( race.value === 'Raza' ) {
      setEsRaza( true );
      setEsMestizo( false );
    }
    if ( race.value === 'Mestizo / cruza' ) {
      setEsRaza( false );
      setEsMestizo( true );
    }
  }, [ race ] );

  useEffect( () => {
    race.value = "Raza";
  }, [ breedType ] );

  useEffect( () => {
    race.value = "Cruza";
  }, [ crossbreed ] );


  useEffect( () => {
    if ( publication !== "" ) {
      // Aqui me fijo si en el objeto catalogue viene algun archivo con extension de video.
      // Separo esa URL
      publication.catalogue.map( ( element ) => {
        if ( element.includes( 'jpg' ) || element.includes( 'jpeg' ) || element.includes( 'png' ) )
          setImageUrl( element );
        else
          setVideoUrl( element );
      } )
      setTitle( publication.title );
      setValue( 'title', publication.title );
      setValue( 'description', publication.description );
      setValue( 'internal_description', publication.internal_description );
      setValue( 'expiration_date', publication.expirationDate );
      setValue( 'offer.price', publication.offer.price );
      setValue( 'lot_type', publication.lot_type );
      setValue( 'batch.age', publication.batch?.age );
      setValue( 'batch.weight', publication.batch?.weight );
      setValue( 'batch.category', publication.batch?.category );

      if ( publication.batch?.race.substring( 0, 5 ) === 'Cruza' ) {
        setValue( 'batch.crossbreed', publication.batch?.race );
      } else {
        setValue( 'batch.breedType', publication.batch?.race );
      }
      setValue( 'batch.where_weighted', publication.batch.where_weighted );
      setValue( 'batch.count', publication.batch.count );
      setValue( 'batch.review.quality', publication.batch.review.quality );
      setValue( 'batch.review.health_zone', publication.batch.review.health_zone );
      setValue( 'batch.review.health', publication.batch.review.health );
      setValue( 'batch.review.knows_eat', publication.batch.review.knows_eat );
      setValue( 'cbu', publication.cbu );
      setOffdes( publication.batch.review.weaned_off );
      setPublicationApproved( publication.enabled );
      setPublicationState( publication.state );
      setEstablishment( publication.establecimiento );
      setPosition( [ publication.establecimiento.lat, publication.establecimiento.lng ] )
      setEsBulto( publication.es_bulto );

      if ( publication.batch.review.weaned_off_date ) {
        const unixTimeStamp = publication.batch.review.weaned_off_date;
        const newDate = new Date( unixTimeStamp );
        setWeanedOff( newDate );
      }
      setRequireReviewed( publication.requires_review );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ publication ] );


  // Set TABLES
  const handleSetRevision = () => {
    setLote( false );
    setRevision( true );
    setUbication( false );
    setDatosBancarios( false );
  };
  const handleSetLote = () => {
    setLote( true );
    setRevision( false );
    setUbication( false );
    setDatosBancarios( false );
  };
  const handleSetUbication = () => {
    setLote( false );
    setRevision( false );
    setUbication( true );
    setDatosBancarios( false );
  };
  const handleDatosBancarios = () => {
    setLote( false );
    setRevision( false );
    setUbication( false );
    setDatosBancarios( true );
  };
  // END Set TABLES

  // Custom functions:
  const handleDetail = async ( data ) => {
    const toSend = {
      idConsignataria: 1,
      idPublication: publication?.id,
      precioXKg: data?.offer?.price,
      pesoPromedio: data?.batch?.weight,
      cantidad: data?.batch?.count,
      es_bulto: esBulto,
      subasta_directa: publication.subasta_directa
    };
    try {
      const { data } = await getPublicationDetailNew( toSend );
      setDetail( data );
    } catch ( err ) {
      console.log( err );
    }
  };

  //FUNCTION DROP:
  const onDrop = useCallback( ( acceptedFiles ) => {
    remove( 0 );
    const radr = new FileReader();
    radr.onerror = () => console.log( "file reading has failed" );
    acceptedFiles.forEach( async ( acceptedFile ) => {
      append( {
        preview: URL.createObjectURL( acceptedFile ),
        file: acceptedFile,
      } );
      setImageUrl( URL.createObjectURL( acceptedFile ) );
      setImageFile( acceptedFile );
    } );
  }, [] );

  const { getInputProps, getRootProps } = useDropzone( {
    accept: "image/jpeg,image/png",
    maxFiles: 1,
    maxSize: 3000000,
    onDropRejected: () =>
      Swal.fire( "Solo se permiten imágenes jpg | png de hasta 3 Mb" ),
    onDrop: onDrop,
    multiple: false,
  } );

  // ============= VIDEO UPLOAD ==============================
  const uploadVideos = async ( videoFiles ) => {

    const f = new FormData();

    for ( let index = 0; index < videoFiles.length; index++ ) {
      f.append( "videos", videoFiles[ index ] );
    }
    try {
      setUploadingVideos( true );
      // Envio los videos al backend para ser subidos a Google Storage
      const { data } = await postPublicationVideo( f );
      successfulVideoUploadModal();
      // Cargo el Array "data" en la variable "videosURLs", para luego agregarlas al objeto
      // "catalogue" donde se graban las URLs de imagenes + videos al grabar la publicacion      
      setVideoUrl( ...data );
      setUploadingVideos( false );
    } catch ( error ) {
      Swal.fire( {
        title: "Error",
        text: "No pudo actualizarse el video. Reintente por favor.",
        icon: "error",
      } );
    }
  }
  // ============= VIDEO UPLOAD ==============================   


  const successfulVideoUploadModal = () => {
    Swal.fire( {
      title: "Carga de video finalizada",
      text: "Su video ha sido actualizado con éxito.",
      icon: "success",
    } );
  };

  const onSubmit = async data => {
    setLoadImage( true )

    const { isConfirmed } = await Swal.fire( {
      title: "¿Está seguro de modificar la publicación?",
      text: "Una vez editada, quedará pendiente de aprobación y podrás verla en tu panel de control.",
      icon: "warning",
      confirmButtonColor: "#22B24C",
      confirmButtonText: "¡Si, Editar!",
      showCancelButton: true,
      cancelButtonColor: "#e4a5a5",
      cancelButtonText: "Cancelar",
    } );

    if ( isConfirmed ) {
      Swal.fire( {
        title: "Editando publicación",
        text: "Aguarde unos instantes",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
      } );

      try {
        const newCatalogueImg = [];
        let newCustomCatalogue = [];

        if ( !!imageFile ) {
          //si la imagen cambio, subo img al storage
          newCatalogueImg.push( imageFile );
          const { data: imagesURLs } = await postPublicationImage( newCatalogueImg );
          newCustomCatalogue.push( ...imagesURLs );
        } else {
          //vuelvo a subir url de img existente
          newCustomCatalogue.push( imageUrl );
        }

        //agrego url de video al array que se graba en DB
        newCustomCatalogue.push( videoUrl );

        const { lot_type, batch, ...rest } = data;
        const { review } = batch;

        if ( batch.race === "Raza" ) {
          batch.race = batch.breedType;
          delete batch.crossbreed;
          delete batch.breedType;
        } else {
          batch.race = batch.crossbreed;
          delete batch.breedType;
          delete batch.crossbreed;
        }

        const toSend = {
          ...rest,
          id_consignatary: 1,
          catalogue: newCustomCatalogue,
          lot_type: type_lote,
          batch: {
            ...batch,
            // weight: weigth,
            review: {
              ...review,
              weaned_off_date: weanedOff,
            }
          },
          requires_review: requireReviewed,
          reviewer: null,
          envio_incluido: false,
          establecimiento: establishment,
          expiration_date: expirationDate,
          es_bulto: esBulto,
          subasta_directa: publication.subasta_directa,
          description: descriptionText,
          internal_description: internalDescriptionText,
        };

        // console.log( toSend );

        await postPublicationEdit( match.params.id, toSend );
        await Swal.fire( {
          title: "¡Publicación editada correctamente!",
          text: "Puedes ver tu publicación en tu panel de control.",
          icon: "success",
        } );
        history.push( routers.user_sales.path );
      } catch ( err ) {
        console.error( err );
        await Swal.fire( {
          title: "¡No se pudo editar la publicación!",
          text: "Por favor intente de nuevo más tarde.",
          icon: "error",
        } );
        history.push( routers.home.path );
      }
    }
  }
  //END HANDLESUBMIT

  useEffect( () => { // lot_type, address
    let title;
    let text;
    if ( errors?.title ) {
      title = "Título vacio";
      text = "Por favor ingrese un título válido.";
    } else if ( errors?.offer?.price ) {
      title = "Precio vacío"
      text = "Por favor ingrese un precio válido.";
    } else if ( errors?.description ) {
      title = "Descripcion vacía";
      text = "Por favor ingrese una descripción";
    } else if ( errors?.batch?.count || errors?.batch?.review?.quality ) {
      title = "Pestaña Revisión incompleta";
      text = "Hay campos de carácter obligatorio vacíos en la pestaña Revisión.";
      handleSetRevision();
    } else if ( errors?.address || errors?.batch || errors?.lot_type ) {
      title = "Pestaña Lote incompleta";
      text = "Hay campos de carácter obligatorio vacíos en la pestaña Lote.";
      handleSetLote();
    } else if ( errors?.establishment ) {
      title = "Pestaña Ubicación incompleta";
      text = "Hay campos de carácter obligatorio vacíos en la pestaña Ubicación.";
      handleSetUbication();
    } else if ( errors?.datosBancarios ) {
      title = "Pestaña Mi Cuenta incompleta";
      text = "Hay campos de carácter obligatorio vacíos en la pestaña Mi Cuenta.";
      handleDatosBancarios();
    } else {
      return;
    }

    text && Swal.fire( title, text, "warning" );
  }, [ errors ] );

  const handleEstablishment = ( e ) => {
    const est = establishments.find( establishment => {
      return establishment.id === Number( e.target.value );
    } );
    setEstablishment( est );
    if ( !est ) {
      setPosition( [ -31.4135, -64.18105 ] ); // CENTRO DE CORDOBA
      return;
    }
    setPosition( [ est.lat, est.lng ] );
  };

  return (
    ( publication === "" ? getViewLoading() :
      <div className="row">
        <div className="container__RebuildPublication p-2 p-lg-4">
          <form onSubmit={ handleSubmit( onSubmit ) }>
            <div className="row me-0 row-cols-2 row-cols-lg-3">
              <div className="col-2 col-lg-1 d-flex align-items-center justify-content-start">
                <div
                  className="backarrow container__BackArrow"
                  onClick={ () => history.goBack() }>
                  <i className="fas fa-angle-left mt-1 ml-1"></i>
                </div>
              </div>
              <div className="col-10 col-lg-6">
                <span className="badge badge-light">Lote nro: { publication.id }</span>
                <h1 className="container__description">Título</h1>
                <div className="form-group w-100">
                  <CustomInput
                    placeholder={ Traslate.NEW_PUBLICATION.TITLE_HINT }
                    name="title"
                    register={ register }
                    params_register={ { required: true } }
                    error={ errors?.title }
                    maxLength="60"
                    tooltip="Máximo 60 caracteres"
                    onChange={ ( e ) => setTitle( e.target.value ) }
                  />
                </div>
                Solicitud de publicación:
                <span className={ publicationApproved ? "badge bg-success m-1" : "badge bg-danger m-1" }>
                  { publicationApproved ? "Aprobado" : "En revision" }
                </span><br></br>
                Estado de Publicación:
                <span className={ publicationState === "Publicada" ? "badge bg-success m-1" : "badge bg-warning m-1" }>
                  { publicationState }
                </span>
              </div>
            </div>
            {/* Media and sell conditions */ }
            {/* Media */ }
            <div
              className='row row-cols-1 row-cols-lg-2 mt-2'>
              <div className="col col-lg-8 px-lg-5">

                <div className="d-flex justify-content-center">
                  <video id="my-video"
                    src={ videoUrl }
                    width={ '100%' } className={ 'shadow-lg p-1 bg-light rounded' } controls='controls'></video>
                </div>

                <hr />
                <div className="d-flex justify-content-center">
                  <img src={ imageUrl } width={ '100%' } style={ { "maxWidth": '500px' } } className={ 'shadow-lg p-1 bg-light rounded' }></img>
                </div>

                <div>
                  <div { ...getRootProps() }>
                    <input { ...getInputProps() } />
                    <br />
                    <button type="button" className="btn btn-info btn-block mx-auto w-100">
                      Cambiar imágen  (solo jpg, png - tamaño máximo: 3Mb)
                    </button>
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="files"
                    disabled={ uploadingVideos }
                    className="btn btn-info btn-block">
                    { ( uploadingVideos ) ? 'Cargando video...' : 'Cambiar video' }
                  </label>
                  <input multiple={ false } style={ { visibility: 'hidden' } } type="file" name="files" id="files" onChange={ ( e ) => uploadVideos( e.target.files ) } />
                </div>
              </div>
              {/* Sell conditions */ }

              
              <div className="col col-lg-4">
                <div className="container__sell_conditions">
                  <span className="badge badge-light">#{ publication.id }</span>
                  <h1>{ title ?? 'Título' }</h1>
                  <p className='PublicationCostsDetail-subtitle'>
                    Precio total
                  </p>
                  <p className='PublicationCostsDetail-price'>
                    { formatPrice( detail?.total ) }
                  </p>
                  { !publication.subasta_directa 
                  ? 
                  (<p className='PublicationCostsDetail-subtitle'>
                      Detalle de costos
                    </p>)
                  : null}

                  { !publication.subasta_directa 
                  ? (<>
                  { detail ? (
                    <ul className="PublicationCostsDetail-list">
                      <li>Costo de publicación: { formatPrice( detail?.costoPublicacion ) }</li>
                      <li>
                        Gastos de comercialización:
                        <span style={ { color: 'red', marginLeft: '1rem' } }>
                          - { formatPrice( detail?.gastosComercializacion ) }
                        </span>
                      </li>
                      <li>Sub-Total: { formatPrice( detail?.subTotal ) }</li>
                      <li>
                        IVA:
                        <span style={ { color: 'green', marginLeft: '0.5rem' } }>
                          + { formatPrice( detail?.iva ) }
                        </span>
                      </li>
                      <li><small className='bold'>Pueden incluirse costos extra</small></li>
                    </ul>
                  ) : null }
                  </>)
                  : null}
                  
                  <div className='d-grid gap-2'>
                    <button
                      className='btn btn-info w-100'
                      onClick={ handleSubmit( handleDetail ) }
                      type='button'
                    >
                      Calcular detalle
                    </button>
                  </div>

                </div>
              </div>
            </div>
            {/* Description and info */ }
            <div className="col-12 col-lg-6">
              <h1 className="container__description">
                Descripción del producto* (requerido)
              </h1>
              <CustomInput
                name="description"
                register={ register }
                onChange={ e => setDescriptionText( e.target.value ) }
                params_register={ { required: true } }
                tootlip="Descripcion general"
                placeholder="Agregue una descripción"
                error={ errors?.description }
                type="text-area"
                min="0" />
            </div>
            {
              publication.subasta_directa ? 
              (<div className="col-12 col-lg-6">
                <h1 className="container__description">Datos internos</h1>
                <CustomInput
                  name="internal_description"
                  onChange={ e => setInternalDescriptionText( e.target.value ) }
                  register={ register }
                  params_register={ { required: true } }
                  tootlip="Descripcion interna"
                  placeholder="Datos cliente / vendedor"
                  error={ errors?.description }
                  type="text-area"
                  min="0" />
              </div>): null
            } 
            { isAdmin() || isCommercial() 
            ? (<div className="col-12 col-lg-6">
                <h1 className="container__description">
                  Fecha de expiración
                </h1>
                <DatePicker
                  locale='es'
                  className='form-control'
                  minDate={ new Date() }
                  selected={ expirationDate }
                  onChange={ ( date ) => setExpirationDate( date ) }
                  dateFormat="dd/MM/yyyy"
                />
              </div>)
            : null}
            

            <div className="row row-cols-1 row-cols-lg-2 mt-2">
              {/* info */ }
              <div className='col-12 col-lg-8 ms-3 my-3'>
                <div className="col-lg-11 col-sm-12">
                  <div className="container__info_buttons">
                    <Button
                      className="botton-classname btn-custom-width"
                      inverted={ lote ? false : true }
                      onClick={ handleSetLote }
                    >
                      Lote
                    </Button>
                    <Button
                      className="botton-classname btn-custom-width"
                      inverted={ revision ? false : true }
                      onClick={ handleSetRevision }
                    >
                      Revisión
                    </Button>
                    <Button
                      className="botton-classname btn-custom-width"
                      inverted={ ubication ? false : true }
                      onClick={ handleSetUbication }
                    >
                      Ubicación
                    </Button>
                    <Button
                      className="botton-classname btn-custom-width"
                      inverted={ datosBancarios ? false : true }
                      onClick={ handleDatosBancarios }
                    >
                      Mi cuenta
                    </Button>
                  </div>
                  <div className="container__info">
                    <div className='row' style={ { height: lote ? "100%" : "0", overflow: "hidden" } }>
                      <div className="col-md-4">
                        <ComboBox
                          label="Raza*"
                          styles={ CUSTOM_BOX_STYLES }
                          name="batch.race"
                          placeholder="Seleccione raza..."
                          values={ cowBreed }
                          onChange={ race.onChange }
                          register={ register }
                          // params_register={ { required: true } }
                          error={ errors?.batch?.race }
                        />
                        <ComboBox
                          hidden={ !esRaza }
                          styles={ CUSTOM_BOX_STYLES }
                          name="batch.breedType"
                          placeholder="Seleccione raza..."
                          values={ breedTypes }
                          onChange={ breedType.onChange }
                          register={ register }
                          params_register={ { required: true } && esRaza }
                          error={ errors?.batch?.breedType }
                        />
                        <ComboBox
                          hidden={ !esMestizo }
                          styles={ CUSTOM_BOX_STYLES }
                          name="batch.crossbreed"
                          placeholder="Seleccione raza de cruza..."
                          values={ crossbreedData }
                          onChange={ crossbreed.onChange }
                          register={ register }
                          params_register={ { required: true } && esMestizo }
                          error={ errors?.batch?.crossbreed }
                        />
                        <CustomInput
                          label="Edad*"
                          name="batch.age"
                          placeholder="Ingrese edad promedio..."
                          register={ register }
                          params_register={ { required: true } }
                          error={ errors?.batch?.age }
                        />

                        <h3>Precio por bulto</h3>
                        <div className='switch-container'>
                          <label className='switch'>
                            <input
                              type='checkbox'
                              disabled={ publication.subasta_directa }
                              checked={ esBulto }
                              onChange={ () => setEsBulto( prevState => !prevState ) } />
                            <span id='slider-switch' className='slider-switch round container__vendor_slider'></span>
                          </label>
                          { publication.subasta_directa 
                          ? (<span><p>Es subasta directa, solo puede ser precio por bulto</p></span>) : null}
                        </div>
                        <CustomInput
                          label= { `Precio por ${ !esBulto ? 'kilogramo' : 'bulto' }` }
                          placeholder="Ingrese el precio"
                          name="offer.price"
                          type="number"
                          min="0"
                          step="0.01"
                          register={ register }
                          params_register={ { required: true } }
                          error={ errors?.offer?.price }
                        />
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <ComboBox
                          label="Categoria*"
                          name="batch.category"
                          placeholder="Ingrese categoria..."
                          values={ categories }
                          styles={ CUSTOM_BOX_STYLES }
                          register={ register }
                          params_register={ { required: true } }
                          error={ errors?.batch?.category }
                        />  
                        { !esBulto ? (
                          <CustomInput
                            label="Peso*"
                            name="batch.weight"
                            tooltip="peso promedio del lote"
                            type="number"
                            min="0"
                            step="0.01"
                            placeholder="Ingrese peso promedio..."
                            // onChange={(e) => setWeigth(e.target.value)}
                            params_register={ { required: true } }
                            register={ register }
                            error={ errors?.batch?.weight }
                          />
                        ) : null }  
                        { !esBulto ? (
                          <ComboBox
                            label="Lugar donde se pesó*"
                            name="batch.where_weighted"
                            placeholder={ "Seleccione una opción" }
                            values={ [ "Balanza Propia", "Balanza Pública" ] }
                            register={ register }
                            params_register={ { required: true } }
                            styles={ CUSTOM_BOX_STYLES }
                            error={ errors?.batch?.where_weighted }
                          />
                        ) : null }
                        <ComboBox
                          label="Tipo de lote*"
                          name="lot_type"
                          placeholder={ "Seleccione una opcion" }
                          values={ [ "Invernada", "Faena", "Cría" ] }
                          register={ register }
                          onChange={ ( e ) => setTypelote( e.target.value ) }
                          styles={ CUSTOM_BOX_STYLES }
                          params_register={ { required: true } }
                          error={ errors?.lot_type }
                        />

                      </div>                      
                    </div>
                    <div className='row' style={ { height: revision ? "100%" : "0px", overflow: "hidden" } }>
                      <div className="col-md-4">
                        <CustomInput
                          label="Cantidad*"
                          name="batch.count"
                          type="number"
                          placeholder="Ingrese cantidad..."
                          register={ register }
                          params_register={ { required: true } }
                          error={ errors?.batch?.count }
                        />
                        <ComboBox
                          label="Calidad"
                          name="batch.review.quality"
                          placeholder={ "Seleccione una opcion" }
                          values={ qualityConstant }
                          register={ register }
                          styles={ CUSTOM_BOX_STYLES }
                          params_register={ { required: true } }
                          error={ errors?.batch?.review?.quality }
                        />
                        <ComboBox
                          label="Sanidad"
                          name="batch.review.health"
                          placeholder={ "Seleccione una opción" }
                          values={ [ "Completa", "Incompleta" ] }
                          register={ register }
                          styles={ CUSTOM_BOX_STYLES }
                          error={ errors.batch?.review?.health }
                        />
                        <ComboBox
                          label="Zona"
                          name="batch.review.health_zone"
                          placeholder={ "Seleccione una opcion" }
                          values={ [ "Sucia", "Limpia" ] }
                          register={ register }
                          styles={ CUSTOM_BOX_STYLES }
                        />
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                      <CustomInput
                        label="Yuyo"
                        name="batch.review.knows_weeds"
                        placeholder="Yuyos que conoce..."
                        register={ register }
                        error={ errors?.batch?.review?.knows_weeds }
                      />
                      <div className="row switch-container">
                        <h3>Sabe comer</h3>
                        <div className="col"></div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={ knowsEat }
                            value={ knowsEat }
                            name="batch.review.knows_eat"
                            ref={ register }
                            onChange={ ( e ) =>
                              setKnowsEat( e.target.checked )
                            }
                          />
                          <span
                            id="slider-switch"
                            className="slider-switch round container__vendor_slider"
                          ></span>
                        </label>
                      </div>
                      <div className="row switch-container">
                        <h3>Destetado</h3>
                        <div className="col"></div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={ offdes }
                            value={ offdes }
                            name="batch.review.weaned_off"
                            ref={ register }
                            onChange={ ( e ) => setOffdes( e.target.checked ) }
                          />
                          <span
                            id="slider-switch"
                            className="slider-switch round container__vendor_slider"
                          ></span>
                        </label>
                      </div>
                      </div>                      
                    </div>
                    <div style={ { height: ubication ? "100%" : "0", overflow: "hidden" } }>
                      <h1><b>Información de ubicación</b></h1>
                      <BottonLine width="100%" margintop="mx-auto" />
                      <p>Por favor selecione en cual Establecimiento se encuentra la hacienda. Este dato es obligatorio.</p>
                      <div className="mt-4 mb-4">
                        <div className="form-group">
                          <select
                            id="id_establishment"
                            className="form-control"
                            value={ establishment?.id }
                            name="establishment"
                            error={ errors?.establishment }
                            onChange={ handleEstablishment }
                            ref={ register( { required: true } ) }
                          >
                            <option value=''>Seleccione un establecimiento</option>
                            { establishments.map( ( e ) => (
                              <option key={ e.id } value={ e.id }>
                                { e.nombre }
                              </option>
                            ) ) }
                          </select>
                        </div>
                      </div>
                      <div
                        className="container__map"
                      >
                        { position ? (
                          <MapContainer center={ position } zoom={ 14 }>
                            <MapRealloc position={ position } />
                            {/* <TileLayer
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            /> */}
                            <TileLayer
                              url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                              maxZoom={ 20 }
                              subdomains={ [ 'mt0', 'mt1', 'mt2', 'mt3' ] }
                            />
                            <Marker position={ position } />
                          </MapContainer>
                        ) : null }
                      </div>
                    </div>
                    <div style={ { height: datosBancarios ? '100%' : '0', overflow: 'hidden' } }>
                      <h1><b>Información para Liquidación</b></h1>
                      <BottonLine width="100%" margintop="mx-auto" />
                      <p>En este apartado le solicitamos ingrese el número de CBU/CVU donde recibirá la liquidación de la venta.
                        En caso de ya tener uno registrado con nosotros, puede acceder a modificarlo.</p>
                      <table className="table table-striped responsiveBatch">
                        <tbody>
                          <tr>
                            <th scope="row"></th>
                            <td className="background_td_switch d-none d-lg-table-cell">
                              CBU
                            </td>
                            <td>
                              <CustomInput
                                label="CBU/CVU*"
                                maxLength={ 22 }
                                tooltip="Máximo 22 dígitos"
                                name="cbu"
                                placeholder="Ingrese cbu..."
                                register={ register }
                                params_register={ { required: true } }
                                error={ errors?.cbu }
                              />
                            </td>
                            <td>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 w-100">
                <div className="w-lg-100 container__vendor">
                  <div>
                    <h1 className="container__vendor_title">
                      Información del vendedor
                    </h1>
                    <BottonLine width="80%" margintop="mx-auto" />
                  </div>
                  <div className="mt-4">
                    <h2 className="bold">Consignataria</h2>
                    <div className="row">
                      <img className="logo-cc"
                        src={ `${ process.env.PUBLIC_URL }/images/logo-cc.png` }
                      />
                      <p className="mt-3 bold">
                        Consignaciones Córdoba
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col col-lg-4">
                <div className="btn-group w-100">
                  <button
                    className="btn btn-success"
                    disabled={ loadimage }
                    type="submit"
                  >
                    { TraslateAdmin.COMMON.SAVE }
                  </button>
                  <button
                    className={ `btn btn-outline-secondary ml-3` }
                    onClick={ () => history.push( routers.user_sales.path ) }
                    type="submit"
                  >
                    { TraslateAdmin.COMMON.CANCEL }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default RebuildPublicationEdit;
