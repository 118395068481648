// React
import React from 'react';

// Components
import Swal from 'sweetalert2';

// EndpointFunctions
import { postPublicationCancel, postPublicationPause, postPublicationResume } from '../../../../api/private/client'

// Others
import routers from '../../../../config/routers';

/**
 * @brief Opciones de las publicaciones de las cuales el usuario
 * es dueño
 * 
 * @param {useHistory} history useHistory de react-router 
 * @param {object} publication la publicacion entera  
 * @param {function} isApproved funcion que indica si el usuario esta habilitado o no retornando un bool 
 */
function OwnerOptions( { history, publication} ) {

    const pausePublication = async () => {
        Swal.fire( {
            title: "Pausando publicación",
            text: "Aguarde unos momentos.",
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false,
        } );
        const res = await postPublicationPause( publication.id );
        res.status === 200 ?
            Swal.fire( "Publicación pausada con éxito", "", "success" ).then( () => history.go( 0 ) )
            :
            Swal.fire( "Hubo un error", "Intentelo mas tarde", "error" )
    }

    const resumePublication = async () => {
        Swal.fire( {
            title: "Reanudando publicación",
            text: "Aguarde unos momentos.",
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false,
        } );
        const res = await postPublicationResume( publication.id );
        res.status === 200 ?
            Swal.fire( "Publicación reanudada con éxito", "", "success" ).then( () => history.go( 0 ) )
            :
            Swal.fire( "Hubo un error", "Intentelo mas tarde", "success" )
    }

    const editPublication = () => {
        history.push( routers.user_new_publication_edit.path_1( publication.id ) );
    }

    const cancelPublication = async () => {
        Swal.fire( {
            title: "Cancelando publicación",
            text: "Aguarde unos momentos.",
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false,
        } );
        try {
            await postPublicationCancel( publication.id );
            await Swal.fire( {
                title: "Publicación cancelada",
                text: "La publicación ha sido cancelada correctamente.",
                icon: "success",
            } );
        } catch {
            await Swal.fire( {
                title: "Ha ocurrido un error",
                text: "Intente de nuevo más tarde",
                icon: "error",
            } );
        } finally {
            history.go( 0 );
        }
    };

    return <>
        {
            ( publication.state === "Creada_Venta" || publication.state === "Publicada" || publication.state === "Calificada" ) &&
            <li key={ "pause" }><a type={ "button" } className="dropdown-item" onClick={ () => pausePublication() }>Pausar</a></li>
        }
        {
            publication.state === "Pausada" &&
            <li key={ "resume" }><a type={ "button" } className="dropdown-item" onClick={ () => resumePublication() }>Resumir/Reanudar</a></li>
        }
        {
            publication.state !== "Creada_Venta" && publication.state !== "Publicada" && publication.state !== "Pausada" && publication.state !== "Calificada" &&
            <li key={ "pause" }><a type={ "button" } className="dropdown-item disabled">Pausar</a></li>
        }
        {
            ( publication.state === "Comprada" || publication.state === "Cancelada" ) ?
                <>
                    <li key={ "edit" }><a type={ "button" } className="dropdown-item disabled">Editar</a></li>
                    <li key={ "delete" }><a type={ "button" } className="dropdown-item disabled">Cancelar</a></li>
                </>
                :
                <>
                    <li key={ "edit" }><a type={ "button" } className="dropdown-item" onClick={ () => editPublication() }>Editar</a></li>
                    <li key={ "delete" }><a type={ "button" } className="dropdown-item" onClick={ () => cancelPublication() }>Cancelar</a></li>
                </>
        }
    </>
}

export default OwnerOptions;