import { useEffect, useState } from 'react';

import { getLocalities, getProvinces } from '../../api/private/client';

const useAddress = (provinceId = null) => {
    const [provinces, setProvinces] = useState(null);
    const [localities, setLocalities] = useState(null);

    useEffect(() => {
        (async () => {
            const { data } = await getProvinces();
            const sorted = data.sort((a, b) => {
                if (a.province > b.province) return 1;
                if (a.province < b.province) return -1;
                return 0;
            });
            setProvinces(sorted);
            if (provinceId) changeProvince(provinceId);
        })();
    }, []);

    const changeProvince = async id => {
        try {
            const { data } = await getLocalities(id);
            const sorted = data.localities.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
            setLocalities(sorted);
        } catch (err) {
            setLocalities(null);
        };
    };


    return { provinces, localities, changeProvince };
};

export default useAddress;
