import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

import LogoNavbar from "./LogoNavbar/LogoNavbar";
import { SearchIcon } from "../Icons/Icons";
import routers from "../../config/routers";
import { useAuthContext } from "../../context/AuthenticatedContext";
import useWindowSize from "../../hooks/useWindows";
import Modal from "react-modal";

import "./MobileNavbar.css";
import "./Navbar.css";
import "./WebNavbar.css";
import { getUser } from "../../api/private/client";
import { profileValidations } from '../../utils/profileValidations';


const MobileNavbar = ( { dropdownItems } ) => {
  const { auth, isLogged } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [ isDdOpen, setDdOpen ] = useState( false );
  const [ show, setShow ] = useState( false );
  let notif = JSON.parse( localStorage.getItem( "notif" ) );

  const handleClose = () => setShow( false );
  const handleShow = () => {
    setShow( true );
    notif = JSON.parse( localStorage.getItem( "notif" ) );
  };

  const handleDelete = () => {
    notif = [];
    localStorage.removeItem( "notif" );
    handleClose();
  };

  const onSubmit = ( data ) => {
    const q = new URLSearchParams();
    q.set( "query", data.search );
    history.push( `${ routers.publication_search.path }?${ q.toString() }` );
    history.go( 0 );
  };

  return (
    <>
      <div className="MobileNavbar-container row">
        <div className="col-2 d-flex align-items-center justify-content-center">
          { isLogged() ? (
            <i
              className="MobileNavbar-hamburger fa fa-bars"
              onClick={ () => setDdOpen( ( open ) => !open ) }
            />
          
          ) : null }
        </div>
        <div className="col-5 d-flex align-items-center justify-content-center">
          <LogoNavbar image="xercana-brand-blue-2.svg" />
        </div>
        <div className="col-5 d-flex align-items-center justify-content-around">
          { isLogged() ? (
            <>
              <a
                className="d-flex btn btn-success session-button"
                onClick={ () => setDdOpen( ( open ) => !open ) }
              >
                { auth.user?.name }
              </a>
              
              <Modal
                isOpen={ show }
                onRequestClose={ handleClose }
                style={ {
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: "100%",
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2rem",
                    borderRadius: "10px",
                    borderColor: "#f0f0f0",
                  },
                  overlay: { backgroundColor: "rgba(50, 50, 50, 0.904)" },
                } }
              >
                <div
                  style={ {
                    display: "flex",
                    flexDirection: "column",
                    width: "90%",
                  } }
                >
                  <h1 className="text-center font-weight-bold">
                    Notificaciones
                  </h1>
                  <p>Estas son tus notificaciones</p>
                  <ul>
                    { notif?.map( ( item ) => (
                      <li>
                        { item.title }
                        <ul>
                          <li>{ item.body }</li>
                        </ul>
                      </li>
                    ) ) }
                  </ul>
                  <button
                    type="button"
                    className="btn btn-block btn-danger"
                    onClick={ handleDelete }
                  >
                    Borrar todo
                  </button>
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={ handleClose }
                  >
                    Cerrar
                  </button>
                </div>
              </Modal>

            </>
          ) : (
            <a className="MobileNavbar-login-text" href={ process.env.REACT_APP_GOOGLE_AUTH_URL }>
              Ingresar
            </a>
          ) }
        </div>
      </div>
      <div className="MobileNavbar-searchbar-container row">
        <div className="col-12 px-3">
          <form
            className="MobileNavbar-searchbar-form"
            onSubmit={ handleSubmit( onSubmit ) }
          >
            <div
              className="MobileNavbar-searchbar-icon"
              onClick={ handleSubmit( onSubmit ) }
            >
              <SearchIcon className="" fill="#ff9e18" />
            </div>
            <input
              className="MobileNavbar-searchbar"
              placeholder="Buscar..."
              name="search"
              autoComplete="off"
              ref={ register( { required: true } ) }
              type="text"
            />
          </form>
        </div>
      </div>
      { isDdOpen && isLogged() && (
        <ul className="MobileNavbar-dropdown-list">
          { dropdownItems &&
            dropdownItems.map( ( item, i ) => (
              <Fragment key={ item.id }>
                { i !== 0 && <hr className="MobileNavbar-dropdown-divider" /> }
                <li className="MobileNavbar-dropdown-item">
                  <a
                    className="MobileNavbar-dropdown-item-text"
                    onClick={ item.onClick }
                    href={ item.href }
                  >
                    { item.text }
                  </a>
                </li>
              </Fragment>
            ) ) }
        </ul>
      ) }
    </>
  );
};

const WebNavbar = ( { dropdownItems } ) => {
  const { auth, isLogged } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [ isDdOpen, setIsDdOpen ] = useState( false ); // Dropdown is open
  const [ isLoading, setIsLoading ] = useState( false );
  const [ show, setShow ] = useState( false );

  let notif = JSON.parse( localStorage.getItem( "notif" ) );

  const handleClose = () => setShow( false );
  const handleShow = () => {
    setShow( true );
    notif = JSON.parse( localStorage.getItem( "notif" ) );
  };

  const handleDelete = () => {
    notif = [];
    localStorage.removeItem( "notif" );
    handleClose();
  };

  const onSubmit = ( data ) => {
    const q = new URLSearchParams();
    q.set( "query", data.search );
    history.push( `${ routers.publication_search.path }?${ q.toString() }` );
    history.go( 0 );
  };

  const handleSell = async ( e ) => {
    e.preventDefault();
    setIsLoading( true );
    const { data } = await getUser();
    if ( await profileValidations( history, data ) === true ) history.push( routers.user_new_publication.path, data.company.cbu );
    setIsLoading( false );
  };

  return (
    <div className="WebNavbar-container row shadow">
      <div className="WebNavbar-left col-4 col-lg-2 d-flex align-items-center justify-content-center">
        <LogoNavbar image="xercana-brand-blue.svg" />
      </div>
      <div className="WebNavbar-center col-5 col-lg-6 align-items-center">
        <form
          className="WebNavbar-searchbar-form "
          onSubmit={ handleSubmit( onSubmit ) }
        >
          <div
            className="WebNavbar-searchbar-icon"
            onClick={ handleSubmit( onSubmit ) }
          >
            <SearchIcon className="" fill="#ff9e18" />
          </div>
          <input
            className="WebNavbar-searchbar"
            placeholder="Buscar..."
            name="search"
            autoComplete="off"
            ref={ register( { required: true } ) }
            type="text"
          />
        </form>
      </div>
      <div className="col-2 col-lg-4 d-flex align-items-center justify-content-center">
        { isLogged() ? (
          <>
            <a
              title="Realizar una publicacion"
              className="sale-button btn btn-info mr-2"
              onClick={ handleSell }
              href={ routers.user_new_publication.path }
            >
              { isLoading ? 'Cargando...' : 'VENDER' }
            </a>

            <a className="d-flex btn btn-success session-button"
              onClick={ ( e ) => {
                e.preventDefault();
                setIsDdOpen( ( open ) => !open );
              } }>
              { auth.user?.name }

              <span className="fas fa-angle-down ml-2 pt-1" aria-hidden="true"></span>
            </a>

            

            <Modal
              isOpen={ show }
              onRequestClose={ handleClose }
              style={ {
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: "100%",
                  display: "flex",
                  width: "60%",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2rem",
                  borderRadius: "10px",
                  borderColor: "#f0f0f0",
                },
                overlay: { backgroundColor: "rgba(50, 50, 50, 0.904)" },
              } }
            >
              <div
                style={ {
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                } }
              >
                <h1 className="text-center font-weight-bold">Notificaciones</h1>
                <p>Estas son tus notificaciones</p>
                <ul>
                  { notif?.map( ( item ) => (
                    <li>
                      { item.title }
                      <ul>
                        <li>{ item.body }</li>
                      </ul>
                    </li>
                  ) ) }
                </ul>
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  onClick={ handleDelete }
                >
                  Borrar todo
                </button>
                <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={ handleClose }
                >
                  Cerrar
                </button>
              </div>
            </Modal>

            { isDdOpen ? (
              <ul className="WebNavbar-dropdown-list">
                { dropdownItems &&
                  dropdownItems.map( ( item, i ) => (
                    <Fragment key={ item.id }>
                      { i !== 0 && <hr className="WebNavbar-dropdown-divider" /> }
                      <li className="WebNavbar-dropdown-item">

                        <a
                          className="WebNavbar-dropdown-item-text"
                          onClick={ item.onClick }
                          href={ item.href }
                        >
                          { item.text }
                        </a>
                      </li>
                    </Fragment>
                  ) ) }
              </ul>
            ) : null }
          </>
        ) : (
          <>
            <a className="btn btn-success login-button" href={ process.env.REACT_APP_GOOGLE_AUTH_URL } >
              <span className="fa fa-user fa-lg mr-3" aria-hidden="true"></span>
              Ingresar
            </a>
          </>
        ) }
      </div>
    </div>
  );
};

const Navbar = () => {
  const auth = useAuthContext();
  const history = useHistory();
  const size = useWindowSize();
  const [ isMobile, setIsMobile ] = useState( false );
  const [ isLoading, setIsLoading ] = useState( false );


  useEffect( () => {
    if ( size.width < 800 ) {
      setIsMobile( true );
    } else {
      setIsMobile( false );
    }
  }, [ size ] );

  let dropdownItems = [
    {
      id: 0,
      text: "Perfil",
      onClick: async ( e ) => {
        setIsLoading( true );
        e.preventDefault();
        const { data } = await getUser();
        if ( await profileValidations( history, data ) === true ) {
          history.push( {
            pathname: '/user/register',
            state: {
              fromMenu: true,
            },
          } );
          setIsLoading( false );
        }
        setIsLoading( false );
      },
      href: routers.user_register_data.path,
    },
    {
      id: 1,
      text: "Resumen - Preguntas",
      onClick: ( e ) => {
        e.preventDefault();
        if ( !auth.isEnabled() ) {
          Swal.fire( {
            customClass: "swal-wide",
            title: "Su perfil no se encuentra verificado",
            text: "Su cuenta aún no está habilitada. Recibirá una notificación dentro de las próximas 48 hrs hábiles una vez que verifiquemos sus datos. Muchas gracias",
            icon: "warning",
          } );
          return;
        }
        history.push( routers.user_dashboard.path );
      },
      href: routers.user_dashboard.path,
    },
    {
      id: 2,
      text: "Mis movimientos",
      onClick: ( e ) => {
        e.preventDefault();
        if ( !auth.isEnabled() ) {
          Swal.fire( {
            customClass: "swal-wide",
            title: "Su perfil no se encuentra verificado",
            text: "Su cuenta aún no está habilitada. Recibirá una notificación dentro de las próximas 48 hrs hábiles una vez que verifiquemos sus datos. Muchas gracias",
            icon: "warning",
          } );
          return;
        }
        history.push( routers.user_sales.path );
      },
      href: routers.user_sales.path,
    },
    {
      id: 8,
      text: `${ isLoading ? 'Cargando...' : "Vender" }`,
      onClick: async ( e ) => {
        setIsLoading( true );
        e.preventDefault();
        const { data } = await getUser();
        if ( await profileValidations( history, data ) === true ) {
          history.push( routers.user_new_publication.path, data.company.cbu );
          setIsLoading( false );
        }
        setIsLoading( false );
      },
      href: routers.user_new_publication.path,
    },
    {
      id: 3,
      text: "Cerrar sesión",
      onClick: ( e ) => {
        e.preventDefault();
        auth.logOut();
        history.go( 0 );
      },
      href: "",
    }
  ];

  if ( auth.isAdmin() ) {
    const adminItems = [
      {
        id: 4,
        text: "Administración",
        onClick: ( e ) => {
          e.preventDefault();
          history.push( routers.admin.path );
        },
        href: routers.admin.path,
      },
      {
        id: 5,
        text: "Usuarios",
        onClick: ( e ) => {
          e.preventDefault();
          history.push( routers.admin_users.path_1( 0 ) );
        },
        href: routers.admin_users.path_1( 0 ),
      },
      {
        id: 7,
        text: "Parámetros de consignataria",
        onClick: ( e ) => {
          e.preventDefault();
          history.push( routers.admin_consignee.path );
        },
        href: routers.admin_consignee.path,
      },
      {
        id: 9,
        text: "Parámetros de transporte",
        onClick: ( e ) => {
          e.preventDefault();
          history.push( routers.admin_transport.path );
        },
        href: routers.admin_transport.path,
      },
      {
        id: 10,
        text: "Moderar Comentarios",
        onClick: ( e ) => {
          e.preventDefault();
          history.push( routers.admin_comments.path );
        },
        href: routers.admin_comments.path,
      },
    ];
    dropdownItems = adminItems.concat( dropdownItems );
  }

  return (
    <>
      { !isMobile ? (
        <WebNavbar dropdownItems={ dropdownItems } />
      ) : (
        <MobileNavbar dropdownItems={ dropdownItems } />
      ) }
    </>
  );
};

export default Navbar;

