import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Container from '../../components/container/Container';
import { IconCheck } from '../../components/Icons/Icons';
import routers from '../../config/routers';


export const PaymentOk = () => {

  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams( search );

  const { innerHeight: height } = window;

  return (
    <>
      <div className='"vh-100 d-inline-block"'
        style={ { width: 100, height: height / 6, backgroundColor: 'white' } }
      >
      </div>

      <Container className="rounded border-success p-5 m-5" centerElement={ true }>

        <>
          <IconCheck></IconCheck>

          <h1>Transacción finalizada con éxito</h1>
          <h1>Su código de autorización es: { searchParams.get( 'txid' ) }</h1>
          <hr />

          <h3>Le hemos enviado por correo el detalle de su compra.</h3><br />
          <h3>Si no visualiza el correo en su bandeja de entrada, por favor revisar la carpeta de Correo no Deseado.</h3><br /><br />

          <h3>Muchas Gracias por su compra!</h3>

          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-success"
              type="button"
              onClick={ () => history.push( routers.user_sales.path ) }> Ir a Mis Movimientos </button>
          </div>

        </>
      </Container>
      <div className='"vh-100 d-inline-block"'
        style={ { width: 100, height: height / 6, backgroundColor: 'white' } }
      >
      </div>
    </>
  );
};

