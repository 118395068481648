import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import routers from '../../../../config/routers';
import { FaArrowLeft } from 'react-icons/fa6';

export default function Title(label, id) {
    const history=useHistory();
    return <div className='title-container'>
        {/* <nav aria-label='breadcrumb'> */}
            <div className='d-flex w-100 '>                
                <div style={{cursor:'pointer'}} onClick={()=>history.goBack()}>                    
                    <FaArrowLeft></FaArrowLeft>  
                </div>
                <div className='row'>
                    <span className="badge badge-light ml-3 d-flex align-items-center">#{id}</span>  
                    <h1 className='breadcrumb-item ml-3 bold'>
                        {label}
                    </h1>  
                </div>
            </div>
        {/* </nav> */}
    </div>
}