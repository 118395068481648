
import { useEffect } from 'react';
import { useStepperContext } from '../../../../components/StepperModal/StepperModal';
import { formatDouble } from '../../../../utils/helper'
import './CostsStep.css'

const FormatPrice = ({ price }) => {
    price = Number(price);

    const options = {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
    };

    return <>
        {price.toLocaleString('es-ar', options)}
    </>;
};

const CostsStep = ({ context }) => {
    const { purchase, calculateCosts, hasCarrier, hasEstablishment, isLoading } = context;

    const { handleBack, handleSubmit } = useStepperContext();


    const handleNoChasis = (e) => {
        calculateCosts(0);
        handleSubmit(e);
    };
    

    const handleChasis = (e) => {
        calculateCosts(purchase.costs?.chasis);
        handleSubmit(e);
    };


    const handleSimple = (e) => {
        calculateCosts(purchase.costs?.simple);
        handleSubmit(e);
    };

    const handleDouble = (e) => {
        calculateCosts(purchase.costs?.doble);
        handleSubmit(e);
    };

    if (!hasEstablishment()) {
        return (
            <div className='container'>
                <h2 className='d-flex justify-content-center'>
                    Por favor seleccione el establecimiento.
                </h2>
                <div className='d-flex gap-2 justify-content-end'>
                    <button
                        className='btn btn-primary'
                        onClick={handleBack}
                        type='button'
                    >
                        Atrás
                    </button>
                </div>
            </div>
        );
    }

    if (purchase.transportaXercana == "no") {
        return (
            <div className='container'>
                <div className=' d-flex justify-content-center mt-5'>
                    <div className="alert alert-success distanceAlert" role="alert">
                        <h3>La distancia entre el establecimiento de origen y destino, es de {purchase.costs?.cantKm.toFixed(1)} km</h3>
                    </div>
                </div>
                <div className='d-flex gap-2 justify-content-end'>
                    <button
                        className='btn btn-primary'
                        onClick={handleBack}
                        type='button'
                    >
                        Atrás
                    </button>
                    <button
                        className='btn btn-info ml-4'
                        onClick={handleNoChasis}
                        disabled={purchase.costs?.simple === null}
                        type='submit'
                    >
                        Siguiente
                    </button>
                </div>
            </div >
        );
    }


    return <div className='container'>
        <h2 className='d-flex justify-content-center'>
            Opciones para {formatDouble(purchase.costs?.cantKm)} Kilometros de envío
        </h2>
        {isLoading ? (
            <div className="d-flex justify-content-center my-4">
                <div className="spinner-border" role="status" />
            </div>
        ) : (
            <div className='row'>
                <div className='col-12 col-lg-6 p-2 p-lg-4'>
                    <div className='card'>
                        <div className='card-body'>
                            <h4 className='card-title mb-3'>Chasis</h4>
                            <h1 className='text-center h2 font-weight-light mb-3'>
                                <FormatPrice price={purchase.costs?.chasis} />
                            </h1>
                            <button
                                className='btn btn-outline-info'
                                onClick={handleChasis}
                                disabled={purchase.costs?.chasis === null}
                                type='submit'
                            >
                                Seleccionar Chasis
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 p-2 p-lg-4 '>
                    <div className='card'>
                        <div className='card-body'>
                            <h4 className='card-title mb-3'>Jaula Simple</h4>
                            <h1 className='text-center h2 font-weight-light mb-3'>
                                <FormatPrice price={purchase.costs?.simple} />
                            </h1>
                            <button
                                className='btn btn-outline-info align-center'
                                onClick={handleSimple}
                                disabled={purchase.costs?.simple === null}
                                type='submit'
                            >
                                Seleccionar Jaula Simple
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6 p-2 p-lg-4'>
                    <div className='card'>
                        <div className='card-body'>
                            <h4 className='card-title mb-3'>Jaula Doble</h4>
                            <h1 className='text-center h2 font-weight-light mb-3'>
                                <FormatPrice price={purchase.costs?.doble} />
                            </h1>
                            <button
                                className='btn btn-outline-info align-center'
                                onClick={handleDouble}
                                disabled={purchase.costs?.doble === null}
                                type='submit'
                            >
                                Seleccionar Jaula Doble
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {/* <EstablishmentCard establishment={purchase.establishment} /> */}
        <div className='d-flex gap-2 justify-content-end'>
            <button
                className='btn btn-primary'
                onClick={handleBack}
                type='button'
            >
                Atrás
            </button>
        </div>
    </div>
};

export default CostsStep;
