import React from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

import { modalActions } from "../../Modal/PublicationsModal";
import { postPublicationAcceptDelivery } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";


const BuyerOptions = ({ publication }) => {
  const history = useHistory();
  const { modal } = usePublicationsContext();

  const infoModal = () => {
    Swal.fire({
      title: "Enviando confirmación",
      text: "Aguarde unos instantes.",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  const successModal = async () => {
    return await Swal.fire({
      title: "Confirmado",
      text: "El reporte ha sido confirmado satisfactoriamente.",
      icon: "success",
    });
  };

  const errorModal = () => {
    Swal.fire({
      title: "Error",
      text: "El reporte no ha podido ser confirmado satisfactoriamente.",
      icon: "error",
    });
  };

  const handleAcceptDelivery = async () => {
    infoModal();
    try {
      await postPublicationAcceptDelivery({
        idPublication: publication.id,
      });
      await successModal();
      history.go(0);
    } catch (err) {
      errorModal();
    }
  };

  return (
    <>
      {publication.state === "PendienteConfirmacion" && (
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleAcceptDelivery()}
            type="button"
          >
            Aceptar entrega
          </a>
        </li>
      )}
      <li>
        <a
          className="dropdown-item"
          onClick={() =>
            modal.openModal(publication.id, modalActions.purchaseDetail)
          }
          type="button"
        >
          Detalle de compra
        </a>
      </li>
    </>
  );
};

export default BuyerOptions;
