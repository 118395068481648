import React from 'react';
import { InfoIcon, VerifyIcon } from '../../Icons/Icons';

const ProfileStatus = ({ enabled }) => {
    let alertColor = '';
    if (enabled) {
        alertColor = 'alert-success';
    } else {
        alertColor = 'alert-danger';
    }
    return (
        <>
            <div className={`justify-content-center d-flex align-items-center mb-2 ${alertColor} `}>
                <h1 className="mr-3">Estado del perfil:</h1>
                <h1>
                    {enabled === true ? (
                        <><VerifyIcon /> Verificado</>
                    ) : (
                        <><InfoIcon /> Pendiente de Verficación</>
                    )}
                </h1>

            </div>
            {enabled === false &&
                <div className="text-center alert alert-info" role="alert">
                    Una vez completados los datos, su perfil sera revisado por un Administrador.{<br></br>}
                    Se le enviará un email cuando el proceso haya finalizado.
                </div>
            }
        </>
    );
};

export default ProfileStatus;
