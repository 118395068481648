import React from 'react';

function Review({review, setReview, honorarios, setHonorarios}) {
    return <>
        {/* Review */}
        <div className="row my-1">
            <div className="col">
                <h3>Calificación*</h3>
                <textarea value={review?.review} name="review" onChange={(e) => setReview(e.target.value)} className="form-control" type="text" />
            </div>
        </div>
        {/* Price */}
        <div className="row my-1">
            <div className="col">
                <h3>Honorarios*</h3>
                <input value={honorarios} name="price" onChange={(e) => setHonorarios(e.target.value)} className="form-control" type="number" />
            </div>
        </div></>
}

export default Review;