import React from 'react';

const Admin = () => {
    return (
        <div className="pt-5" style={{backgroundColor:'#FFF'}}>
            <div style={{minHeight:'100vh'}} className="container">
                <h1 style={{fontSize:25}} className="font-weight-bold text-center">Bienvenido</h1>
                <div style={{marginTop:"3rem",color:'#323232',textAlign:'center',padding:'4rem',borderRadius:'10px',boxShadow:'0px 0px 5px 0px #e1e1e1'}} className="col-12">Xercana proporciona una posibilidad de mantener tus ventas de manera directa y virtual generando mayor nivel de ventas y posibilidades de expansión 
                <div style={{marginTop:'4rem',borderTop:'1px solid #e1e1e1',paddingTop:'2rem',textAlign:'center'}} className='col-12 d-flex '>
                    <div className=" col-12">
                        <p>Desde esta sección podrás administrar publicaciones y usuarios estableciendo los roles destinados a cada uno</p>
                    </div>
                   
                </div>

                </div>
                <div style={{marginTop:'2rem'}} className="col-12 col-md-5 col-lg-5 col-xl-5">
                        <p className="w-100">Para mayor información puede comunicarse directamente con Xercana mediante <a href='mailto:info@xercana.com'><i>info@xercana.com</i></a>para consultas y será respondido a la brevedad</p>
                </div>
                
               
            </div>
        </div>

    )
}

export default Admin;