import './multipleSwitch.css';
/**
 * @param labelName String. This is the name for Button.
 * @param className CSS ClassName. Example element-switch
 * @param showBorder Bool. Default true
 * @requires children
 * @requires labelName
 */
const ElementSwitch = ({ children, className, showBorder }) => {
    return (
        <div        
            className={showBorder ? `container-element p-4 solid ${className} elementsitchresponsive ` : `container-element ${className} elementsitchresponsive`}>
            {children}
        </div>
    )
}

ElementSwitch.defaultProps = {
    showBorder: true
}
export default ElementSwitch;