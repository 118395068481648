import React from 'react';
import { Redirect, Route } from 'react-router';

import routers from './routers';
import Footer from '../components/footer/Footer';
import { useAuthContext } from '../context/AuthenticatedContext';
import AuctionsHomePage from '../views/Auctions/AuctionsHomePage';
import { AuctionsNavbar } from '../components/navbar/Auctions/AuctionsNavbar';

const AuctionRoute = ({ component: Component, ...rest }) => {
    // const { isLogged } = useAuthContext();

    return (
            <Route {...rest} render={props =>
                <>
                    <AuctionsNavbar/>
                    <Component {...props} />
                    <Footer isAuctionsPages = {true} />
                </>
            } />
    );
};

export default AuctionRoute;
