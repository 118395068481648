import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Traslate from '../../utils/translation/User.json';
import TraslateAdmin from '../../utils/translation/Admin.json';
import routers from '../../config/routers';
import Button from '../button/Button';

const SwalReact = withReactContent(Swal)

export const ModalConfirm = async (res) => Swal.fire({
  title: TraslateAdmin.MODALS.SURE,
  // showDenyButton: true,
  showCancelButton: true,
  // denyButtonText: TraslateAdmin.COMMON.CANCEL,
  confirmButtonText: TraslateAdmin.COMMON.SAVE,
  cancelButtonText:"cancelar",
  // reverseButtons: true,
  confirmButtonColor: '#22B24C',
  cancelButtonColor: '#e4a5a5',
}).then((result) => {
  if (result.isConfirmed) {
    // console.log(res);
    // if(res!==200){
    //   Swal.fire(TraslateAdmin.MODALS.NOT_SAVED,'error en el servidor, intente nuevamente','error');
    //   return false;
    // }
    Swal.fire(TraslateAdmin.MODALS.SAVED, '', 'success')
    return true;
  } else if (result.isDenied) {
    Swal.fire(TraslateAdmin.MODALS.NOT_SAVED, '', 'info')
    return false;
  }
});

export const ModalError = async (message) => SwalReact.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Algo salio mal!',
  footer: message
})

export const ModalAlert = (message) => SwalReact.fire({
  icon: 'error',
  title: 'Oops...',
  showConfirmButton: false,
  html: <div>
    <h2 className='col-12'>{Traslate.ERROR.NEED_MORE_INF}</h2>
    <h4 className='col-12'><a href={routers.user_profile.path}>{Traslate.MESSAGE.JOIN}</a></h4>
    <Button width='small' onClick={SwalReact.clickConfirm}>{Traslate.COMMON.CONFIRM}</Button>
  </div>
})

