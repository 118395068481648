import React from "react";
import Modal from "react-modal";

import AdminModal from "./RolesModal/AdminModal";
import HistoryModal from "./RolesModal/HistoryModal";
import PurchaseDetailModal from "./RolesModal/PurchaseDetailModal";
import SellerModal from "./RolesModal/SellerModal";
import OfferModal from "./RolesModal/OfferModal";
// import QualificationModal from "./RolesModal/QualificationModal";
import { usePublicationsContext } from "../../../context/PublicationsContext";
import { PaymentCommentsModal } from "./RolesModal/PaymentCommentsModal";

export const modalActions = {
  dte: "DTE",
  history: "HISTORY",
  liquidate: "LIQUIDATE",
  offers: "OFFERS",
  paymentComments: "PAYMENT_COMMENTS",
  purchaseDetail: "PURCHASE_DETAIL",
  qualification: "QUALIFICATION",
};

const PublicationsModal: React.FC = () => {
  const { modal } = usePublicationsContext();

  const action = () => {
    switch ( modal?.modalAction ) {
      case modalActions.dte:
        return <SellerModal />;
      case modalActions.history:
        return <HistoryModal />;
      case modalActions.liquidate:
        return <AdminModal />;
      case modalActions.purchaseDetail:
        return <PurchaseDetailModal />;
      case modalActions.offers:
        return <OfferModal />;
        case modalActions.paymentComments:
        return <PaymentCommentsModal />;
      // case modalActions.qualification:
      // return <QualificationModal />;
      default:
        modal?.closeModal();
        return null;
    }
  };

  return (
    <Modal isOpen={ modal?.isOpen ?? false } onRequestClose={ modal?.closeModal }>
      <div className="d-flex justify-content-end mb-3">
        <i
          className="far fa-times-circle"
          onClick={ () =>
            modal?.closeModal() }
          style={ {
            color: "black",
            cursor: "pointer",
            fontSize: "25px",
          } }
        />
      </div>
      <div className="container p-0 px-lg-4">
        { action() }
      </div>
    </Modal>
  );
};

export default PublicationsModal;
