import React from 'react';
import { Fragment } from 'react';

const Padding = ({ children , className}) => {

    const value = Array.isArray(children) ? children.length : 1;

    if (value === 1) {
        return <div className={`${className} row`}>
            { React.cloneElement(children, { className: children.props.className + ' col-12' }) }
        </div>;
    }

    const col = (12/value) - 1;

    const classNameCol = ` col-md-${col} col-12`;
    
    return <div className={`${className} row`}>
        {
            children.map( (child, index) => 
            <Fragment key={index}>
                { React.cloneElement(child, { key: index, className: child.props.className + classNameCol }) }
                { ((index) !== (value - 1 )) ? <div className='col' /> : null }
            </Fragment>
            )
        }
    </div>

}

export default Padding;