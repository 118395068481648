import { useEffect, useReducer, useState } from 'react';

import { getUser, postRolesAdminless, postUser } from '../../api/private/client';
import { removeHyphen } from '../../utils/helper';

export const ACTIONS = {
    SET_ACTIVITY: 'SET_ACTIVITY',
    SET_ADDRESS_LATITUDE: 'SET_ADDRESS_LATITUDE',
    SET_ADDRESS_LOCALITY: 'SET_ADDRESS_LOCALITY',
    SET_ADDRESS_LONGITUDE: 'SET_ADDRESS_LONGITUDE',
    SET_ADDRESS_PROVINCE: 'SET_ADDRESS_PROVINCE',
    SET_CARRIER_SIMPLE: 'SET_CARRIER_SIMPLE',
    SET_CARRIER_DOUBLE: 'SET_CARRIER_DOUBLE',
    SET_COMPANY_CBU: 'SET_COMPANY_CBU',
    SET_COMPANY_CUIT: 'SET_COMPANY_CUIT',
    SET_COMPANY_CVU: 'SET_COMPANY_CVU',
    SET_COMPANY_NAME: 'SET_COMPANY_NAME',
    SET_CUIT: 'SET_CUIT',
    SET_FIRST_NAME: 'SET_FIRST_NAME',
    SET_IVA_SITUATION: 'SET_IVA_SITUATION',
    SET_LAST_NAME: 'SET_LAST_NAME',
    SET_PHONE: 'SET_PHONE',
    SET_PROFILE: 'SET_PROFILE',
    SET_ROLES: 'SET_ROLES',
};

const profileReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_ACTIVITY:
            return { ...state, legal_activity: action.payload };
        case ACTIONS.SET_ADDRESS_LATITUDE:
            return { ...state, address: { ...state.address, latitud: action.payload } };
        case ACTIONS.SET_ADDRESS_LOCALITY:
            if (action.payload === '') {
                return { ...state, address: { ...state.address, locality: null } };
            }
            return { ...state, address: { ...state.address, locality: { id: action.payload } } };
        case ACTIONS.SET_ADDRESS_LONGITUDE:
            return { ...state, address: { ...state.address, longitude: action.payload } };
        case ACTIONS.SET_ADDRESS_PROVINCE:
            if (action.payload === '') {
                return { ...state, address: { ...state.address, province: null } };
            }
            return { ...state, address: { ...state.address, province: { id: action.payload } } };
        case ACTIONS.SET_CARRIER_SIMPLE:
            return { ...state, transportistaDto: { ...state.transportistaDto, km_simple: action.payload } };
        case ACTIONS.SET_CARRIER_DOUBLE:
            return { ...state, transportistaDto: { ...state.transportistaDto, km_doble: action.payload } };
        case ACTIONS.SET_COMPANY_CBU:
            return { ...state, company: { ...state.company, cbu: action.payload } };
        case ACTIONS.SET_COMPANY_CUIT:
            return { ...state, company: { ...state.company, cuit: action.payload } };
        case ACTIONS.SET_COMPANY_CVU:
            return { ...state, company: { ...state.company, cvu: action.payload } };
        case ACTIONS.SET_COMPANY_NAME:
            return { ...state, company: { ...state.company, businessName: action.payload } };
        case ACTIONS.SET_CUIT:
            return { ...state, cuit: action.payload };
        case ACTIONS.SET_FIRST_NAME:
            return { ...state, name: action.payload };
        case ACTIONS.SET_IVA_SITUATION:
            return { ...state, iva_situation: action.payload };
        case ACTIONS.SET_LAST_NAME:
            return { ...state, surname: action.payload };
        case ACTIONS.SET_PHONE:
            return { ...state, phone: action.payload };
        case ACTIONS.SET_PROFILE:
            return action.payload;
        case ACTIONS.SET_ROLES:
            return { ...state, roles: action.payload };
        default:
            throw new Error();
    }
};

const establishmentActionTypes = {
    appendEstablishment: 'APPEND_ESTABLISHMENT',
    deleteEstablishment: 'DELETE_ESTABLISHMENT',
    editEstablishment: 'EDIT_ESTABLISHMENT',
    setEstablishments: 'SET_ESTABLISHMENTS',
};

const establishmentReducer = (state, action) => {
    switch (action.type) {
        case establishmentActionTypes.setEstablishments:
            return action.payload;
        case establishmentActionTypes.deleteEstablishment:
            state.splice(action.payload, 1);
            return [...state];
        case establishmentActionTypes.editEstablishment:
            state[action.payload.index] = action.payload;
            return [...state];
        case establishmentActionTypes.appendEstablishment:
            return [...state, action.payload];
        default:
            throw new Error();
    }
};

const useProfile = () => {
    const [profile, dispatch] = useReducer(profileReducer, null);
    const [establishments, dispatchEstablishment] = useReducer(establishmentReducer, []);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const { data } = await getUser();
            dispatch({ payload: data, type: ACTIONS.SET_PROFILE });
            dispatchEstablishment({
                payload: data.establecimientos,
                type: establishmentActionTypes.setEstablishments,
            });
            setIsLoading(false);
        })();
    }, []);

    const addEstablishment = (establishment) => {
        dispatchEstablishment({ payload: establishment, type: establishmentActionTypes.appendEstablishment });
    };

    const deleteEstablishment = (establishment) => {
        dispatchEstablishment({ payload: establishment, type: establishmentActionTypes.deleteEstablishment });
    }

    const editEstablishment = (establishment) => {
        dispatchEstablishment({ payload: establishment, type: establishmentActionTypes.editEstablishment });
    };

    const reloadEstablishments = (establishments) => {
        dispatchEstablishment({ payload: establishments, type: establishmentActionTypes.setEstablishments }); 
    };

    const postProfile = async () => {
        const toSend = {
            ...profile,
            establecimientos: [...establishments],
            // cuit: removeHyphen(profile.cuit),
            // Cuit Personal puesto en 0 para que que el backend acepte la data.
            // No le solicitamos mas este dato al usuario.
            cuit: 0,
            company: {
                ...profile.company,
                cbu: 0,
                cvu: 0,
                cuit: removeHyphen(profile.company.cuit),
            }
        };
        await postUser(toSend);
        await postRolesAdminless(profile.id, { id: profile.id, roles: profile.roles });
    };

    return {
        profile,
        isLoading,
        dispatch,
        establishments,
        addEstablishment,
        deleteEstablishment,
        editEstablishment,
        postProfile,
        reloadEstablishments
    };
};

export default useProfile;
