import ElementSwitch from "../../../../components/multipleSwitch/ElementSwitch";


export default function getDatosBancariosSwitch(mobil, product) {
    return <ElementSwitch
        showBorder={!mobil}
        labelName= 'Mi Cuenta'>
        <strong className='d-flex text-left'>Datos Bancarios para Liquidación</strong>
        <table
        style={{border:'1px solid #e1e1e1',borderRadius:'5px'}}
        className='table table-striped responsiveBatch'>
            <tbody>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">CBU / CVU</td>
                    <td>{product?.cbu?product.cbu: ''}</td>
                </tr>
            </tbody>
        </table>
    </ElementSwitch>;
}