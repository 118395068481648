import React, { useEffect, useState } from "react";
import { usePublicationsContext } from "../../../../context/PublicationsContext";
import { getPaymentMethodComments } from "../../../../api/private/client";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { formatDate } from "../../../../utils/helper";


interface PaymentComment {
    id: number;    
    person_id: number;
    publication_id: number;
    payment_comment: string;
    created_at: string;
    user_name: string;
}
  
  const LoadingSpinner: React.FC = () => {
    return (
      <div className="d-flex justify-content-center my-4">
        <div className="spinner-border" role="status" />
      </div>
    );
  };

export const PaymentCommentsModal = () => {
    const { idPublication } = usePublicationsContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [paymentComments, setPaymentComments] = useState<PaymentComment[]>([])

    useEffect(() => {
        (async () => {
          setIsLoading(true);
          try {
            const { data } = await getPaymentMethodComments(
              idPublication,
            ) as AxiosResponse<PaymentComment[]>;
            console.log(data)
            setPaymentComments(data);
          } catch (err) {
            Swal.fire({
              text: "Se ha producido un error. Reintente",
              icon: "error",
            });
          }
          setIsLoading(false);
        })();
    }, []);


    return (
        <>
        <h2 className="d-flex justify-content-center bold mb-5">
          Métodos de pago ofrecidos
        </h2>
        
        {isLoading ? <LoadingSpinner /> : (
          <>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                    <th scope="col">Fecha</th>                  
                    <th scope="col">Usuario</th>
                    <th scope="col">Medio de pago</th>
                </tr>
              </thead>
              <tbody>
              { paymentComments && 
              (paymentComments.map( (comment) => (               
                <tr key={comment.id}>
                    <td>{ formatDate(comment.created_at) }</td>
                    <td>{ comment.user_name }</td>
                    <td>{ comment.payment_comment }</td>
                </tr>
                )))
              }
              </tbody>
            </table>
          </>
        )}
      </>
    )
}
