import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import theme from '../../utils/GlobalColors.json';
import './button.css';

/**
 *  @param children required
 *  @param name String or Number. It's event.target.name
 *  @param value String or Number. It's event.target.value. Default TRUE
 *  @param shadow bool. True if has shadow. Default FALSE
 *  @param onClick function. It's a callback
 *  @param inverted bool. True if has inverted color. Default FALSE
 *  @param color bool. if it is true. It use the primary-colors else the secondary-color
 *  @param disabled bool. if is true, The button is disabled. Default FALSE.
 *  @param className CSS Class reference.
 */
const Button = ({ 
    children,
    name,
    value,
    type, 
    onClick, 
    shadow, 
    inverted, 
    width, 
    color,  
    disabled,
    className}) => {

    const [ style, setStyle ] = useState('');

    useEffect( ()=>{

        let temp = shadow ? 'shadow' : '';

        temp = inverted ? temp + ' inverted' : temp;

        temp = disabled ? temp + ' disabled' : temp;
        
        setStyle(temp);

    },[inverted, disabled, shadow])

    const styleColor = {
        '--primary-color': color ? theme["light-colors"]["--primary-color"] : theme["light-colors"]["--secondary-color"],
        '--second-color': color ? theme["light-colors"]["--primary-light-color"] : theme["light-colors"]["--secondary-light-color"]
    }
    
    return (
        <button 
            className={`btn btn-sm container-button ${style} ${width} ${className}`}
            style = {styleColor}
            type={type}
            name={name}
            value={value}
            onClick={ disabled ? null : onClick}>
            { children }
        </button>
    );
}

Button.PropType = {
    label: PropType.string.isRequired,
    name: PropType.string.isRequired,
    value: PropType.bool,
    type: PropType.oneOf(['button','reset','submit']),
    shadow: PropType.bool,
    inverted: PropType.bool,
    width: PropType.oneOf(['small','normal','big', null]),
    uppercase: PropType.bool,
    disabled: PropType.bool,
    onClick: PropType.func,
    className: PropType.string
}

Button.defaultProps = {
    type: 'button',
    name: 'BUTTON',
    value: true,
    shadow: false,
    inverted: false,
    width: null,
    color: true,
    disabled: false
}

export default Button;