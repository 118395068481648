import React from "react";

import "./Card.css";

interface ReviewerCardProps {
  reviewer: any;
}

const ReviewerCard: React.FC<ReviewerCardProps> = ({ reviewer }) => {
  return (
    <div className="card my-4">
      <div className="row card-body">
        <div
          className="col-12 col-lg-2 d-flex flex-column justify-content-center align-items-center"
        >
          <p className="Card-title">Corredor</p>
          <img className="Card-avatar" src={reviewer.image} alt="" />
        </div>
        <div className="col-12 col-lg-5 px-lg-4 py-3">
          <p className="Card-title">Datos personales</p>
          <p className="card-text">Nombre: {reviewer.name}</p>
          <p className="card-text">Apellido: {reviewer.surname}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewerCard;
