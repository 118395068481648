import React from 'react';
import Container from '../../../../components/container/Container';
import Stars from '../../../../components/Star/Star';
import Line from '../../../../components/line/Line';
import Traslate from '../../../../utils/translation/User.json';
import { IconAdress, IconMail, IconTelephone } from '../../../../components/Icons/Icons';
import { Tooltip } from '@material-ui/core';

export default function getSellerContainer(mobil, product) {
    return <Container
        showBorder={!mobil}
        className='col publication-offer '
        shadow={false}
    >
        <div className='row p-3'>
            {/* <h1 className=' col-12' style={{ marginTop: '20px', marginBottom: '20px' }}>
                {Traslate.PUBLICATION_LIVE_STOCK.SELLER}
            </h1>
            <div className='col-12 d-flex justify-content-center'>
                <Stars score={product.seller?.score_seller ? product.seller.score_seller : 0} count={product.seller?.count_seller ? product.seller.count_seller : 0} />
            </div>
            <Line className='col-12 line-edit' /> */}
            {/* <h1 className='col-12 '>{Traslate.PUBLICATION_LIVE_STOCK.PRODUCT_DESCRIPTION}</h1>
            <h5 className='col-11 text-justify' style={{lineHeight:"2"}}>{product.description}</h5>
            <Line className='col-12 line-edit' /> */}
            <h1 className='col-12 bold'>{Traslate.PUBLICATION_LIVE_STOCK.CONSIGNEE}</h1>
            <div className="w-100 d-flex align-items-center">
                <img className="logo-cc" src={ `${ process.env.PUBLIC_URL }/images/logo-cc.png` }/>
                <h4 className='bold'>{product?.consignatary?.name}</h4>
            </div>
            {/* {product?.consignatary?.mail && (
                <div className="w-100 d-flex align-items-center mt-1 mb-1"><IconMail /><h4 className='ml-2'>{product.consignatary.mail}</h4></div>
            )} */}
            {product?.consignatary?.telephone && (
                <div className="w-100 d-flex align-items-center"><IconTelephone /><h4 className='ml-2'>{product.consignatary.telephone}</h4></div>
            )}
            {/* <Line className='col-12 line-edit mt-5' /> */}
            {product?.review?.reviewer?.person && (
                <>
                    <h1 className=' col-12 ' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        Corredor
                    </h1>
                    <div style={{ width: '95%', margin: '0 auto', boxShadow: '0px 0px 3px 0px #e1e1e1', padding: '2rem' }}>

                        <div style={{ width: '100%', margin: '0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ borderRadius: '100px', overflow: 'hidden' }}>
                                <img src={product?.review?.reviewer?.person?.image} alt="" style={{ height: '5rem', width: '5rem' }} />
                            </div>
                            <div style={{ width: '50%', justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', margin: '0 auto' }}>
                                <p style={{ textTransform: 'capitalize' }}>Nombre: {product?.review?.reviewer?.person?.name}</p><p style={{ textTransform: 'capitalize' }}> {product?.review?.reviewer?.person?.surname}</p>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                            <p>Pendiente de calificación</p>
                        </div>

                    </div>
                </>
            )}


        </div>
    </Container>
}