
import Swal from "sweetalert2";


export const profileValidations = async (history, data) => {

  let completeProfile = false;
  let isEnabled = false;
  if (data.perfil_completo === "si") {
    completeProfile = true
  }
  if (data.enabled === true) {
    isEnabled = true
  }
  if (!completeProfile) {
    return await Swal.fire({
      title: "Su perfil no se encuentra completo",
      text: "Para continuar es necesario completar el siguiente formulario",
      icon: "warning",
      confirmButtonColor: "green",
      confirmButtonText: "Continuar",
      showCancelButton: true,
      cancelButtonColor: 'red',
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) return history.push({
        pathname: '/user/phone',
        state: {
          isEditing: false,
        },
      });
      if (result.isDenied) return false;
    });
  }

  if (!isEnabled && completeProfile) {
    return await Swal.fire({
      customClass: "swal-wide",
      title: "Su perfil no se encuentra verificado",
      text: "Su cuenta aún no está habilitada para operar. Recibirá una notificación dentro de las próximas 24 hrs hábiles una vez que verifiquemos sus datos. Muchas gracias",
      icon: "warning",
      confirmButtonColor: "green",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) return false;
    });
  }

  return true;
}