import { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2';

import { postAdminUserEnable, postAdminUserRoles } from '../api/private/client';
import {
    getAdminChats,
    postResponseAdmin,
} from '../config/EndpointFunctions';
import './TableItem.css';

Modal.setAppElement( '#root' );




const TableItem = ( { item, setResultsLoader, rolesAvailable } ) => {
    const { enabled } = item;

    const history = useHistory();
    const [ oldEnabled, setOldEnabled ] = useState( enabled );
    const [ roles, setRoles ] = useState( item.roles );
    const [ modalChatBroker, setModalChatBroker ] = useState( false );
    const [ messageChats, setMessageChats ] = useState( [] );
    const [ messageChatSend, setMessageChatSend ] = useState( '' );

    const closeChatBroker = () => {
        setModalChatBroker( false );
    };

    const handleChatBroker = async () => {
        const { data } = await getAdminChats( item.id );
        setMessageChats( data );
        setModalChatBroker( true );
    };

    const onChangeEnabled = async ( e ) => {
        if ( e.target.value === 'true' && ( !roles.some( e => e.id === 4 ) || !roles.some( e => e.id === 5 ) ) ) {
            faltanRolesModal();
        } else {
            Swal.fire( {
                title: 'Enviando datos',
                text: 'Aguarde unos instantes.',
                icon: 'info',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            } );
            try {
                setOldEnabled( e.target.value );
                await postAdminUserEnable( item.id, e.target.value );
                Swal.fire( {
                    title: '¡Usuario actualizado!',
                    text: 'El usuario ha sido actualizado correctamente.',
                    icon: 'success',
                } )
            } catch ( err ) {
                Swal.fire( {
                    title: 'Error',
                    text: 'El usuario no ha podido ser editado correctamente.',
                    icon: 'error',
                } );
            }
        }
    };

    const faltanRolesModal = () => {
        Swal.fire( {
            title: 'Faltan roles por asignar',
            text: 'Por favor, asigne Rol Comprador y Vendedor al usuario antes de habilitarlo.',
            icon: 'info',
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
        } );
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const handleChangeMessageChat = ( e ) => {
        setMessageChatSend( e.target.value );
    };

    const handleSubmitMessageChat = async () => {
        if ( messageChatSend === '' ) {
            Swal.fire( '!El mensaje no puede estar vacío!', 'Debes completar el mensaje para enviarlo', 'warning' );
            return;
        }
        const send = {
            message: messageChatSend,
        };
        await postResponseAdmin( item.id, send );
    };

    // Fast multiple selection not working
    const handleRole = async ( role ) => {
        const newRoles = roles.filter( ( { id } ) => role.id !== id );
        if ( newRoles.length === roles.length ) {
            newRoles.push( role );
        }
        setRoles( newRoles );
        try {
            await postAdminUserRoles( item.id, { roles: newRoles } );
            Swal.fire( '¡Usuario actualizado!', 'El usuario ha sido actualizado correctamente', 'info' );
        } catch ( err ) {
            console.log( err );
        };
    };

    const strManipulationSplit = item.creation_date.split( "T" )[ 0 ];
    const reversedDate = strManipulationSplit.split( '-' ).reverse().join( '-' );

    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={ true }
                isOpen={ modalChatBroker }
                onRequestClose={ closeChatBroker }
                style={ { overlay: { backgroundColor: '#929292' }, content: customStyles } }
                contentLabel="Example Modal"
            >
                <div>
                    <Tooltip title="Cerrar" placement="top-end">

                        <i
                            onClick={ closeChatBroker }
                            style={ { fontSize: '22px', color: '#369', cursor: 'pointer' } } className="far fa-times-circle"
                        ></i>
                    </Tooltip>

                </div>

                <div style={ { width: '80%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' } }>
                    <div style={ { width: '100%', marginTop: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } }>
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80" alt="" style={ { height: '5.3rem', width: '5.3rem', borderRadius: '100px', overflow: 'hidden', marginTop: '-2rem', objectFit: 'cover' } } />
                        <div style={ { display: 'flex', flexDirection: 'column', width: '90%', justifyContent: 'space-between', alignItems: 'flex-start' } }>
                            <p style={ { padding: '1.3rem', backgroundColor: '#efeae6', borderRadius: '10px' } }>Hola, que día podría ir a verificar la calidad del ganado?</p>
                            <p style={ { padding: '0px', margin: '0px', marginTop: '.4rem' } }><i>Angelica Corleone</i></p>
                        </div>
                    </div>
                    <div style={ { width: '100%', marginTop: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } }>
                        <div style={ { display: 'flex', flexDirection: 'column', width: '90%', justifyContent: 'space-between', alignItems: 'flex-end' } }>
                            <p style={ { padding: '1.3rem', backgroundColor: '#ffdfc5', borderRadius: '10px' } }>Buenos días, el martes de la próxima semana podrías?</p>
                            <p style={ { padding: '0px', margin: '0px', marginTop: '.4rem' } }><i>Tú</i></p>
                        </div>
                        <img src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80" alt="" style={ { height: '5.3rem', width: '5.3rem', borderRadius: '100px', overflow: 'hidden', marginTop: '-2rem', objectFit: 'cover' } } />
                    </div>
                    <div style={ { position: 'absolute', bottom: '0', width: '100%', marginBottom: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } }>
                        <input value={ messageChatSend } onChange={ e => handleChangeMessageChat( e ) } style={ { width: '80%', margin: '0 auto', justifyContent: 'center', alignItems: 'center', textAlign: 'left', height: '100%', padding: '2rem', border: '1px solid #e1e1e1', borderRadius: '10px', display: 'flex' } } placeholder="Your message" />
                        <div
                            style={ { width: '10%' } }
                        >
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={ handleSubmitMessageChat }
                            >
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <tr>
                <td>{ item.id }</td>
                <td>{ item.full_name }</td>
                <td>{ reversedDate }</td>
                <td>{ item.mail }</td>
                <td>
                    <div className="form-group col-7 mx-auto responsiveInput">
                        <select
                            className="form-control"
                            style={ !( JSON.parse( oldEnabled ) ) ? {
                                background: '#faad',
                                color: '#fff',
                                borderRadius: '10px',
                            } : {
                                background: '#a7c58f',
                                color: '#fff',
                                borderRadius: '10px',
                            } }
                            onChange={ e => onChangeEnabled( e ) }
                            value={ oldEnabled }
                        >
                            <option value="true">Habilitado</option>
                            <option value="false">Deshabilitado</option>
                        </select>
                    </div>
                </td>
                <td>
                    <button
                        style={ { marginTop: '-1rem' } }
                        className="btn btn-block btn-info "
                        onClick={ () => history.push( `/admin/user/${ item.id }` ) }
                    >
                        Ver
                    </button>
                </td>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            width: '100%',
                            margin: '0 auto',
                            marginTop: '1rem'
                        }
                    }
                >
                    { rolesAvailable?.map( role =>
                        <div key={ role.id } className='form-check'>
                            <input
                                className='form-check-input'
                                value={ role.name }
                                checked={ roles.some( ( { id } ) => id === role.id ) }
                                onChange={ () => handleRole( role ) }
                                type='checkbox'
                            />
                            <label className='form-check-label'>
                                { role.description }
                            </label>
                        </div>
                    ) }
                </div>
                <td>
                    <button
                        className="btn btn-block btn-secondary"
                        style={ { marginTop: '-1rem' } }
                        onClick={ handleChatBroker }
                    >
                        Enviar Mensaje
                    </button>
                </td>
            </tr>
        </>
    );
}

export default TableItem;
