import React, { useEffect, useState } from 'react';
import CarrierStep from '../ModalSteps/CarrierStep';
import CostsStep from '../ModalSteps/CostsStep';
import DetailStep from '../ModalSteps/DetailStep';
import LocationStep from '../ModalSteps/LocationStep';
import Container from '../../../../components/container/Container';
import StepperModal from '../../../../components/StepperModal/StepperModal';
import usePurchase from '../../../../hooks/publications/usePurchase';
import { formatPrice } from '../../../../utils/helper';
import Translate from '../../../../utils/translation/User.json';
import './SaleContainer.css'
import { getUser } from "../../../../api/private/client";
import { useHistory } from "react-router";
import { profileValidations } from '../../../../utils/profileValidations';
import BottonLine from '../../../../components/BottonLine';
import { PreOfferForm } from '../PreOfferForm/PreOfferForm';
import Line from '../../../../components/line/Line';



const SaleContainer = ({ mobile, product, detail, disabledBuy, sameBuyerAsSeller, sale }) => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();  


    const carrier = product?.envioIncluido ? {
        ...product?.person?.transportistaDto,
        id: product?.person?.id,
        person_id: {
            name: product?.person?.name,
            surname: product?.person?.surname,
            image: product?.person?.image,
        }
    } : null;

    const purchase = usePurchase(product?.id, product?.envioIncluido, carrier);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const origin = [
            product.establecimiento.lat,
            product.establecimiento.lng,
        ];
        purchase.addOrigin(origin);
    }, []);

    const closeModal = () => setIsOpen(false);
    const openModal = async () => {
        setIsLoading(true);
        const { data } = await getUser();
        if (await profileValidations(history, data) === true) setIsOpen(true);
        setIsLoading(false);
    };

    const steps = [
        { id: 0, title: 'Transportista', component: CarrierStep },
        { id: 1, title: 'Ubicación', component: LocationStep },
        { id: 2, title: 'Tipo de flete', component: CostsStep },
        { id: 3, title: 'Detalle y forma de pago', component: DetailStep },
    ];

    return (
        <Container
            showBorder={!mobile}
            className='col publication-offer'
            shadow={true}
        >
            <div className='row p-3'>               
                <p className='SaleContainer-subtitle'>
                    Precio total
                </p>                  
                <p className='SaleContainer-price'>
                    { ( product.subasta_directa ) ? formatPrice( detail?.costoPublicacion ): formatPrice(detail?.total) }
                </p>                

                { ( !product.subasta_directa ) &&
                (<>
                    <Line></Line>
                    <p className='SaleContainer-subtitle'> Detalle de costos</p>
                    {detail ? (
                    <ul className="PublicationCostsDetail-list">
                        <li>Costo de publicación: {formatPrice(detail?.costoPublicacion)}</li>
                        <li>
                            Gastos de comercialización:
                            {(sale === "no") ? 
                                <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                                    - {formatPrice(detail?.gastosComercializacion)}
                                </span>
                            : 
                                <span style={{ color: 'green', marginLeft: '0.5rem' }}>
                                    + {formatPrice(detail?.gastosComercializacion)}
                                </span>                    
                            }
                        </li>
                        <li>Sub-Total: {formatPrice(detail?.subTotal)}</li>                    
                        <li>
                            IVA:
                            <span style={{ color: 'green', marginLeft: '0.5rem' }}>
                                + {formatPrice(detail?.iva)}
                            </span>
                        </li>
                        <li><small className='bold'>Pueden incluirse costos extra</small></li>
                    </ul>
                    ) : null}
                </>)
                }                
               
                <div className='col-12 d-flex justify-content-center'>
                    { ( !product.subasta_directa ) 
                    ? 
                        ( 
                        <div className='col'> 
                            <button
                                className='btn btn-block btn-success mb-2'
                                onClick={openModal}
                                disabled={disabledBuy}
                            >
                                {isLoading ? 'Cargando...' : sameBuyerAsSeller ? "No puede comprar su propia publicación" : Translate.PUBLICATION_LIVE_STOCK.BUY}
                            </button>
                            <StepperModal
                                isOpen={isOpen}
                                closeModal={closeModal}
                                steps={steps}
                                context={purchase}
                            />
                            {!disabledBuy && <PreOfferForm product ={product} price = {detail?.total} />}
                        </div>
                        )
                    : 
                        (                             
                            <PreOfferForm product ={product} price = {detail?.costoPublicacion}/>  
                        ) 
                    }
                       
                    
                </div>
            </div>
        </Container>
    );
};

export default SaleContainer;
