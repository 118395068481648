import { useHistory } from "react-router";
import { useAuthContext } from "../../../context/AuthenticatedContext";
import { FaGoogle, FaSignOutAlt } from "react-icons/fa";
import routers from '../../../config/routers';
import '../WebNavbar.css';


export const AuctionsNavbar = () => {

  const logoSrc = `${process.env.PUBLIC_URL}/images/xercana-brand-blue.svg`;

  const { auth, isLogged, logOut } = useAuthContext(); 

  const history = useHistory(); 

    const handleLogout = (e) =>{
      e.preventDefault();        
      logOut();
      history.go( 0 );      
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light " style={{backgroundColor: '#e3f2fd'}}> 
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img height="50" className='d-inline-block' src={logoSrc} alt='Logo' /> 
          <span className="webNavbar-title"> REMATES</span>         
        </a>

                
        {
          isLogged() 
        ? 
          <SessionButtons/>
        :  <a className="btn btn-info mx-3" href={ process.env.REACT_APP_AUCTIONS_GOOGLE_AUTH_URL }>     
            <FaGoogle className="mr-1"/> Ingresar con Google
          </a>
        }        

      </div>
    </nav>  
  )
}


const SessionButtons = () =>{
  const { auth, logOut, isAdmin } = useAuthContext();
  const history = useHistory(); 

    const handleLogout = (e) =>{
      e.preventDefault();        
      logOut();
      history.go( 0 );      
  }


  return (
    <>
      <div className="d-flex justify-content-start">
        <ul className="navbar-nav">            
          
          {isAdmin() ? <AdminButton/> : null}
          
        </ul>
      </div> 
      <div className = "d-flex">
        <a className="nav-link webNavbar-link" href="#" onClick={ handleLogout }> {auth.user?.name} | Logout <FaSignOutAlt/></a>
      </div>
    </>   
  )

}

const AdminButton = () =>{

  return (
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle webNavbar-link" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Administrar
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li><a className="dropdown-item" href={routers.auctions_dashboard.path}>Remates</a></li>
        <li><a className="dropdown-item" href="#">Categorias</a></li>
        <li><a className="dropdown-item" href="#">Something else here</a></li>
      </ul>
    </li>
  )

}