import axios from 'axios';

const publicClient = axios.create( {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
} );

const publicGET = async ( path ) => {
    try {
        const res = await publicClient.get( path );
        if ( res.data && res.status === 200 ) {
            return res;
        }
    } catch ( error ) {
        throw new Error( error );
    }
};

const publicPOST = async ( path, data ) => {
    try {
        const res = await publicClient.post( path, data );
        return res;
    } catch ( error ) {
        throw new Error( error );
    }
};

export const getAdvertisements = async () => await publicGET( '/advertisements' );

export const getPublicationAuction = async () => await publicGET( '/publication/auction' );

export const getPublicationAuctionNear = async () => await publicGET( '/publication/auction/near' );

export const getPublicationAuctionRecomended = async () => await publicGET( '/publication/auction/recomended' );

export const getLinears = async () => await publicGET( '/linears' );

export const getPublicationOffer = async () => await publicGET( '/publication/offer' );

export const getPublicationOfferNear = async () => await publicGET( '/publication/offer/near' );

export const getPublicationOfferRecomended = async () => await publicGET( '/publication/offer/recomended' );

export const getAllActiveAuctions = async () => await publicGET( '/auctions' );

export const getPublicationPublic = async ( id ) => await publicGET( `/publication/${ id }/public` );

export const getPublicationDetailPublic = async ( data ) => await publicPOST( '/publication/detalle/public', data );
