import React from 'react';
import { useForm } from "react-hook-form";
import Proptype from 'prop-types';
import './switch.css';
import Tooltip from '../tooltip/Tooltip';

/**
 * 
 * @param callback es el handleChange del valor principal 
 */
const Switch = ({ value, color, onChange, register, name, label, tooltip, className }) => {

    const { getValues } = useForm()

    return (
        <div className={className}>
            <div className='row switch-container'>
                <h3>{label}{tooltip ? <Tooltip msj={tooltip} /> : null}</h3>
                <div className='col'></div>
                <label className="switch">
                    <input
                        type="checkbox"
                        value={value ?? getValues(name)}
                        name={name}
                        ref={register}
                        onChange={onChange} />
                    <span style={{ color: value ? color : '#E2E2E2' }} id='slider-switch' className='slider-switch round'></span>
                </label>
            </div>
        </div>
    )

}

Switch.prototype = {
    label: Proptype.string,
    value: Proptype.bool.isRequired,
    color: Proptype.string,
    handleChange: Proptype.func.isRequired
}

Switch.defaultProps = {
    color: '#2725B7'
}

export default Switch;