/**
 * @brief Tabs segun roles del usuario
 * 
 * Este componente revisa los roles del usuario en cuestion
 * mandados por parametro/prop y renderiza los correspondientes
 * tabs segun los roles que dicho usuario posea. Dichos tabs
 * seran utilizados para navegar en las distintas listas de
 * publicaciones que el usuario tenga disponibles segun sus
 * roles.
 * 
 * @param {Array<Roles>} roles roles a verificar
 */
const Tabs = ( { roles } ) => {

    const buildOptions = () => {
        roles.sort( ( a, b ) => {
            if ( a.id < b.id ) return 1
            if ( a.id > b.id ) return -1
            return 0
        } );
        const jsx = [];
        roles.forEach( ( role ) => {
            if ( role === "ROLE_CARRIER" ) {
                jsx.push(
                    <li className='nav-item' key={ role.id } role='presentation'>
                        <button
                            id='carrier-tab'
                            className='nav-link'
                            data-bs-toggle='tab'
                            data-bs-target='#shipper-pane'
                            aria-controls='shipper-pane'
                            aria-selected='true'
                            role='tab'
                            type='button'
                        >
                            Transportista
                        </button>
                    </li>
                );
            }
            if ( role === "ROLE_REVIEWER" ) {
                jsx.push(
                    <li className='nav-item' key={ role.id } role='presentation'>
                        <button
                            id='broker-tab'
                            className='nav-link'
                            data-bs-toggle='tab'
                            data-bs-target='#broker-pane'
                            aria-controls='broker-pane'
                            aria-selected='true'
                            role='tab'
                            type='button'
                        >
                            Corredor
                        </button>
                    </li>
                );
            }
            if ( role === "ROLE_BUYER" ) {
                jsx.push( <li className='nav-item' key={ role.id } role='presentation'>
                    <button
                        id='purchases-tab'
                        className='nav-link'
                        data-bs-toggle='tab'
                        data-bs-target='#purchases-pane'
                        aria-controls='purchases-pane'
                        aria-selected='true'
                        role='tab'
                        type='button'
                    >
                        Compras
                    </button>
                </li> );
            }
            if ( role === "ROLE_SELLER" ) {
                jsx.push(
                    <>
                        <li className='nav-item' key={ role.id } role='presentation'>
                            <button
                                id='publications-tab'
                                className='nav-link'
                                data-bs-toggle='tab'
                                data-bs-target='#publications-pane'
                                aria-controls='publications-pane'
                                aria-selected='true'
                                role='tab'
                                type='button'
                            >
                                Publicaciones
                            </button>
                        </li>
                        <li className='nav-item' key='sales-tab' role='presentation'>
                            <button
                                id='sales-tab'
                                className='nav-link'
                                data-bs-toggle='tab'
                                data-bs-target='#sales-pane'
                                aria-controls='sales-pane'
                                aria-selected='true'
                                role='tab'
                                type='button'
                            >
                                Ventas
                            </button>
                        </li>
                    </>
                );
            }
            if ( role === "ROLE_ADMIN" ) {
                jsx.push( <li className='nav-item' key={ role } role='presentation'>
                    <button
                        id='admin-tab'
                        className='nav-link bg-info text-white'
                        data-bs-toggle='tab'
                        data-bs-target='#admin-pane'
                        aria-controls='admin-pane'
                        aria-selected='true'
                        role='tab'
                        type='button'
                    >
                        Administrador
                    </button>
                </li> );
            }
        } );
        return jsx;
    }

    return (
        <ul id='profile-tab' className='nav nav-tabs' role='tablist'>
            { buildOptions() }
        </ul>
    );
};

export default Tabs;