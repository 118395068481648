import { useEffect, useReducer, useState } from 'react';

import JWT from 'jsonwebtoken';
import { getUserNavbar } from '../api/private/client';


const actionTypes = {
    logIn: 'LOG_IN',
    logOut: 'LOG_OUT',
    setUser: 'SET_USER',
};

const authReducer = ( state, action ) => {
    switch ( action.type ) {
        case actionTypes.logIn:
            localStorage.setItem( process.env.REACT_APP_ACCESS_TOKEN, action.payload );
            return { token: action.payload };
        case actionTypes.logOut:
            localStorage.removeItem( process.env.REACT_APP_ACCESS_TOKEN );
            return { token: null, user: null };
        case actionTypes.setUser:
            return { ...state, user: action.payload };
        default:
            throw new Error();
    }
};

const useAuth = () => {
    const token = localStorage.getItem( process.env.REACT_APP_ACCESS_TOKEN );

    const [ auth, dispatch ] = useReducer( authReducer, { token } );
    const [ isLoading, setIsLoading ] = useState( true );

    useEffect( () => {
        if ( auth.token ) {
            ( async () => {
                setIsLoading( true );
                const { data } = await getUserNavbar();
                dispatch( { payload: data, type: actionTypes.setUser } );
                setIsLoading( false );
            } )();
        }
    }, [ auth.token ] );

    const getRoles = () => {
        let { user_type } = JWT.decode( localStorage.getItem( 'user_type' ) ? localStorage.getItem( 'user_type' ) : null );
        return user_type.split( ',' );
    }

    const isEnabled = () => auth.user?.enabled;
    const isLogged = () => auth.token ? true : false;

    const logIn = ( token ) => dispatch( { payload: token, type: actionTypes.logIn } );
    const logOut = () => dispatch( { type: actionTypes.logOut } );

    const hasRole = ( role ) => auth.user?.roles?.find( r => r === role ) ?? false;
    const isAdmin = () => hasRole( 'ROLE_ADMIN' );
    const isCommercial = () => hasRole( 'ROLE_COMMERCIAL' );
    const isBuyer = () => hasRole( 'ROLE_BUYER' );
    const isCarrier = () => hasRole( 'ROLE_CARRIER' );
    const isSeller = () => hasRole( 'ROLE_SELLER' );

    return {
        auth,
        getRoles,
        hasRole,
        isAdmin,
        isCommercial,
        isBuyer,
        isCarrier,
        isEnabled,
        isLoading,
        isLogged,
        isSeller,
        logIn,
        logOut,
    };
};

export default useAuth;
