import React from "react";

import "./Card.css";

interface BuyerCardProps {
  buyer: any;
  isAdminLogged: boolean
}

const BuyerCard: React.FC<BuyerCardProps> = ({ buyer, isAdminLogged }) => {
  return (
    <div className="card my-4">
      <div className="row card-body">
        <div
          className="col-12 col-lg-2 d-flex flex-column justify-content-center align-items-center"
        >
          <p className="Card-title">Comprador</p>
          <img className="Card-avatar" src={buyer.image} alt="" />
        </div>
        <div className="col-12 col-lg-5 px-lg-4 py-3">
          <p className="Card-title">Datos personales</p>
          <p className="card-text">Nombre: {buyer.name}</p>
          <p className="card-text">Apellido: {buyer.surname}</p>
          {isAdminLogged && <p className="card-text">Teléfono: {buyer.phone}</p>}
          {isAdminLogged && <p className="card-text">Email: {buyer.mail}</p>}
        </div>
      </div>
    </div>
  );
};

export default BuyerCard;
