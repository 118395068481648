import React from 'react';
import { Fragment } from 'react';

const BottonLine = ({width='85%',margintop="mt-3",marginTopStyle="",marginBottomStyle="" }) => {
    return (
        <Fragment> 
        <div className={` ${margintop}`} style={{width:width,marginTop:marginTopStyle,marginBottom:marginBottomStyle}}>
        <hr/>
        </div>
        </Fragment>
     );
}
 
export default BottonLine;