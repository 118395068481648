/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { useAuthContext } from "../../../context/AuthenticatedContext";


import { formatPrice } from "../../../utils/helper";
import Traslate from "../../../utils/translation/User.json";
import TraslateAdmin from "../../../utils/translation/Admin.json";

import routers from "../../../config/routers";
import ComboBox from "../../../components/ComboBox/ComboBox";

import { TileLayer, MapContainer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useFormString } from "../../../hooks/UseForm";
import Switch from "../../../components/switch/Switch";
import { IconAdress } from "../../../components/Icons/Icons";
import "./PublicationUser.css";
import BottonLine from "../../../components/BottonLine";

// materiales
import Swal from "sweetalert2";
import JWT from "jsonwebtoken";
import Button from "../../../components/button/Button";
import "./RebuildPublicationUser.css";
import { CustomInput } from "../../../components/CustomInput/CustomInput";
import { categories, CUSTOM_BOX_STYLES, qualityConstant, breedTypes, cowBreed, crossbreedData } from "./constants";
import MapRealloc from "../../../components/MapRealloc/MapRealloc";
import {
  getPublicationDetailNew,
  getUserEstablishments,
  postPublicationNew,
  postPublicationVideo,
} from '../../../api/private/client';

import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';



// WORKING
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions( {
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
} )


const RebuildPublication = () => {
  const [ approbed, setApprobed ] = useState();
  const [ brokerData, setBrokerData ] = useState( [] );
  const [ brokerSelectName, setBrokerSelectName ] = useState( '' );
  const [ datosBancarios, setDatosBancarios ] = useState( false );
  const [ descriptionText, setDescriptionText ] = useState( '' );
  const [ detail, setDetail ] = useState( null );
  const [ duration, setDuration ] = useState( 0 );
  const [ esBulto, setEsBulto ] = useState( false );
  const [ esMestizo, setEsMestizo ] = useState( false );
  const [ esRaza, setEsRaza ] = useState( false );
  const [ establishment, setEstablishment ] = useState( null );
  const [ establishments, setEstablishments ] = useState( [] );
  const [ internalDescriptionText, setInternalDescriptionText ] = useState( '' );
  const [ loadimage, setLoadImage ] = useState( false );
  const [ lote, setLote ] = useState( true );
  const [ price, setPrice ] = useState( '' );
  const [ readOnlyCbu, setReadOnlyCbu ] = useState( true );
  const [ requireReviewed, setRequireReviewed ] = useState( false );
  const [ revision, setRevision ] = useState( false );
  const [ ubication, setUbication ] = useState( false );
  const [ weanedOff, setWeanedOff ] = useState();
  const history = useHistory();
  const location = useLocation();
  const detailRef = useRef(null);

  const [ subastaDirecta, setSubastaDirecta ] = useState(false);

  const [ videosURLs, setVideosURLs ] = useState( [] )
  const [ uploadingVideos, setUploadingVideos ] = useState( false );

  // CUSTOM USESTATES:
  const [ position, setPosition ] = useState( [ -31.4135, -64.18105 ] );

  // Forms:
  const [ title, setTitle ] = useState( "" );

  const { isCommercial } = useAuthContext();


  // Library FORM
  const { register, control, formState: { errors }, handleSubmit, reset } = useForm( {
    defaultValues: {
      cbu: location.state //por aca recibo el cbu por param de ruta
    }
  } );
  const { fields, append, remove } = useFieldArray( {
    control,
    name: "catalogue",
  } );


  const race = useFormString();
  const breedType = useFormString();
  const crossbreed = useFormString();
  const categorie = useFormString();
  const vitality = useFormString();
  const zone = useFormString();
  const quality = useFormString();

  // END LIBRARY FORM

  useEffect( () => {
    ( async () => {
      const { data } = await getUserEstablishments();
      setEstablishments( data );
    } )();
  }, [] );

  //EFFECTS ENABLED AND BROKER:
  useEffect( () => {
    let decoded = JWT.decode( localStorage.getItem( 'user_type' ) ? localStorage.getItem( 'user_type' ) : null );
    if ( decoded ) {
      const { enabled } = decoded;
      setApprobed( enabled );
    }
    if ( approbed === false ) {
      history.push( '/' );
    }
  }, [ approbed, history, setBrokerData ] )

  useEffect( () => {
    if ( race.value === 'Raza' ) {
      setEsRaza( true );
      setEsMestizo( false );
    }
    if ( race.value === 'Mestizo / cruza' ) {
      setEsRaza( false );
      setEsMestizo( true );
    }
  }, [ race ] )

  //END EFFECT ENABLED AND BROKER

  // Set TABLES
  const handleSetRevision = () => {
    setLote( false );
    setRevision( true );
    setUbication( false );
    setDatosBancarios( false );
  };
  const handleSetLote = () => {
    setLote( true );
    setRevision( false );
    setUbication( false );
    setDatosBancarios( false );
  };
  const handleSetUbication = () => {
    setLote( false );
    setRevision( false );
    setUbication( true );
    setDatosBancarios( false );
  };
  const handleDatosBancarios = () => {
    setLote( false );
    setRevision( false );
    setUbication( false );
    setDatosBancarios( true );
    if ( location.state === null ) {
      setReadOnlyCbu( false );
    } else {    //   
      setReadOnlyCbu( true );
    }
  };
  // END Set TABLES

  const handleUpdateCBU = () => {
    setReadOnlyCbu( false );
  };

  // Custom functions:
  const handleDetail = async ( data ) => {
    const toSend = {
      idConsignataria: 1,
      precioXKg: data?.offer?.price,
      pesoPromedio: data?.batch?.weight,
      cantidad: data?.batch?.count,
      es_bulto: esBulto,
      subasta_directa: subastaDirecta
    };
    
    try {
      const { data } = await getPublicationDetailNew( toSend );
      setDetail( data );
    } catch ( err ) {
      console.log( err );
    }
  };


  const onSubmit = async ( data ) => {
    setLoadImage( true );
    if ( videosURLs.length === 0 ) {
      Swal.fire( {
        heightAuto: false,
        title: '¡El video no puede estar vacío!',
        text: 'Debes subir un video de tu tropa a la publicación',
        icon: 'warning'
      } )
      setLoadImage( false );
      return;
    }

    if ( detail === null ) {
      Swal.fire( {
        heightAuto: false,
        title: 'Detalle de Costos incompleto',
        text: 'Debe calcular el detalle de costos de su publicación presionando el botón "Calcular detalle"',
        icon: 'warning'
      } )
      setLoadImage( false );
      return;
    }

    const { isConfirmed } = await Swal.fire( {
      heightAuto: false,
      title: "¿Estás seguro de crear la publicación?",
      text: "Una vez creada podrás editar o eliminarla",
      icon: "question",
      confirmButtonColor: "#22B24C",
      showCancelButton: true,
      cancelButtonColor: "#e4a5a5",
      confirmButtonText: "¡Si, publicar!",
    } );

    if ( isConfirmed && videosURLs ) {
      Swal.fire({
        title: 'Por favor espere',
        html: 'Creando publicación...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        willOpen: () => {
            Swal.showLoading()
        },
      });

      try {

        const { catalogue, batch, ...rest } = data;
        const { review } = batch;

        if ( batch.race === "Raza" ) {
          batch.race = batch.breedType;
          delete batch.crossbreed;
          delete batch.breedType;
        } else {
          batch.race = batch.crossbreed;
          delete batch.breedType;
          delete batch.crossbreed;
        }

        const toSend = {
          ...rest,
          id_consignatary: 1, // HardCoded, TODO: change
          cuig: null, // HardCoded, TODO: change
          expiration_date: null, // HardCoded, TODO: change
          description: descriptionText,
          internal_description: internalDescriptionText,
          duration: duration,
          catalogue: videosURLs,
          batch: {
            ...batch,
            review: {
              ...review,
              weaned_off_date: weanedOff,
            }
          },
          reviewer: null,
          requires_review: requireReviewed,
          envio_incluido: false,
          establecimiento: establishment,
          es_bulto: esBulto,
          subasta_directa: subastaDirecta
        };

        await postPublicationNew( toSend );
        Swal.close();
        await Swal.fire( {
          heightAuto: false,
          title: "¡Publicación creada!",
          text: "Puedes ver tu publicación en tu panel de control.",
          icon: "success",
        } );
        history.push( routers.user_sales.path );

      } catch ( err ) {
        console.error( err );
        await Swal.fire( {
          heightAuto: false,
          title: "Ha ocurrido un error",
          text: "Por favor intente de nuevo más tarde.",
          icon: "error",
        } );
        history.push( routers.home.path );
      }
    }
  };

  useEffect( () => { // lot_type, address

    let title;
    let text;
    if ( errors?.title ) {
      title = "Título vacío";
      text = "Por favor ingrese un título valido.";
    } else if ( errors?.offer?.price ) {
      title = "Precío vacio"
      text = "Por favor ingrese un precio valido.";
    } else if ( errors?.description ) {
      title = "Descripcion vacía";
      text = "Por favor ingrese una descripcion";
    }else if ( errors?.duration ) {
      title = "Duración vacía";
      text = "Por favor ingrese la duracion de la publicación";
    } else if ( errors?.batch?.count || errors?.batch?.review?.quality ) {
      title = "Pestaña Revisión incompleta";
      text = "Hay campos de caracter obligatorio vacíos en la pestaña Revisión.";
      handleSetRevision();
    } else if ( errors?.address || errors?.batch || errors?.lot_type ) {
      title = "Pestaña Lote incompleta";
      text = "Hay campos de caracter obligatorio vacíos en la pestaña Lote.";
      handleSetLote();
    } else if ( errors?.establishment ) {
      title = "Pestaña Ubicación incompleta";
      text = "Hay campos de caracter obligatorio vacíos en la pestaña Ubicación.";
      handleSetUbication();
    } else if ( errors?.cbu ) {
      title = "Pestaña Liquidación incompleta";
      text = "Hay campos de caracter obligatorio vacíos en la pestaña Liquidación.";
      handleDatosBancarios();
    } else {
      return;
    }

    text && Swal.fire( { title, text, icon: "warning", heightAuto: false } );
  }, [ errors ] );

  const handleEstablishment = ( e ) => {
    const est = establishments.find( establishment => {
      return establishment.id === Number( e.target.value );
    } );
    setEstablishment( est );
    if ( !est ) {
      setPosition( [ -31.4135, -64.18105 ] );
      return;
    }
    setPosition( [ est.lat, est.lng ] );
  };


  // ============= VIDEO UPLOAD ==============================
  const uploadVideos = async ( videoFiles ) => {

    const f = new FormData();

    for ( let index = 0; index < videoFiles.length; index++ ) {
      f.append( "videos", videoFiles[ index ] );
    }
    try {
      setUploadingVideos( true );
      // Envio los videos al backend para ser subidos a Google Storage
      const { data } = await postPublicationVideo( f );
      // Cargo el Array "data" en la variable "videosURLs", para luego agregarlas al objeto
      // "catalogue" donde se graban las URLs de imagenes + videos al grabar la publicacion
      setVideosURLs( data );
      setUploadingVideos( false );
    } catch ( error ) {
      console.log( error );
    }
  }
  // ============= VIDEO UPLOAD ==============================

  return (
    <div className="row">
      <div className="container__RebuildPublication p-2 p-lg-4">
        <form onSubmit={ handleSubmit( onSubmit ) }>
          <div className="row me-0 row-cols-lg-3 row-cols-2 ">
            <div className="col-2 col-lg-1 d-flex align-items-center">
              <div
                className="backarrow container__BackArrow"
                onClick={ () => history.goBack() }>
                <i className="fas fa-angle-left mt-1 ml-1" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-3 mt-lg-0 px-3">
            <h1 className="container__description">Título</h1>
              <CustomInput                
                placeholder={ Traslate.NEW_PUBLICATION.TITLE_HINT }
                name="title"
                register={ register }
                params_register={ { required: true } }
                error={ errors?.title }
                maxLength="60"
                tooltip="Máximo 60 caracteres"
                onChange={ ( e ) => setTitle( e.target.value ) }
              />
            </div>
            { isCommercial() 
            ? (<div className="form-check">
                <input className="form-check-input" 
                  name="subasta_directa"
                  type="checkbox" 
                  checked={ subastaDirecta }
                  id="flexCheckChecked"
                  onChange={ () => {
                    setSubastaDirecta( prevState => !prevState ); 
                    setEsBulto( prevState => !prevState )
                  } }
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Subasta Directa
                </label>
              </div> )
            : null} 
             
          </div>
          {/* Media and sell conditions */ }
          {/* Media */ }
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-8 px-lg-5">
              <div className="d-flex justify-content-center mt-3 ">
                <video width={ 600 } src={ videosURLs[ 0 ] } className={ 'shadow-lg p-1 bg-light rounded' } controls></video>
              </div>
              <hr />
              <div className="mt-4">
                <label
                  htmlFor="files"
                  disabled={ uploadingVideos }
                  style={ { backgroundColor: ( videosURLs.length > 0 ) ? 'green' : 'info' } }
                  className="btn btn-info btn-block">
                  { ( uploadingVideos ) ? <div className="spinner-border" /> : ( videosURLs.length > 0 ) ? 'Video cargado exitosamente!!' : 'Cargar video' }
                </label>
                <input multiple={ false } type="file" name="files" id="files" onChange={ ( e ) => uploadVideos( e.target.files ) } hidden />
              </div>
            </div>
            {/* Sell Conditions */ }
            <div className="col col-lg-4">             
              <div className="container__sell_conditions">
                <h1>{ title ?? 'Título' }</h1>
                <p className='PublicationCostsDetail-subtitle'>
                  Precio total
                </p>
                <p className='PublicationCostsDetail-price'>
                  { subastaDirecta ? formatPrice( detail?.costoPublicacion) : formatPrice( detail?.total ) }
                </p>                
                { ( detail && !subastaDirecta) ? (
                  <>
                    <p className='PublicationCostsDetail-subtitle'>
                      Detalle de costos
                    </p>
                    <ul className="PublicationCostsDetail-list">
                    <li>Costo de publicación: { formatPrice( detail?.costoPublicacion ) }</li>
                    <li>
                      Gastos de comercialización:
                      <span style={ { color: 'red', marginLeft: '1rem' } }>
                        - { formatPrice( detail?.gastosComercializacion ) }
                      </span>
                    </li>
                    <li>Sub-Total: { formatPrice( detail?.subTotal ) }</li>
                    <li>
                      IVA:
                      <span style={ { color: 'green', marginLeft: '0.5rem' } }>
                        + { formatPrice( detail?.iva ) }
                      </span>
                    </li>
                    <li><small className='bold'>Pueden incluirse costos extra</small></li>
                  </ul>
                  </>                  
                ) : null }
                <div className='d-grid gap-2' ref={detailRef} id="detailId">
                  <button                    
                    className='btn btn-info w-100'
                    onClick={ handleSubmit( handleDetail ) }
                    type='button'
                  >
                    Calcular detalle
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Description and info */ }
          <div className="row row-cols-1 row-cols-lg-2 mt-2">
            <div className="col-12 col-lg-6 px-3">
              <h1 className="container__description">Descripción del producto</h1>              
                <div className="form-group">
                  <CustomInput
                    name="description"
                    onChange={ e => setDescriptionText( e.target.value ) }
                    register={ register }
                    params_register={ { required: true } }
                    tootlip="Descripcion general"
                    placeholder="Agregue una descripción"
                    error={ errors?.description }
                    type="text-area"
                    min="0" />
                  
                  {
                    subastaDirecta ? 
                    (<>
                      <h1 className="container__description">Datos internos</h1>
                      <CustomInput
                        name="internal_description"
                        onChange={ e => setInternalDescriptionText( e.target.value ) }
                        register={ register }
                        params_register={ { required: true } }
                        tootlip="Descripcion interna"
                        placeholder="Datos cliente / vendedor"
                        error={ errors?.description }
                        type="text-area"
                        min="0" />
                    </>): null
                  }
                   <h1 className="container__description">Duración de la publicación</h1>
                      <CustomInput
                        name="duration"
                        onChange={ e => setDuration( e.target.value ) }
                        register={ register }
                        params_register={ { required: true } }
                        tootlip="Duracion en dias de la publicación"
                        placeholder="Dias de duración"
                        error={ errors?.duration }
                        type="number"
                        min="0" />
                </div>              
            </div>
            {/* info */ }
            <div className="col-12 col-lg-8 ms-3 my-3">
              <div className="row">
                <div className="col-lg-11 col-sm-12">
                  <div>
                    <div className="container__info_buttons">
                      <Button
                        className="botton-classname btn-custom-width"
                        inverted={ lote ? false : true }
                        onClick={ handleSetLote }
                      >
                        Lote
                      </Button>

                      <Button
                        className="botton-classname btn-custom-width"
                        inverted={ revision ? false : true }
                        onClick={ handleSetRevision }
                      >
                        Revisión
                      </Button>
                      <Button
                        className="botton-classname btn-custom-width"
                        inverted={ ubication ? false : true }
                        onClick={ handleSetUbication }
                      >
                        Ubicación
                      </Button>
                      <Button
                        className="botton-classname btn-custom-width"
                        inverted={ datosBancarios ? false : true }
                        onClick={ handleDatosBancarios }
                      >
                        Mi cuenta
                      </Button>
                    </div>
                    <div className="container__info">
                      <div className="row" style={ { height: lote ? '100%' : '0', overflow: 'hidden' } } >                        
                        <div className="col-md-4">
                          <ComboBox
                            label="Raza"
                            styles={ CUSTOM_BOX_STYLES }
                            name="batch.race"
                            placeholder="Seleccione tipo..."
                            values={ cowBreed }
                            onChange={ race.onChange }
                            register={ register }
                            params_register={ { required: true } }
                            error={ errors?.batch?.race }
                          />
                          <ComboBox
                            hidden={ !esRaza }
                            styles={ CUSTOM_BOX_STYLES }
                            name="batch.breedType"
                            placeholder="Seleccione raza..."
                            values={ breedTypes }
                            onChange={ breedType.onChange }
                            register={ register }
                            params_register={ { required: true } && esRaza }
                            error={ errors?.batch?.breedType }
                          />
                          <ComboBox
                            hidden={ !esMestizo }
                            styles={ CUSTOM_BOX_STYLES }
                            name="batch.crossbreed"
                            placeholder="Seleccione raza de cruza..."
                            values={ crossbreedData }
                            onChange={ crossbreed.onChange }
                            register={ register }
                            params_register={ { required: true } && esMestizo }
                            error={ errors?.batch?.crossbreed }
                          />

                          <CustomInput
                            label="Edad"
                            name="batch.age"
                            placeholder="Ingrese edad promedio..."
                            register={ register }
                            params_register={ { required: true } }
                            error={ errors?.batch?.age }
                          />
                          
                          <h3>Precio por bulto</h3>
                          <div className='switch-container'>
                            <label className='switch'>
                              <input
                                name="bulto"
                                type='checkbox'
                                disabled={ subastaDirecta }
                                checked={ esBulto }
                                onChange={ () => setEsBulto( prevState => !prevState ) } />
                              <span id='slider-switch' className='slider-switch round container__vendor_slider'></span>
                            </label>
                          </div>
                          <CustomInput
                            label= { `Precio por ${ !esBulto ? 'kilogramo' : 'bulto' }` }
                            placeholder="Ingrese el precio..."
                            name="offer.price"
                            type="number"
                            min="0"
                            step="0.01"
                            onChange={ ( e ) => setPrice( e.target.value ) }
                            register={ register }
                            params_register={ { required: true } }
                            error={ errors?.offer?.price }
                          />
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                        <ComboBox
                            label="Categoria"
                            name="batch.category"
                            placeholder="Ingrese categoria..."
                            values={ categories }
                            styles={ CUSTOM_BOX_STYLES }
                            onChange={ categorie.onChange }
                            register={ register }
                            params_register={ { required: true } }
                            error={ errors?.batch?.category }
                          />
                          { !esBulto ? (                              
                            <CustomInput
                              label="Peso"
                              name="batch.weight"
                              type="number"
                              min="0"
                              step="0.01"
                              placeholder="Ingrese peso estimado..."
                              params_register={ { required: true } }
                              register={ register }
                              error={ errors?.batch?.weight }
                            />                               
                          ) : null }
                          
                          { !esBulto ? (                              
                            <ComboBox
                              label="Lugar donde se pesó"
                              name="batch.where_weighted"
                              placeholder={ "Seleccione una opción" }
                              values={ [ "Balanza Propia", "Balanza Pública" ] }
                              register={ register }
                              params_register={ { required: true } }
                              styles={ CUSTOM_BOX_STYLES }
                              error={ errors?.batch?.where_weighted }
                            />                              
                          ) : null }
                          <ComboBox
                            label="Tipo de lote"
                            name="lot_type"
                            placeholder={ "Seleccione una opción" }
                            values={ [ "Invernada", "Faena", "Cría" ] }
                            register={ register }
                            styles={ CUSTOM_BOX_STYLES }
                            params_register={ { required: true } }
                            error={ errors?.lot_type }
                          />
                        </div>
                      </div>
                      <div className="row" style={ { height: revision ? '100%' : '0px', overflow: 'hidden' } }>
                        <div className="col-md-4">
                          <CustomInput
                            label="Cantidad"
                            name="batch.count"
                            type="number"
                            placeholder="Ingrese cantidad..."
                            register={ register }
                            params_register={ { required: true } }
                            error={ errors?.batch?.count }
                          />
                          <ComboBox
                            label="Calidad"
                            name="batch.review.quality"
                            placeholder={ "Seleccione una opcion" }
                            values={ qualityConstant }
                            onChange={ quality.onChange }
                            register={ register }
                            styles={ CUSTOM_BOX_STYLES }
                            params_register={ { required: true } }
                            error={ errors?.batch?.review?.quality }
                          />
                          <ComboBox
                            label="Sanidad"
                            name="batch.review.health"
                            placeholder={ "Seleccione una opcion" }
                            values={ [ "Completa", "Incompleta" ] }
                            onChange={ vitality.onChange }
                            register={ register }
                            styles={ CUSTOM_BOX_STYLES }
                            error={ errors.batch?.review?.health }
                          />  
                          <ComboBox
                            label="Zona"
                            name="batch.review.health_zone"
                            placeholder={ "Seleccione una opción" }
                            values={ [ "Sucia", "Limpia" ] }
                            onChange={ zone.onChange }
                            register={ register }
                            styles={ CUSTOM_BOX_STYLES }
                          />                            
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                          <CustomInput
                            label="Yuyo"
                            name="batch.review.knows_weeds"
                            placeholder="Yuyos que conoce..."
                            register={ register }
                            error={ errors?.batch?.review?.knows_weeds }
                          />

                          <h3>Sabe comer</h3>
                          <div className="mb-5">
                          <label className='switch'>
                            <Switch
                              name="batch.review.knows_eat"
                              register={ register }
                            />
                            </label>
                          </div>
                                                       
                          <h3>Destetado</h3>
                          <div >
                          <label className='switch'>
                            <Switch
                              name="batch.review.weaned_off"
                              register={ register }
                            />
                            </label>
                          </div>                          
                        </div>                        
                      </div>
                      <div style={ { height: ubication ? '100%' : '0', overflow: 'hidden' } }>
                        <h1><b>Información de Ubicación</b></h1>
                        <BottonLine width="100%" margintop="mx-auto" />
                        <p>Por favor selecione en cual Establecimiento se encuentra la hacienda. Este dato es obligatorio.</p>
                        <div className="mt-4 mb-4">
                          <div className='container-comboBox'>
                            <div className="form-group">
                              <select
                                className='form-control'
                                value={ establishment?.id }
                                name='establishment'
                                error={ errors?.establishment }
                                onChange={ handleEstablishment }
                                ref={ register( { required: true } ) }
                              >
                                <option value=''>Seleccione un establecimiento</option>
                                { establishments.map( ( e ) => (
                                  <option key={ e.id } value={ e.id }>
                                    { e.nombre }
                                  </option>
                                ) ) }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="container__map">
                          { position ? (
                            <MapContainer center={ position } zoom={ 14 }>
                              <MapRealloc position={ position } />
                              <TileLayer
                                url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                maxZoom= {20}
                                subdomains={['mt0','mt1','mt2','mt3']}
                              />
                              <Marker position={ position } />
                            </MapContainer>
                          ) : null }
                        </div>
                      </div>
                      <div style={ { height: datosBancarios ? '100%' : '0', overflow: 'hidden' } }>
                        <h1><b>Información para Liquidación</b></h1>
                        <BottonLine width="100%" margintop="mx-auto" />
                        <p>En este apartado le solicitamos ingrese el número de CBU/CVU donde recibirá la liquidación de la venta.
                          En caso de ya tener uno registrado con nosotros, puede acceder a modificarlo.</p>
                        <table className="table table-striped responsiveBatch">
                          <tbody>
                            <tr>
                              <th scope="row"></th>
                              <td className="background_td_switch d-none d-lg-table-cell">
                                CBU
                              </td>
                              <td>
                                <CustomInput
                                  readonly={ readOnlyCbu }
                                  label="CBU/CVU*"
                                  maxLength={ 22 }
                                  tooltip="Máximo 22 dígitos"
                                  name="cbu"
                                  placeholder="Ingrese cbu..."
                                  register={ register }
                                  params_register={ { required: true } }
                                  error={ errors?.cbu }
                                />
                              </td>
                              <td>
                                <Button
                                  className="botton-classname btn-custom-width"
                                  disabled={ !readOnlyCbu }
                                  onClick={ handleUpdateCBU }
                                >
                                  Modificar
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 w-100">
              <div className="w-lg-100 container__vendor">
                <div>
                  <h1 className="container__vendor_title">
                    Información del vendedor
                  </h1>
                  <BottonLine width="80%" margintop="mx-auto" />
                </div>
                <div className="mt-4">
                  <h2 className="bold">Consignataria</h2>
                  <div className="row">
                    <img className="logo-cc"
                      src={ `${ process.env.PUBLIC_URL }/images/logo-cc.png` }
                    />            
                    <p className="mt-3 bold">
                      Consignaciones Córdoba
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-center">
            <div className="col col-lg-4">
              <div className="btn-group w-100">
                <button
                  className="btn btn-success"
                  disabled={ loadimage || uploadingVideos }
                  type='submit'
                >
                  { TraslateAdmin.COMMON.SAVE }
                </button>
                <button
                  className='btn btn-outline-secondary ml-3'
                  onClick={ () => history.push( routers.user_sales.path ) }
                  type='submit'
                >
                  { TraslateAdmin.COMMON.CANCEL }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default RebuildPublication;
