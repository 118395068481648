import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useWindowSize from "../../../hooks/useWindows";

import Container from '../../../components/container/Container';

import { getAdminUser, postAdminUser } from '../../../api/private/client';
import { InfoIcon, VerifyIcon } from "../../../components/Icons/Icons";

interface UserPageForm {
  id: number;
  name: string;
  surname: string;
  image: string;
  phone: number;
  mail: string;
  cuit: number;
  enabled: boolean;
  creation_date: Date;
  iva_situation: null;
  legal_activity: null;
  company: Company;
  transportistaDto: null;
  establecimientos: Establecimiento[];
  roles: Role[];
  perfil_completo: null;
}

export interface Company {
  id: number;
  businessName: string;
  cuit: string;
  cvu: null;
  cbu: string;
}

export interface Establecimiento {
  id: number;
  nombre: string;
  lat: number;
  lng: number;
  codeRenspa: string;
  province: Province;
  locality: Locality;
  direccion: null;
  referencia: null;
}

export interface Locality {
  id: number;
  name: string;
  province: Province;
}

export interface Province {
  id: number;
  province: string;
}

export interface Role {
  id: number;
  name: string;
  description: string;
}


const UserPage: React.FC<any> = ( { match } ) => {

  const { register, errors, watch, setValue } = useForm<UserPageForm>();
  const [ isLoading, setIsLoading ] = useState<boolean>( false );
  const [ isMobile, setIsMobile ] = useState<boolean>( false );
  const [ data, setData ] = useState<any>();
  // const size = useWindowSize();

  // useEffect( () => {
  //   if ( size?.width < 800 ) {
  //     setIsMobile( true );
  //   } else {
  //     setIsMobile( false );
  //   }
  // }, [ size ] );


  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const data = await getAdminUser( match.params.id );
      if ( data?.data.company === null ) {
        data.data.company = {
          cuit: 0,
          businessName: "",
          cbu: 0
        }
      }
      setData( data?.data );

      setIsLoading( false );

      setValue( 'user_id', data?.data.id );

      setValue( 'user_name', data?.data.name );
      setValue( 'user_surname', data?.data.surname );
      setValue( 'user_email', data?.data.mail );

      setValue( 'user_phone', data?.data.phone );
      setValue( 'user_cuit', data?.data.company.cuit ?? 0 );

      // Format Date 
      const strManipulationSplit = data?.data.creation_date.split( "T" )[ 0 ];
      const reversedDate = strManipulationSplit.split( '-' ).reverse().join( '-' );
      setValue( 'user_creation_date', reversedDate );

      setValue( 'user_enabled', data?.data.enabled );
      setValue( 'user_business_name', data?.data.company.businessName ?? "" );
      setValue( 'user_cbu', data?.data.company.cbu ?? 0 );
    } )();
  }, [] )

  let alertColor = '';
  if ( data?.enabled ) {
    alertColor = 'alert-success';
  } else {
    alertColor = 'alert-danger';
  }

  const handleSubmit = async ( e: any ) => {
    // e.preventDefault();
    // const dataToSend = {
    //   company : {
    //     businessName: watch( 'user_business_name', false ),
    //     cbu: watch( 'user_cbu', false ),
    //     cuit: watch( 'user_cuit', false ),
    //     id: 2
    // }
    // }
  }


  return <>
    <div className="container">
      <Container className='ProfilePage-container xercana-main-container' hover={ undefined } href={ undefined } redirected={ undefined }>
        { isLoading ? (
          <div className='ProfilePage-spinner'>
            <div className='spinner-border' role='status' />
          </div>
        ) : (
          <form onSubmit={ handleSubmit }>
            <div className={ `justify-content-center d-flex align-items-center mb-2 ${alertColor} mb-5` }>
              <h1 className="mr-3">#{ data?.id } - Estado del perfil:</h1>
              <h1>
                { data?.enabled === true ? (
                  <><VerifyIcon /> Verificado</>
                ) : (
                  <><InfoIcon /> Pendiente de Verficación</>
                ) }
              </h1>

            </div>

            <div className="form-row">
              {/* <div className="col">
                <div className="mb-3">
                  <img src={ data?.image } alt="yoast seo" height="100" width="100" />
                </div>
              </div> */}

              <div className="col">
                {/* <div className="mb-3">
                  <label className="font-weight-bold">ID</label>
                  <input
                    id="user_id"
                    className={ `form-control ${!data?.id ? "is-invalid" : ""}` }
                    name="user_id"
                    placeholder="User ID"
                    ref={ register( { required: true } ) }
                    // disabled={ !puedeEditar }
                    type="text"
                  />
                </div> */}
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">NOMBRE</label>
                  <input
                    id="user_name"
                    className={ `form-control ${!data?.name ? "is-invalid" : ""}` }
                    name="user_name"
                    placeholder="Nombre del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">APELLIDO</label>
                  <input
                    id="user_surname"
                    className={ `form-control ${!data?.surname ? "is-invalid" : ""}` }
                    name="user_surname"
                    placeholder="Apellido del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">EMAIL</label>
                  <input
                    id="user_email"
                    className={ `form-control ${!data?.mail ? "is-invalid" : ""}` }
                    name="user_email"
                    placeholder="Email del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">

                <div className="mb-3">
                  <label className="font-weight-bold">TELEFONO</label>
                  <input
                    id="user_phone"
                    className={ `form-control ${!data?.phone ? "is-invalid" : ""}` }
                    name="user_phone"
                    placeholder="Telefono del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                </div>
              </div>

              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">CBU</label>
                  <input
                    id="user_cbu"
                    className={ `form-control ${!data?.company?.cbu ? "is-invalid" : ""}` }
                    name="user_cbu"
                    placeholder="CBU"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                  <div id="chofer_id" className="invalid-feedback">
                    Por favor, ingrese un CBU.
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">FECHA DE REGISTRO</label>
                  <input
                    id="user_creation_date"
                    className={ `form-control ${!data?.creation_date ? "is-invalid" : ""}` }
                    name="user_creation_date"
                    placeholder="Fecha de registro del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">RAZON SOCIAL</label>
                  <input
                    id="user_business_name"
                    className={ `form-control ${!data?.company.businessName ? "is-invalid" : ""}` }
                    name="user_business_name"
                    placeholder="Razon social"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                  <div id="chofer_id" className="invalid-feedback">
                    Por favor, ingrese una razon social.
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label className="font-weight-bold">CUIT</label>
                  <input
                    id="user_cuit"
                    className={ `form-control ${!data?.company.cuit ? "is-invalid" : ""}` }
                    name="user_cuit"
                    placeholder="Cuit del usuario"
                    ref={ register( { required: true } ) }
                    disabled={ true }
                    type="text"
                  />
                  <div id="chofer_id" className="invalid-feedback">
                    Por favor, ingrese un CUIT para operar.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="font-weight-bold mt-4">ROLES</label>
              <table className="table table-striped table-info table-bordered w-50">
                <thead>
                  <tr className="text-center">
                    {/* <th>ID</th> */ }
                    {/* <th>Nombre</th> */ }
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  { data?.roles.map( ( e: any, index: number ) => (
                    <tr key={ index } className="text-center">
                      {/* <th>{ e.id }</th> */ }
                      {/* <td>{ e.name }</td> */ }
                      <td>{ e.description }</td>
                    </tr>
                  ) ) }
                </tbody>
              </table>
            </div>
            <div>
              <label className="font-weight-bold mt-4">ESTABLECIMIENTOS</label>
              <table className="table table-striped table-info table-bordered align-middle w-100">
                <thead>
                  <tr className="text-center">
                    {/* <th>ID</th> */ }
                    <th>Nombre</th>
                    <th>Cod. Renspa</th>
                    <th>Provincia</th>
                    <th>Localidad</th>
                  </tr>
                </thead>
                <tbody>
                  { data?.establecimientos.map( ( e: any, index: number ) => (
                    <tr key={ index } className="text-center">
                      {/* <th>{ e.id }</th> */ }
                      <td>{ e.nombre }</td>
                      <td>{ e.codeRenspa }</td>
                      <td>{ e.province.province }</td>
                      <td>{ e.locality.name }</td>
                    </tr>
                  ) ) }
                </tbody>
              </table>
            </div>


          </form>
        ) }

      </Container>
    </div>
  </>;
};

export default UserPage;
