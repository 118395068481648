import React from "react";

import { modalActions } from "../../Modal/PublicationsModal";
import { usePublicationsContext } from "../../../../context/PublicationsContext";

const SellerOptions = ({ publication }) => {
  const { modal } = usePublicationsContext();

  return (
    <>
      {(publication.state === "Comprada" || publication.state === "DteCargado" || publication.state === "Despachada" || publication.state === "PendienteConfirmacion"
        || publication.state === "Entregada" || publication.state === "Liquidada" || publication.state === "Finalizada") && (
        <li>
          <a
            className="dropdown-item"
            onClick={() =>
              modal.openModal(publication.id, modalActions.purchaseDetail)
            }
            type="button"
          >
            Detalle de compra
          </a>
        </li>
      )}
      {publication.state === "Comprada" && (
        <li>
          <a
            className="dropdown-item"
            onClick={() => modal?.openModal(publication.id, modalActions.dte)}
            type="button"
          >
            Cargar DTE
          </a>
        </li>
      )}
    </>
  );
};

export default SellerOptions;
