import axios from 'axios';
import store from 'store-js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const handlePayMP = async ( path ) => {
    try {
        const data = await axios.get( path, {
            baseURL: API_BASE_URL,
            // timeout:1000,
            // headers:`Bearer ${window.localStorage.getItem('user_type')}`,
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': "*",
                Authorization: `Bearer ${ store.get( 'user_type' ) }`,
            },
        } );
        return data;
        // if(data.data && data.status === 200){
        //     dispatch(data.data);
        //     return data.data;

        // }else{
        //     window.location.href = routers.error500.path
        //     return data;

        // }            
    } catch ( error ) {
        // TODO: AXIOS CLIENT ERROR.
        return new Error( error );
    }
}

const handlePOSTrestricted = async ( path, data ) => {
    try {
        const res = await axios.post( path, data, {
            baseURL: API_BASE_URL,
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ store.get( 'user_type' ) }`,
            },
        } );
        return res;
    } catch ( error ) {
        // TODO: AXIOS CLIENT ERROR.
        console.error( "data: ", error.response?.data )
        console.error( "status: ", error.response?.status )
        console.error( "message: ", error.message )
        throw new Error( error );
    }
}

const handleDELETErestricted = async ( path ) => {
    try {
        const resp = await axios.delete( path, {
            baseURL: API_BASE_URL,
            // headers:`Bearer ${window.localStorage.getItem('user_type')}`,
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': "*",
                Authorization: `Bearer ${ store.get( 'user_type' ) }`
            },
        } );
        return resp;
    } catch ( error ) {
        // TODO: AXIOS CLIENT ERROR.
        return new Error( error );
    }
}

export const getPrice = async () => {
    const response = await axios.get( 'https://www.decampoacampo.com/gh_funciones.php?function=getListadoPreciosGordo',
        {
            headers: { "Access-Control-Allow-Origin": "*" }
        } );
    return response.data;
};

export const getChatByPublication = async ( id ) => await handlePayMP( `/user/chat/reviewer/${ id }` )

export const getAdminChats = async ( id ) => await handlePayMP( `/admin/chat/new/${ id }` );

export const getAdminOnlyChat = async () => await handlePayMP( `/admin/chat` );

export const createChat = async ( id ) => await handlePayMP( `/user/publication/${ id }/newChat` )

export const postResponeChat = async ( id, data ) => await handlePOSTrestricted( `/user/chat/reviewer/${ id }`, data )

export const getChatAdminWithUser = async () => await handlePayMP( '/user/chat/admin' );

export const postMessageAdminWithUser = async ( id, data ) => await handlePOSTrestricted( `/user/chat/admin/${ id }`, data )

export const postResponseAdmin = async ( id, data ) => await handlePOSTrestricted( `/admin/chat/${ id }`, data )

export const getMyReviewes = async () => await handlePayMP( '/review/publications/reviewed' );

export const deletePublication = async ( id ) => await handleDELETErestricted( `/publication/${ id }` )

export const deleteAdminPublication = async ( id ) => await handleDELETErestricted( `/admin/publication/${ id }` )

export const postBrokerPublication = async ( id, data ) => await handlePOSTrestricted( `/reviewer/publication/${ id }`, data )
