import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../utils/GlobalColors.json';

import Tooltip from '../tooltip/Tooltip';
import './Input.css';

/**
 * @description: componentse de tipo Input, con imagen
 * @param callback: handleChange
 * @param icon: string del nombre del icono, por ejemplo: photo
 * @param tooltip: es un string, es para generar texto de ayuda.
 */
export const Input = ({ id, label, value, className="", placeholder, name, register, params_register, icon, tooltip, error, readonly, type, onChange, defaultValue, step,min,mb="mb-3",maxLength}) => {

    const noPuntoComa=( event )=> {
  
        console.log('WORK')
        var e = event || window.event;
        var key = e.keyCode || e.which;
    
        if ( key === 110 || key === 190 || key === 188 ) {     
            
           e.preventDefault();     
        }
    }
    // css : dark-gray 
    const colorReadOnly = readonly ? {color: Colors["light-colors"]["--dark-gray"]} : null;

    return (
        <div className={`input-group ${mb} ${className}`} style={ colorReadOnly }>
            { label ? <h3 className={error ? 'input-label-error' : ''}>{label}{ tooltip ? <Tooltip msj={tooltip} /> : null }</h3> : null }
                <div className={ error ? `row container-icon input-error` : `row container-icon` }>
                    {type==="text-area"?(
                        <textarea
                        id={ id }
                        type={ type }
                        step={step}
                        min={min?min:''}
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={ register ? register(params_register) : null }
                        className={` form-control`}
                        aria-label="small"
                        style={ colorReadOnly }
                        placeholder={ placeholder }
                        readOnly={ readonly }
                        onChange={onChange}
                        >

                        </textarea>
                    )
                    :type==='number'?(
                        <input
                        id={ id }
                        type="number"
                        step={step}
                        min="1"
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={ register ? register(params_register) : null }
                        className='form-control'
                        aria-label="small"
                        style={ colorReadOnly }
                        placeholder={ placeholder }
                        readOnly={ readonly }
                        onChange={onChange}
                        pattern="^[0-9]+"
                        onKeyDown={(e)=>noPuntoComa(e)}
                    />
                    ):
                    (
                        <input
                        id={ id }
                        type={ type }
                        step={step}
                        min={min?min:''}
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={ register ? register(params_register) : null }
                        className='form-control'
                        aria-label="small"
                        style={ colorReadOnly }
                        placeholder={ placeholder }
                        readOnly={ readonly }
                        onChange={onChange}
                        maxLength={maxLength}
                    />
                    )}
                    
                   
                    {icon ? (<i className={`d-flex flex-row-reverse fa fa-${icon} col-1 -icon`}></i>) : null}
                </div>
        </div>
    )
}

Input.propType = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    callback: PropTypes.func,
    icon: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool
}

Input.defaultProps = {
    type: 'text',
    icon: null,
    readonly: false,
}