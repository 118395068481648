import { useState } from "react";
import * as XLSX from "xlsx";

export const BtnExcelExport = ({offers}) => {

    const [loading, setLoading] = useState(false);

    const longitudes = [10, 30, 30, 30, 20, 10];

    const handleDownload = () => {
        setLoading(true);

        let tabla = [
            {
              A: "Id Publicación",
              B: "Fecha",
              C: "Usuario",
              D: "Mail de contacto",
              E: "Teléfono",
              F: "Monto"
            },
        ];

        offers.forEach((offer) => {
            tabla.push({
                A: offer.publication_id,
                B: offer.created_at,
                C: offer.user_name,
                D: offer.mail_contacto,
                E: offer.phone,
                F: offer.amount,
            });
        });

        const libro = XLSX.utils.book_new();

        const hoja = XLSX.utils.json_to_sheet(tabla, { skipHeader: true });
      
        let propiedades = [];
    
        longitudes.forEach((col) => {
        propiedades.push({
            width: col,
        });
        });
    
        hoja["!cols"] = propiedades;

        XLSX.utils.book_append_sheet(libro, hoja, "Ofertas");

        setTimeout(() => {
            XLSX.writeFile(libro, "Ofertas.xlsx");
            setLoading(false);
        }, 1000);
    };


    return (
        <>
          {!loading ? (
            <button className="btn btn-success" onClick={handleDownload}>
              Exportar a Excel
            </button>
          ) : (
            <button className="btn btn-success" disabled>
              <span> Generando...</span>
            </button>
          )}
        </>
      );
}
