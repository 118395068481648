import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { getAdminTransportCosts, postAdminConsignee, postAdminTransportCosts } from "../../../api/private/client";

interface TransportCostsForm {
  chasis: {
    arranque: number
    id_transportista: number
    iva: number
    precio_km: number
    seguro: number
    vigencia_desde: string
  },
  simple: {
    arranque: number
    id_transportista: number
    iva: number
    precio_km: number
    seguro: number
    vigencia_desde: string
  },
  doble: {
    arranque: number
    id_transportista: number
    iva: number
    precio_km: number
    seguro: number
    vigencia_desde: string
  },
}

interface TransportCostsResponse {
  data: TransportCostsForm;
}

const ConsigneePage: React.FC<any> = () => {
  const { register, handleSubmit, reset, errors, watch } = useForm<TransportCostsForm>();
  const [ isLoading, setIsLoading ] = useState<boolean>( false );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const { data } = await getAdminTransportCosts() as TransportCostsResponse;
      reset( data );
      setIsLoading( false );
    } )();
  }, [] );



  const confirmModal = async (): Promise<boolean> => {
    const { isConfirmed } = await Swal.fire( {
      title: "¿Está seguro de guardar?",
      icon: "question",
      confirmButtonColor: "green",
      confirmButtonText: "Confirmar",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "Cancelar",
    } );
    return isConfirmed;
  };

  const errorModal = () => {
    Swal.fire( {
      title: "Error",
      text: "Parámetros de transporte editados insatisfactoriamente.",
      icon: "error",
    } );
  };

  const infoModal = () => {
    Swal.fire( {
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
    } );
  };

  const successModal = () => {
    Swal.fire( {
      title: "Confirmado",
      text: "Parámetros de transporte editados satisfactoriamente.",
      icon: "success",
    } );
  };

  const onSubmit = async () => {
    const dataToSend = {
      arranqueChasis: watch( 'chasis.arranque', false ),
      precioKmChasis: watch( 'chasis.precio_km', false ),
      seguroChasis: watch( 'chasis.seguro', false ),
      arranqueSimple: watch( 'simple.arranque', false ),
      precioKmSimple: watch( 'simple.precio_km', false ),
      seguroSimple: watch( 'simple.seguro', false ),
      arranqueDoble: watch( 'doble.arranque', false ),
      precioKmDoble: watch( 'doble.precio_km', false ),
      seguroDoble: watch( 'doble.seguro', false ),
      iva: watch( 'chasis.iva', false ),
      vigencia_desde: watch( 'chasis.vigencia_desde', false ) + "T00:00:00.000",
      id_transportista: watch( 'chasis.id_transportista', false )
    }

    const isConfirmed = await confirmModal();
    if ( isConfirmed ) {
      infoModal();
      try {
        await postAdminTransportCosts( {
          ...dataToSend,
        } );
        successModal();
      } catch ( err ) {
        errorModal();
      }
    }
  };

  return <>
    <div className="container">
      <div className="card my-5">
        <div className="card-body">
          <p className="mb-4" style={ { fontSize: "22px", fontWeight: "bold", textAlign: "center" } }>
            Parámetros de transporte
          </p>
          { isLoading ? (
            <div className="h-100 d-flex justify-content-center w-100 my-5">
              <div className="spinner-border" role="status" />
            </div>
          ) : (
            <form onSubmit={ handleSubmit( onSubmit ) }>
              <div className="row justify-content-center">
                <div className="alert alert-danger w-50" role="alert">
                  <div className="mb-3">
                    <label htmlFor="vigencia_id" className="font-weight-bold">Vigencia desde</label>
                    <input
                      id="vigencia_id"
                      className={ `form-control ${errors.chasis?.vigencia_desde ? "is-invalid" : ""}` }
                      name="chasis.vigencia_desde"
                      placeholder="Ingrese fecha..."
                      ref={ register( { required: true } ) }
                      type="date"
                    />
                    <div id="vigencia_id" className="invalid-feedback">
                      Por favor, ingrese una fecha válida.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="iva_id" className="font-weight-bold">IVA</label>
                    <input
                      id="iva_id"
                      className={ `form-control ${errors.chasis?.iva ? "is-invalid" : ""}` }
                      name="chasis.iva"
                      placeholder="Ingrese porcentaje de IVA..."
                      ref={ register( { required: true, valueAsNumber: true } ) }
                      type="text"
                    />
                    <div id="iva_id" className="invalid-feedback">
                      Por favor, ingrese un porcentaje válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_transportista" className="font-weight-bold">ID transportista</label>
                    <input
                      id="transportista"
                      className={ `form-control ${errors.chasis?.id_transportista ? "is-invalid" : ""}` }
                      name="chasis.id_transportista"
                      placeholder="Ingrese ID..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="transportista" className="invalid-feedback">
                      Por favor, ingrese un ID válido.
                    </div>
                  </div>
                </div>
              </div>



              <div className="row">
                <div className="alert alert-info col-sm" role="alert">
                  <div className="mb-3">
                    <label htmlFor="id_arranque_chasis" className="font-weight-bold">Arranque - CHASIS*</label>
                    <input
                      id="id_arranque_chasis"
                      className={ `form-control ${errors.chasis?.arranque ? "is-invalid" : ""}` }
                      name="chasis.arranque"
                      placeholder="Ingrese precio de arranque..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_arranque_chasis" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_precio_km_chasis" className="font-weight-bold">Precio por KM - CHASIS*</label>
                    <input
                      id="id_precio_km_chasis"
                      className={ `form-control ${errors.chasis?.precio_km ? "is-invalid" : ""}` }
                      name="chasis.precio_km"
                      placeholder="Ingrese precio por KM..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_precio_km_chasis" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_seguro_chasis" className="font-weight-bold">Precio de seguro - CHASIS*</label>
                    <input
                      id="id_seguro_chasis"
                      className={ `form-control ${errors.chasis?.seguro ? "is-invalid" : ""}` }
                      name="chasis.seguro"
                      placeholder="Ingrese precio del seguro..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_seguro_chasis" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <small>*Precios expresados en Pesos Argentinos</small>
                </div>
                <div className="alert alert-dark col-sm ml-2 mr-2" role="alert">
                  <div className="mb-3">
                    <label htmlFor="id_arranque_simple" className="font-weight-bold">Arranque - SIMPLE*</label>
                    <input
                      id="id_arranque_simple"
                      className={ `form-control ${errors.simple?.arranque ? "is-invalid" : ""}` }
                      name="simple.arranque"
                      placeholder="Ingrese precio de arranque..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_arranque_simple" className="invalid-feedback">
                      Por favor, ingrese una precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_precio_km_simple" className="font-weight-bold">Precio por KM - SIMPLE*</label>
                    <input
                      id="id_precio_km_simple"
                      className={ `form-control ${errors.simple?.precio_km ? "is-invalid" : ""}` }
                      name="simple.precio_km"
                      placeholder="Ingrese precio por KM..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_precio_km_simple" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_seguro_simple" className="font-weight-bold">Precio de seguro - SIMPLE*</label>
                    <input
                      id="id_seguro_simple"
                      className={ `form-control ${errors.simple?.seguro ? "is-invalid" : ""}` }
                      name="simple.seguro"
                      placeholder="Ingrese precio del seguro..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_seguro_simple" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                <small>*Precios expresados en Pesos Argentinos</small>
                </div>
                <div className="alert alert-info col-sm" role="alert">
                  <div className="mb-3">
                    <label htmlFor="id_arranque_doble" className="font-weight-bold">Arranque - DOBLE*</label>
                    <input
                      id="id_arranque_doble"
                      className={ `form-control ${errors.doble?.arranque ? "is-invalid" : ""}` }
                      name="doble.arranque"
                      placeholder="Ingrese precio de arranque..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_arranque_doble" className="invalid-feedback">
                      Por favor, ingrese una precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_precio_km_doble" className="font-weight-bold">Precio por KM - DOBLE*</label>
                    <input
                      id="id_precio_km_doble"
                      className={ `form-control ${errors.doble?.precio_km ? "is-invalid" : ""}` }
                      name="doble.precio_km"
                      placeholder="Ingrese precio por KM..."
                      ref={ register( { required: true } ) }
                      type="number"
                    />
                    <div id="id_precio_km_doble" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="id_seguro_doble" className="font-weight-bold">Precio de seguro - DOBLE*</label>
                    <div className="row">
                      <input
                        id="id_seguro_doble"
                        className={ `form-control ${errors.doble?.seguro ? "is-invalid" : ""}` }
                        name="doble.seguro"
                        placeholder="Ingrese precio del seguro..."
                        ref={ register( { required: true } ) }
                        type="number"
                      />
                    </div>
                    
                    <div id="id_seguro_doble" className="invalid-feedback">
                      Por favor, ingrese un precio válido.
                    </div>
                  </div>
                  <small>*Precios expresados en Pesos Argentinos</small>
                </div>
              </div>


              <button className="btn btn-info" type="submit">
                Enviar
              </button>
            </form>
          ) }
        </div>
      </div>
    </div>
  </>;
};

export default ConsigneePage;
