import React from "react";

import { formatDouble, formatPrice } from "../../../utils/helper";
import "./CostsCard.css";
import "./Card.css";

interface CostsCardProps {
  costs: {
    costoCorredor: number;
    costoEnvio: number;
    costoPublicacion: number;
    costoTotal: number;
    gastosComercializacion: number;
    gastosVarios: number;
    iva: number;
    kmEnvio: number;
    subTotal: number;
  };
}

const CostsCard: React.FC<CostsCardProps> = ({ costs }) => {
  return (
    <>
      <div className="card my-4 p-0 p-lg-4">
        <p className="Card-title text-center">
          Importes
        </p>
        <div className="card-body">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-lg-10">
              <table className="CostsCard-table">
                <tbody>
                  <tr>
                    <td>Costo de publicación</td>
                    <td className="CostsCard-table-number">
                      {formatPrice(costs.costoPublicacion)}
                    </td>
                  </tr>
                  <tr className="CostsCard-table-row">
                    <td>Gastos de comercialización</td>
                    <td className="CostsCard-table-number CostsCard-red">
                      {formatPrice(costs.gastosComercializacion)}
                    </td>
                  </tr>
                  { costs.costoCorredor !== null ?
                    <tr className="CostsCard-table-row">
                      <td>Costo de corredor</td>
                      <td className="CostsCard-table-number CostsCard-red">
                        {formatPrice(costs.costoCorredor)}
                      </td>
                    </tr>: null
                  }                  
                  <tr className="CostsCard-table-row">
                    <td>Costo de envío</td>
                    <td className="CostsCard-table-number CostsCard-red">
                      {formatPrice(costs.costoEnvio)}
                    </td>
                  </tr>
                  <tr className="CostsCard-table-row">
                    <td>Cantidad de kilometros</td>
                    <td className="CostsCard-table-number CostsCard-blue">
                      {formatDouble(costs.kmEnvio)}
                    </td>
                  </tr>
                  <tr className="CostsCard-table-row">
                    <td>Sub-Total</td>
                    <td className="CostsCard-table-number">
                      {formatPrice(costs.subTotal)}
                    </td>
                  </tr>
                  <tr className="CostsCard-table-row">
                    <td>IVA</td>
                    <td className="CostsCard-table-number CostsCard-red">
                      {formatPrice(costs.iva)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              className="Card-title CostsCard-table-number col-12 col-lg-10 my-4"
            >
              Total: {formatPrice(costs.costoTotal)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostsCard;
