import {  useState } from 'react';
import Button from '../button/Button';
import './multipleSwitch.css';

/**
 * @param children this required ElementSwitch
 * @param className Css class reference
 * @param classNameChild Css class reference
 */
const MultipleSwitch = ({ children, classNameChild }) => {

    const [state, setState] = useState(0);

    const handleButton = (event) => setState(Number(event.target.name))

    return (
        <>
            <div className='col-lg-11 col-sm-12'>
                <div className={`container__info_buttons mb-3`}>
                    {
                        children.map((child, index) => {   
                            return (
                                <Button
                                    className='botton-classname btn-custom-width'
                                    key={index}
                                    name={index}
                                    inverted={index === state ? false : true}
                                    onClick={handleButton}
                                >
                                    {child?.props?.labelName}
                                </Button>                         
                            )
                        })
                    }
                </div>
            </div>
            
            <div className='row'>
                <div className={classNameChild}>
                    {children[state]}
                </div>
            </div>
        </>
    )
}

export default MultipleSwitch;