import { useEffect } from 'react';
import { GeoSearchControl, MapBoxProvider, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';


const SearchField = ({ apiKey }) => {

  const provider = new OpenStreetMapProvider({
    params: {
      'accept-language': 'es',
      countrycodes: 'ar',
      // addressdetails: 1,
    },
  });

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    notFoundMessage: 'No pudimos encontrar su dirección.',
    provider: provider,
    searchLabel: 'Ingrese dirección o palabras clave',
    autoClose: true,
    showMarker: false,
    style: 'bar',
    keepResult: true,
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

export default SearchField;