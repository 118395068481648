import axios from 'axios';
import routers from '../../config/routers'


const privateClient = axios.create( {
    baseURL: process.env.REACT_APP_API_BASE_URL,
} );

const privateFileClient = axios.create( {
    baseURL: process.env.REACT_APP_API_BASE_URL,
} );

privateClient.interceptors.request.use(
    config => {
        config.headers[ 'Authorization' ] = `Bearer ${ localStorage.getItem( process.env.REACT_APP_ACCESS_TOKEN ) }`;
        config.headers[ 'Content-Type' ] = 'application/json';
        return config;
    },
    error => Promise.reject( error ),
);

privateClient.interceptors.response.use(
    response => response,
    error => {
        if ( error.response ) {
            if ( error.response.status === 401 ) {
                localStorage.removeItem( process.env.REACT_APP_ACCESS_TOKEN );
                window.location.assign( routers.home.path );
            }
        }
        return Promise.reject( error );
    },
);

const privateGET = async ( path ) => {
    try {
        const res = await privateClient.get( path );
        if ( res.data && res.status === 200 ) {
            return res;
        }
    } catch ( error ) {
        throw new Error( error );
    }
};

const privatePOST = async ( path, data ) => {
    try {
        const res = await privateClient.post( path, data );
        return res;
    } catch ( error ) {
        throw new Error( error );
    }
};

privateFileClient.interceptors.request.use(
    config => {
        config.headers[ 'Authorization' ] = `Bearer ${ localStorage.getItem( process.env.REACT_APP_ACCESS_TOKEN ) }`;
        // config.headers['Content-Type'] = 'multipart/form-data';
        return config;
    },
    error => Promise.reject( error ),
);

const privateFilePOST = async ( path, data ) => {
    try {
        const res = await privateFileClient.post( path, data );
        return res;
    } catch ( error ) {
        throw new Error( error );
    }
};

//AUCTION

export const getAuctionUser = async () => await privateGET( '/auctions/user' );
export const postAuctionUser = async ( data ) => await privatePOST( '/auctions/user', data );

// USER

export const getCompaniesAvailable = async () => await privateGET( '/user/companies/available' );

export const getUser = async () => await privateGET( '/user' );

export const postUser = async ( data ) => await privatePOST( '/user', data );

export const getUserNavbar = async () => await privateGET( '/user/navbar' );

export const getUserPurchases = async () => await privateGET( '/user/purchases' );

export const getUserSales = async () => await privateGET( '/user/sales' );

export const postFcmToken = async ( data ) => await privatePOST( '/user/fcmtoken', data );

export const sendSmsCode = async ( data ) => await privatePOST( '/sendcode', data );

export const checkSmsCode = async ( data ) => await privatePOST( '/checkcode', data );

// ADMIN

export const getAdminCompanies = async ( page ) => await privateGET( `/admin/companies/${ page }` );

export const getAdminCompany = async ( id ) => await privateGET( `/admin/companies/id=${ id }` );

export const postAdminCompany = async ( id, data ) => await privatePOST( `/admin/companies/id=${ id }`, data );

export const getAdminConsignee = async ( id = 1 ) => await privateGET( `/admin/consignatary/${ id }` );

export const postAdminConsignee = async ( data ) => await privatePOST( `/admin/consignatary`, data );

export const getAdminTransportCosts = async () => await privateGET( `/admin/transport` );

export const postAdminTransportCosts = async ( data ) => await privatePOST( `/admin/transport`, data );

export const postSetAdminTransportData = async ( data ) => await privatePOST( `/admin/setTransportData`, data );

export const postUpdateAdminTransportData = async ( data ) => await privatePOST( `/transportista/updateTransportData`, data );

export const getAdminHistory = async () => await privateGET( '/admin/history' );

export const getAdminPublication = async ( id ) => await privateGET( `/admin/publication/${ id }` );

export const postAdminPublication = async ( id, data ) => await privatePOST( `/admin/publication/${ id }`, data );

export const getAdminPublications = async ( page ) => await privateGET( `/admin/publications/states/${ page }` );

export const getAdminRoles = async () => await privateGET( '/roles/all' );

export const getAdminUser = async ( id ) => await privateGET( `/admin/user/${ id }` );

export const postAdminUser = async ( id, data ) => await privatePOST( `/admin/user/${ id }`, data )

export const postAdminUserRoles = async ( id, data ) => await privatePOST( `/admin/roles/${ id }`, data );

export const postAdminUserEnable = async ( id, data ) => await privatePOST( `/admin/user/enable/${ id }`, data );

export const getAdminUsers = async ( page ) => await privateGET( `/admin/users/${ page }` );

export const getQuestionsToApprove = async () => await privateGET( '/comments/admin' );

export const postQuestionApprove = async ( data ) => await privatePOST( '/question/approve', data );

export const postAnswerApprove = async ( data ) => await privatePOST( '/answer/approve', data );

export const getQuestionsToAnswer = async () => await privateGET( '/comments/seller' );

// ADDRESS

export const getProvinces = async () => await privateGET( '/provinces' );

export const getLocalities = async ( id ) => await privateGET( `/localities/${ id }` );

// ROLES

export const getRolesAdminless = async () => await privateGET( '/roles/adminless' );

export const postRolesAdminless = async ( id, data ) => await privatePOST( `/roles/${ id }`, data );

// COMMENTS

export const getComments = async ( id ) => await privateGET( `/comments/${ id }` );

export const postCommentAnswer = async ( id, data ) => await privatePOST( `/answer/${ id }`, data );

export const postCommentQuestion = async ( id, data ) => await privatePOST( `/question/${ id }`, data );

// PUBLICATION

export const getPublication = async ( id ) => await privateGET( `/publication/${ id }` );

export const getPublicationDetail = async ( data ) => await privatePOST( '/publication/detalle', data );

export const getPublicationDetailNew = async ( data ) => await privatePOST( '/publication/detalle/creacion', data );

export const getPublicationUnificada = async () => await privateGET( `/publication/unificada` );

export const getPublicationHistory = async ( id ) => await privateGET( `/publication/history/${ id }` );

export const getPublicationCompra = async ( id ) => await privateGET( `/publication/compra/${ id }` );

export const getPublicationSearch = async ( query ) => await privateGET( `/publication/buscador/${ query }` );

export const getAdvancedPublicationSearch = async ( data ) => await privatePOST( `/publication/buscador_avanzado`, data );

export const postPublicationApprove = async ( id ) => await privatePOST( `/publication/approve/${ id }`, null );

export const postPublicationDisapprove = async ( data ) => await privatePOST( `/publication/disapprove`, data );

export const postPublicationEdit = async ( id, data ) => await privatePOST( `/publication/${ id }`, data );

export const postPublicationNew = async ( data ) => await privatePOST( `/publication/new`, data );

export const postPublicationPause = async ( id ) => await privatePOST( `/publication/pause/${ id }` );

export const postPublicationResume = async ( id ) => await privatePOST( `/publication/resume/${ id }` );

export const postPublicationCancel = async ( id ) => await privatePOST( `/publication/cancel/${ id }` );

export const postPublicationGrade = async ( id, data ) => await privatePOST( `/publication/grade/${ id }`, data );

export const postPublicationInformDispatch = async ( data ) => await privatePOST( '/publication/informDispatch', data );

export const postPublicationInformDelivery = async ( data ) => await privatePOST( '/publication/informDelivery', data );

export const postPublicationAcceptDelivery = async ( data ) => await privatePOST( '/publication/acceptDelivery', data );

export const postPublicationVideo = async ( data ) => await privateFilePOST( '/publication/video', data );

//  Preoferta subasta directa
export const postPreoffer = async ( data ) => await privatePOST( '/preoffer/new', data );

export const getPublicationOffers = async ( publicationId ) => await privateGET( `/preoffer/${ publicationId }` );

// Metodo de pago alternativo
export const postPaymentMethodComment = async ( data ) => await privatePOST( '/payment_method/new_comment', data );
export const getPaymentMethodComments = async ( publicationId ) => await privateGET( `/payment_method/${ publicationId }` );



export const postPublicationImage = async ( images ) => {
    const form = new FormData();
    images.forEach( ( i ) => {
        form.append( 'imagenes', i );
    } );

    return await privateClient.post(
        '/publication/imagen',
        form,
        {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${ form._boundary }`
            },
        },
    );
};

// REVIEWERS

export const getReviewerPublications = async () => await privateGET( '/reviewer/publications' );

export const getReviewers = async () => await privateGET( '/reviewers/all' );

// CARRIERS

export const getTransportTypes = async () => await privateGET( '/tipo_transporte' );

// PAY 

export const getTxStatus = async ( id ) => await privateGET( `/payment/get-status/${ id }` );

export const getPaymentMethods = async () => await privateGET( `/payment/payment-methods` );

export const getHashForm = async ( data ) => await privatePOST( `/payment/create`, data );

export const getPay = async ( data ) => await privatePOST( `/pay`, data );

export const getCarriers = async () => await privateGET( `/transportista/all` );

export const getCosts = async ( data ) => await privatePOST( `/transportista/costoEnvio`, data );

// ESTABLISHMENT

export const getUserEstablishments = async () => await privateGET( '/user/establecimientos' );
export const getUserEstablishmentsById = async ( userId ) => await privateGET( `/user/establecimientos/${ userId }` );

export const postUserEstablishmentsDelete = async ( data ) => await privatePOST( '/user/establecimientos/delete', data );
export const createUserEstablishment = async ( userId, data ) => await privatePOST( `/user/establecimientos/create/${ userId }`, data );
export const updateUserEstablishment = async ( userId, data ) => await privatePOST( `/user/establecimientos/update/${ userId }`, data );



// DTE

export const postDte = async ( { id, dte } ) => {
    const form = new FormData();
    form.append( 'dte', dte );
    form.append( 'idPublication', id );
    return await privateClient.post(
        '/publication/loadDTE',
        form,
        {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${ form._boundary }`
            },
        },
    );
};

// LIQUIDATE

export const postLiquidate = async ( { id, sale, purchase } ) => {
    const form = new FormData();
    form.append( 'idPublication', id );
    form.append( 'liquidacionVenta', sale );
    form.append( 'liquidacionCompra', purchase );
    return await privateClient.post(
        '/publication/uploadLiquidaciones',
        form,
        {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${ form._boundary }`
            },
        },
    );
};

export const postAuctionPrices = async ( images ) => {
    const form = new FormData();
    images.forEach( ( i ) => {
        form.append( 'imagenes', i );
    } );

    return await privateClient.post(
        '/admin/pricesTables',
        form,
        {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${ form._boundary }`
            },
        },
    );
};
