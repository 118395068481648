import React from 'react'
import BottonLine from '../../../components/BottonLine';
import { RealBreadCrumb } from '../../../components/breadcrumb/Breadcrumb';
import LinkWsp from '../../../components/LinkWsp/LinkWsp';
import routers from '../../../config/routers';
import '../HelpScreen.css';


export default function FirstSteps() {
   
    return (
        <div style={{minHeight:'80vh',paddingBottom:'5rem'}}>            
            <h1 className="text-center w-100 font-weight-bold" style={{fontSize:'2rem',marginTop:'3rem'}}>Primeros pasos</h1>
            <div style={{width:'70%',margin:'0 auto',marginTop:'1rem'}}>
                <RealBreadCrumb
                    refNames={['Ayuda ','Primeros pasos']}
                    hrefs={[routers.help.path,  '#']}/>
            </div>
            <div className="helpScreenContainerDiv helpIndividual">
                <div style={{display:'flex',flexDirection:'column'}}>
                    <h1 className="text-center">Bienvenido a la nueva forma de comprar Hacienda</h1>
                    <BottonLine width="100%"/>
                    <ol className="mt-4">
                        <li className="m-4">Ingresá con tu cuenta de Google y completa tus datos</li>
                        <li className="m-4">Deberás esperar que tu cuenta sea verificada por un administrador</li>
                        <li className="m-4">Buscá el producto que necesitas</li>
                        <li className="m-4">Elegí tu forma de pago y envío</li>
                        <li className="m-4">Comprá</li>
                        <li className="m-4">Recibí el pedido de tu lote</li>
                    </ol>
                    
                    <LinkWsp></LinkWsp>                    
                </div>
            </div>
        </div>
    )
}
