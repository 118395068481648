// React
import { useState, useEffect } from "react";
import { useHistory } from "react-router";

// Components
import PublicationOptions from "../Options/PublicationOptions";
import Swal from "sweetalert2";

// Styles
import "./PublicationCard.css";

// Other
import {
  postPublicationApprove,
  postPublicationDisapprove,
} from "../../../api/private/client";

import { useAuthContext } from "../../../context/AuthenticatedContext";


/**
 * @brief Card de publicacion
 *
 * Este componente renderiza una card de bootstrap con informacion
 * de una publicacion especifica y opciones  segun el rol enviado por
 * parametro.
 *
 * @param {html span tag} state badge del estado de la publicacion
 * @param {object} publication publicacion a leer
 * @param {int} index para el key de react
 * @param {string} role rol del nav en cuestion
 * @param {boolean} approved usuario aprobado o no
 */
function PublicationCard( { state, modificacion, publication, index, role, approved } ) {
  const history = useHistory();
  const { isAdmin } = useAuthContext();
  const values = [ "Habilitada", "Deshabilitada" ];

  const infoModal = ( msg ) => {
    Swal.fire( {
      title: "Espere un momento",
      text: `Aguarde unos instantes, estamos ${ msg } la publicación.`,
      icon: "info",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false

    } );
  };

  const successModal = ( msg ) => {
    return Swal.fire( {
      title: `Publicación ${ msg } con éxito`,
      icon: "success",
    } );
  };

  const errorModal = () => {
    return Swal.fire( {
      title: "Error",
      text: "Ha ocurrido un error. Por favor inténtelo más tarde.",
      icon: "error",
    } );
  };

  const handleEnabled = async ( name ) => {
    if ( name === "Habilitada" ) {
      infoModal( "habilitando" );
      try {
        await postPublicationApprove( publication.id );
        await successModal( "habilitada" );
        history.go( 0 );
      } catch {
        await errorModal();
      }
    } else if ( name === "Deshabilitada" ) {
      infoModal( "deshabilitando" );
      try {
        await postPublicationDisapprove( { idPublication: publication.id } );
        await successModal( "deshabilitada" );
        history.go( 0 );
      } catch {
        await errorModal();
      }
    }
  };
  return (
    <div className={ `card mb-3 ${ publication.vencida && 'border-primary' }` }  >
      { ( publication.vencida ) && (
        <div className="card-header bg-danger bold">
          Vencida
        </div>
      )}
      <div key={ publication.id }>
        <span className="badge badge-light m-1">#{ publication.id }</span>
        { isAdmin() && <span className="badge badge-light text-wrap">Usuario: { publication.user }</span> }
        { publication.subastaDirecta && <span className="badge badge-secondary text-wrap">Subasta Directa</span> }
      </div>
      <div className="row">
        <div className="col-md-3 mt-lg-3">
          <img src={ publication.media } className="card__img" />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <div className="row justify-content-between">
              <h1 className="bold">{ publication.title }</h1>
              <div key={ "options" } className="">
                <PublicationOptions
                  role={ role }
                  publication={ publication }
                >
                </PublicationOptions>
              </div>
            </div>
            <hr></hr>
            { role === "admin" &&
              (
                <div key={ "enabled" } className="row">
                  <div className="mt-lg-1">
                    <b>Habilitación:</b>
                  </div>
                  <div className="form-group">
                    <select
                      className='form-control ml-lg-3'
                      value={ publication.enabled ? "Habilitada" : "Deshabilitada" }
                      name='enabled'
                      onChange={ ( e ) => handleEnabled( e.target.value ) }
                    >
                      <option value=''>Seleccione una opción</option>
                      { values.map( ( value ) => (
                        <option key={ value } value={ value }>{ value }</option>
                      ) ) }
                    </select>
                  </div>
                </div>
              ) }
            <div key={ "state" } className="form-group">
              <div><b>Estado:</b> { state }</div>
            </div>
            <p className="card-text"><small className="text-muted">Ultimo movimiento: { modificacion }</small></p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PublicationCard;
