// React
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Components
import Swal from 'sweetalert2';
import AdminOptions from './RolesOptions/AdminOptions';
import BrokerOptions from './RolesOptions/BrokerOptions/BrokerOptions';
import BuyerOptions from './RolesOptions/BuyerOptions';
import OwnerOptions from './RolesOptions/OwnerOptions';
import SellerOptions from './RolesOptions/SellerOptions';
import ShipperOptions from './RolesOptions/ShipperOptions';

// Context
import { modalActions } from '../Modal/PublicationsModal';
import { usePublicationsContext } from '../../../context/PublicationsContext';

// Others
import routers from '../../../config/routers';

/**
 * @brief Opciones de una card publication
 * 
 * Este componente renderiza las opciones de una card segun
 * el rol del nav en cuestion.
 * 
 * @param {string} role rol del nav
 * @param {object} publication publicacion en cuestion
 */
function PublicationOptions({ role, publication }) {
    const history = useHistory();
    const { modal } = usePublicationsContext();
    

    return <>
        <div className="dropdown">
            {/* Dropdown button */}
            <div
                id="dropdownMenuButton1"
                className="d-flex justify-content-center justify-content-lg-end"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                type="button"
            >
                <div><i className="fas fa-ellipsis-v"></i></div>
            </div>
            {/* Dropdown options */}
            <ul className="dropdown-menu text-center" aria-labelledby="dropdownMenuButton1">
                <li>
                    <Link
                        className="dropdown-item"
                        to={routers.publicationViewNoSale.path_1(publication.id ?? publication.id_publication, "no")}
                    >
                        Ver
                    </Link>
                </li>
                <li>
                    <a
                        className="dropdown-item"
                        onClick={() => modal?.openModal(publication.id, modalActions.offers)}
                        type="button"
                    >
                        Ver Ofertas
                    </a>
                </li>
                <li>
                    {(role === "owner" || role === "admin") &&
                    <a
                        className="dropdown-item"
                        onClick={() => modal?.openModal(publication.id, modalActions.history)}
                        type="button"
                    >
                        Historial
                    </a>}
                </li>
                {
                    (role === "owner" || role === "admin") &&
                    <OwnerOptions history={history} publication={publication} />
                }
                {
                    role === "broker" &&
                    <BrokerOptions
                        history={history}
                        publicationID={publication.id}
                        state={publication.state}
                    />
                }
                {
                    role === "buyer" &&
                    <BuyerOptions publication={publication} />
                }
                {
                    (role === "shipper" || role === "admin") &&
                    <ShipperOptions publication={publication} />
                }
                {
                    (role === "seller" || role === "admin") &&
                    <SellerOptions publication={publication} />
                }
                {
                    role === "admin" &&
                    <AdminOptions publication={publication} />
                }
            </ul>
        </div>
    </>;
}

export default PublicationOptions;