export const cowBreed = [ 'Mestizo / cruza', 'Raza' ].sort();
export const breedTypes = [
  'Aberdeen Angus',
  'Bobino Criollo',
  'Braford',
  'Brahman',
  'Brangus',
  'Charolais',
  'Flieckvieh Simmental',
  'Hereford',
  'Holando Argentino',
  'Jersey',
  'Limangus',
  'Limousin',
  'Piemontese',
  'Polled Hereford',
  'Retinta',
  'Santa Gertrudis',
  'Shorthorn',
  'West Highland',
  'Otra' ];
export const crossbreedData = [
  'Cruza Aberdeen Angus',
  'Cruza Bobino Criollo',
  'Cruza Braford',
  'Cruza Brahman',
  'Cruza Brangus',
  'Cruza Charolais',
  'Cruza Flieckvieh Simmental',
  'Cruza Hereford',
  'Cruza Holando Argentino',
  'Cruza Jersey',
  'Cruza Limangus',
  'Cruza Limousin',
  'Cruza Piemontese',
  'Cruza Polled Hereford',
  'Cruza Retinta',
  'Cruza Santa Gertrudis',
  'Cruza Shorthorn',
  'Cruza West Highland',
];
export const categories = [
  'MEJ',
  'Novillitos',
  'Novillos',
  'Terneras',
  'Terneros',
  'Toros',
  'Torunos',
  'Vac. c/c',
  'Vaca Cria',
  'Vacas',
  'Vaquillonas',
];
export const CUSTOM_BOX_STYLES = { boxShadow: '0px 0px 2px 0px #e1e1e1', border: 'none', background: 'transparent', borderBottom: '1px solid #e1e1e1' };
export const qualityConstant = [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10" ];
export const URL_WHATSAPP = 'https://api.whatsapp.com/send?phone=+5493515165705&text=Hola,%20quisiera%20conocer%20mas%20sobre%20Xercana.%20Gracias!';