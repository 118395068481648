import React from 'react';
import { Link } from 'react-router-dom';

import routers from '../../../config/routers';
import './LogoNavbar.css';

const LogoNavbar = ({ image }) => {
    const logoSrc = `${process.env.PUBLIC_URL}/images/${image}`;

    return (
        <Link to={routers.home.path}>
            <img className='LogoNavbar-img' src={logoSrc} alt='Logo' />
        </Link>
    );
};

export default LogoNavbar;
