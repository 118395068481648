import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import JWT from 'jsonwebtoken';

import { getComments, postCommentQuestion } from '../../api/private/client';
import './comment.css';

const Comment = ({ product }) => {

    const [datatoSend, setDatatoSend] = useState({});
    const [comments, setComments] = useState([]);
    const [uniqueComment, setUniqueComment] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingComments, setLoadingComments] = useState(false);

    useEffect(() => {
        if (product) {
            let decoded = JWT.decode(localStorage.getItem('user_type') ? localStorage.getItem('user_type') : null);
            if (decoded) {
                // Desactivamos la pregunta en caso que sea el mismo usuario creador de la publicacion.
                if (decoded.sub === product.mail) {
                    setDisabled(true);
                }
            }
            (async () => {
                const { data } = await getComments(product.id);
                setComments(data);
            })();
        }
    }, []);

    const handleChangeInput = (e) => {
        setUniqueComment(e.target.value);
        setDatatoSend({
            [e.target.name]: e.target.value,
        });
    }

    const errorQuestionModal = async () => {
        await Swal.fire({
            heightAuto: false,
            title: 'No se pudo publicar su pregunta',
            text: 'Intente nuevamente',
            icon: 'error'
        });
    }

    const successQuestionModal = async () => {
        await Swal.fire({
            heightAuto: false,
            title: 'Hemos registrado tu pregunta',
            // text: 'La misma será verfificada por un administrador; te avisaremos de su apobación',
            icon: 'success'
        });
    }

    const emptyQuestionModal = async () => {
        await Swal.fire({
            heightAuto: false,
            title: 'Atención!',
            text: 'La pregunta no puede estar vacía',
            icon: 'info'
        });
    }

    const handleSubmit = async () => {
        let memoryComments = [];
        setLoading(true);
        if (!datatoSend.question) {
            setLoading(false);
            await emptyQuestionModal();
            return;
        }
        const { data } = await postCommentQuestion(product?.id, datatoSend);
        if (data) {
            setDatatoSend({});
            setLoading(false);
            setUniqueComment('');
            await successQuestionModal();
        } else {
            await errorQuestionModal();
            setLoading(false);
        }
        setLoadingComments(true);
        memoryComments = [...comments];
        memoryComments.unshift(data);
        setComments(memoryComments);
        setUniqueComment('');
        setLoadingComments(false);
    }

    return (
        <div className='row container-comment p-4 d-flex justify-content-center'>
            <h1 className='col-12 bold text-center' style={disabled ? { marginBottom: '2.3rem' } : {}}>Preguntas y respuestas</h1>
            {
                !disabled &&
                <>
                    <div className='col-12 mb-3 d-md-flex d-lg-flex d-xl-flex mt-2'>
                        <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12 col-xs-12 mb-3 ">
                            <textarea
                                value={uniqueComment}
                                onChange={(e) => handleChangeInput(e)}
                                name="question"
                                type="text"
                                rows="1"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                placeholder='Ingrese pregunta al vendedor'>
                            </textarea>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12">
                            {!loading ? (
                                <button onClick={() => handleSubmit()} type="button" className="btn btn-success">
                                    Preguntar
                                </button>)
                                : (
                                    <button className="btn btn-success" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        Cargando...
                                    </button>
                                )}
                        </div>
                    </div>
                </>
            }

            <div className='row container-question'>
                {comments?.length === 0 ? (
                    <h2 className="w-100 text-center mt-5 font-italic">Esta publicación no tiene comentarios todavía, ¡haz el primero!</h2>
                ) : (loadingComments ? (
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border text-success" role="status" />
                    </div>
                ) : (<>
                    <h2 className='col-12 last-comments mb-5 bold'>Ultimas preguntas realizadas</h2>
                    {comments?.map((item => (
                        <div style={{ width: '91%', marginBottom: '1.8rem' }}>
                            <h3 className='col-12'>{item.question}</h3>
                            <h4 className='col-12 ml-3 answer'>{item.answer}</h4>
                            <div className="d-flex justify-content-between">
                                {item.person_question && <h5 >Pregunta de <span style={{ color: '#a5a5a5', textTransform: 'capitalize' }}>{item.person_question}</span></h5>}
                                <h5>{moment(item.date_question).format('DD/MM/YYYY')}</h5>
                            </div>
                            <hr />
                        </div>
                    )))}
                </>)

                )
                }

            </div>
        </div >
    );
}

export default Comment;
