import React from 'react'
import BottonLine from '../../../components/BottonLine';
import { RealBreadCrumb } from '../../../components/breadcrumb/Breadcrumb';
import LinkWsp from '../../../components/LinkWsp/LinkWsp';
import routers from '../../../config/routers';

import '../HelpScreen.css';

export default function SeeFacture() {   
    return (
        <div style={{minHeight:'80vh',paddingBottom:'5rem'}}>
        <h1 className="text-center w-100 font-weight-bold" style={{fontSize:'2rem',marginTop:'3rem'}}>Ver Factura</h1>
        <div style={{width:'70%',margin:'0 auto',marginTop:'1rem'}}>
            <RealBreadCrumb
                    refNames={['Ayuda ','Factura']}
                    hrefs={[routers.help.path,  '#']}
                    />
            </div>
        <div className="helpScreenContainerDiv helpIndividual">
             <div style={{display:'flex',flexDirection:'column'}}>
                <h1 className="text-center">¿Donde puedo el detalle de la compra?</h1>
                <BottonLine width="100%"/>
                <p className="p-5 mt-4 mb-1">
                En el menú superior en la sección Mis compras encontrarás la lista de publicaciones que has comprado, dentro de cada una de ellas podrás visualizar un menú que desplegará la opción <a href="http://localhost:3000/user/purchases"><b>"Ver comprobante"</b></a>
                </p>
                <span className="mb-4 text-center">El comprobante en formato PDF puede ser descargado o enviado para imprimir directamente.</span>
                <LinkWsp></LinkWsp>
            </div>
        </div>
        </div>
    )
}
