
import BottonLine from '../../../components/BottonLine';
import { RealBreadCrumb } from '../../../components/breadcrumb/Breadcrumb';
import LinkWsp from '../../../components/LinkWsp/LinkWsp';
import routers from '../../../config/routers';
import '../HelpScreen.css';

export default function Prices() {    
    return (
        <div style={{minHeight:'80vh',paddingBottom:'5rem'}}>
        <h1 className="text-center w-100 font-weight-bold" style={{fontSize:'2rem',marginTop:'3rem'}}>Precios</h1>
        <div style={{width:'70%',margin:'0 auto',marginTop:'1rem'}}>
            <RealBreadCrumb
                    refNames={['Ayuda ','Precios']}
                    hrefs={[routers.help.path,  '#']}
                    />
            </div>
        <div className="helpScreenContainerDiv helpIndividual">
             <div style={{display:'flex',flexDirection:'column'}}>
                <h1 className="text-center">¿El precio es final?</h1>
                <BottonLine width="100%"/>
                <p className="p-5 mt-4 mb-1">
                Los precios se encuentran en pesos argentinos, los mismos son fijados por el vendedor de cada publicación, los precios pueden variar dependiendo el medio de pago seleccionado.
                </p>
                <LinkWsp></LinkWsp>
            </div>
        </div>
        </div>
    )
}
