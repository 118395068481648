import ElementSwitch from '../../../../components/multipleSwitch/ElementSwitch';
import { formatPrice } from '../../../../utils/helper';
import Traslate from '../../../../utils/translation/User.json';
import './BatchSwitch.css'

export default function getBatchSwitch(mobil, product) {
    return <ElementSwitch
        showBorder={!mobil}
        labelName={Traslate.PUBLICATION_LIVE_STOCK.BATCH}>
        
        <table
            style={{ border: '1px solid #e1e1e1', borderRadius: '5px' }}
            className='table table-striped responsiveBatch'>
            <tbody>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_RACE}</td>
                    <td>{product?.batch?.race}</td>
                </tr>
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_AVG_AGE}</td>                    
                    <td>{product?.batch?.age}</td>

                </tr>
                <tr>
                    <th scope='row'></th>
                    <td className='background_td_switch'>Precio por bulto</td>
                    <td>{product?.es_bulto ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <th scope='row'></th>
                    <td className='background_td_switch'>
                        {`Precio por ${!product?.es_bulto ? 'kilogramo' : 'bulto'}`}
                    </td>
                    <td>{formatPrice(product?.offer?.price)}</td>
                </tr>
                {!product?.es_bulto ? (
                    <tr>
                        <th scope='row'></th>
                        <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_AVG_WEIGHT}</td>
                        <td>{product?.batch?.weight} Kg.</td>
                    </tr>
                ) : null}
                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_RETAILS_TYPE}</td>                    
                    <td>{product?.publication_type}</td>

                </tr>
                {product?.batch?.per_performance && (
                    <tr>
                        <th scope='row'></th>
                        <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_PER_PERFORMANCE}</td>
                        <td>{product?.batch?.per_performance}%</td>
                    </tr>
                )}
                {product?.batch?.per_rough_down && (
                    <tr>
                        <th scope='row'></th>
                        <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_PER_ROUGH_DOWN}</td>
                        <td>{product?.batch?.per_rough_down}%</td>
                    </tr>
                )}

                <tr>
                    <th scope='row'></th>
                    <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_CATEGORY}</td>
                    <td>{product?.batch?.category}</td>
                </tr>
                {!product?.es_bulto ? (
                    <tr>
                        <th scope='row'></th>
                        <td className="background_td_switch">{Traslate.PUBLICATION_LIVE_STOCK.BATCH_WHERE_WEIGHTED}</td>
                        <td>{product?.batch?.where_weighted}</td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    </ElementSwitch>;
}