import React from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

import { modalActions } from "../../Modal/PublicationsModal";
import {
  postPublicationInformDelivery,
  postPublicationInformDispatch,
} from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";

const ShipperOptions = ({ publication }) => {
  const history = useHistory();
  const { modal } = usePublicationsContext();

  const infoModal = () => {
    Swal.fire({
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  const successModal = async () => {
    return await Swal.fire({
      title: "Confirmado",
      text: "El reporte ha sido confirmado satisfactoriamente.",
      icon: "success",
    });
  };

  const errorModal = () => {
    Swal.fire({
      title: "Error",
      text: "El reporte no ha podido ser confirmado satisfactoriamente.",
      icon: "error",
    });
  };

  const handleInformDispatch = async () => {
    infoModal();
    try {
      await postPublicationInformDispatch({
        idPublication: publication.id,
      });
      await successModal();
      history.go(0);
    } catch (err) {
      errorModal();
    }
  };

  const handleInformDelivery = async () => {
    infoModal();
    try {
      await postPublicationInformDelivery({
        idPublication: publication.id,
      });
      await successModal();
      history.go(0);
    } catch (err) {
      errorModal();
    }
  };

  return (
    <>      
      {publication.state === "DteCargado" && (
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleInformDispatch()}
            type="button"
          >
            Reportar despacho
          </a>
        </li>
      )}
      {publication.state === "Despachada" && (
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleInformDelivery()}
            type="button"
          >
            Reportar entrega
          </a>
        </li>
      )}
    </>
  );
};

export default ShipperOptions;
