import { useEffect, useReducer, useState } from 'react';
import Swal from 'sweetalert2';

import {
    getCarriers,
    getCosts,
    getPay,
    getPublicationDetail,
    getUser,
    getUserEstablishments,
} from '../../api/private/client';

const initPurchase = ( { idProduct, shippingIncluded, carrier } ) => {
    return {
        idProduct,
        shippingIncluded,
        origin: null,
        destination: null,
        carrier: null,
        carriers: [],
        establishment: null,
        establishments: [],
        detail: null,
        transportaXercana: '',
        cuit_transporte: '',
        empresa_transporte: '',
        marca_transporte: '',
        chofer: '',
        patente_acoplado: '',
        patente_chasis: '',
        tipo_transporte: '',
        tiene_acoplado: false
    };
};

const errorModal = () => {
    return Swal.fire( {
        title: "Error",
        text: "Error al calcular distancia, por favor verifique el establecimiento de destino.",
        icon: "error",
    } );
};

const actionTypes = {
    setCarrier: 'SET_CARRIER',
    setCarriers: 'SET_CARRIERS',
    setCosts: 'SET_COSTS',
    setDestination: 'SET_DESTINATION',
    setDetail: 'SET_DETAIL',
    setEstablishment: 'SET_ESTABLISHMENT',
    setEstablishments: 'SET_ESTABLISHMENTS',
    setOrigin: 'SET_ORIGIN',
    setTransportaXercana: 'SET_TRANSPORTAXERCANA',
    setCuitTransporte: 'SET_CUITTRANSPORTE',
    setEmpresaTransporte: 'SET_EMPRESATRANSPORTE',
    setMarcaTransporte: 'SET_MARCATRANSPORTE',
    setChofer: 'SET_CHOFER',
    setPatenteAcoplado: 'SET_PATENTEACOPLADO',
    setPatenteChasis: 'SET_PATENTECHASIS',
    setTipoTransporte: 'SET_TIPOTRANSPORTE',
    setTieneAcoplado: 'SET_TIENEACOPLADO'
};

const purchaseReducer = ( state, action ) => {
    switch ( action.type ) {
        case actionTypes.setCarrier:
            return { ...state, carrier: action.payload };
        case actionTypes.setCarriers:
            return { ...state, carriers: action.payload };
        case actionTypes.setCosts:
            return { ...state, costs: action.payload };
        case actionTypes.setDestination:
            return { ...state, destination: action.payload };
        case actionTypes.setDetail:
            return { ...state, detail: action.payload };
        case actionTypes.setEstablishment:
            return { ...state, establishment: action.payload };
        case actionTypes.setEstablishments:
            return { ...state, establishments: action.payload };
        case actionTypes.setOrigin:
            return { ...state, origin: action.payload };
        case actionTypes.setTransportaXercana:
            return { ...state, transportaXercana: action.payload };
        case actionTypes.setCuitTransporte:
            return { ...state, cuit_transporte: action.payload };
        case actionTypes.setEmpresaTransporte:
            return { ...state, empresa_transporte: action.payload };
        case actionTypes.setMarcaTransporte:
            return { ...state, marca_transporte: action.payload };
        case actionTypes.setChofer:
            return { ...state, chofer: action.payload };
        case actionTypes.setPatenteAcoplado:
            return { ...state, patente_acoplado: action.payload };
        case actionTypes.setPatenteChasis:
            return { ...state, patente_chasis: action.payload };
        case actionTypes.setTipoTransporte:
            return { ...state, tipo_transporte: action.payload };
        case actionTypes.setTieneAcoplado:
            return { ...state, tiene_acoplado: action.payload };

        default:
            throw new Error();
    }
};


const usePurchase = ( idProduct, shippingIncluded, carrier ) => {
    const [ purchase, dispatch ] = useReducer(
        purchaseReducer,
        { idProduct, shippingIncluded, carrier },
        initPurchase
    );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ distanceError, setDistanceError ] = useState( false );

    useEffect( () => {
        ( async () => {
            const { data } = await getCarriers();
            dispatch( { payload: data, type: actionTypes.setCarriers } );
        } )();
        ( async () => {
            const { data } = await getUser();
            const address = data.address;
            const destination = { lat: address?.latitud, lng: address?.longitude };
            dispatch( { payload: destination, type: actionTypes.setDestination } );
        } )();
        ( async () => {
            const { data } = await getUserEstablishments();
            dispatch( { payload: data, type: actionTypes.setEstablishments } );
        } )();
    }, [] );


    // Aqui calculamos los costos de envio en cuanto "PURCHASE" obtiene el establecimiento de destino
    // en el segundo paso de la compra. 
    useEffect( () => {
        if ( hasEstablishment() && purchase.transportaXercana === "si" ) {
            // Si hay un establecimiento y Xercana va a transportar, se realiza el calculo de costos.
            ( async () => {
                setIsLoading( true );
                try {
                    const data = await getCosts( {
                        origin: { lat: purchase.origin[ 0 ], lng: purchase.origin[ 1 ] },
                        destination: { lat: purchase.destination[ 0 ], lng: purchase.destination[ 1 ] },
                        idTransportista: 0,
                        idEstablecimiento: purchase.establishment.id
                    } );
                    dispatch( { payload: data.data, type: actionTypes.setCosts } );
                } catch ( error ) {
                    console.log( error );
                    await errorModal();
                    setDistanceError( true );
                }
                setIsLoading( false );
            } )();
        }
        if ( hasEstablishment() && purchase.transportaXercana === "no" ) {
            // Si hay un establecimiento y Xercana NO va a transportar, no se debe sumar el costo de transporte.
            // Ya que el mismo comprador va a transportar la compra.
            ( async () => {
                setIsLoading( true );
                try {
                    const data = await getCosts( {
                        origin: { lat: purchase.origin[ 0 ], lng: purchase.origin[ 1 ] },
                        destination: { lat: purchase.destination[ 0 ], lng: purchase.destination[ 1 ] },
                        idTransportista: 0,
                        idEstablecimiento: purchase.establishment.id
                    } );
                    // Ponemos en cero los costos, ya que el mismo comprador trasladara la compra.
                    // Nos quedamos con data.cantKm para poder mostrarle al comprador la distancia.
                    data.data.chasis = 0;
                    data.data.simple = 0;
                    data.data.doble = 0;
                    dispatch( { payload: data.data, type: actionTypes.setCosts } );
                } catch ( error ) {
                    console.log( error );
                    await errorModal();
                    setDistanceError( true );
                }
                setIsLoading( false );
            } )();
        }
    }, [ purchase.establishment ] );


    const addDestination = ( latlng ) => dispatch( { payload: latlng, type: actionTypes.setDestination } );
    const addOrigin = ( latlng ) => dispatch( { payload: latlng, type: actionTypes.setOrigin } );

    const hasCarrier = () => !!purchase.carrier;
    const hasEstablishment = () => !!purchase.establishment;
    const hasTransportCosts = () => !!purchase.costs;

    const selectCarrier = ( idCarrier ) => {
        const carrier = purchase.carriers?.find( c => c.id === Number( idCarrier ) );
        dispatch( { payload: carrier, type: actionTypes.setCarrier } );
    };

    const setTransportaXercana = ( transportaXercana ) => {
        dispatch( { payload: transportaXercana, type: actionTypes.setTransportaXercana } );
    }

    const setTieneAcoplado = ( tiene_acoplado ) => {
        dispatch( { payload: tiene_acoplado, type: actionTypes.setTieneAcoplado } );
    }

    const setTipoTransporte = ( tipo_transporte ) => {
        dispatch( { payload: tipo_transporte, type: actionTypes.setTipoTransporte } );
    }

    const setPatenteChasis = ( patente_chasis ) => {
        dispatch( { payload: patente_chasis, type: actionTypes.setPatenteChasis } );
    }

    const setPatenteAcoplado = ( patente_acoplado ) => {
        dispatch( { payload: patente_acoplado, type: actionTypes.setPatenteAcoplado } );
    }

    const setChofer = ( chofer ) => {
        dispatch( { payload: chofer, type: actionTypes.setChofer } );
    }

    const setMarcaTransporte = ( marca_transporte ) => {
        dispatch( { payload: marca_transporte, type: actionTypes.setMarcaTransporte } );
    }

    const setEmpresaTransporte = ( empresa_transporte ) => {
        dispatch( { payload: empresa_transporte, type: actionTypes.setEmpresaTransporte } );
    }

    const setCuitTransporte = ( cuit_transporte ) => {
        dispatch( { payload: cuit_transporte, type: actionTypes.setCuitTransporte } );
    }

    const selectEstablishment = ( idEstablishment ) => {
        const establishment = purchase.establishments?.find( e => e.id === Number( idEstablishment ) );
        const destination = [ establishment?.lat, establishment?.lng ];
        dispatch( { payload: establishment, type: actionTypes.setEstablishment } );
        addDestination( destination );
    };

    const calculateCosts = async ( shippingCost ) => {
        setIsLoading( true );
        const { data } = await getPublicationDetail( {
            idPublication: purchase.idProduct,
            esCompra: true,
            costoEnvio: shippingCost,
            kmEnvio: purchase.costs.cantKm,
        } );
        dispatch( { payload: data, type: actionTypes.setDetail } );
        setIsLoading( false );
    };

    const getMPUrl = async () => {
        const { data } = await getPay( {
            idPublication: purchase.idProduct,
            idTransportista: purchase.carrier.id,
            // idTransportista: purchase.carrier?.id ?? 0,
            idEstablecimiento: purchase.establishment.id,
            total: purchase.detail.total,
            kmEnvio: purchase.detail.kmEnvio,
            costoEnvio: purchase.detail.costoEnvio,
        } );
        return data;
    };

    return {
        purchase,
        addOrigin,
        calculateCosts,
        getMPUrl,
        hasCarrier,
        hasEstablishment,
        hasTransportCosts,
        selectCarrier,
        selectEstablishment,
        distanceError,
        isLoading,
        setIsLoading,
        setTransportaXercana,
        setCuitTransporte,
        setEmpresaTransporte,
        setMarcaTransporte,
        setChofer,
        setPatenteAcoplado,
        setPatenteChasis,
        setTipoTransporte,
        setTieneAcoplado
    };
};

export default usePurchase;
