import React, { useEffect, useState } from 'react'
import BottonLine from '../../../components/BottonLine';
import './MovesAdminPage.css';
import moment from 'moment';
import { Fragment } from 'react';

import { getAdminHistory } from '../../../api/private/client';

const MovesAdminPage = () => {
    const [administration,setAdministration]=useState();
    const [arrayUsers,setArrayUsers]=useState([]);
    const [createPublication,setCreatePublication]=useState([]);
    const [purchases,setPurchases]=useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await getAdminHistory();
            setAdministration(data);
        })();
    }, []);

    console.log(administration);

    useEffect(()=>{
        if(administration){
            setArrayUsers(administration.filter(item=>item.entry.includes('unir')));
            setCreatePublication(administration.filter(item=>item.entry.includes('creo')))
            setPurchases(administration.filter(item=>item.entry.includes('compro')))
            console.log(purchases)
        }
        // console.log('algo');
    },[administration])
    

    return ( 
        <div  className="container_moves_page">
            <div className="container mt-5">
            <h1 className="title_moves_page">Ultimos movimientos</h1>
            <BottonLine width="90%" margintop="mx-auto"/>
            
            <div className=" container_buyandWish">
                <div className="container_buyer">
                    {/* <h1 className="text-center">Compras</h1> */}
                    <div className="card_buyer_container mx-auto">
                        {/* <h1 className="text-center">Vacas: 1</h1> */}
                        {
                        purchases.map(({date,entry})=>(
                            <Fragment>
                                <div className="container_content_new_publications">
                                    <div style={{width:'50%',textAlign:'center'}}>{moment(date).format('DD-MM-YYYY , h:mm:ss a')}</div>
                                    <div  style={{width:'50%',textAlign:'center'}}>{entry}</div>
                                </div>
                            </Fragment>
                        ))
                    }

                    </div>
                </div>
                
                {/* <div className="container_buyer">
                    <h1 className="text-center">Ventas</h1>
                    <div className="card_buyer_container">
                    </div>
                </div> */}
            </div>
            <div className="col-12 mt-5">
                <div className="w-100 title_moves_page"> Nuevas Publicaciones</div>
                <BottonLine width="90%" margintop="mx-auto"/>
                <div className="new_Publications">
                    {
                        createPublication.map(({date,entry})=>(
                            <Fragment>
                                <div className="container_content_new_publications">
                                    <div style={{width:'50%',textAlign:'center'}}>{moment(date).format('DD-MM-YYYY , h:mm:ss a')}</div>
                                    <div  style={{width:'50%',textAlign:'center'}}>{entry}</div>
                                </div>
                            </Fragment>
                        ))
                    }
                </div>
            </div>
            <div className="col-12 mt-5">
                <div className="w-100 title_moves_page"> Nuevos Usuarios</div>
                <BottonLine width="90%" margintop="mx-auto"/>
                <div className="new_Publications">
                    {
                        arrayUsers.map(({date,entry})=>(
                            <Fragment>
                                <div className="container_content_new_publications">
                                    <div style={{width:'100%', textAlign:'center'}}>{moment(date).format('DD-MM-YYYY , h:mm:ss a')}</div>
                                    <div  style={{width:'100%',textAlign:'center'}}>{entry}</div>
                                </div>
                            </Fragment>
                        ))
                    }
                </div>

            </div>
            </div>
        </div>
     );
}
 
export default MovesAdminPage;