import React from 'react';
import Container from '../../components/container/Container';

const Error500 = () => {
    return (
        <div style={{height: '400px'}}>
            <Container className='xercana-main-container'>
                <h1>Internal Server Error</h1>
                <h2>Estamos teniendo problemas internos, por favor vuelva mas tarde</h2>
            </Container>
        </div>
    )
}

export default Error500;