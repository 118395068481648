import React, { createContext, useContext, useState } from "react";

import useModal from "../hooks/useModal";

interface IPublicationsModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: (id: number, modalAction: string) => void;
  modalAction?: string;
}

interface IPublicationsContext {
  idPublication?: number;
  modal?: IPublicationsModal;
}

const PublicationsContext = createContext<IPublicationsContext>({});

const PublicationsProvider: React.FC = ({ children }) => {
  const { isOpen, openModal: open, closeModal } = useModal();
  const [idPublication, setIdPublication] = useState<number>();
  const [modalAction, setModalAction] = useState<string>();

  const openModal = (idPublication: number, action: string) => {
    setIdPublication(idPublication);
    setModalAction(action);
    open();
  };

  return (
    <PublicationsContext.Provider
      value={{
        idPublication,
        modal: { isOpen, closeModal, openModal, modalAction },
      }}
    >
      {children}
    </PublicationsContext.Provider>
  );
};

export const usePublicationsContext = () => useContext(PublicationsContext);

export default PublicationsProvider;
