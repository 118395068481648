import React from "react";

import { modalActions } from "../../Modal/PublicationsModal";
import { usePublicationsContext } from "../../../../context/PublicationsContext";

const AdminOptions = ({ publication }) => {
  const { modal } = usePublicationsContext();

  return (
    <>
      {publication.state === "Entregada" && (
        <li>
          <a
            className="dropdown-item"
            onClick={() =>
              modal?.openModal(publication.id, modalActions.liquidate)
            }
            type="button"
          >
            Liquidar
          </a>
        </li>
      )}
      <li>
          <a
            className="dropdown-item"
            onClick={() =>
              modal?.openModal(publication.id, modalActions.paymentComments)
            }
            type="button"
          >
            Otros medios de pago
          </a>
        </li>
    </>
  );
};

export default AdminOptions;
