import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/Tooltip';
import './comboBox.css';

const ComboBox = ( { label, name, className = "", value, values, placeholder, error, tooltip, onChange, register, params_register, styles, hidden } ) => {
    // eslint-disable-next-line
    const [ state, setState ] = useState( true );

    const handelChange = event => {
        setState( event.target.value === '' );
        onChange( event );
    }

    const classNameError = error ? 'is-invalid' : '';

    return (
        <div className={ `container-comboBox ${ className } ${ classNameError }` } hidden={ hidden }>
            <h3>{ label }{ tooltip ? <Tooltip msj={ tooltip } /> : null }</h3>

            {/* <div className='select-div d-flex flex-row-reverse align-items-center'> */ }
            <div className="form-group">
                <select name={ name } value={ value } onChange={ handelChange } ref={ register ? register( params_register ) : null }
                    style={ styles ? styles : {} }
                    // className={ state ? 'placeholder col' : 'col' }
                    className={ `form-control ${ classNameError }` }
                >
                    <option value=''>{ placeholder }</option>
                    { values.map( ( value ) => (
                        <option key={ value } value={ value }>{ value }</option>
                    ) ) }
                </select>
                {/* <i className='fas fa-angle-down' /> */ }
            </div>
        </div>
    )
}

ComboBox.propType = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    values: PropTypes.arrayOf( PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ) ).isRequired,
    callback: PropTypes.func.isRequired,
    name: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool
}

ComboBox.defaultProps = {
    values: [],
    error: null,
    readOnly: false,
    onChange: () => null
}

export default ComboBox;