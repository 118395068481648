import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import routers from '../config/routers';
import { useAuthContext } from '../context/AuthenticatedContext';

const OAuth2RedirectHandler = () => {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const error = query.get('error');

    const { logIn } = useAuthContext();

    if (token) {
        logIn(token);
        return <Redirect to={routers.home.path} />;
    }

    return <Redirect to={{ path: routers.join.path, state: { error: error } }} />;
};

export default OAuth2RedirectHandler;
