import { useState, useEffect } from 'react';
import BottonLine from '../../components/BottonLine';
import './Dashboard.css';
import useWindowSize from '../../hooks/useWindows';
import { DownArrow } from '../../components/Icons/Icons';
import { Link } from 'react-router-dom';
import JWT from 'jsonwebtoken';

import { getQuestionsToAnswer, getUserPurchases, getUserSales } from '../../api/private/client';

const Dashboard = () => {
    const size = useWindowSize();
    // eslint-disable-next-line
    const [mobil, setMobil] = useState(false);
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const [nameUser, setNameUser] = useState(null);
    // eslint-disable-next-line
    const [mail, setMail] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        if (localStorage.getItem('user_type')) {
            let decoded = JWT.decode(localStorage.getItem('user_type'))
            const { name, sub } = decoded;
            setNameUser(name);
            setMail(sub);
        }
        (async () => {
            const { data } = await getQuestionsToAnswer();
            setQuestions(data);
        })();
        (async () => {
            const { data } = await getUserSales();
            setSales(data);
        })();
        (async () => {
            const { data } = await getUserPurchases();
            setPurchases(data);
            setIsLoading(false);
        })();
    }, [])

    useEffect(() => {
        if (size.width < 500) {
            setMobil(true);
        } else {
            setMobil(false);
        }
        console.log(questions);

    }, [size])


    return (
        <div className='row '>
            <div className="col container_dashboard_main">
                <div className="alert alert-success mt-5">
                    <h1 className="text-center bold">Bienvenido/a {nameUser}, este es el resumen de tu cuenta</h1>

                </div>
                {isLoading ? (
                    <>
                        <div className="d-flex justify-content-center mt-5">
                            <h3 className='text-center'>Por favor aguarda mientras recopilamos tu información</h3>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="dashboard_Titles">
                            <div className="row d-flex justify-content-between">
                                <h2 className="mb-3 bold">Total de publicaciones:</h2>
                                <h2 className='mb-3 bold text-right'>{sales.cant_publicaciones}</h2>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <h2 className="mb-3 bold">Total de compras:</h2>
                                <h2 className='mb-3 bold text-right'>{purchases.cant_compras}</h2>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <h2 className="mb-3 bold">Total de Ventas:</h2>
                                <h2 className='mb-3 bold text-right'>{sales.cant_ventas}</h2>
                            </div>
                        </div>
                        <div className="dashboard_yourPanel" >
                            <div className="dashboard_yourpanel_container" style={{ width: '45%' }}>
                                {/* <p>Tus pendientes</p> */}
                                <div style={{ borderRadius: '10px', backgroundColor: '#FFF', boxShadow: '0px 0px 4px 0px #f3f3f3', padding: '2rem' }}>
                                    <h1 style={{ fontWeight: 'bold' }}>Contactos por responder</h1>
                                    <BottonLine width="100%" />
                                    {questions.length === 0 ? (
                                        <p>No tienes preguntas para responder</p>
                                    ):(
                                        <Link to={{
                                            pathname: "/user/comments",
                                            state: {
                                                questions: questions
                                            }
                                        }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <div><p>Preguntas sin responder</p></div>
                                            <div><p>{questions.length} <DownArrow className="rotate90 mb-1" /> </p></div>
                                        </Link>
                                    )}                                    
                                </div>
                            </div>
                            <div className="dashboard_yourpanel_container" style={{ width: '45%' }}>
                                <div style={{ borderRadius: '10px', backgroundColor: '#FFF', boxShadow: '0px 0px 4px 0px #f3f3f3', padding: '2rem' }}>
                                    <h1 style={{ fontWeight: 'bold' }}>Publicaciones</h1>
                                    <BottonLine width="100%" />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <div><p>Publicaciones activas</p></div>
                                        <div><p>{sales.cant_publicaciones}</p></div>
                                    </div>
                                    <BottonLine width="100%" />
                                    <Link to="/user/publications" style={{ color: "#3483fa", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <div><p>Ver publicaciones activas</p></div>
                                        <div><p><DownArrow className="rotate90 mb-1" /> </p></div>

                                    </Link>
                                </div>
                            </div>
                        </div>                        
                    </>
                )}
            </div>

        </div>
    )
}

export default Dashboard;