import React, { useEffect, useState } from 'react'
import BottonLine from '../../../components/BottonLine';
import './ModerateCommentsPage.css';
import { getQuestionsToApprove, postAnswerApprove, postQuestionApprove } from '../../../api/private/client';
import Swal from 'sweetalert2';
import moment from 'moment';

//Pagina temporal, para que en una primera etapa, 
//el administrador modere las preguntas y respuestas que se hacen en las publicaciones.


const ModerateCommentsPage = () => {

    const [comments, setComments] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState();

    
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const { data } = await getQuestionsToApprove();
            setComments(data);
            setIsLoading(false);
        })();
    }, []);

    useEffect(()=>{
        if(setComments){
            setQuestions(comments.filter(item=>item.answer === null));
            setAnswers(comments.filter(item=>item.answer !== null));            
        }
    },[comments])

    const handleApprove = async (id, type, approve) => {
        let accion = (approve) ? 'aprobar' : 'desaprobar';
        let textConfirm = (approve) ? 'Una vez aprobado no podrás volver atrás y será visible para todos los usuarios registrados.'
            : 'Una vez rechazado no podrás volver atrás y no será visible para los usuarios.'
        
        Swal.fire({
            title: `¿Estás seguro de ${accion} el comentario?`,
            text: textConfirm,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#22B24C',
            cancelButtonColor: '#e4a5a5',
            confirmButtonText: `Si, ${accion}!`
        }).then(async (result) => {
            if (result.isConfirmed) {    
                
                Swal.fire({
                    html: 
                    '<div class="spinner-border" role="status">' +                
                    '</div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                
                let data;

                const toSend = {
                    id: id,
                    approve: approve
                };

                if(type === "question"){
                    data = await postQuestionApprove(toSend);
                }
                if(type === "answer"){
                    data = await postAnswerApprove(toSend);
                }

                if (data.data.filas === 1) {
                    if(approve){
                        await Swal.fire({
                            title: 'Aprobado!',
                            text: 'El comentario fue aprobado correctamente',
                            icon: 'success'                        
                        });
                    }else{
                        Swal.fire({
                            title: 'Desaprobado!',
                            text: 'El comentario fue desaprobado correctamente',
                            icon: 'success'                        
                        });
                    }
                    if(type === "question"){
                        setQuestions(questions.filter((item) => item.id !== id));
                    }else{
                        setAnswers(answers.filter((item) => item.id !== id));
                    }
                                       
                }else{
                    Swal.fire({
                        title: 'Se ha producido un error',
                        text: 'No pudo ejecutarse la acción. Intente nuevamente.',
                        icon: 'error'                        
                    });
                }
            }
        })
    }
    

    return ( 
        <div  className="container_moves_page">
            <div className="container mt-5">
                <h1 className="title_moves_page">Preguntas para aprobar</h1>
                <BottonLine width="90%" margintop="mx-auto"/>
                
                <div className=" container_buyandWish">
                    {isLoading ? (
                        <div className='ProfilePage-spinner my-4'>
                            <div className='spinner-border' role='status' />
                        </div>
                    ) : (
                    <div className="container_buyer">
                        <div className="card_buyer_container mx-auto">
                            {
                            questions.map((question)=>(
                                <>
                                    <div key={question.id} className="container_content">
                                        <div className="row">
                                            <div>
                                                <b>{moment(question.date_question).format('DD/MM/YYYY')}</b> - El usuario 
                                                <b> {question.question_name}</b> preguntó en la publicación 
                                                <b> #{question.publication_id} - {question.title}:</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='question_container'>
                                                {question.question}
                                            </div>
                                        </div>                                    
                                        <div className="row mt-2">
                                            <button className='btn btn-outline-success btn-sm mr-3' 
                                                onClick={()=> handleApprove(question.id, "question", true)}>
                                                Aprobar
                                            </button>
                                            <button className='btn btn-outline-primary btn-sm'
                                                onClick={()=> handleApprove(question.id, "question", false)}>
                                                Desaprobar
                                            </button>
                                        </div>
                                            
                                    </div>
                                </>
                            ))
                        }
                        </div>
                    </div>
                    )}
                </div>                
                <div className="title_moves_page mt-5">Respuestas para aprobar</div>
                <BottonLine width="90%" margintop="mx-auto"/>
                <div className=" container_buyandWish">
                    {isLoading ? (
                        <div className='ProfilePage-spinner my-4'>
                            <div className='spinner-border' role='status' />
                        </div>
                    ) : (
                    <div className="container_buyer">
                        <div className="card_buyer_container mx-auto">
                            {
                                answers.map((answer)=>(
                                    <>
                                    <div key={answer.id} className="container_content">
                                        <div className="row">
                                            <div>
                                                <b>{moment(answer.date_answer).format('DD/MM/YYYY')}</b> - El usuario 
                                                <b> {answer.answer_name}</b> respondió en la publicación 
                                                <b> #{answer.publication_id} - {answer.title}:</b>
                                            </div>
                                        </div>
                                        <div className="card my-2">{answer.question}</div>
                                        <div className="row">
                                            <div className='question_container'>
                                                {answer.answer}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <button className='btn btn-outline-success btn-sm mr-3' 
                                                onClick={()=>handleApprove(answer.id, "answer", true)}>
                                                Aprobar
                                            </button>
                                            <button className='btn btn-outline-primary btn-sm mr-3'
                                                onClick={()=>handleApprove(answer.id, "answer", false)}>
                                                Desaprobar
                                            </button>                                                                                        
                                        </div>                                                                           
                                    </div>
                                </>
                                ))
                            }
                        </div>
                    </div>
                    )}
                </div>                                
            </div>
        </div>
     );
}

export default ModerateCommentsPage;