import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import routers from "../../../config/routers";

import BottonLine from '../../../components/BottonLine';
import Container from '../../../components/container/Container';
import Translate from '../../../utils/translation/Admin.json';
import './PhoneValidationStepOne.css';
import { sendSmsCode, checkSmsCode } from '../../../api/private/client';


const PhoneValidationStepOne = (props) => {

  const isEditing = props.history.location.state?.isEditing

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [mensajeEnviado, setMensajeEnviado] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');

  const [code, setCode] = useState('');
  const [request_id, setRequest_id] = useState('');
  const [smsTo, setSmsTo] = useState('');

  const r = routers;


  const handleSubmit = async e => {
    setSmsLoading(true);
    e.preventDefault();
    // Cargamos el destinatario, para poder enviarlo despues a la hora de checkear el codigo.
    setSmsTo(phoneNumber);
    const toSend = {
      smsTo: phoneNumber,
    }
    try {
      const { data } = await sendSmsCode(toSend);
      if (data?.status === 'OK') {
        sendSmsOkModal();
        setSmsLoading(false);
        setRequest_id(data.requestId);
        setTimeout(() => {
          setMensajeEnviado(true);
        }, 2000)
      }
    } catch (error) {
      setSmsLoading(false);
      errorModal();
      console.log(error);
      //throw new Error('Hubo un problema');
    }
  };

  const handleCheck = async e => {
    setSmsLoading(true);
    e.preventDefault();
    const toSend = {
      requestId: request_id,
      code: code,
      smsTo: smsTo
    }
    try {
      const { data } = await checkSmsCode(toSend);
      if (data?.status === 'OK') {
        checkCodeSuccessfullyModal();
        setSmsLoading(false);
        setTimeout(() => {
          setSmsLoading(false);
          if (isEditing) history.push(r.home.path);
          if (!isEditing) history.push(r.user_register_data.path);
        }, 3000)
      } else {
        codeErrorModal();
        setSmsLoading(false);
      }
    } catch (error) {
      setSmsLoading(false);
      codeErrorModal();
      console.log(error);
      // throw new Error('Hubo un problema');
    }
  };

  const handlePhoneNumber = ({ target }) => {
    setPhoneNumber(target.value);
  }

  const handleCode = ({ target }) => {
    setCode(target.value);
  }

  const handleCancel = () => {
    history.push(r.home.path);
  };

  const errorModal = async () => {
    await Swal.fire({
      title: "El mensaje no ha podido ser enviado",
      text: "Por favor, verifique el número ingresado",
      icon: "error",
      confirmButtonColor: "red",
      confirmButtonText: "Ok",
    })
  }

  const codeErrorModal = async () => {
    await Swal.fire({
      title: "Verificación fallida",
      text: "Por favor, verifique el código ingresado",
      icon: "error",
      confirmButtonColor: "red",
      confirmButtonText: "Ok",
    })
  }

  const sendSmsOkModal = async () => {
    await Swal.fire({
      title: "Código  enviado!!",
      text: "Hemos enviado el código de validación a " + phoneNumber + ", por favor revise sus mensajes de texto.",
      icon: "success",
      confirmButtonColor: "green",
      confirmButtonText: "Ok",
    })
  }

  const checkCodeSuccessfullyModal = async () => {
    await Swal.fire({
      title: "Código verificado exitosamente",
      text: isEditing ? 'Muchas gracias por registrar su número telefónico.' : 'Por favor, necesitamos que complete el siguiente formulario.',
      icon: "success",
      confirmButtonColor: "green",
      confirmButtonText: "Ok",
    })
  }


  return (
    <div className='responsive_custom_container'>
      <Container className='ProfilePage-container xercana-main-container'>
        <h1 className='profil-titles col-12'>Validación de número telefónico</h1>
        <BottonLine width='80%' margintop='mx-auto mb-4 mt-2' />
        {isLoading ? (
          <div className='ProfilePage-spinner'>
            <div className='spinner-border' role='status' />
          </div>
        ) : (
          <>
            {!mensajeEnviado ? (
              <div className="text-center alert alert-info" role="alert">
                Para poder operar, primero necesitamos validar su número de teléfono celular.{<br></br>}
                Por favor ingrese su número y le enviaremos un código por SMS para poder verificarlo.
              </div>) : null}
            {/* <ProfileStatus enabled={profile.enabled} /> */}
            <div className='d-flex justify-content-center '>
              <form onSubmit={mensajeEnviado ? handleCheck : handleSubmit} method='post'>

                {!mensajeEnviado ? (
                  <>
                    <div className='form-group'>
                      <label htmlFor='phone_number'>
                        <small><strong>Número de teléfono (sin 0 y sin 15)</strong></small>
                      </label>
                      <input
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                        }}
                        type='number'
                        onChange={e => handlePhoneNumber(e)}
                        maxLength="10"
                        id='phone_number'
                        value={phoneNumber}
                        className='form-control'
                        name='phone.number'
                        placeholder='Ej: 3516542733'
                        required={true}
                      />
                    </div>

                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn btn-outline-secondary mr-2'
                        onClick={handleCancel}
                        type='button'
                      >
                        {Translate.COMMON.CANCEL}
                      </button>
                      <button
                        className='btn btn-success'
                        type='submit'
                        disabled={smsLoading}
                      >
                        {Translate.COMMON.SEND_PIN}
                      </button>
                    </div>
                    {smsLoading ? (
                      <div className='ProfilePage-spinner mt-3'>
                        <div className='spinner-border' role='status' />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <div className='form-group'>
                      <h3>
                        <strong>Ingrese el código recibido por SMS</strong>
                      </h3>
                      <input
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                        }}
                        type='number'
                        onChange={e => handleCode(e)}
                        maxLength={4}
                        id='code'
                        value={code}
                        className='form-control mt-3'
                        name='code'
                        placeholder='Ej: 4053'
                        required={true}
                      />
                    </div>

                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn btn-outline-secondary mr-2'
                        onClick={handleCancel}
                        type='button'
                      >
                        {Translate.COMMON.CANCEL}
                      </button>
                      <button
                        className='btn btn-success'
                        type='submit'
                        disabled={smsLoading}
                      >
                        {Translate.COMMON.CHECK_PIN}
                      </button>
                    </div>
                    <div className='d-flex justify-content-center mt-4'>
                      <small><strong>
                        <a href='#' onClick={() => setMensajeEnviado(false)} className="link-info">No recibí el código</a>
                      </strong></small>
                    </div>
                    {smsLoading ? (
                      <div className='ProfilePage-spinner mt-3'>
                        <div className='spinner-border' role='status' />
                      </div>
                    ) : null}
                  </>
                )}

              </form>
            </div>
          </>
        )}
      </Container>
    </div >
  );
};

export default PhoneValidationStepOne;
