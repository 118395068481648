import React from 'react';
import { Route } from 'react-router-dom';

import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props =>
            <>
                <Navbar />
                <Component {...props} />
                <Footer />
            </>
        } />
    );
}

export default PublicRoute;
