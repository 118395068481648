import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

import { postLiquidate } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";

interface LiquidateForm {
  idPublication: number;
  sale: FileList;
  purchase: FileList;
}

const AdminModal: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<LiquidateForm>();
  const { modal, idPublication } = usePublicationsContext();
  const history = useHistory();

  const infoModal = () => {
    Swal.fire({
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  };

  const successModal = async () => {
    return await Swal.fire({
      title: "Datos enviados",
      text: "Liquidación concretada satisfactoriamente.",
      icon: "success",
    });
  };

  const errorModal = () => {
    Swal.fire({
      title: "Error.",
      text: "Ha ocurrido un error al momento de cargar la liquidación.",
      icon: "error",
    });
  };

  const onSubmit = async (data: LiquidateForm) => {
    infoModal();
    try {
      await postLiquidate({
        id: idPublication,
        sale: data.sale[0],
        purchase: data.purchase[0],
      });
      await successModal();
      modal?.closeModal();
      history.go(0);
    } catch (err) {
      errorModal();
    }
  };

  return (
    <>
      <h2 className="d-flex justify-content-center">
        Liquidar
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="sale">
            Venta
          </label>
          <input
            id="sale"
            className={`form-control-file ${errors.sale && "is-invalid"}`}
            name="sale"
            ref={register({ required: true })}
            type="file"
          />
          <div className="invalid-feedback">
            La liquidación de venta es requerida.
          </div>
        </div>
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="purchase">
            Compra
          </label>
          <input
            id="purchase"
            className={`form-control-file ${errors.purchase && "is-invalid"}`}
            name="purchase"
            ref={register({ required: true })}
            type="file"
          />
          <div className="invalid-feedback">
            La liquidación de compra es requerida.
          </div>
        </div>
        <button type="submit">
          Enviar
        </button>
      </form>
    </>
  );
};

export default AdminModal;
