import React from 'react';
import { Redirect, Route } from 'react-router';

import routers from './routers';
import Footer from '../components/footer/Footer';
import { useAuthContext } from '../context/AuthenticatedContext';
import NavbarAdmin from '../views/Admin/Navbar/NavbarAdmin';

const AdminRoute = ({ component: Component, ...rest }) => {
    const { isAdmin, isLoading, isLogged  } = useAuthContext();

    if (isLoading) {
        return null;
    }

    if (!isLogged()) {
        return <Redirect to={routers.join.path} />;
    }

    return (
        isAdmin() ?
            <Route {...rest} render={props =>
                <>
                    <NavbarAdmin />
                    <Component {...props} />
                    <Footer />
                </>
            } /> :
            <Redirect to={routers.admin_login.path} />
    );
};

export default AdminRoute;
