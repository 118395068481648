
import { URL_WHATSAPP } from "../../views/Dashboard/Publication/constants";
import "./BtnWsp.css";

const IC_WHATSAPP = `${process.env.PUBLIC_URL}/ic_wsp.png`;

const BtnWsp = () => {

  const handleClick = () => {
    window.open(URL_WHATSAPP, "_blank").focus();
  };

  return (
    <div onClick={handleClick} className="container_BtnWsp">
      <img src={IC_WHATSAPP} alt="" height="100%" width="100%" />
    </div>
  );
};

export default BtnWsp;
