import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import routers from "../../../../config/routers";

import EstablishmentCard from './EstablishmentCard';
import { useStepperContext } from '../../../../components/StepperModal/StepperModal';
import './LocationStep.css';

const LocationStep = ( { context } ) => {
    const { purchase, hasEstablishment, selectEstablishment, hasTransportCosts, isLoading, distanceError } = context;

    const history = useHistory();

    const { handleBack } = useStepperContext();

    const handleEstablishment = e => {
        selectEstablishment( e.target.value );
    };

    useEffect( () => {
        ( distanceError ) && history.push( routers.user_register_data.path );
    }, [ distanceError ] )


    return <>
        <div className='container'>
            <h2 className='d-flex justify-content-center'>
                Elegir ubicación
            </h2>
            <div className='form-group'>
                <label className='col-form-label' htmlFor='id_establishment'>
                    Establecimiento
                </label>
                <select
                    id='id_establishment'
                    className='form-control'
                    value={ purchase.establishment?.id }
                    onChange={ handleEstablishment }
                >
                    <option value=''>Seleccione un establecimiento...</option>
                    { purchase.establishments.map( e =>
                        <option key={ e.id } value={ e.id }>
                            { e.nombre }
                        </option>
                    ) }
                </select>
            </div>
            <div className='d-flex gap-2 justify-content-end'>
                <button
                    className='btn btn-primary mx-4'
                    onClick={ handleBack }
                    type='button'
                >
                    Atrás
                </button>
                { !isLoading
                    ?
                    ( <button className='btn btn-info' type='submit' disabled={ !hasTransportCosts() }>
                        Siguiente
                    </button> )
                    :
                    ( <button className="btn btn-info" type="button" disabled>
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Cargando...
                    </button> ) }

            </div>
            { hasEstablishment() ? <EstablishmentCard establishment={ purchase.establishment } /> : null }
        </div>
    </>
};

export default LocationStep;
