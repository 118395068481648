import React, { useState, useEffect, useRef } from 'react';
import Proptype from 'prop-types';
import useWindowSize from '../../hooks/useWindows';

import { Carousel } from 'react-responsive-carousel';
import Card from '../card/Card';

import './scroll.css';

/**
 * @param data array of object. with id, title, subtitle, img and array detail 
 */
const Scroll = ({ data, colorArrow="#1275D8",className="", scrollTop="false"}) => {

    const [elements, setElements] = useState(4);
    const [mobil, setMobil] = useState(false);
    const [useHover, setHover] = useState(false);

    const size = useWindowSize();

    const stageCanvasRef = useRef(null);

    useEffect(() => {

        let width;

        if (stageCanvasRef.current) {
            width = stageCanvasRef.current.offsetWidth;
        }

        setMobil(width < 600);
        
        if(width > 320){
            setElements(2);
        }else{
            setElements(1);
        }

    }, [size])
    // console.log(elements);
    return (
        <div className='col-12' ref={stageCanvasRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Carousel
                showArrows={useHover ? !mobil : false}
                showStatus={false}
                showThumbs={false}
                emulateTouch={true}
                infiniteLoop={false}
                showIndicators={false}
                autoPlay={false}
                centerSlidePercentage={mobil ? 40 : 100}
                renderArrowNext={
                    (onClickHandler, hasPrev) =>
                        hasPrev && (
                            <button type="button" className='arrow' onClick={onClickHandler} style={{ right: '10px',top:'90px' }}>
                                <i className='fa fa-chevron-right' style={{color:colorArrow}}></i>
                            </button>
                        )
                }
                renderArrowPrev={
                    (onClickHandler, hasPrev) =>
                        hasPrev && (
                            <button type="button" className='arrow' onClick={onClickHandler} style={{ left: '10px',top:'90px',color:colorArrow}}>
                                <i className='fa fa-chevron-left' style={{color:colorArrow}}></i>
                            </button>
                        )
                }
            >
                {BuildWidget(data, elements,className,scrollTop)}
            </Carousel>
        </div>
    );
}
Scroll.PropType = {
    data: Proptype.object.isRequired
}

export default Scroll;

const BuildWidget = (data, elements,className,scrollTop) => data ? ScrollWithData(data, elements,className,scrollTop) : ScrollWithoutData(elements);

function ScrollWithData(data, elements,className,scrollTop) {

    let widgetResponse = [];
    let listOfList = [];
    let listOfElements = [];
    const iterations = Math.ceil(data.length / elements);
    data.forEach((d, i) => {
        listOfElements.push(
            (
                <div key={d.id} className={(`col-${parseInt(12 / elements)}`)} style={{ paddingBottom: '10px' }}>

                    <Card
                        href={`/publication/view/${d.id}`}
                        key={d.id}
                        publicationId={d.id}
                        title={d.title}
                        subtitle={d.price}
                        img={d.photo}
                        detailList={d.detail}
                        state={d.state}
                        expiration={d.expiration_date}
                    />
                </div>
            )
        );
        if (((i + 1) % elements === 0 && i !== 0) || elements === 1) {
            listOfList.push([...listOfElements]);
            listOfElements = [];
        }
        if (i === data.length - 1)
            listOfList.push([...listOfElements]);
    });

    for (let i = 0; i < iterations; i++) {
        widgetResponse.push(
            (
                <div key={i} className={`d-flex  row ${className}`} style={{ paddingLeft: '6%', paddingRight: '6%',width:"100%" }}>

                    {listOfList[i]}
                </div>
            )
        );
    }
    return widgetResponse;
}

function ScrollWithoutData(elements) {
    let listOfElements = [];
    for (let i = 0; i < elements; i++) {
        listOfElements.push(
            <div key={i} className={`col-${parseInt(12 / elements)}`} style={{ paddingBottom: '10px' }}>
                <Card preloader={true} />
            </div>
        );
    }

    return (
        <div className='d-flex justify-content-center row' style={{ paddingLeft: '6%', paddingRight: '6%' }}>
            {listOfElements.map(e => e)}
        </div>
    );
}
