// React
import React, { useState } from "react";

import { usePublicationsContext } from "../../../../../context/PublicationsContext";
import { modalActions } from "../../../Modal/PublicationsModal";

// Components

import QualificationModal from "./modal/QualificationModal";

/**
 * @brief Opciones de las publicaciones que el usuario
 * fue asignado como corredor.
 *
 * @param {useHistory} history useHistory de react-router
 * @param {int} publicationID la publicacion
 */
function BrokerOptions({ history, publicationID, state }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { modal } = usePublicationsContext();

  return (
    <>
      <QualificationModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        publicationID={publicationID}
        history={history}
      >
      </QualificationModal>
      {state !== "Calificada"
        ? (
          <li key={"qualify"}>
            <a
              type={"button"}
              className="dropdown-item"
              onClick={() =>
                modal?.openModal(publicationID, modalActions.qualification)}
            >
              Calificar
            </a>
          </li>
        )
        : (
          <li key={"qualify"}>
            <a type={"button"} className="dropdown-item disabled">Calificar</a>
          </li>
        )}
    </>
  );
}

export default BrokerOptions;
