import React, { useContext, useState } from 'react';
import Modal from 'react-modal';

import './StepperModal.css';

const StepperContext = React.createContext();

export const useStepperContext = () => useContext(StepperContext);

const StepperModal = ({ isOpen, closeModal, steps, context }) => {
    const n_steps = steps.length;

    const [step, setStep] = useState(0); // current step

    const handleBack = () => {
        if (step !== 0) setStep(prevStep => --prevStep);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (step !== n_steps - 1) setStep(prevStep => ++prevStep);
        else console.log('enviar');
    };

    return <div className=''>
        <StepperContext.Provider value={{ step, setStep, handleBack, handleSubmit }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
            >
                <div className='d-flex justify-content-end mb-3'>
                    <i
                        className='far fa-times-circle StepperModal-btn-close'
                        onClick={closeModal}
                    />
                </div>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-around'>
                        {steps.map(({ id, title }, index) =>
                            <div className='d-flex flex-column align-items-center' key={id}>
                                <div
                                    className={`StepperModal-circle ${step === id ? 'StepperModal-active' : ''}`}
                                >
                                    {index + 1}
                                </div>
                                <p className='d-none d-lg-block'>
                                    {title}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <form onSubmit={handleSubmit}>
                            {steps.map(({ component: Component, id }) =>
                                step === id ? <Component key={id} context={context} /> : null)}
                        </form>
                    </div>
                </div>
            </Modal>
        </StepperContext.Provider>
    </div>
};

export default StepperModal;
