import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../utils/GlobalColors.json';

import Tooltip from '../tooltip/Tooltip';
import './CustomInput.css';

/**
 * @description: componentse de tipo Input, con imagen
 * @param callback: handleChange
 * @param icon: string del nombre del icono, por ejemplo: photo
 * @param tooltip: es un string, es para generar texto de ayuda.
 */
export const CustomInput = ({
    id,
    label,
    value,
    className = "",
    placeholder,
    name, register,
    params_register,
    icon,
    tooltip,
    error,
    readonly,
    type,
    onChange,
    defaultValue,
    step,   
    min,
    mb = "mb-3",
    maxLength,
    hidden
}) => {
    const colorReadOnly = readonly ? { color: Colors["light-colors"]["--dark-gray"] } : null;

    return (
        <div className={`input-group ${mb} ${className}`} style={colorReadOnly}>
            {label ? (
                <h3 className={error ? 'input-label-error' : ''}>
                    {label}{tooltip ? <Tooltip msj={tooltip} /> : null}
                </h3>
            ) : null}
            <div className={`row container-icon ${error ? 'input-error' : ''}`}>
                {type === 'text-area' ? (
                    <textarea
                        hidden={hidden}
                        id={id}
                        type={type}
                        step={step}
                        min={min ? min : ''}
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={register ? register(params_register) : null}
                        className={`form-control customImput__RebuildPublication`}
                        aria-label='small'
                        style={{colorReadOnly, border: '1px solid #e1e1e1', background: 'transparent', boxShadow:'0px 0px 2px 0px #e1e1e1' }}
                        placeholder={placeholder}
                        readOnly={readonly}
                        onChange={onChange}
                        rows = "2"
                    />
                ) : type === 'number' ? (
                    <input
                        hidden={hidden}
                        id={id}
                        type='number'
                        step={step}
                        min='1'
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={register ? register(params_register) : null}
                        className='form-control customImput__RebuildPublication'
                        aria-label='small'
                        style={{ colorReadOnly, border: '1px solid #e1e1e1', background: 'transparent' }}
                        placeholder={placeholder}
                        readOnly={readonly}
                        onChange={onChange}
                        pattern='^([0-9]*[.])?[0-9]+'
                    />
                ) : (
                    <input
                        hidden={hidden}
                        id={id}
                        type={type}
                        step={step}
                        min={min ? min : ''}
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        ref={register ? register(params_register) : null}
                        className='form-control customImput__RebuildPublication'
                        aria-label='small'
                        style={{colorReadOnly, border: '1px solid #e1e1e1', background: 'transparent' }}
                        placeholder={placeholder}
                        readOnly={readonly}
                        onChange={onChange}
                        maxLength={maxLength}
                    />
                )}
                {icon ? (
                    <i className={`d-flex flex-row-reverse fa fa-${icon} col-1 -icon`} />
                ) : null}
            </div>
        </div>
    );
};

CustomInput.propType = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    callback: PropTypes.func,
    icon: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool
};

CustomInput.defaultProps = {
    type: 'text',
    icon: null,
    readonly: false,
    hidden:false
};
