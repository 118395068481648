import React from 'react';

const googleLogo = `${ process.env.PUBLIC_URL }/images/google-logo.png`;
const LoginAdmin = () => {
    return (
        <div className="col-12 w-100 vh-100 ">
            <h1 className="text-center mt-5 font-weight-bold">Esta sección es solo para un administrador</h1>
            <p className="mt-5 text-center">Si usted es un administrador por favor inicie sesión aquí</p>
            <div className="col-12 col-lg-6 mt-5 col-md-6 col-xl-6 text-center mx-auto">
                <a className="mx-auto btn btn-block social-btn google" href={ process.env.REACT_APP_GOOGLE_AUTH_URL }>
                    <img src={ googleLogo } alt="Google" /> Ingresar con google</a>
                {/* <GoogleLoginComponent /> */ }
            </div>
        </div>
    )

}

export default LoginAdmin;