import { URL_WHATSAPP } from "../../views/Dashboard/Publication/constants";


const IC_WHATSAPP = `${process.env.PUBLIC_URL}/ic_wsp.png`;

const LinkWsp = () => {

    const handleClick = () => {
        window.open(URL_WHATSAPP, "_blank").focus();
    };

    return (

        <div className='d-flex justify-content-center'>
            <button type="button" className="btn btn-outline-success d-flex justify-content-center" onClick={handleClick}>
                <img src={IC_WHATSAPP} alt="WSPLOGO" height="30px" width="30px"/>
                Quiero hablar con un representante
            </button>
        </div> 
    );
    };

export default LinkWsp;