import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.css';

export const BreadTitle = ({ label }) => {
    return (
        <div className='breadcrumb-container'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <h1 className='breadcrumb-item'>{label}</h1>
                </ol>
            </nav>
        </div>
    )
}

export const RealBreadCrumb = ({ hrefs = [], refNames = [] }) =>
    <div className='breadcrumb-container col-12'>
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
                {
                    hrefs.map((item, index) => {
                        return (hrefs.length - 1) !== index ?
                            <Fragment key={index}>
                                <li className="breadcrumb-item"><Link to={item}>{refNames[index]}</Link></li>
                                {/* <h5>|</h5> */}
                            </Fragment>
                            :
                            <li className="breadcrumb-item" key={index}><Link className="activecrumb" to={item}>{refNames[index]}</Link></li>
                        }
                    )
                }
            </ol>
        </nav>
    </div>
