
export const AuctionsTabs = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="border shadow rounded" style={{ width: '75%' }}>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button 
                className="nav-link active border" 
                id="beneficios-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#beneficios-tab-pane" 
                type="button" 
                role="tab" 
                aria-controls="beneficios-tab-pane" 
                aria-selected="true"
              >
              <h5>Beneficios de Preofertas</h5>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className="nav-link border" 
                id="condiciones-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#condiciones-tab-pane" 
                type="button" 
                role="tab" 
                aria-controls="condiciones-tab-pane" 
                aria-selected="false"
              >
              <h5>Condiciones comerciales</h5>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className="nav-link border" 
                id="sani-toros-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#sani-toros-tab-pane" 
                type="button" 
                role="tab" 
                aria-controls="sani-toros-tab-pane" 
                aria-selected="false"
              >
              <h5>Sanidad Toros</h5>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className="nav-link border" 
                id="sani-vaqui-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#sani-vaqui-tab-pane" 
                type="button" 
                role="tab" 
                aria-controls="sani-vaqui-tab-pane" 
                aria-selected="false"
              >
              <h5>Sanidad Vaquillonas</h5>
              </button>
            </li>
          </ul>
          <div className="tab-content m-2 p-1" id="myTabContent">
            <div 
              className="tab-pane fade show active" 
              id="beneficios-tab-pane" 
              role="tabpanel" 
              aria-labelledby="beneficios-tab" 
              tabIndex="0"
            >
              <h5><strong>¡Benefíciese con las Pre-Ofertas!</strong></h5>
              <h5>Todos los que pre-oferten adquieren los siguientes descuentos:</h5>
              <h5>
              <ul>
                <li><strong>7 % de Descuento / OFERTA GANADORA</strong></li>
                En el precio final del lote ofertado para el oferente que termine con la OFERTA MÁS ALTA (mientras que haya más de un Oferente).
              </ul>
              <ul>
                <li><strong>7 % de Descuento / OFERTA GANADORA</strong></li>
                En el precio final del lote ofertado para el oferente que termine con la OFERTA MÁS ALTA (mientras que haya más de un Oferente).
              </ul>
              <ul>
                <li><strong>5 % de Descuento / APERTURA</strong></li>
                En el precio final del lote ofertado para el oferente que ABRE (primera oferta).
              </ul>
              <ul>
                <li><strong>3 % de Descuento / PARTICIPACIÓN</strong></li>
                En el precio final del lote ofertado para todos los oferentes que participen de la PRE-OFERTA.
              </ul>
              <strong>NOTA:</strong> LOS DESCUENTOS NO SON ACUMULABLES Y SON APLICABLES UNICAMENTE AL PRODUCTO PREOFERTADO.</h5>
            </div>
            <div 
              className="tab-pane fade" 
              id="condiciones-tab-pane" 
              role="tabpanel" 
              aria-labelledby="condiciones-tab" 
              tabIndex="0"
            >
            <h5>
              <ul>
                <li>90 días LIBRES o 5 CUOTAS (30-60-90-120-150 días).</li>
              </ul>
              <ul>
                <li>24% de descuento por pago de contado (dentro de los 7 días)</li>
              </ul>
              <ul>
                <li>16% de descuento por pago de contado (entre 7 y 30 días)</li>
              </ul>
              <ul>
                <li>Iva y Comisión A LOS 30 DIAS.</li>
              </ul>
              <ul>
                <li>FLETE GRATIS a todo el País.</li>
              </ul>
              
              <strong>Nota:</strong> los pagos deber ser con valores de pago diferido.
              <br/>
              <br/>

              <strong>TARJETAS RURALES:</strong>
              <br/>
              <ul>
                <li>AGRONACIÓN</li>
                <li>PLATAFORMA GALICIA RURAL</li>
              </ul>
              <strong>Consulte las tasas disponibles.</strong></h5>
            </div>
            <div 
              className="tab-pane fade" 
              id="sani-toros-tab-pane" 
              role="tabpanel" 
              aria-labelledby="sani-toros-tab" 
              tabIndex="0"
            >
            <h5>
            <strong>VACUNAS</strong>
            <br /><br />

              <strong>Respiratoria - BIOPOLIGEN AIR (Biogénesis Bagó)</strong> para la prevención de las Enfermedades Respiratorias Bovinas (ERB) y Queratoconjuntivitis Infecciosa Bovina (QIB).
              IBR, DVB, BRSV, Virus de Parainfluenza bovina tipo 3, Mannheimia haemolytica (Pasteurella haemolytica), Pasteurella multocida, Histophilus somni (Haemophilus somnus)<br/><br/>
              <strong>Clostridiales - BIOCLOSTRIGEN J5 (Biogénesis Bagó)</strong> para la prevención de cuadros de mancha y gangrena gaseosa producidas por Clostridium chauvoei y C. septicum, y enterotoxemias producidas por C. perfringens tipos C y D. Contribuye a la prevención de cuadros entéricos o sistémicos producidos por toxinas de bacterias Gram negativas.<br/><br/>
              <strong>Reproductiva - BIOABORTOGEN H (Biogénesis Bagó)</strong> contra la Rinotraqueitis Infecciosa Bovina (IBR), Vibriosis bovina, Leptospirosis, Haemophilus somnus y Diarrea Vírica Bovina (DVB).<br/><br/>
              <strong>LIBRES DE TRICOMONIASIS Y CAMPILOBACTEORIOSIS; los reproductores cuentan con 3 Raspajes Negativos.</strong><br/><br/>
              <strong>INMUNIZACION</strong><br/>

              Realizada con Vacuna Bío Jajá (Litoral Biológicos – Grupo CGL)<br/><br/>
              <strong>TOROS de 3 años: 3 (tres) Dosis.</strong><br/>
              <strong>TOROS de 2 años: 2 (dos) Dosis</strong></h5>
            </div>
            <div 
              className="tab-pane fade" 
              id="sani-vaqui-tab-pane" 
              role="tabpanel" 
              aria-labelledby="sani-vaqui-tab" 
              tabIndex="0"
            >
            <h5>
            <strong>VACUNAS</strong><br/><br/>

              <strong>Respiratoria - BIOPOLIGEN AIR (Biogénesis Bagó)</strong> para la prevención de las Enfermedades Respiratorias Bovinas (ERB) y Queratoconjuntivitis Infecciosa Bovina (QIB).
              IBR, DVB, BRSV, Virus de Parainfluenza bovina tipo 3, Mannheimia haemolytica (Pasteurella haemolytica), Pasteurella multocida, Histophilus somni (Haemophilus somnus)<br/><br/>
              <strong>Clostridiales - BIOCLOSTRIGEN J5 (Biogénesis Bagó)</strong> para la prevención de cuadros de mancha y gangrena gaseosa producidas por Clostridium chauvoei y C. septicum, y enterotoxemias producidas por C. perfringens tipos C y D. Contribuye a la prevención de cuadros entéricos o sistémicos producidos por toxinas de bacterias Gram negativas.<br/><br/>
              <strong>Reproductiva - BIOABORTOGEN H (Biogénesis Bagó)</strong> contra la Rinotraqueitis Infecciosa Bovina (IBR), Vibriosis bovina, Leptospirosis, Haemophilus somnus y Diarrea Vírica Bovina (DVB).<br/><br/>
              <strong>INMUNIZACION</strong><br/>

              Realizada con Vacuna Bío Jajá (Litoral Biológicos – Grupo CGL)<br/><br/>
              <strong>VAQUILLONAS con 2 (dos) Dosis.</strong></h5>
            </div>
          </div>
        </div>
      </div>
  )
}
