import React, { useEffect, useState } from 'react';

import { getRolesAdminless } from '../../../api/private/client';
import Padding from '../../../components/padding/Padding';
import { ACTIONS } from '../../../hooks/profile/useProfile';
import Translate from '../../../utils/translation/User.json';

const ProfileType = ( { profile, dispatch, buyerSeller } ) => {
    const activities = Translate.PERSON.ACTIVITY_LIST;

    const [ rolesAvailable, setRolesAvailable ] = useState( null );

    useEffect( () => {
        ( async () => {
            const { data } = await getRolesAdminless();
            // Aqui filtramos los roles, solamente dejamos "Corredor" y "Transportista".
            // Si el usuario desea ser "Comprador" y "Vendedor", debera usar el formulario de Registro.
            // let roles = data.filter(r => r.name !== "ROLE_VISITOR");
            let roles = data.filter( r => r.name !== "ROLE_VISITOR" && r.name !== "ROLE_BUYER" && r.name !== "ROLE_SELLER" );
            setRolesAvailable( roles );
            // Aqui seteamos los Roles para el Form de Registro para ser "Comprador" y "Vendedor".

            // Si "doubleRole" es verdadero, es que estamos en el form de Registro.
            // Si "doubleRole" es falso, es que estamos en el form de edicion de Perfil.
            if ( !profile.roles.some( e => e.id === 4 || e.id === 5 ) ) {
                if ( buyerSeller ) {
                    const profileRoles = [ ...profile.roles ];
                    const buyerRole =
                    {
                        id: 4,
                        name: "ROLE_BUYER",
                        description: "Comprador"
                    }

                    const sellerRole =
                    {
                        id: 5,
                        name: "ROLE_SELLER",
                        description: "Vendedor"
                    };
                    profileRoles.push( sellerRole, buyerRole );
                    const finalRoles = [ ...new Map( profileRoles.map( ( m ) => [ m.id, m ] ) ).values() ];
                    dispatch( { payload: finalRoles, type: ACTIONS.SET_ROLES } );
                }
            }
        } )();
    }, [] );

    const handleActivity = e => {
        dispatch( { payload: e.target.value, type: ACTIONS.SET_ACTIVITY } )
    };

    const handleRole = async role => {
        const newRoles = profile.roles.filter( ( { id } ) => role.id !== id );
        if ( newRoles.length === profile.roles.length ) {
            newRoles.push( role );
        }
        dispatch( { payload: newRoles, type: ACTIONS.SET_ROLES } );
    };

    return (
        <>
            <h2 className='col-12 d-flex justify-content-center mt-4 font-weight-bold'>
                { Translate.PERSON.PERFIL_TYPE }
            </h2>
            <div className='mt-3 mb-5 w-100'>
                <Padding>
                    <div className='col-12 d-flex justify-content-center'>
                        { rolesAvailable?.map( role =>

                            <div key={ role.id } className='form-check form-check-inline '>
                                <input
                                    style={ { 'accentColor': 'green' } }
                                    id={ role.name }
                                    className='form-check-input'
                                    value={ role.name }
                                    checked={ profile.roles.some( ( { id } ) => id === role.id ) }
                                    onChange={ () => handleRole( role ) }
                                    type='checkbox'
                                />
                                <div className="vr"></div>
                                <label style={ { color: 'black' } } htmlFor={ role.name } className='form-check-label'>
                                    { role.description }
                                </label>
                            </div>
                        ) }
                    </div>
                </Padding>
            </div>
            {/* <Padding>
                <div className='form-group'>
                    <label>{Translate.PERSON.ACTIVITY}</label>
                    <select
                        id='id_activity'
                        className='form-control'
                        name='legal_activity'
                        value={profile.legal_activity}
                        onChange={handleActivity}
                    >
                        {activities.map((activity, i) =>
                            <option
                                key={i}
                                value={activity}
                            >
                                {activity}
                            </option>
                        )}
                    </select>
                </div>
            </Padding> */}
        </>
    );
};

export default ProfileType;
