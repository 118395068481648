import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { postSetAdminTransportData } from "../../../api/private/client";
import { postUpdateAdminTransportData } from "../../../api/private/client";
import Swal from "sweetalert2";
import "./TransportCard.css";

//En este componente el Administrador puede cargar y editar los datos de transporte en el caso 
//de que Xercana se encargue del transporte.
//Tambien comprador puede editar los datos de transporte cuando es su propio transportista

interface TransportCardForm {
  profile : any,
  transport: {
    publicacion: {
      id: number,
      state: string
    }
    comprador: {
      id: number
    }
    tipo_transporte: number;
    chofer: string;
    cuit_transporte: string;
    empresa_transporte: string;
    marca_transporte: string;
    patente_chasis: string;
    tiene_acoplado: boolean;
    patente_acoplado: string;
    costoEnvio: number;
  };
  tiposTransporte: ITransportType[]
}

interface ITransportType {
  id: number;
  tipo: string;
}

interface IRoles {
  id: number;
  name: string;
  description: string;
}

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center my-4">
      <div className="spinner-border" role="status" />
    </div>
  );
};


const TransportCard: React.FC<TransportCardForm> = ( { profile, transport, tiposTransporte} ) => {
  
  const { register, handleSubmit, errors, watch, setValue } = useForm<TransportCardForm>();
  const [ newRegister, setNewRegister ] = useState<boolean>( false );
  const [ transportTypes, setTransportTypes ] = useState<ITransportType[]>( [] );
  const [ transportType, setTransportType ] = useState<number>();
  const [ puedeEditar, setPuedeEditar ] = useState<boolean>( false );
  const [ transportaComprador, setTransportaComprador ] = useState<string>( "no" );
  const [ isLoading, setIsLoading ] = useState<boolean>( false );
  const [checked, setChecked] = useState(false); 

  useEffect( () => {
    ( async () => {
      setTransportTypes( tiposTransporte );
    } )();
  }, [] );


  const successModal = () => {
    Swal.fire( {
      title: "Confirmado",
      text: "Datos de transporte editados satisfactoriamente.",
      icon: "success",
    } );
  };

  const errorModal = () => {
    Swal.fire( {
      title: "Error",
      text: "Los datos de transporte no pudieron ser editados",
      icon: "error",
    } );
  };

  useEffect( () => {
    ( async () => {
      if ( transport.chofer != undefined ) {
        setNewRegister( false );
        setTransportType( transport.tipo_transporte );
        setValue( 'transport.chofer', transport.chofer );
        setValue( 'transport.cuit_transporte', transport.cuit_transporte );
        setValue( 'transport.empresa_transporte', transport.empresa_transporte );
        setValue( 'transport.marca_transporte', transport.marca_transporte );
        setValue( 'transport.patente_chasis', transport.patente_chasis );
        setValue( 'transport.tiene_acoplado', transport.tiene_acoplado );
        setChecked(transport.tiene_acoplado);
        setValue( 'transport.patente_acoplado', transport.patente_acoplado );
        if ( transport.publicacion.state === "Despachada" || transport.publicacion.state === "PendienteConfirmacion" ||
          transport.publicacion.state === "Entregada" || transport.publicacion.state === "Liquidada" ||
          transport.publicacion.state === "Finalizada" ) {
          setPuedeEditar( false );
        }
      } else {
        setNewRegister( true );
      }
    } )();
  }, [] );

  useEffect( () => {
    if ( profile === null ) {
      return;
    } else {
      const isAdmin = profile.roles.find( ( obj: IRoles ) => obj.id === 2 );
      const userId = profile.id;
      if ( isAdmin || ( transport.comprador.id === userId && transport.costoEnvio === 0 ) ) {
        setPuedeEditar( true );
      }
      if ( transport.costoEnvio === 0 ) {
        setTransportaComprador( "si" );
      }
    }
  }, [ profile ] )

  const onSubmit = async () => {
    setIsLoading( true );
    const dataToSend = {
      tiene_transportista: transportaComprador,
      tipo_transporte: watch( 'transport.tipo_transporte', false ),
      empresa_transporte: watch( 'transport.empresa_transporte', false ),
      cuit_transporte: watch( 'transport.cuit_transporte', false ),
      chofer: watch( 'transport.chofer', false ),
      marca_transporte: watch( 'transport.marca_transporte', false ),
      patente_chasis: watch( 'transport.patente_chasis', false ),
      tiene_acoplado: watch( 'transport.tiene_acoplado', false ),
      patente_acoplado: watch( 'transport.patente_acoplado', false ),
      publication_id: transport.publicacion.id
    }
    try {
      if ( newRegister ) {
        await postSetAdminTransportData( {
          ...dataToSend,
        } );
        setIsLoading( false );
        successModal();
      } else {
        await postUpdateAdminTransportData( {
          ...dataToSend,
        } );
        setIsLoading( false );
        successModal();
      }
      
    } catch ( err ) {
      console.error( err );
      setIsLoading( false );
      errorModal();
    }
  }

  const handleTransportTypes = ( e: { target: { value: any; }; } ) => {
    const est = transportTypes.find( ( tipo: ITransportType ) => {
      return tipo.id === Number( e.target.value );
    } );
    setTransportType( est?.id );
  };

  return (
    <>
    
      <div className="card my-4 p-0 p-lg-4">
        <p className="Card-title text-center">
          Datos del transporte
        </p>
        <div className="card-body">
          <form onSubmit={ handleSubmit( onSubmit ) }>

            <div className="row row-cols-1 row-cols-lg-2">
              <div className="alert alert-info w-50" role="alert">
                <div className="mb-3">
                  <label htmlFor="iva_id" className="font-weight-bold">Tipo de transporte</label>
                  <select
                    placeholder="Seleccione el tipo de transporte"
                    id="tipo_transporte_id"
                    className="form-control"
                    value={ transportType }
                    name="transport.tipo_transporte"
                    onChange={ handleTransportTypes }
                    ref={ register( { required: true } ) }
                    disabled={ !puedeEditar }
                  >
                    { transportTypes?.map( ( e: ITransportType ) => (
                      <option key={ e.id } value={ e.id }>
                        { e.tipo }
                      </option>
                    ) ) }
                  </select>
                  {/* <div id="tipo_transporte_id" className="invalid-feedback">
                        Por favor, ingrese un tipo de transporte valido.
                      </div> */}
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold">Chofer</label>
                  <input
                    id="chofer_id"
                    className={ `form-control ${!transport?.chofer ? "is-invalid" : ""}` }
                    name="transport.chofer"
                    placeholder="Nombre del chofer"
                    ref={ register( { required: true } ) }
                    disabled={ !puedeEditar }
                    type="text"
                  />
                  {/* <div id="chofer_id" className="invalid-feedback">
                        Por favor, ingrese un nombre.
                      </div> */}
                </div>
                <div className="mb-5">
                  <label className="font-weight-bold">CUIT transporte</label>
                  <input
                    id="cuit_id"
                    className={ `form-control ${!transport?.cuit_transporte ? "is-invalid" : ""}` }
                    name="transport.cuit_transporte"
                    placeholder="CUIT transporte"
                    ref={ register( { required: true } ) }
                    type="text"
                    disabled={ !puedeEditar }
                  />
                  {/* <div id="cuit_id" className="invalid-feedback">
                        Por favor, ingrese CUIT.
                      </div> */}
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold">Tiene acoplado?</label>
                  <div className="col-md-2 float-right">
                    <input
                      id="tiene_acoplado_id"
                      className={ `form-control ${!transport?.tiene_acoplado ? "is-invalid" : ""} ` }
                      name="transport.tiene_acoplado"
                      placeholder="Tiene acoplado?"
                      ref={ register() }
                      type="checkbox"
                      disabled={ !puedeEditar }
                      onChange = {() => {
                        setChecked(!checked);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="alert alert-info w-50" role="alert">
                <div className="mb-3">
                  <label className="font-weight-bold">Marca de transporte</label>
                  <input
                    id="marca_id"
                    className={ `form-control ${!transport?.marca_transporte ? "is-invalid" : ""}` }
                    name="transport.marca_transporte"
                    placeholder="Marca transporte"
                    ref={ register( { required: true } ) }
                    type="text"
                    disabled={ !puedeEditar }
                  />
                  {/* <div id="marca_id" className="invalid-feedback">
                        Por favor, ingrese marca del transporte.
                      </div> */}
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold">Patente del chasis</label>
                  <input
                    id="patente_chasis_id"
                    className={ `form-control ${!transport?.patente_chasis ? "is-invalid" : ""}` }
                    name="transport.patente_chasis"
                    placeholder="Patente chasis"
                    ref={ register( { required: true } ) }
                    type="text"
                    disabled={ !puedeEditar }
                  />
                  {/* <div id="patente_chasis_id" className="invalid-feedback">
                        Por favor, ingrese patente del chasis.
                      </div> */}
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold">Empresa de transporte</label>
                  <input
                    id="empresa_id"
                    className={ `form-control ${!transport?.empresa_transporte ? "is-invalid" : ""}` }
                    name="transport.empresa_transporte"
                    placeholder="Nombre de empresa transporte"
                    ref={ register( { required: true } ) }
                    type="text"
                    disabled={ !puedeEditar }
                  />
                  {/* <div id="empresa_id" className="invalid-feedback">
                        Por favor, ingrese nombre de la empresa.
                      </div> */}
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold">Patente acoplado</label>
                  <input
                    id="patente_acoplado_id"
                    className={ `form-control ${( transport?.tiene_acoplado && !transport?.patente_acoplado ) ? "is-invalid" : ""}` }
                    name="transport.patente_acoplado"
                    placeholder="Patente acoplado"
                    ref={ register( { required: transport?.tiene_acoplado  || checked} ) }
                    type="text"
                    disabled={ !puedeEditar }
                  />
                  {/* <div id="patente_acoplado_id" className="invalid-feedback">
                        Por favor, ingrese patente del acoplado.
                      </div> */}
                </div>
              </div>
            </div>
            { puedeEditar && (
              <div className="d-flex justify-content-center">
                { isLoading ? <LoadingSpinner /> : (
                  <button
                    className="btn btn-info"
                    type="submit"
                    disabled={ !puedeEditar }
                  >
                    Guardar datos de transporte
                  </button>
                )}
              </div>
            ) }
          </form>
        </div >
      </div >   
      
    </>
  );
};

export default TransportCard;
