import React from "react";

import BottonLine from "../../../components/BottonLine";
import { RealBreadCrumb } from "../../../components/breadcrumb/Breadcrumb";
import LinkWsp from "../../../components/LinkWsp/LinkWsp";
import routers from "../../../config/routers";
import "../HelpScreen.css";


const HowQuestion = () => {
 
  return (
    <div style={{ minHeight: "80vh", paddingBottom: "5rem" }}>
      <h1
        className="text-center w-100 font-weight-bold"
        style={{ fontSize: "2rem", marginTop: "3rem" }}
      >
        Cómo Preguntar
      </h1>
      <div style={{ width: "70%", margin: "0 auto", marginTop: "1rem" }}>
        <RealBreadCrumb
          refNames={["Ayuda ", "Cómo preguntar"]}
          hrefs={[routers.help.path, "#"]}
        />
      </div>
      <div className="helpScreenContainerDiv helpIndividual">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="text-center">¿Cómo preguntar en una publicación?</h1>
          <BottonLine width="100%" />
          <p className="p-5 mt-4 mb-1">
            Si tienes dudas, puedes realizar tu consulta directamente en la
            misma publicación en la sección "Preguntas y respuestas", rellena
            los campos, y envía tu consulta, la cual quedará registrada para ser
            respondida a al brevedad por el vendedor.<br />{" "}
            En su defecto, podés contactarnos por Whatsapp de Lunes a Viernes de
            10 a 17 hs.
          </p>
          <LinkWsp></LinkWsp>
        </div>
      </div>
    </div>
  );
}

export default HowQuestion;
