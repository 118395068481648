import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import { postCommentAnswer } from '../../../api/private/client';


const AnswerCommentsPage = () => {

    const location = useLocation();
    const { questions } = location.state;
    // Comments:
    const [answer, setAnswer] = useState({});

    const [questionsVar, setQuestionsVar] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeInput = (e) => {
        setAnswer({
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setQuestionsVar(questions);
    }, []);


    const emptyAnswerModal = async () => {
        await Swal.fire({
            heightAuto: false,
            title: 'Atención!',
            text: 'La respuesta no puede estar vacía',
            icon: 'info'
        });
    }

    const waitingModal = async () => {
        await Swal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            heightAuto: false,
            title: 'Enviando...',
            text: 'Aguarde mientras registramos su respuesta',
            icon: 'info'
        });
    }

    const successModal = async () => {
        await Swal.fire({
            allowOutsideClick: false,
            heightAuto: false,
            html: `Hemos registrado tu respuesta <br> muchas gracias.`,
            icon: 'success'
        });
    }



    const submitAnswer = async (id, index) => {
        waitingModal();
        setIsLoading(true);
        if (answer?.answer?.length === 0 || answer?.answer?.length == undefined) {
            await emptyAnswerModal();
            setIsLoading(false);
            return;
        } else {
            waitingModal();
            const data = await postCommentAnswer(id, answer);
            if (data) {
                successModal();
                setAnswer({})
                let currentInput = document.getElementById(index);
                currentInput.value = '';
                let questionMemory = questionsVar;
                questionMemory.splice(index, 1);
                setQuestionsVar(questionMemory);
            } else {
                Swal.fire('No se pudo publicar su respuesta', 'Intente nuevamente', 'error');
            }
            setIsLoading(false);
        }
    }


    return (
        <div className="row">
            <div className="container__RebuildPublication p-2 p-lg-4">
                {questionsVar.map((item, index) => (<div key={index}>
                    <div key={index} style={{ width: '80%', margin: '0 auto' }}>
                        <div className="form-group">
                            <h4 className='bold'>Publicación #{item.publication_id} {item.title}</h4>
                            <h4><b>Fecha:</b> {moment(item.date_question).format('DD/MM/YYYY')}</h4>
                            <h4><b>Usuario: </b>{item.person_question}</h4>
                            <h4><b>Pregunta: </b>{item.question}</h4>
                            <input
                                id={index}
                                name="answer"
                                onChange={(e) => handleChangeInput(e, index)}
                                className="form-control"
                                placeholder='Escriba aqui su respuesta' />
                            {!isLoading ? (
                                <div className="col-2">
                                    <button onClick={() => submitAnswer(item.id, index)} className="btn btn-success mt-2">Enviar</button>
                                </div>) : (
                                <div className="col-2">
                                    <button disabled onClick={() => submitAnswer(item.id, index)} className="btn btn-success mt-2">Enviar</button>
                                </div>)}
                        </div>
                    </div>
                    <hr />
                </div>
                ))}
            </div>
        </div>
    );

}

export default AnswerCommentsPage;