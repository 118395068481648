import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { getPublicationOffers } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";
import { formatDate } from "../../../../utils/helper";
import { BtnExcelExport } from "../../../../components/Excel/BtnExcelExport";
import { useAuthContext } from "../../../../context/AuthenticatedContext";

interface Offer {
  id: number;
  amount: number;
  userId: number;
  publicationId: number;
  created_at: string;
  user_name: string;
  mail_contacto: string;
  phone: string;
}

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center my-4">
      <div className="spinner-border" role="status" />
    </div>
  );
};

const OfferModal: React.FC = () => {
  const { isAdmin, isCommercial } = useAuthContext();
  const { idPublication } = usePublicationsContext();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await getPublicationOffers(
          idPublication,
        ) as AxiosResponse<Offer[]>;
        setOffers(data);
      } catch (err) {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <h2 className="d-flex justify-content-center bold mb-5">
        Ofertas de la publicación
      </h2>
      { (isAdmin() || isCommercial()) && 
        <div className="float-right mb-3">
          <BtnExcelExport offers={offers} />
        </div>
      }
      
      {isLoading ? <LoadingSpinner /> : (
        <>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                {
                  (isAdmin() || isCommercial()) && 
                  <>
                  <th scope="col">Usuario</th>
                  <th scope="col">Email de contacto</th>
                  <th scope="col">Teléfono</th>
                  </>                      
                }
                
                <th scope="col">Monto</th>
              </tr>
            </thead>
            <tbody>
            { offers && 
            (offers.map( (offer) => (               
              <tr key={offer.id}>
                  <td>{ formatDate(offer.created_at) }</td>
                  { (isAdmin() || isCommercial() ) && 
                  <><td>{ offer.user_name }</td>
                  <td>{ offer.mail_contacto }</td>
                  <td>{ offer.phone }</td></>}
                  <td align="right">{ offer.amount }</td>
              </tr>
              )))
            }
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default OfferModal;
