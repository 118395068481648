import React from "react";

import "./Card.css";

interface SellerCardProps {
  seller: any;
}

const SellerCard: React.FC<SellerCardProps> = ({ seller }) => {
  return (
    <div className="card my-4">
      <div className="row card-body">
        <div
          className="col-12 col-lg-2 d-flex flex-column justify-content-center align-items-center"
        >
          <p className="Card-title">Vendedor</p>
          <img className="Card-avatar" src={seller.image} alt="" />
        </div>
        <div className="col-12 col-lg-5 px-lg-4 py-3">
            <p className="Card-title">Datos personales</p>
            <p className="card-text">Nombre: {seller.name}</p>
            <p className="card-text">Apellido: {seller.surname}</p>
            <p className="card-text">Teléfono: {seller.phone}</p>
            <p className="card-text">Email: {seller.mail}</p>
        </div>
      </div>
    </div>
  );
};

export default SellerCard;