import React from "react";

import { states } from "../../views/Publications/List/publicationStates";

interface StateBadgeProps {
  state: string;
}

const StateBadge: React.FC<StateBadgeProps> = ({ state }) => {
  const obj = states.find((s) => s.name === state);

  return (
    <span className={`badge rounded-pill ${obj ? obj.style : ""}`}>
      {obj?.description ?? "-"}
    </span>
  );
};

export default StateBadge;
