import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './container.css';

/**
 * 
 * @param children ReactcomponentsChildren
 * @param className className. recibe css class
 * @param fit Bool. Default true: fit-content; false: width = 100%
 * @param centerElement Bool. Defautl true: center all elements children.
 * @param shadow bool. Default true.
 * @param showBorder Bool. Default true.
 * @param borderRadius String. Default 0px.
 * @param margin String css maring. Default 5%
 * @param hover Bool. Default False. Efect Shadow
 * @requires children 
 */
const Container = ({
    children,
    className,
    fit,
    centerElement,
    shadow,
    showBorder,
    hover,
    href,
    redirected,   
}) => {

    const [styleState, setStyle] = useState('');

    useEffect(() => {

        let style = fit ? 'div-container fix' : 'div-container';

        style = centerElement ? style + ' center' : style;

        style = shadow ? style + ' shadow' : style;

        style = showBorder ? style + ' border-container' : style;

        style = hover ? style + ' hover' : style;

        style = className ? style + ' ' + className : style;

        setStyle(style);
    }, [fit, centerElement, shadow, showBorder, hover, className]);

    const widget = (<div className= {styleState}> {children} </div>)

    return (href ? ( <Link to={href}> {widget} </Link> ) : widget );
   
}

Container.defaultProps = {
    fit: false,
    centerElement: false,
    shadow: true,
    showBorder: false,
}

export default Container;