import { Input } from '../../../components/input/Input';
import Container from '../../../components/container/Container'
import Padding from '../../../components/padding/Padding';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Traslate from '../../../utils/translation/Admin.json';
import ComboBox from '../../../components/ComboBox/ComboBox';
import { useHistory } from 'react-router-dom';
import routers from '../../../config/routers';
import Button from '../../../components/button/Button';
import { ModalConfirm } from '../../../components/Modals/Modals';

import { getAdminCompany, postAdminCompany } from '../../../api/private/client';
import './CompanyPage.css';

const CompanyPage = ({ match }) => {

    const history = useHistory();

    const [data, setData] = useState();
    const [users, setUsers] = useState([]);
    const [choice, setChoice] = useState();
    const [owner, setOwner] = useState({
        full_name_owner: "",
        id_owner: 0
    });
    const { register, handleSubmit, setValue } = useForm();

    const onSubmit = async data => {
        if(!choice)
            history.goBack();
        const confirm = await ModalConfirm()
        if(confirm) {
            await postAdminCompany(match.params.id, { 
                members: users, 
                ...owner, 
                ...data})
            history.goBack();
        }
    }

    const handleOwner = event => {
        const user = users.find( user => user.full_name === event.target.value)
        setOwner({
            id_owner: user.id,
            full_name_owner: user.full_name
        });
    }

    useEffect(() => {
        (async () => {
            console.log(match.params.id);
            const { data } = await getAdminCompany(match.params.id);
            setData(data);
        })();
    }, [])

    useEffect(() => {
        if (data) {
            console.log(data)
            const { members, id_owner, full_name_owner, ...rest } = data;
            for (let i in rest)
                setValue(i, data[i]);
            if(members)
                setUsers([ ...members ]);
            setOwner({
                full_name_owner,
                id_owner
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return <Container className='xercana-main-container'>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className='row'
        >
            <h1 className='col-12'>{Traslate.COMPANIES_PAGE.COMPANY}</h1>
            <Padding>
                <Input
                    label={Traslate.COMPANIES_PAGE.COMPANY}
                    name="bussiness_name"
                    register={register}
                />
                <Input
                    label={Traslate.COMPANIES_PAGE.CUIT}
                    name="cuit"
                    register={register}
                />
            </Padding>
            <Padding>
                <Input
                    label={Traslate.COMPANIES_PAGE.CREATION_DATE}
                    name="creation_date"
                    register={register}
                />
                <Input
                    label={Traslate.COMPANIES_PAGE.MODIFIER_USER}
                    name="modified_user"
                    register={register}
                />
            </Padding>
            <Padding>
                <Input
                    label={Traslate.COMPANIES_PAGE.LAST_MODIFIED_DATE}
                    name="last_modified_date"
                    register={register}
                />
                <ComboBox
                    label={Traslate.COMPANIES_PAGE.STATE}
                    name="state"
                    placeholder={"Seleccione un estado"}
                    values={Traslate.STATE.STATE_LIST}
                    register={register}
                />
            </Padding>
            <Padding>
                <Input
                    label={Traslate.COMPANIES_PAGE.CBU}
                    name="CBU"
                    register={register}
                />
                <Input
                    label={Traslate.COMPANIES_PAGE.CVU}
                    name="cvu"
                    register={register}
                />
            </Padding>
            <Padding>
                <ComboBox
                    label={Traslate.COMPANIES_PAGE.ADMIN_USER}
                    value={owner.full_name_owner}
                    values={users.map( user => user.full_name )}
                    onChange={handleOwner}
                />
                <div />
            </Padding>
            <Padding>
                <h3 className='xercana-vertical-padding '>Miembros</h3>
            </Padding>
            <table className="table table-striped table-data">
                <thead>
                    <th scope="col">{Traslate.COMPANIES_PAGE.USERS}</th>
                    <th scope="col">{Traslate.COMPANIES_PAGE.CUIT}</th>
                </thead>
                <tbody>
                    {
                        users.map(user =>
                            <tr key={user.id} className='xercana-hover' onClick={() => history.push(routers.admin_user.path_1(user.id))}>
                                <td>{user.full_name}</td>
                                <td>{user.cuit}</td>
                            </tr>)
                    }
                </tbody>
            </table>
            <Padding className='xercana-vertical-padding '>
                <Button
                    color={false}
                    onClick={ ()=> setChoice(false)}
                    type='submit'>{Traslate.COMMON.CANCEL}</Button>
                <Button
                    onClick={ ()=> setChoice(true)}
                    type='submit'>{Traslate.COMMON.SAVE}</Button>
            </Padding>
        </form>
    </Container>
}

export default CompanyPage;