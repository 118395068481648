import React from "react";
import Swal from "sweetalert2";

import { postUserEstablishmentsDelete } from "../../../api/private/client";
import { useProfileContext } from "../../../context/ProfileContext";

const TableHeader = () => {
  return (
    <>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Código Renspa</th>
          <th>Acciones</th>
        </tr>
      </thead>
    </>
  );
};

const EstablishmentTable = ( { establishments = [], openModal } ) => {
  const { deleteEstablishment } = useProfileContext();

  const handleEdit = ( e ) => {
    openModal( Number( e.target.value ) );
  };

  const handleDelete = async ( e ) => {
    e.persist();
    const { isConfirmed } = await Swal.fire( {
      title: "¿Está seguro?",
      text: "Se eliminará el respectivo establecimiento.",
      icon: "question",
      confirmButtonColor: "green",
      confirmButtonText: "Sí",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "Cancelar",
    } );
    if ( isConfirmed ) {
      Swal.fire( {
        title: "Eliminando",
        text: "Aguarde unos instantes.",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
      } );
      try {
        const est = establishments.slice().splice( Number( e.target.value ), 1 );
        await postUserEstablishmentsDelete( { establecimientos: est } );
        deleteEstablishment( Number( e.target.value ) );
        Swal.fire( {
          title: "Establecimiento eliminado",
          text: "El establecimiento ha sido eliminado satisfactoriamente.",
          icon: "success",
        } );
      } catch ( err ) {
        Swal.fire( {
          title: "Error",
          icon: "error",
        } );
      }
    }
  };

  return (
    <>
      <table
        className="table table-striped table-data table-responsive-sm mt-4"
      >
        <TableHeader />
        <tbody>
          { establishments.map( ( e, index ) => (
            <tr key={ index }>
              <td>{ e.nombre }</td>
              <td>{ e.codeRenspa }</td>
              <td>
                <button
                  className="btn btn-info mb-1 mb-lg-0 mr-lg-2"
                  value={ index }
                  onClick={ handleEdit }
                  type="button"
                >
                  Modificar establecimiento
                </button>
                <button
                  className="btn btn-primary"
                  value={ index }
                  onClick={ handleDelete }
                  type="button"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ) ) }
        </tbody>
      </table>
    </>
  );
};

export default EstablishmentTable;
