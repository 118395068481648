export const states = [
  {
    name: "Creada_Venta",
    style: "bg-creada text-white",
    description: "Creada",
  },
  {
    name: "Publicada",
    style: "bg-publicada text-white",
    description: "Publicada",
  },
  { name: "Pausada", style: "bg-pausada", description: "Pausada" },
  {
    name: "Comprada",
    style: "bg-comprada text-white",
    description: "Comprada",
  },
  {
    name: "Calificada",
    style: "bg-calificada text-white",
    description: "Calificada",
  },
  { name: "DteCargado", style: "bg-dte-cargado", description: "DTE Cargado" },
  {
    name: "Despachada",
    style: "bg-despachada text-white",
    description: "Despachada",
  },
  {
    name: "PendienteConfirmacion",
    style: "bg-pendiente-confirmacion text-white",
    description: "Pendiente Confirmación",
  },
  {
    name: "Entregada",
    style: "bg-entregada text-white",
    description: "Entregada",
  },
  {
    name: "Liquidada",
    style: "bg-liquidada",
    description: "Liquidada",
  },
  {
    name: "Aceptada",
    style: "bg-aceptada text-white",
    description: "Aceptada",
  },
  {
    name: "Cancelada",
    style: "bg-cancelada text-white",
    description: "Cancelada",
  },
];
