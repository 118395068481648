import Admin from "../views/Admin/AdminDashboard";
import CompanyPage from "../views/Admin/CompanyPage/CompanyPage";
import Dashboard from "../views/Dashboard/Dashboard";
import Error500 from "../views/ErrorPage/500";
import HomeComponent from "../views/HomePage/HomePage";
import JoinPage from "../views/JoinPage/JoinPage";
import AuctionJoinPage from "../views/AuctionJoinPage/AuctionJoinPage";
import LoginAdmin from "../views/Admin/LoginAdmin/LoginAdmin";
import PhoneValidationStepOne from "../views/Dashboard/Profile/PhoneValidationStepOne";
import PublicationLiveStock from "../views/Publication/PublicationLiveStock";
import RegisterDataPage from "../views/Dashboard/Profile/RegisterDataPage";
import UserPage from "../views/Admin/UserPage/UserPage";
import UsersPage from "../views/Admin/UsersPage/UsersPage";
import WishPage from "../views/Dashboard/Wish/WishPage";
// oAuth
import { PaymentError } from "../views/PaymentResults/PaymentError";
import { PaymentOk } from "../views/PaymentResults/PaymentOk";
import AnswerCommentsPage from "../views/Dashboard/AnswerComments/AnswerComments";
import AtentionTime from "../views/HelpScreen/MultiHelpScreen/AtentionTime";
import AuctionsHomePage from "../views/Auctions/AuctionsHomePage";
import Conditions from "../views/Conditions";
import ConsigneePage from "../views/Admin/ConsigneePage/ConsigneePage";
import FirstStep from "../views/HelpScreen/MultiHelpScreen/FirstStep";
import HelpScreen from "../views/HelpScreen/HelpScreen";
import HowQuestion from "../views/HelpScreen/MultiHelpScreen/HowQuestion";
import ModerateCommentsPage from "../views/Admin/ModerateCommentsPage/ModerateCommentsPage";
import MovesAdminPage from "../views/Admin/MovesAdminPage/MovesAdminPage";
import OAuth2RedirectHandler from "../views/Oauth";
import Prices from "../views/HelpScreen/MultiHelpScreen/Prices";
import PublicationSearch from "../views/PublicationSearch/PublicationSearch";
import PublicationsPage from "../views/Publications/PublicationsPage";
import RebuildPublication from "../views/Dashboard/Publication/RebuildPublicationUser";
import RebuildPublicationEdit from "../views/Dashboard/Publication/RebuildPublicationEdit";
import SecuritySection from "../views/HelpScreen/MultiHelpScreen/SecuritySection";
import SeeFacture from "../views/HelpScreen/MultiHelpScreen/SeeFacture";
import TransportCostsPage from "../views/Admin/TransportCostsPage/TransportCostsPage";
import AuctionsOAuth2RedirectHandler from "../views/OauthAuctions";
import { AuctionsRegisterPage } from "../views/Auctions/Register/AuctionsRegisterPage";
import { AdminAuctionDashboard } from "../views/Auctions/AdminAuctionDashboard/AdminAuctionDashboard";

const publicRoutes = {
    "home": {
        "path": "/",
        "component": HomeComponent,
        "exact": true
    },
    "join": {
        "path": "/join",
        "component": JoinPage,
    },
    "join_auction": {
        "path": "/join-auction",
        "component": AuctionJoinPage,
    },
    "admin_login": {
        "path": "/admin/login",
        "component": LoginAdmin,
        "exact": true
    },
    "error500": {
        "path": "/error/500",
        "component": Error500,
        "exact": true
    },
    "oauth": {
        "path": "/oauth2/redirect",
        "component": OAuth2RedirectHandler,
        "exact": true
    },
    "auctions_oauth": {
        "path": "/auctions/oauth2/redirect",
        "component": AuctionsOAuth2RedirectHandler,
        "exact": true
    },
    "help": {
        "path": "/help",
        "component": HelpScreen,
        "exact": true
    },
    "primarySteps": {
        "path": "/firststep",
        "component": FirstStep,
        "exact": true
    },
    "howquestion": {
        "path": "/howquestion",
        "component": HowQuestion,
        "exact": true
    },
    "atentiontime": {
        "path": "/atentiontime",
        "component": AtentionTime,
        "exact": true,
    },
    "prices": {
        "path": "/prices",
        "component": Prices,
        "exact": true
    },
    "seefacture": {
        "path": "/seefacture",
        "component": SeeFacture,
        "exact": true
    },
    "securitySection": {
        "path": "/securitysection",
        "component": SecuritySection,
        "exact": true
    },
    "conditions": {
        "path": "/conditions",
        "component": Conditions,
        "exact": true
    },
    "payment_ok": {
        "path": "/paymentok",
        "component": PaymentOk,
        "exact": true,
    },
    "payment_error": {
        "path": "/paymenterror",
        "component": PaymentError,
        "exact": true,
    },

    "publicationView": {
        "path": "/publication/view/:id",
        "path_1": id => `/publication/view/${ id }`,
        "component": PublicationLiveStock
    },
    
   
};

const privateRoutes = {    
    "publicationViewNoSale": {
        "path": "/publication/view/:id/:sale",
        "path_1": ( id, sale ) => ( `/publication/view/${ id }/${ sale }` ),
        "component": PublicationLiveStock
    },
    // "publicationView": {
    //     "path": "/publication/view/:id",
    //     "path_1": id => `/publication/view/${ id }`,
    //     "component": PublicationLiveStock
    // },

    "user_dashboard": {
        "path": "/user/dashboard",
        "component": Dashboard
    },
    "user_comments": {
        "path": "/user/comments",
        "component": AnswerCommentsPage
    },
    // "user_profile": {
    //     "path": "/user/profile",
    //     "component": ProfilePage
    // },
    "user_register_data": {
        "path": "/user/register",
        "component": RegisterDataPage
    },
    "phone_validation_step_one": {
        "path": "/user/phone",
        "component": PhoneValidationStepOne
    },
    "user_sales": {
        "path": "/user/publications",
        "component": PublicationsPage
    },
    "user_wish": {
        "path": "/user/wish/:page",
        "path_1": page => `/user/wish/${ page }`,
        "component": WishPage
    },
    "user_new_publication": {
        "path": "/user/rebuildpublication/",
        "component": RebuildPublication,
        "exact": true
    },
    "user_new_publication_edit": {
        "path": "/user/rebuildpublicationEdit/:id",
        "path_1": id => `/user/rebuildpublicationEdit/${ id }`,
        "component": RebuildPublicationEdit,
        // "exact":true
    },
    "publication_search": {
        "path": "/publication/search",
        "component": PublicationSearch,
        "exact": true,
    },
    
}

const adminRoutes = {
    "admin": {
        "path": "/admin",
        "component": Admin,
        "exact": true
    },
    "admin_users": {
        "path_1": page => `/admin/users/${ page }`,
        "path": "/admin/users/:page",
        "component": UsersPage,
        "exact": true,
    },
    "admin_user": {
        "path": "/admin/user/:id",
        "path_1": id => `/admin/user/${ id }`,
        "component": UserPage
    },
    "admin_company": {
        "path": "/admin/company/:id",
        "path_1": id => `/admin/company/${ id }`,
        "component": CompanyPage
    },
    "admin_moves": {
        "path": "/admin/moves/:page",
        "path_1": page => `/admin/moves/${ page }`,
        "component": MovesAdminPage

    },
    "admin_consignee": {
        "path": "/admin/consignee",
        "component": ConsigneePage,
        "exact": true,
    },
    "admin_transport": {
        "path": "/admin/transport",
        "component": TransportCostsPage,
        "exact": true,
    },
    "admin_comments": {
        "path": "/admin/moderate_comments",
        "component": ModerateCommentsPage,
        "exact": true,
    },
    // "auctions": {
    //     "path": "/auctions",
    //     "component": AuctionsHomePage,
    //     // "exact": true,
    // },
    // "auctions_register": {
    //     "path": "/auctions/register",
    //     "component": AuctionsRegisterPage,
    //     // "exact": true,
    // },
}

const auctionRoutes = {
    "auctions_register": {
        "path": "/auctions/register",
        "component": AuctionsRegisterPage,
        // "exact": true,
    },
    "auctions_dashboard": {
        "path": "/auctions/dashboard",
        "component": AdminAuctionDashboard,
        // "exact": true,
    },
    "auctions": {
        "path": "/auctions",
        "component": AuctionsHomePage,
        // "exact": true,
    },
}

const addState = ( routes, state ) => {

    const array = Object.entries( routes ).map( elements => {
        return {
            [ elements[ 0 ] ]: {
                ...elements[ 1 ],
                state
            }
        }
    } )

    let responseAsObject = {}

    array.forEach( element => {
        responseAsObject = {
            ...responseAsObject,
            ...element
        }
    } )

    return responseAsObject;
}

const routers = {
    ...addState( publicRoutes, 'Public' ),
    ...addState( privateRoutes, 'Private' ),
    ...addState( adminRoutes, 'Admin' ),
    ...addState( auctionRoutes, 'Auction' )
}

export default routers;
