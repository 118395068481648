import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { states } from "../../List/publicationStates";
import { getPublicationHistory } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";
import { formatDate } from "../../../../utils/helper";

interface History {
  idHistory: number;
  state: string;
  startTime?: string;
  endTime?: string;
}

interface HistoryCardProps {
  history: History;
}

const StateBadge: React.FC<{ state: string }> = ({ state }) => {
  const obj = states.find((s) => s.name === state);

  return (
    <span className={`badge rounded-pill ${obj ? obj.style : ""}`}>
      {obj?.description ?? "-"}
    </span>
  );
};

const HistoryCard: React.FC<HistoryCardProps> = ({ history }) => {
  return (
    <div className="col-12 col-lg-4 py-2 p-lg-2">
      <div className="card">
        <div className="card-body p-2 p-lg-4">
          <h2 className="mb-4">
            Estado: <StateBadge state={history.state} />
          </h2>
          <p>Fecha de inicio: {formatDate(history.startTime)}</p>
          <p>Fecha de finalización: {formatDate(history.endTime)}</p>
        </div>
      </div>
    </div>
  );
};

const LoadingSpinner: React.FC = () => {
  return (
    <div className="d-flex justify-content-center my-4">
      <div className="spinner-border" role="status" />
    </div>
  );
};

const HistoryModal: React.FC = () => {
  const { idPublication } = usePublicationsContext();
  const [history, setHistory] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await getPublicationHistory(
          idPublication,
        ) as AxiosResponse<History[]>;
        setHistory(data);
      } catch (err) {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <h2 className="d-flex justify-content-center">
        Historial de Publicación
      </h2>
      {isLoading ? <LoadingSpinner /> : (
        <div className="row mt-4">
          {history.map((h) => <HistoryCard history={h} key={h.idHistory} />)}
        </div>
      )}
    </>
  );
};

export default HistoryModal;
