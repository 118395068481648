import React, { Fragment } from "react";

export const IconWrong = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        fill="red" className="bi bi-x-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </>
  );
};


export const IconCheck = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        fill="green" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
      </svg>
    </>
  );
};


export const IconAdress = () => {
  return (
    <Fragment>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H2C0.89 0 0 0.9 0 2ZM12 6C12 7.66 10.66 9 9 9C7.34 9 6 7.66 6 6C6 4.34 7.34 3 9 3C10.66 3 12 4.34 12 6ZM3 14C3 12 7 10.9 9 10.9C11 10.9 15 12 15 14V15H3V14Z"
          fill="#1275D8"
        />
      </svg>
    </Fragment>
  );
};
export const IconMail = () => {
  return (
    <Fragment>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
          fill="#1275D8"
        />
      </svg>
    </Fragment>
  );
};
export const IconTelephone = () => {
  return (
    <Fragment>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.01 12.38C15.78 12.38 14.59 12.18 13.48 11.82C13.13 11.7 12.74 11.79 12.47 12.06L10.9 14.03C8.07 12.68 5.42 10.13 4.01 7.2L5.96 5.54C6.23 5.26 6.31 4.87 6.2 4.52C5.83 3.41 5.64 2.22 5.64 0.99C5.64 0.45 5.19 0 4.65 0H1.19C0.65 0 0 0.24 0 0.99C0 10.28 7.73 18 17.01 18C17.72 18 18 17.37 18 16.82V13.37C18 12.83 17.55 12.38 17.01 12.38Z"
          fill="#1275D8"
        />
      </svg>
    </Fragment>
  );
};
export const IconLocation = ({ height = "14", width = "20" }) => {
  return (
    <Fragment>
      <svg
        width={height}
        height={width}
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.2806 19.5965C0.983281 11.3684 0 10.5239 0 7.5C0 3.35785 3.13399 0 7 0C10.866 0 14 3.35785 14 7.5C14 10.5239 13.0167 11.3684 7.7194 19.5965C7.37177 20.1345 6.6282 20.1345 6.2806 19.5965ZM7 10.625C8.61084 10.625 9.91667 9.2259 9.91667 7.5C9.91667 5.7741 8.61084 4.375 7 4.375C5.38916 4.375 4.08333 5.7741 4.08333 7.5C4.08333 9.2259 5.38916 10.625 7 10.625Z"
          fill="#1275D8"
        />
      </svg>
    </Fragment>
  );
};

export const SearchIcon = ({ fill = "#FF9E18", className = "" }) => {
  return (
    <Fragment>
      <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7552 15.5648L14.2499 12.0595C14.0917 11.9013 13.8772 11.8134 13.6522 11.8134H13.0791C14.0495 10.5723 14.6261 9.01121 14.6261 7.31304C14.6261 3.27329 11.3528 0 7.31303 0C3.27329 0 0 3.27329 0 7.31304C0 11.3528 3.27329 14.6261 7.31303 14.6261C9.01121 14.6261 10.5723 14.0495 11.8134 13.0791V13.6522C11.8134 13.8772 11.9013 14.0917 12.0595 14.2499L15.5648 17.7552C15.8953 18.0857 16.4297 18.0857 16.7567 17.7552L17.7517 16.7602C18.0822 16.4297 18.0822 15.8953 17.7552 15.5648ZM7.31303 11.8134C4.82731 11.8134 2.81271 9.80228 2.81271 7.31304C2.81271 4.82731 4.82379 2.81271 7.31303 2.81271C9.79876 2.81271 11.8134 4.82379 11.8134 7.31304C11.8134 9.79876 9.80228 11.8134 7.31303 11.8134Z"
          fill={fill}
        />
      </svg>
    </Fragment>
  );
};

export const DropArrow = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.61138 5.90769L0.160979 1.45728C-0.0536598 1.24264 -0.0536598 0.894656 0.160979 0.68004L0.680047 0.160974C0.89432 -0.0532985 1.24159 -0.0537109 1.45637 0.160057L5.00001 3.68709L8.54363 0.160057C8.75841 -0.0537109 9.10568 -0.0532985 9.31995 0.160974L9.83902 0.68004C10.0537 0.894679 10.0537 1.24266 9.83902 1.45728L5.38864 5.90769C5.174 6.12231 4.82602 6.12231 4.61138 5.90769Z"
        fill="white"
      />
    </svg>
  );
};
export const IconUser = ({ className, width = "40", height = "40" }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C15.79 10 14 11.79 14 14C14 16.21 15.79 18 18 18C20.21 18 22 16.21 22 14C22 11.79 20.21 10 18 10ZM20 14C20 12.9 19.1 12 18 12C16.9 12 16 12.9 16 14C16 15.1 16.9 16 18 16C19.1 16 20 15.1 20 14ZM24 23C23.8 22.29 20.7 21 18 21C15.3 21 12.2 22.29 12 23.01V24H24V23ZM10 23C10 20.34 15.33 19 18 19C20.67 19 26 20.34 26 23V26H10V23Z"
        fill="#1275D8"
      />
    </svg>
  );
};
export const IconNotification = ({ fill = "#FF9E18", className = "" }) => {
  return (
    <svg
      className={className}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z"
        fill={fill}
      />
    </svg>
  );
};

export const IconMarketPrice = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM16 9V11H8V9H16ZM16 13V15H8V13H16Z"
        fill="#1275D8"
      />
    </svg>
  );
};

export const IconCountElements = ({ className = "", fill = "#1275D8" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2.97053C4 3.60446 3.49107 4.11339 2.85714 4.11339C2.22321 4.11339 1.71429 3.60446 1.71429 2.97053C1.71429 2.3366 2.22321 1.82767 2.85714 1.82767C3.49107 1.82767 4 2.3366 4 2.97053ZM13.5268 8.11339C13.5268 7.80981 13.4018 7.51517 13.1964 7.30089L6.8125 0.925885C6.35714 0.470529 5.49107 0.113385 4.85714 0.113385H1.14286C0.517857 0.113385 0 0.631243 0 1.25624V4.97053C0 5.60446 0.357143 6.47053 0.8125 6.91696L7.19643 13.3098C7.40179 13.5152 7.69643 13.6402 8 13.6402C8.30357 13.6402 8.59822 13.5152 8.8125 13.3098L13.1964 8.91696C13.4018 8.7116 13.5268 8.41696 13.5268 8.11339Z"
        fill={fill}
      />
    </svg>
  );
};
export const IconBag = ({
  className = "",
  fill = "#1275D8",
  height = "22",
  width = "24",
}) => {
  return (
    <svg
      className="p-0"
      width={width}
      height={height}
      viewBox="5 -2 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5816 8.8115C15.0358 8.3069 15.3111 7.64531 15.3111 6.92097C15.3111 5.33797 13.996 4.05469 12.3736 4.05469C10.7513 4.05469 9.43612 5.33797 9.43612 6.92097C9.43612 7.64531 9.71148 8.3069 10.1656 8.8115H7.58594C7.10143 8.8115 6.68663 9.15883 6.6015 9.63579L5.02096 18.4911C4.91157 19.104 5.38281 19.6668 6.00541 19.6668H18.6725C19.2974 19.6668 19.7692 19.1001 19.656 18.4856L18.0245 9.63031C17.9371 9.15589 17.5234 8.8115 17.041 8.8115H14.5816ZM13.6863 6.92097C13.6863 7.62827 13.0987 8.20165 12.3738 8.20165C11.6489 8.20165 11.0613 7.62827 11.0613 6.92097C11.0613 6.21367 11.6489 5.64029 12.3738 5.64029C13.0987 5.64029 13.6863 6.21367 13.6863 6.92097Z"
        fill={fill}
      />
    </svg>
  );
};

export const EqualIcon = ({ className = "", height = "24", width = "24" }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM16 9V11H8V9H16ZM16 13V15H8V13H16Z"
        fill="#1275D8"
      />
    </svg>
  );
};

export const PigIcon = ({
  className = "",
  height = "64",
  width = "64",
  fill = "#25B27F",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M50.6667 25.3334L41.3334 16.0001C41.3334 14.2667 41.5734 12.5601 42.0267 10.9067C39.4401 11.5734 37.3334 13.4934 36.4534 16.0001H20.0001C14.8534 16.0001 10.6667 20.1867 10.6667 25.3334C10.6667 30.3467 13.9201 43.0667 16.0267 50.6667H21.3334V45.3334H37.3334V50.6667H42.6934L46.8267 36.9334L53.3334 34.7467V25.3334H50.6667ZM34.6667 24.0001H21.3334V18.6667H34.6667V24.0001ZM42.6667 29.3334C41.2001 29.3334 40.0001 28.1334 40.0001 26.6667C40.0001 25.2001 41.2001 24.0001 42.6667 24.0001C44.1334 24.0001 45.3334 25.2001 45.3334 26.6667C45.3334 28.1334 44.1334 29.3334 42.6667 29.3334Z"
          fill={fill}
        />
      </g>
      <path
        d="M39.9999 26.6667C39.9999 25.2 41.1999 24 42.6666 24C44.1333 24 45.3333 25.2 45.3333 26.6667C45.3333 28.1334 44.1333 29.3334 42.6666 29.3334C41.1999 29.3334 39.9999 28.1334 39.9999 26.6667ZM21.3333 24H34.6666V18.6667H21.3333V24ZM58.6666 20V38.5867L51.1466 41.0934L46.6666 56H31.9999V50.6667H26.6666V56H11.9999C11.9999 56 5.33325 33.44 5.33325 25.3334C5.33325 17.2267 11.8933 10.6667 19.9999 10.6667H33.3333C35.7599 7.44004 39.6266 5.33337 43.9999 5.33337C46.2133 5.33337 47.9999 7.12004 47.9999 9.33337C47.9999 9.89337 47.8932 10.4 47.6799 10.88C47.3066 11.7867 46.9866 12.8267 46.8266 13.9467L52.8799 20H58.6666ZM53.3333 25.3334H50.6666L41.3333 16C41.3333 14.2667 41.5733 12.56 42.0266 10.9067C39.4399 11.5734 37.3333 13.4934 36.4533 16H19.9999C14.8533 16 10.6666 20.1867 10.6666 25.3334C10.6666 30.3467 13.9199 43.0667 16.0266 50.6667H21.3333V45.3334H37.3333V50.6667H42.6933L46.8266 36.9334L53.3333 34.7467V25.3334Z"
        fill={fill}
      />
    </svg>
  );
};
export const ClockIcon = ({
  className = "",
  height = "64",
  width = "64",
  fill = "#7F62D3",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M32.0001 10.6666C20.2134 10.6666 10.6667 20.2133 10.6667 32C10.6667 43.7866 20.2134 53.3333 32.0001 53.3333C43.7867 53.3333 53.3334 43.7866 53.3334 32C53.3334 20.2133 43.7867 10.6666 32.0001 10.6666ZM43.3334 43.0666L29.3334 34.6666V18.6666H33.3334V32.6666L45.3334 39.7866L43.3334 43.0666Z"
        fill={fill}
      />
      <path
        d="M31.9733 5.33337C17.2533 5.33337 5.33325 17.28 5.33325 32C5.33325 46.72 17.2533 58.6667 31.9733 58.6667C46.7199 58.6667 58.6666 46.72 58.6666 32C58.6666 17.28 46.7199 5.33337 31.9733 5.33337ZM31.9999 53.3334C20.2133 53.3334 10.6666 43.7867 10.6666 32C10.6666 20.2134 20.2133 10.6667 31.9999 10.6667C43.7866 10.6667 53.3333 20.2134 53.3333 32C53.3333 43.7867 43.7866 53.3334 31.9999 53.3334ZM33.3333 18.6667H29.3333V34.6667L43.3333 43.0667L45.3333 39.7867L33.3333 32.6667V18.6667Z"
        fill={fill}
      />
    </svg>
  );
};
export const SecureIcon = ({
  className = "",
  height = "64",
  width = "64",
  fill = "#1275D8",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M16 16.8267V29.5733C16 40.24 22.8 50.1067 32 53.12C41.2 50.1067 48 40.2667 48 29.5733V16.8267L32 11.1733L16 16.8267ZM44.2667 26.3467L29.1733 41.44L19.7333 32L23.4933 28.24L29.1467 33.8933L40.4533 22.5867L44.2667 26.3467Z"
        fill={fill}
      />
      <path
        d="M32.0003 5.33337L10.667 13.3334V29.5734C10.667 43.04 19.7603 55.6 32.0003 58.6667C44.2403 55.6 53.3337 43.04 53.3337 29.5734V13.3334L32.0003 5.33337ZM48.0003 29.5734C48.0003 40.24 41.2003 50.1067 32.0003 53.12C22.8003 50.1067 16.0003 40.2667 16.0003 29.5734V16.8267L32.0003 11.1734L48.0003 16.8267V29.5734ZM23.5203 28.24L19.7337 32L29.1737 41.44L44.267 26.3467L40.507 22.5867L29.2003 33.8934L23.5203 28.24Z"
        fill={fill}
      />
    </svg>
  );
};
export const DownArrow = ({ className = "", fill = "#1275D8" }) => {
  return (
    <svg
      className={className}
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 6L0.73686 5.64421e-08L10.2631 8.89256e-07L5.5 6Z"
        fill={fill}
      />
    </svg>
  );
};

export const DeleteIcon = ({ className = "", fill = "#E03B3B" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
        fill={fill}
      />
    </svg>
  );
};
export const AddIcon = ({ className = "", fill = "" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z"
        fill="#1275D8"
      />
    </svg>
  );
};
export const UpIconRed = ({ className = "", fill = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 8V12H16L12 16L8 12H11V8H13Z"
        fill="#E03B3B"
      />
    </svg>
  );
};
export const IconUpGreen = ({ className = "", fill = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM11 16L11 12H8L12 8L16 12L13 12L13 16L11 16Z"
        fill="#5BB225"
      />
    </svg>
  );
};
export const VerifyIcon = ({ className = "", fill = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM10.09 16.72L6.29 12.91L7.77 11.43L10.09 13.76L15.94 7.89L17.42 9.37L10.09 16.72Z"
        fill="#5BB225"
      />
    </svg>
  );
};
export const InfoIcon = ({ className = "", fill = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8.75 11.75H7.25V7.25H8.75V11.75ZM8.75 5.75H7.25V4.25H8.75V5.75Z"
        fill="#1275D8"
      />
    </svg>
  );
};
