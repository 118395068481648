import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

import { postDte } from "../../../../api/private/client";
import { usePublicationsContext } from "../../../../context/PublicationsContext";

interface DteForm {
  dte: FileList;
}

const SellerModal: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm<DteForm>();
  const { modal, idPublication } = usePublicationsContext();

  const infoModal = () => {
    Swal.fire({
      title: "Enviando datos",
      text: "Aguarde unos instantes.",
      icon: "info",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  };

  const successModal = () => {
    return Swal.fire({
      title: "Datos enviados",
      text: "DTE cargado correctamente.",
      icon: "success",
    });
  };

  const errorModal = () => {
    return Swal.fire({
      title: "Error.",
      text: "Ha ocurrido un error al momento de cargar el DTE.",
      icon: "error",
    });
  };

  const onSubmit = async (data: DteForm) => {
    infoModal();
    try {
      await postDte({ id: idPublication, dte: data.dte[0] });
      await successModal();
      modal?.closeModal();
    } catch (err) {
      await errorModal();
    } finally {
      history.go(0);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0) {
      const file = files[0];
      const mb = Math.pow(10, 6); // MB
      const size = file.size / mb; // size in MB
      if (size > 5) {
        event.target.value = "";
        Swal.fire({
          title: "Tamaño excedido",
          text: "El tamaño del archivo es superior a 5MB.",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <h2 className="d-flex justify-content-center">
        Carga de DTE
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="dte">DTE</label>
          <input
            id="dte"
            className={`form-control-file ${errors.dte && "is-invalid"}`}
            name="dte"
            ref={register({ required: true })}
            onChange={(e) => handleFileChange(e)}
            type="file"
          />
          <div className="invalid-feedback">
            El DTE es requerido.
          </div>
        </div>
        <button className="btn btn-info" type="submit">Enviar</button>
      </form>
    </>
  );
};

export default SellerModal;
