// eslint-disable-next-line
import React, { useState } from 'react';

// Devuelve un manejador de estados simplificados.
// en el codigo podemos hacer por ejemplos
/*
const holaWord = useFormString('')
<Input
  placeholder='Hola'
  { ...holaWord }
  />
  y de esta forma automaticamente inserta el manejador de errores, el valor y manejador de estado.
*/
export function useFormString(initialValue, initialError = false) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(initialError);

  const handleChange = event => {
    if(!event)
      setValue('')
    else if (event.target)
      setValue(event.target.value);
    else
      setValue(event)
  }

  const handleError = state => setError(state);

  return {
    value,
    onChange: handleChange,
    error,
    handleError
  }
}

export function useFormNumber(initialValue, initialError = false) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(initialError);

  const handleChange = event => setValue(Number(event.target.value));

  const handleError = state => setError(state);

  return {
    value,
    onChange: handleChange,
    error,
    handleError,
    type: 'number'
  }
}

export function useFormBool(initialValue, initialError = false) {

  const [state, setState] = useState(initialValue);
  const [error, setError] = useState(initialError);

  const handleChange = event => setState(!state);

  const handleError = state => setError(state);

  return {
    value: state,
    onChange: handleChange,
    handleError,
    error
  }

}