import React from 'react';
import { useHistory } from "react-router";


import { ACTIONS } from '../../../hooks/profile/useProfile';
import Padding from '../../../components/padding/Padding';
import Translate from '../../../utils/translation/User.json';

const PersonalInformation = ({ profile, dispatch }) => {



    const handleName = e => {
        dispatch({ payload: e.target.value, type: ACTIONS.SET_FIRST_NAME });
    };

    const handleSurname = e => {
        dispatch({ payload: e.target.value, type: ACTIONS.SET_LAST_NAME });
    }

    const handleCuit = e => {
        dispatch({ payload: e.target.value, type: ACTIONS.SET_CUIT });
    }

    const handlePhone = e => {
        dispatch({ payload: e.target.value, type: ACTIONS.SET_PHONE });
    }

    return (
        <>
            <h2 className='col-12 d-flex justify-content-center mt-4 font-weight-bold'>
                {Translate.PERSON.PERSONAL_INFORMATION}
            </h2>
            <Padding>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_first_name'>
                        Nombre
                    </label>
                    <input
                        disabled={true}
                        id='id_first_name'
                        className='form-control'
                        name='name'
                        placeholder='Nombre'
                        value={profile.name}
                        onChange={e => handleName(e)}
                        type='text'
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='id_last_name' className='col-form-label'>
                        Apellido
                    </label>
                    <input
                        disabled={true}
                        id='id_last_name'
                        className='form-control'
                        name='surname'
                        placeholder='Apellido'
                        value={profile.surname}
                        onChange={e => handleSurname(e)}
                        type='text'
                    />
                </div>
            </Padding>
            {/* <Padding>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_cuit'>
                        CUIT
                    </label>
                    <input
                        id='id_cuit'
                        className='form-control'
                        name='cuit'
                        placeholder='CUIT'
                        value={profile.cuit}
                        onChange={handleCuit}
                        pattern='[0-9]{2}-?[0-9]{8}-?[0-9]'
                        type='text'
                        required={profile.company?.cuit ? false : true}
                    />
                    <div className='invalid-feedback'>
                        Este campo es obligatorio.
                    </div>
                </div>
            </Padding> */}
            <Padding>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_phone'>
                        Teléfono
                    </label>
                    <input
                        disabled={true}
                        id='id_phone'
                        className='form-control'
                        name='telephone'
                        placeholder='Teléfono'
                        value={profile.phone}
                        onChange={e => handlePhone(e)}
                        type='number'
                    />

                </div>
                <div className='form-group'>
                    <label className='col-form-label' htmlFor='id_email'>
                        Email
                    </label>
                    <input
                        id='id_email'
                        className='form-control'
                        name='mail'
                        placeholder='Email'
                        value={profile.mail}
                        readOnly
                        type='text'
                    />
                </div>
            </Padding>
        </>
    );
};

export default PersonalInformation;
