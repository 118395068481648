import { useState } from "react";

interface IModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

const useModal = (): IModal => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return { isOpen, closeModal, openModal };
};

export default useModal;
