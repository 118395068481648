import React, { createContext, useContext } from 'react';

import useProfile from '../hooks/profile/useProfile';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const profile = useProfile();

    return (
        <ProfileContext.Provider value={profile}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfileContext = () => useContext(ProfileContext);

export default ProfileProvider;
