import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router';
import './UsersPage.css';
import Tooltip from "@material-ui/core/Tooltip";
import TableItem from '../../../components/TableItem';
import { DownArrow } from '../../../components/Icons/Icons';
import { getAdminRoles, getAdminUsers } from '../../../api/private/client';

const _ = require( 'lodash' );

const UsersPage = ( { match } ) => {
    const filerIcon = `${ process.env.PUBLIC_URL }/images/filtericon.svg`;

    const [ data, setData ] = useState( null );
    const [ roles, setRoles ] = useState( null );
    const [ filters, setFilters ] = useState( {
        full_name: '',
        enabled: '',
        role: '',
    } );
    const [ showFilters, setShowFilters ] = useState( false );
    const [ resultsLoader, setResultsLoader ] = useState( false );
    const [ countPage, setCount ] = useState( 0 );
    const history = useHistory();

    // Filtros de orden:
    const [ orderbyId, setOrderById ] = useState( true );
    const [ orderbyName, setOrderByName ] = useState( false );
    const [ orderbyDate, setOrderByDate ] = useState( false );
    const [ orderbyEmail, setOrderByEmail ] = useState( false );
    const [ orderbyAdmin, setOrderByAdmin ] = useState( false );

    useEffect( () => {
        ( async () => {
            const page = 1;
            const { data } = await getAdminUsers( page );
            // const users = _.orderBy(data, 'id', 'desc');
            setData( data );
            const { data: roles } = await getAdminRoles();
            setRoles( roles );
        } )();
    }, [] );

    useEffect( () => {
        ( async () => {
            if ( data ) {
                let { data: users } = await getAdminUsers( 1 );
                if ( filters.full_name !== '' ) {
                    users = users.filter( user =>
                        user.full_name.toLowerCase().includes( filters.full_name.toLowerCase() ) );
                }
                if ( filters.enabled !== '' ) {
                    users = users.filter( user => user.enabled.toString() === filters.enabled );
                }
                if ( filters.role !== '' ) {
                    users = users.filter( user => {
                        let hasRole = false;
                        user.roles.forEach( role => {
                            if ( role.name.includes( filters.role ) ) {
                                hasRole = true;
                                return;
                            }
                        } );
                        return hasRole;
                    } );
                }
                setData( users );
            }
        } )();
    }, [ filters ] );

    const handlePage = ( event, value ) => {
        history.push( `/admin/users/${ value - 1 }` );
    };

    const handleFilter = () => {
        setShowFilters( show => !show );
    };

    const handleNameSerch = ( e ) => {
        setFilters( prevFilters => {
            return {
                ...prevFilters,
                full_name: e,
            };
        } );
    };

    const handleEnable = ( e ) => {
        setFilters( prevFilters => {
            return {
                ...prevFilters,
                enabled: e,
            };
        } );
    };

    const handleRoleSearch = ( e ) => {
        setFilters( prevFilters => {
            return {
                ...prevFilters,
                role: e,
            };
        } );
    };

    const handleOrderBy = ( key, direction ) => {
        const orderSetters = [
            setOrderById,
            setOrderByName,
            setOrderByDate,
            setOrderByEmail,
            setOrderByAdmin
        ];
        orderSetters.forEach( setter => setter( false ) );
        return _.orderBy( data, key, direction );
    };

    const handleOrderId = ( direction ) => {
        const data = handleOrderBy( 'id', direction );
        setData( data );
        setOrderById( !orderbyId );
    };

    const handleOrderName = ( direction ) => {
        const data = handleOrderBy( 'full_name', direction );
        setData( data );
        setOrderByName( !orderbyName );
    };

    const handleOrderDate = ( direction ) => {
        const data = handleOrderBy( 'creation_date', direction );
        setData( data );
        setOrderByDate( !orderbyDate );
    };

    const handleOrderEmail = ( direction ) => {
        const data = handleOrderBy( 'mail', direction );
        setData( data );
        setOrderByEmail( !orderbyEmail );
    };

    const handleByAdmin = ( direction ) => {
        const data = handleOrderBy( 'enabled', direction );
        setData( data );
        setOrderByAdmin( !orderbyAdmin );
    };

    return (
        <>
            <div className="container_Filter_Users">
                <div className="filterTitle" onClick={ handleFilter }>
                    <Tooltip title="Filtros" placement="top">
                        <img src={ filerIcon } alt="" />
                    </Tooltip>
                    <h1>Filtros</h1>
                </div>
                { showFilters && (
                    <div className="filtersModalContainer">
                        <div className="form-group">
                            <label for="searchbyName">Buscar por nombre</label>
                            <input
                                className="form-control"
                                id="searchbyName"
                                type="text"
                                aria-describedby="emailHelp"
                                placeholder="Ingrese un nombre"
                                onChange={ e => handleNameSerch( e.target.value ) }
                                value={ filters.full_name }
                            />
                        </div>
                        <div className="form-group">
                            <label for="searchbyState">Buscar por habilitación</label>
                            <select
                                className="form-control"
                                id="searchbyState"
                                onChange={ e => handleEnable( e.target.value ) }
                                value={ filters.enabled }
                            >
                                <option value={ "" }>-</option>
                                <option value={ true }>Habilitado</option>
                                <option value={ false }>Deshabilitado</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="searchbyState">Buscar por Rol</label>
                            <select
                                className="form-control"
                                id="searchbyState"
                                onChange={ e => handleRoleSearch( e.target.value ) }
                                value={ filters.role }
                            >
                                <option value={ "" }>-</option>
                                { roles &&
                                    roles.map( role => <option value={ role.name }>{ role.name.substring( 5 ) }</option> ) }
                            </select>
                        </div>
                    </div>
                ) }
            </div>
            <div style={ { minHeight: '70vh' } }>
                { !data ? (
                    <div className="col-12 mb-5 mt-5 text-center vh-100 align-items-center justify-content-center">
                        <div className="spinner-border text-info col-12" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <table className="table table-striped table-data table-responsive-md">
                        <thead>
                            <tr>
                                <th scope="col">
                                    ID
                                    { !orderbyId ? (
                                        <div className="pointer" onClick={ () => handleOrderId( 'asc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) : (
                                        <div className="pointer rotate" onClick={ () => handleOrderId( 'desc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) }
                                </th>
                                <th scope="col">
                                    Nombre
                                    { !orderbyName ? (
                                        <div className="pointer" onClick={ () => handleOrderName( 'asc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) : (
                                        <div className="pointer rotate" onClick={ () => handleOrderName( 'desc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) }
                                </th>
                                <th scope="col">
                                    Fecha de registro
                                    { !orderbyDate ? (
                                        <div className="pointer" onClick={ () => handleOrderDate( 'asc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) : (
                                        <div className="pointer rotate" onClick={ () => handleOrderDate( 'desc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) }
                                </th>
                                <th scope="col" >
                                    Email
                                    { !orderbyEmail ? (
                                        <div className="pointer" onClick={ () => handleOrderEmail( 'asc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) : (
                                        <div className="pointer rotate" onClick={ () => handleOrderEmail( 'desc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) }
                                </th>
                                <th scope="col">
                                    Administrar
                                    { !orderbyAdmin ? (
                                        <div className="pointer" onClick={ () => handleByAdmin( 'asc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) : (
                                        <div className="pointer rotate" onClick={ () => handleByAdmin( 'desc' ) }>
                                            <DownArrow />
                                        </div>
                                    ) }
                                </th>
                                <th scope="col">
                                    <div style={ { marginTop: '-1.3rem' } }>Ver</div>
                                </th>
                                <th scope="col" >
                                    <div style={ { marginTop: '-1.3rem' } }>Tipo de rol</div>
                                </th>
                                <th scope="col">
                                    <div style={ { marginTop: '-1.3rem' } }>Notificar</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { ( roles && data.length ) > 0 ? (
                                data.map( item =>
                                    <TableItem
                                        key={ item.id }
                                        item={ item }
                                        setResultsLoader={ setResultsLoader }
                                        rolesAvailable={ roles }
                                    /> )
                            ) : (
                                <div className="w-100 text-center">
                                    No se encontraron registros, intenta con otros parámetros.
                                </div>
                            ) }
                        </tbody>
                    </table>
                ) }
            </div>
            <div className='col-12 d-flex justify-content-center'>
                <Pagination
                    style={ { padding: '30px' } }
                    count={ countPage }
                    size="large"
                    page={ match.params.page + 1 }
                    onChange={ handlePage }
                />
            </div>
        </>
    );
}

export default UsersPage;
