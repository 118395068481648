import React from "react";

// Components
import { Typography } from "@material-ui/core";
import CarouselPublication from "../../../../../../components/CarouselPublication/Carousel";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

function Media(
  {
    catalogue,
    setCatalogue,
    videoFile,
    setVideoFile,
    videoFileURL,
    setVideoFileURL,
  },
) {
  const removeImg = ( i ) => {
    const newCatalogue = [ ...catalogue ];
    newCatalogue.splice( i, 1 );
    setCatalogue( newCatalogue );
  };

  //FUNCTION DROP:
  const onDrop = ( acceptedFiles ) => {
    const newCatalogue = [ ...catalogue ];
    acceptedFiles.forEach( async ( acceptedFile ) => {
      newCatalogue.push( {
        preview: URL.createObjectURL( acceptedFile ),
        file: acceptedFile,
      } );
    } );
    setCatalogue( newCatalogue );
  };

  const { getInputProps, getRootProps } = useDropzone( {
    accept: "image/jpeg,image/png",
    maxFiles: 5,
    maxSize: 3000000,
    onDropRejected: () =>
      Swal.fire( "Solo se permiten imagenes jpg | png de hasta 3 Mb" ),
    onDrop: onDrop,
    multiple: false,
  } );

  return (
    <>
      {/* Catalogue */ }
      { catalogue.length !== 0
        ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <CarouselPublication
                autoPlay={ true }
                imgs={ catalogue.map( ( e ) => e.preview ) }
                showStatus={ true }
                showIndicators={ false }
                remove={ ( i ) => removeImg( i ) }
                height={ true }
              />
            </div>
          </div>
        )
        : (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img
                className="img-fluid"
                style={ { width: "300px" } }
                src="https://i5.walmartimages.com/asr/d1eb5073-b4a4-46e9-8fa6-8fd0ce6ad5b3_1.5f2eb7a726f1bedf74ec0ddb15e28991.jpeg"
              />
            </div>
          </div>
        ) }
      <div className="row d-flex justify-content-center">
        <div className="col-12">
          <div { ...getRootProps() }>
            <input { ...getInputProps() } />
            <br />
            <Typography
              className="text-center form-control mb-3 custom_height"
              variant="body1"
              fontWeight="bold"
            >
              Arrastra tus imagenes aquí | solo jpeg, jpg | tamaño máximo: 3Mb
            </Typography>
            <button
              type="button"
              className="btn btn-info btn-block mx-auto w-100"
            >
              Cargar imagenes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
