import React from 'react';
import { useHistory } from 'react-router';
import Container from '../../components/container/Container';
import { IconWrong } from '../../components/Icons/Icons';
import routers from '../../config/routers';


export const PaymentError = () => {

  const history = useHistory();

  const { innerHeight: height } = window;

  return (
    <>
      <div className='"vh-100 d-inline-block"'
        style={{ width: 100, height: height / 6, backgroundColor: 'white' }}
      >
      </div>

      <Container className="rounded border-success p-5 m-5" centerElement={true}>
        <>
          <IconWrong></IconWrong>
          <h1>La transacción no pudo completarse.</h1>
          <h3>Por favor intente nuevamente.</h3>

          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => history.push(routers.home.path)}> Volver al Home </button>
          </div>

        </>
      </Container>
      <div className='"vh-100 d-inline-block"'
        style={{ width: 100, height: height / 6, backgroundColor: 'white' }}
      >
      </div>
    </>
  );
};

